import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
    HostingType,
    Period,
    ProductActive,
    RegisteredCompany,
    UpdateHostingAdminInput,
    useMutationUpdateHostingAdminMutation,
    useQueryFindFirstHostingAdminQuery,
} from "../../../../graphql/operation";
import { SwalUtil } from "../../../../utils/swal/SwalUtil";
import { ErrorCatchUtil } from "../../../../utils/errorCatch/ErrorCatchUtil";
import { ErrorConstants } from "../../../../variables/ErrorConstants";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LimitWidthContainer } from "../../../components/limitWidthContainer/LimitWidthContainer";
import { TitleBox } from "../../../components/titleBox/TitleBox";
import { InputLine } from "../../../components/inputLine/InputLine";
import {
    Button,
    Checkbox,
    Divider,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
} from "@mui/material";
import { RegisteredCompanySelect } from "../../../components/RegisteredCompanySelect/RegisteredCompanySelect";
import moment from "moment";
import { PeriodSelect } from "../../../components/PeriodSelect/PeriodSelect";
import NumberFormat from "react-number-format";
import { EditorBox } from "../../../components/editorBox/EditorBox";
import { HostingTypeSelect } from "../../../components/hostingTypeSelect/HostingTypeSelect";

export const ViewHostingUpdate = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    const initInputData: UpdateHostingAdminInput = {
        productActive: ProductActive.Active,
        checkInfo: false,
        domainUrl: "",
        hostingType: HostingType.LinuxBasic,
        id: Number(id),
        period: Period.Annual,
        price: 0,
        registeredCompany: RegisteredCompany.Goldennet,
    };

    const [inputData, setInputData] = useState<UpdateHostingAdminInput>(initInputData);
    const [inputMemo, setInputMemo] = useState<string>("");
    const { data: hostingData } = useQueryFindFirstHostingAdminQuery({
        variables: {
            input: {
                id: Number(id),
            },
        },
    });
    const [updateHostingAdmin] = useMutationUpdateHostingAdminMutation();

    useEffect(() => {
        if (hostingData) {
            setInputData({
                productActive: hostingData.findFirstHostingAdmin.productActive,
                addStorage: hostingData.findFirstHostingAdmin.hostingInfo?.addStorage,
                checkInfo: false,
                createdAt: hostingData.findFirstHostingAdmin.createdAt,
                domainUrl: hostingData.findFirstHostingAdmin.domainUrl,
                ftpId: hostingData.findFirstHostingAdmin.hostingInfo?.ftpId,
                ftpIp: hostingData.findFirstHostingAdmin.hostingInfo?.ftpIp,
                ftpPassword: hostingData.findFirstHostingAdmin.hostingInfo?.ftpPassword,
                hostingType: hostingData.findFirstHostingAdmin.hostingType,
                id: Number(id),
                lastBillingDate: hostingData.findFirstHostingAdmin.lastBillingDate,
                memo: hostingData.findFirstHostingAdmin.memo,
                mysqlDatabase: hostingData.findFirstHostingAdmin.hostingInfo?.mysqlDatabase,
                mysqlId: hostingData.findFirstHostingAdmin.hostingInfo?.mysqlId,
                mysqlPassword: hostingData.findFirstHostingAdmin.hostingInfo?.mysqlPassword,
                nextBillingDate: hostingData.findFirstHostingAdmin.nextBillingDate,
                period: hostingData.findFirstHostingAdmin.period,
                price: hostingData.findFirstHostingAdmin.price,
                registeredCompany: hostingData.findFirstHostingAdmin.registeredCompany,
                userId: hostingData.findFirstHostingAdmin.userId,
            });
            setInputMemo(hostingData.findFirstHostingAdmin.memo);
        }
    }, [hostingData]);

    const handler = {
        onUpdate: () => {
            updateHostingAdmin({
                variables: {
                    input: {
                        ...inputData,
                        memo: inputMemo,
                    },
                },
            })
                .then(() => {
                    return SwalUtil.ok({
                        title: "상품 정보가 수정되었습니다.",
                        icon: "success",
                    });
                })
                .then(() => {
                    navigate(-1);
                })
                .catch(
                    ErrorCatchUtil({
                        [ErrorConstants.notPermission]: () => {
                            return SwalUtil.ok({
                                title: "권한이 없습니다.",
                                icon: "error",
                            });
                        },
                        [ErrorConstants.noData]: () => {
                            return SwalUtil.ok({
                                title: "수정하려는 상품이 없습니다.",
                                icon: "error",
                            });
                        },
                        [ErrorConstants.default]: e => {
                            return SwalUtil.ok({
                                title: e,
                                icon: "error",
                            });
                        },
                    }),
                );
        },
        onCancel: () => {
            navigate(-1);
        },
    };

    return (
        <>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <LimitWidthContainer>
                    <TitleBox title="호스팅 - 수정" />
                    <div className="bg-lightPaper dark:bg-darkPaper p-5">
                        <InputLine title={"도메인"}>
                            <TextField
                                variant="outlined"
                                label="도메인"
                                color="primary"
                                fullWidth
                                size="small"
                                value={inputData.domainUrl}
                                onChange={value => {
                                    setInputData({
                                        ...inputData,
                                        domainUrl: value.currentTarget.value,
                                    });
                                }}
                            />
                        </InputLine>
                        <InputLine title="호스팅 종류">
                            <HostingTypeSelect
                                hostingType={inputData.hostingType}
                                onChangeState={value => {
                                    setInputData({
                                        ...inputData,
                                        hostingType: value,
                                    });
                                }}
                            />
                        </InputLine>
                        <InputLine title={"등록기관"}>
                            <RegisteredCompanySelect
                                registeredCompany={inputData.registeredCompany}
                                onChangeState={value => {
                                    setInputData({
                                        ...inputData,
                                        registeredCompany: value,
                                    });
                                }}
                            />
                        </InputLine>
                        <InputLine title="납부주기">
                            <PeriodSelect
                                period={inputData.period}
                                onChangeState={value => {
                                    setInputData({
                                        ...inputData,
                                        period: value,
                                    });
                                }}
                            />
                        </InputLine>
                        <InputLine title="시작일">
                            <MobileDatePicker
                                onChange={value => {
                                    setInputData({
                                        ...inputData,
                                        createdAt: moment(value).utc().toISOString(),
                                    });
                                }}
                                value={inputData.createdAt}
                                renderInput={params => (
                                    <TextField fullWidth size="small" {...params} />
                                )}
                            />
                        </InputLine>
                        <InputLine title="다음결제일">
                            <MobileDatePicker
                                onChange={value => {
                                    setInputData({
                                        ...inputData,
                                        nextBillingDate: moment(value).utc().toISOString(),
                                    });
                                }}
                                value={inputData.nextBillingDate}
                                renderInput={params => (
                                    <TextField fullWidth size="small" {...params} />
                                )}
                            />
                        </InputLine>
                        <InputLine title="가격">
                            <NumberFormat
                                thousandSeparator={","}
                                suffix=" 원"
                                customInput={TextField}
                                fullWidth
                                size="small"
                                value={inputData.price}
                                onValueChange={value => {
                                    setInputData({
                                        ...inputData,
                                        price: Number(value.value),
                                    });
                                }}
                            />
                        </InputLine>
                        <InputLine title="메모">
                            <EditorBox
                                defaultValue={inputMemo}
                                onChange={value => {
                                    setInputMemo(value);
                                }}
                            />
                        </InputLine>
                        <InputLine title="상태">
                            <ToggleButtonGroup
                                color="primary"
                                size={"small"}
                                value={inputData.productActive}>
                                <ToggleButton
                                    className="w-[90px]"
                                    value={ProductActive.Active}
                                    onClick={(event, value: ProductActive) => {
                                        setInputData({
                                            ...inputData,
                                            productActive: value,
                                        });
                                    }}>
                                    정상
                                </ToggleButton>
                                <ToggleButton
                                    value={ProductActive.Pause}
                                    className="w-[90px]"
                                    onClick={(event, value: ProductActive) => {
                                        setInputData({
                                            ...inputData,
                                            productActive: value,
                                        });
                                    }}>
                                    정지
                                </ToggleButton>
                                <ToggleButton
                                    value={ProductActive.Stop}
                                    className="w-[90px]"
                                    onClick={(event, value: ProductActive) => {
                                        setInputData({
                                            ...inputData,
                                            productActive: value,
                                        });
                                    }}>
                                    만료
                                </ToggleButton>
                                <ToggleButton
                                    value={ProductActive.Delete}
                                    className="w-[90px]"
                                    onClick={(event, value: ProductActive) => {
                                        setInputData({
                                            ...inputData,
                                            productActive: value,
                                        });
                                    }}>
                                    삭제
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </InputLine>

                        <Divider className="my-5" />

                        <InputLine title={"호스팅 정보 수정"}>
                            <Checkbox
                                checked={inputData.checkInfo}
                                color="primary"
                                onChange={value => {
                                    setInputData({
                                        ...inputData,
                                        checkInfo: value.target.checked,
                                    });
                                }}
                                inputProps={{ "aria-label": "호스팅 정보 수정" }}
                            />{" "}
                            호스팅 정보 수정
                        </InputLine>

                        {inputData.checkInfo && (
                            <HostingInfoContainer
                                inputData={inputData}
                                setInputData={setInputData}
                            />
                        )}

                        <div className="my-10" />
                        <div className="flex w-full justify-center gap-10">
                            <Button
                                variant="contained"
                                color="primary"
                                className="w-[120px]"
                                onClick={handler.onUpdate}>
                                정보수정
                            </Button>
                            <Button
                                variant="contained"
                                color="secondary"
                                className="w-[120px]"
                                onClick={handler.onCancel}>
                                취소
                            </Button>
                        </div>
                    </div>
                </LimitWidthContainer>
            </LocalizationProvider>
        </>
    );
};

const HostingInfoContainer = (props: {
    inputData: UpdateHostingAdminInput;
    setInputData: React.Dispatch<React.SetStateAction<UpdateHostingAdminInput>>;
}) => {
    return (
        <>
            <InputLine title={"FTP IP"}>
                <TextField
                    variant="outlined"
                    label="FTP IP"
                    color="primary"
                    fullWidth
                    size="small"
                    value={props.inputData.ftpIp}
                    onChange={value => {
                        props.setInputData({
                            ...props.inputData,
                            ftpIp: value.currentTarget.value,
                        });
                    }}
                />
            </InputLine>
            <InputLine title={"FTP ID"}>
                <TextField
                    variant="outlined"
                    label="FTP ID"
                    color="primary"
                    fullWidth
                    size="small"
                    value={props.inputData.ftpId}
                    onChange={value => {
                        props.setInputData({
                            ...props.inputData,
                            ftpId: value.currentTarget.value,
                        });
                    }}
                />
            </InputLine>
            <InputLine title={"FTP Password"}>
                <TextField
                    variant="outlined"
                    label="FTP Password"
                    color="primary"
                    fullWidth
                    size="small"
                    value={props.inputData.ftpPassword}
                    onChange={value => {
                        props.setInputData({
                            ...props.inputData,
                            ftpPassword: value.currentTarget.value,
                        });
                    }}
                />
            </InputLine>
            <InputLine title={"Database Name"}>
                <TextField
                    variant="outlined"
                    label="Database Name"
                    color="primary"
                    fullWidth
                    size="small"
                    value={props.inputData.mysqlDatabase}
                    onChange={value => {
                        props.setInputData({
                            ...props.inputData,
                            mysqlDatabase: value.currentTarget.value,
                        });
                    }}
                />
            </InputLine>
            <InputLine title={"Database User"}>
                <TextField
                    variant="outlined"
                    label="Database User"
                    color="primary"
                    fullWidth
                    size="small"
                    value={props.inputData.mysqlId}
                    onChange={value => {
                        props.setInputData({
                            ...props.inputData,
                            mysqlId: value.currentTarget.value,
                        });
                    }}
                />
            </InputLine>
            <InputLine title={"Database Password"}>
                <TextField
                    variant="outlined"
                    label="Database Password"
                    color="primary"
                    fullWidth
                    size="small"
                    value={props.inputData.mysqlPassword}
                    onChange={value => {
                        props.setInputData({
                            ...props.inputData,
                            mysqlPassword: value.currentTarget.value,
                        });
                    }}
                />
            </InputLine>
        </>
    );
};
