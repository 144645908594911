import React from "react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { OnChangeString } from "../../../types/ArgsInterface";
import { EditorConfig } from "@ckeditor/ckeditor5-core/src/editor/editorconfig";
import { useReactiveVar } from "@apollo/client";
import { LayoutVars } from "../../../variables/MakeVar";

export const EditorBox = (props: { defaultValue: string; onChange: OnChangeString }) => {
    const isMobile = useReactiveVar(LayoutVars.isMobile);

    const editorConfigurationForMobile: EditorConfig = {
        toolbar: ["FontColor", "FontSize", "Bold", "Italic"],
        language: "ko",
    };

    const editorConfiguration: EditorConfig = {
        toolbar: [
            "SourceEditing",
            "undo",
            "redo",
            "|",
            "FontColor",
            "FontSize",
            "|",
            "Code",
            "Bold",
            "Italic",
            "Link",
            "BlockQuote",
            "BulletedList",
            "NumberedList",
            "|",
            "Alignment",
            "Outdent",
            "Indent",
            "|",
            "ImageUpload",
            "MediaEmbed",
        ],
        language: "ko",
    };

    return (
        <>
            <div style={{ color: "black", width: "100%" }}>
                {isMobile && (
                    <CKEditor
                        editor={ClassicEditor}
                        data={props.defaultValue}
                        config={editorConfigurationForMobile}
                        onChange={(event, editor) => {
                            const content = editor.getData();
                            props.onChange(content);
                        }}
                    />
                )}
                {!isMobile && (
                    <CKEditor
                        editor={ClassicEditor}
                        data={props.defaultValue}
                        config={editorConfiguration}
                        onChange={(event, editor) => {
                            const content = editor.getData();
                            props.onChange(content);
                        }}
                    />
                )}
            </div>
        </>
    );
};
