import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
    QueryCountPaymentForDomainAdminQuery,
    QueryFindFirstDomainAdminQuery,
    QueryFindManyPaymentForDomainAdminQuery,
    useMutationDeletePaymentAdminMutation,
    useQueryCountPaymentForDomainAdminQuery,
    useQueryFindFirstDomainAdminQuery,
    useQueryFindManyPaymentForDomainAdminQuery,
} from "../../../../graphql/operation";
import { TitleBox } from "../../../components/titleBox/TitleBox";
import { NoDataBox } from "../../../components/noDataBox/NoDataBox";
import { LimitWidthContainer } from "../../../components/limitWidthContainer/LimitWidthContainer";
import { LeftUserContainer } from "../../../components/leftUserContainer/LeftUserContainer";
import { Button, IconButton, Tooltip } from "@mui/material";
import { InputLine } from "../../../components/inputLine/InputLine";
import { RegisteredCompanyText } from "../../../components/RegisteredCompanyText/RegisteredCompanyText";
import NumberFormat from "react-number-format";
import moment from "moment";
import { OnChangeNumber } from "../../../../types/ArgsInterface";
import { PaymentMethodText } from "../../../components/paymentMethodText/PaymentMethodText";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBookOpen, faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import { ViewPaginationBox } from "../../../components/paginationBox/ViewPagenationBox";
import { SwalUtil } from "../../../../utils/swal/SwalUtil";
import { ErrorCatchUtil } from "../../../../utils/errorCatch/ErrorCatchUtil";
import { ErrorConstants } from "../../../../variables/ErrorConstants";
import { ProductActiveText } from "../../../components/productActiveText/ProductActiveText";

export const ViewDomainDetail = () => {
    const { id } = useParams();

    const initPagination = {
        take: 10,
        page: 1,
    };

    const [paginationData, setPaginationData] = useState(initPagination);

    const { data: domainData, error: domainError } = useQueryFindFirstDomainAdminQuery({
        variables: {
            input: {
                id: Number(id),
            },
        },
    });

    const { data: paymentData, refetch: paymentRefetch } =
        useQueryFindManyPaymentForDomainAdminQuery({
            variables: {
                input: {
                    domainId: Number(id),
                },
            },
        });

    const { data: countData } = useQueryCountPaymentForDomainAdminQuery({
        variables: {
            input: {
                domainId: Number(id),
            },
        },
    });

    const [deletePaymentAdminMutation] = useMutationDeletePaymentAdminMutation();

    const handler = {
        onChangePage: (page: number) => {
            setPaginationData({
                take: paginationData.take,
                page,
            });
        },
        onClickDeletePayment: (id: number) => {
            SwalUtil.yn({
                title: "정말 삭제하시겠습니까?",
                text: "복구가 불가능합니다.",
                icon: "warning",
            })
                .then(() => {
                    return deletePaymentAdminMutation({
                        variables: {
                            input: {
                                paymentId: id,
                            },
                        },
                    });
                })
                .then(() => {
                    return SwalUtil.ok({
                        title: "납부 정보가 삭제 되었습니다.",
                        icon: "success",
                    });
                })
                .then(() => {
                    return paymentRefetch();
                })
                .catch(
                    ErrorCatchUtil({
                        [ErrorConstants.notPermission]: () => {
                            return SwalUtil.ok({
                                title: "권한이 없습니다.",
                                icon: "error",
                            });
                        },
                        [ErrorConstants.default]: e => {
                            return SwalUtil.ok({
                                title: e,
                                icon: "error",
                            });
                        },
                    }),
                );
        },
    };

    return (
        <>
            <LimitWidthContainer>
                <TitleBox title="도메인" />
                {domainError && (
                    <div className="bg-lightPaper dark:bg-darkPaper p-5 rounded">
                        <NoDataBox />
                    </div>
                )}

                {domainData && (
                    <div className="w-full flex justify-between gap-10">
                        <div className="w-[300px]">
                            <div className="bg-lightPaper dark:bg-darkPaper p-5  rounded w-full">
                                <LeftUserContainer
                                    userId={domainData.findFirstDomainAdmin.userId}
                                />
                            </div>
                        </div>
                        <div className="w-[900px]">
                            <div className="w-full bg-lightPaper dark:bg-darkPaper p-5 rounded">
                                <DomainDetailContainer
                                    domainData={domainData}
                                    domainId={Number(id)}
                                />
                            </div>
                            <div className="mb-10" />
                            <div className="w-full bg-lightPaper dark:bg-darkPaper p-5 rounded">
                                <DomainPaymentContainer
                                    paymentData={paymentData}
                                    countData={countData}
                                    domainId={Number(id)}
                                    onClickDeletePayment={handler.onClickDeletePayment}
                                />
                                {countData && (
                                    <>
                                        <div className="mb-10" />
                                        <ViewPaginationBox
                                            total={countData.countPaymentForDomainAdmin}
                                            size={paginationData.take}
                                            page={paginationData.page}
                                            onChange={handler.onChangePage}
                                        />
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                )}
            </LimitWidthContainer>
        </>
    );
};

const DomainDetailContainer = (props: {
    domainData: QueryFindFirstDomainAdminQuery | undefined;
    domainId: number;
}) => {
    const navigate = useNavigate();

    const handler = {
        onUpdate: () => {
            navigate(`/domain/update/${props.domainId}`);
        },
    };

    return (
        <>
            {!props.domainData && <NoDataBox />}
            {props.domainData && (
                <>
                    <div className="w-full flex justify-between">
                        <div className="flex-1">
                            <TitleBox title={"상세정보"} notBottomMargin={true} />
                        </div>
                        <div className="flex items-center gap-5">
                            <Button variant="contained" color="primary" onClick={handler.onUpdate}>
                                정보수정
                            </Button>
                            {props.domainData.findFirstDomainAdmin.userId ? (
                                <Button variant="contained" color="warning">
                                    연결해제
                                </Button>
                            ) : (
                                <Button variant="contained" color="primary">
                                    회원연결
                                </Button>
                            )}
                        </div>
                    </div>
                    <InputLine title="도메인">
                        {props.domainData.findFirstDomainAdmin.domainUrl}
                    </InputLine>
                    <InputLine title="등록기관">
                        <RegisteredCompanyText
                            registeredCompany={
                                props.domainData.findFirstDomainAdmin.registeredCompany
                            }
                        />
                    </InputLine>
                    <InputLine title={"가격"}>
                        <NumberFormat
                            displayType="text"
                            thousandSeparator=","
                            suffix=" 원"
                            value={props.domainData.findFirstDomainAdmin.price}
                        />
                    </InputLine>
                    <InputLine title="시작일">
                        {props.domainData.findFirstDomainAdmin.createdAt
                            ? moment(props.domainData.findFirstDomainAdmin.createdAt)
                                  .locale("ko")
                                  .format("YYYY-MM-DD")
                            : "-"}
                    </InputLine>
                    <InputLine title="마지막 결제일">
                        {props.domainData.findFirstDomainAdmin.lastBillingDate
                            ? moment(props.domainData.findFirstDomainAdmin.lastBillingDate)
                                  .locale("ko")
                                  .format("YYYY-MM-DD")
                            : "-"}
                    </InputLine>
                    <InputLine title="다음 청구일">
                        {props.domainData.findFirstDomainAdmin.nextBillingDate
                            ? moment(props.domainData.findFirstDomainAdmin.nextBillingDate)
                                  .locale("ko")
                                  .format("YYYY-MM-DD")
                            : "-"}
                    </InputLine>
                    <InputLine title="상태">
                        <ProductActiveText
                            productActive={props.domainData.findFirstDomainAdmin.productActive}
                        />
                    </InputLine>
                    <div
                        className="w-full max-h-[100px] border p-5 overflow-y-auto"
                        dangerouslySetInnerHTML={{
                            __html: props.domainData.findFirstDomainAdmin.memo
                                ? props.domainData.findFirstDomainAdmin.memo
                                : "메모없음",
                        }}></div>
                </>
            )}
        </>
    );
};

const DomainPaymentContainer = (props: {
    paymentData: QueryFindManyPaymentForDomainAdminQuery | undefined;
    countData: QueryCountPaymentForDomainAdminQuery | undefined;
    domainId: number;
    onClickDeletePayment: OnChangeNumber;
}) => {
    const navigate = useNavigate();

    return (
        <>
            <div className="w-full flex justify-between mb-5">
                <div className="flex-1">
                    <TitleBox title="납부목록" notBottomMargin={true} />
                </div>
                <div className="flex gap-5">
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            navigate(`/payment/create/domain/${props.domainId}`);
                        }}>
                        납부등록
                    </Button>
                </div>
            </div>
            <div className="bg-lightBackground dark:bg-darkBackground rounded text-sm h-[35px] items-center flex">
                <div className="w-[20%]">상품금액</div>
                <div className="w-[12.5%]">결제일</div>
                <div className="w-[12.5%]">결제방법</div>
                <div className="w-[15%]">입금인</div>
                <div className="w-[25%]">메모</div>
                <div className="w-[15%]">기능</div>
            </div>
            {!props.paymentData && <NoDataBox />}
            {props.paymentData?.findManyPaymentForDomainAdmin.map((value, index) => (
                <div key={index} className="w-full flex h-[45px] border-b items-center">
                    {value && (
                        <>
                            <div className="w-[20%]">
                                <NumberFormat
                                    thousandSeparator=","
                                    suffix=" 원"
                                    displayType="text"
                                    value={value.price}
                                />
                            </div>
                            <div className="w-[12.5%]">
                                {moment(value.createdAt).locale("ko").format("YYYY-MM-DD")}
                            </div>
                            <div className="w-[12.5%]">
                                <PaymentMethodText paymentMethod={value.paymentMethod} />
                            </div>
                            <div className="w-[15%]">{value.depositor && value.depositor}</div>
                            <div
                                className="w-[25%] truncate"
                                dangerouslySetInnerHTML={{
                                    __html: value.memo,
                                }}
                            />
                            <div className="w-[15%] justify-between">
                                <Tooltip title="상세보기">
                                    <IconButton
                                        color="primary"
                                        onClick={() => {
                                            navigate(`/payment/detail/${value.id}`);
                                        }}>
                                        <FontAwesomeIcon icon={faBookOpen} size="sm" />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="수정" arrow>
                                    <IconButton
                                        color="primary"
                                        onClick={() => {
                                            navigate(`/payment/update/domain/${value.id}`);
                                        }}>
                                        <FontAwesomeIcon icon={faPen} size="sm" />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="삭제" arrow>
                                    <IconButton
                                        color="primary"
                                        onClick={() => {
                                            props.onClickDeletePayment(value.id);
                                        }}>
                                        <FontAwesomeIcon icon={faTrash} size="sm" />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </>
                    )}
                </div>
            ))}
        </>
    );
};
