import React from "react";

export const TitleBox = (props: { title: string; notBottomMargin?: boolean }) => {
    return (
        <>
            <div className="w-full px-5 lg:px-0">
                <div className="text-bold text-2xl">{props.title}</div>
                <div className="w-[20px] border-t-2 border-yellow-300" />
                {!props.notBottomMargin && <div className="mb-10" />}
            </div>
        </>
    );
};
