import React from "react";
import { PaymentMethod } from "../../../graphql/operation";
import { OnChangeValue } from "../../../types/ArgsInterface";
import { MenuItem, TextField } from "@mui/material";

export const PaymentMethodSelect = (props: {
    paymentMethod: PaymentMethod;
    onChangeState: OnChangeValue<PaymentMethod>;
    readOnly?: boolean;
}) => {
    return (
        <>
            <TextField
                variant="outlined"
                label="결제방법"
                color="primary"
                fullWidth
                select
                value={props.paymentMethod}
                inputProps={{ readOnly: props.readOnly || false }}
                size="small"
                onChange={value => {
                    let paymentMethod: PaymentMethod;
                    if (value.target.value === "Card") {
                        paymentMethod = PaymentMethod.Card;
                    } else if (value.target.value === "WithBank") {
                        paymentMethod = PaymentMethod.WithBank;
                    } else if (value.target.value === "VirtualBank") {
                        paymentMethod = PaymentMethod.VirtualBank;
                    } else if (value.target.value === "Mobile") {
                        paymentMethod = PaymentMethod.Mobile;
                    } else {
                        paymentMethod = PaymentMethod.WithoutBank;
                    }
                    props.onChangeState(paymentMethod);
                }}>
                <MenuItem value={PaymentMethod.Card}>카드결제</MenuItem>
                <MenuItem value={PaymentMethod.WithBank}>계좌이체</MenuItem>
                <MenuItem value={PaymentMethod.VirtualBank}>가상계좌</MenuItem>
                <MenuItem value={PaymentMethod.Mobile}>휴대폰결제</MenuItem>
                <MenuItem value={PaymentMethod.WithoutBank}>무통장입금</MenuItem>
            </TextField>
        </>
    );
};
