import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
    UpdateEmailAccountAdminInput,
    useMutationUpdateEmailAccountAdminMutation,
    useQueryFindFirstEmailAccountAdminQuery,
} from "../../../../../graphql/operation";
import { SwalUtil } from "../../../../../utils/swal/SwalUtil";
import { ErrorCatchUtil } from "../../../../../utils/errorCatch/ErrorCatchUtil";
import { ErrorConstants } from "../../../../../variables/ErrorConstants";
import { TitleBox } from "../../../../components/titleBox/TitleBox";
import { InputLine } from "../../../../components/inputLine/InputLine";
import { Button, Checkbox, TextField } from "@mui/material";
import NumberFormat from "react-number-format";
import { EditorBox } from "../../../../components/editorBox/EditorBox";
import { LimitWidthContainer } from "../../../../components/limitWidthContainer/LimitWidthContainer";

export const ViewEmailAccountUpdate = () => {
    const navigate = useNavigate();
    const { emailAccountId } = useParams();

    const initEmailAccountData: UpdateEmailAccountAdminInput = {
        account: "",
        checkRequest: false,
        emailId: 0,
        freeQuota: 0,
        id: Number(emailAccountId),
        memo: "",
        quota: 0,
    };

    const [inputData, setInputData] = useState<UpdateEmailAccountAdminInput>(initEmailAccountData);
    const [inputMemo, setInputMemo] = useState<string>("");
    const { data: emailAccountData } = useQueryFindFirstEmailAccountAdminQuery({
        variables: {
            input: {
                id: Number(emailAccountId),
            },
        },
    });
    const [updateEmailAccountAdmin] = useMutationUpdateEmailAccountAdminMutation();

    useEffect(() => {
        if (emailAccountData) {
            setInputData({
                id: emailAccountData.findFirstEmailAccountAdmin.id,
                account: emailAccountData.findFirstEmailAccountAdmin.account,
                quota: emailAccountData.findFirstEmailAccountAdmin.quota,
                freeQuota: emailAccountData.findFirstEmailAccountAdmin.freeQuota,
                memo: emailAccountData.findFirstEmailAccountAdmin.memo,
                checkRequest: false,
                emailId: emailAccountData.findFirstEmailAccountAdmin.email?.id || 0,
            });
            setInputMemo(emailAccountData.findFirstEmailAccountAdmin.memo);
        }
    }, [emailAccountData]);

    const handler = {
        onUpdate: () => {
            updateEmailAccountAdmin({
                variables: {
                    input: {
                        ...inputData,
                        memo: inputMemo,
                    },
                },
            })
                .then(() => {
                    return SwalUtil.ok({
                        title: "이메일 계정 정보 수정이 완료되었습니다.",
                        icon: "success",
                    });
                })
                .then(() => {
                    navigate(-1);
                })
                .catch(
                    ErrorCatchUtil({
                        [ErrorConstants.notPermission]: () => {
                            return SwalUtil.ok({
                                title: "권한이 없습니다.",
                                icon: "error",
                            });
                        },
                        [ErrorConstants.noData]: () => {
                            return SwalUtil.ok({
                                title: "등록된 이메일 계정이 없습니다.",
                                icon: "error",
                            });
                        },
                        [ErrorConstants.default]: e => {
                            return SwalUtil.ok({
                                title: e,
                                icon: "error",
                            });
                        },
                    }),
                );
        },
        onCancel: () => {
            navigate(-1);
        },
    };

    return (
        <>
            <LimitWidthContainer>
                <TitleBox title="이메일 서비스 - 수정" />
                <div className="bg-lightPaper dark:bg-darkPaper p-5">
                    <InputLine title={"이메일 계정"}>
                        <TextField
                            variant="outlined"
                            label="이메일 계정"
                            color="primary"
                            fullWidth
                            size="small"
                            type="email"
                            value={inputData.account}
                            onChange={value => {
                                setInputData({
                                    ...inputData,
                                    account: value.currentTarget.value,
                                });
                            }}
                        />
                    </InputLine>
                    <InputLine title="용량">
                        <NumberFormat
                            thousandSeparator={","}
                            suffix=" MB"
                            customInput={TextField}
                            fullWidth
                            size="small"
                            type={"tel"}
                            value={inputData.quota}
                            onValueChange={value => {
                                setInputData({
                                    ...inputData,
                                    quota: Number(value.value),
                                });
                            }}
                        />
                    </InputLine>
                    <InputLine title="무료용량">
                        <NumberFormat
                            thousandSeparator={","}
                            suffix=" MB"
                            customInput={TextField}
                            fullWidth
                            size="small"
                            type={"tel"}
                            value={inputData.freeQuota}
                            onValueChange={value => {
                                setInputData({
                                    ...inputData,
                                    freeQuota: Number(value.value),
                                });
                            }}
                        />
                    </InputLine>
                    <InputLine title={"작업요청 등록"}>
                        <Checkbox
                            checked={inputData.checkRequest}
                            color="primary"
                            onChange={value => {
                                setInputData({
                                    ...inputData,
                                    checkRequest: value.target.checked,
                                });
                            }}
                            inputProps={{ "aria-label": "작업요청 등록" }}
                        />{" "}
                        작업요청 등록
                    </InputLine>
                    <InputLine title="메모">
                        <EditorBox
                            defaultValue={inputMemo}
                            onChange={value => {
                                setInputMemo(value);
                            }}
                        />
                    </InputLine>

                    <div className="my-10" />
                    <div className="flex w-full justify-center gap-10">
                        <Button
                            variant="contained"
                            color="primary"
                            className="w-[120px]"
                            onClick={handler.onUpdate}>
                            계정수정
                        </Button>
                        <Button
                            variant="contained"
                            color="secondary"
                            className="w-[120px]"
                            onClick={handler.onCancel}>
                            취소
                        </Button>
                    </div>
                </div>
            </LimitWidthContainer>
        </>
    );
};
