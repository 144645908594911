import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
    ChangeRequestStatusAdminInput,
    QueryFindManyRequestAdminQuery,
    RequestStatus,
    useMutationChangeRequestStatusAdminMutation,
    useMutationDeleteRequestAdminMutation,
    useQueryCountRequestAdminQuery,
    useQueryFindManyRequestAdminQuery,
} from "../../../../graphql/operation";
import { LimitWidthContainer } from "../../../components/limitWidthContainer/LimitWidthContainer";
import { TitleBox } from "../../../components/titleBox/TitleBox";
import {
    Button,
    IconButton,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    Tooltip,
} from "@mui/material";
import { NoDataBox } from "../../../components/noDataBox/NoDataBox";
import { ProductTypeText } from "../../../components/productTypeText/ProductTypeText";
import moment from "moment";
import { ViewPaginationBox } from "../../../components/paginationBox/ViewPagenationBox";
import { RequestStatusText } from "../../../components/requestStatusText/RequestStatusText";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faPen, faSpinner, faTrash } from "@fortawesome/free-solid-svg-icons";
import { ErrorCatchUtil } from "../../../../utils/errorCatch/ErrorCatchUtil";
import { ErrorConstants } from "../../../../variables/ErrorConstants";
import { SwalUtil } from "../../../../utils/swal/SwalUtil";
import { OnChangeNumber, OnChangeValue } from "../../../../types/ArgsInterface";
import { UserVars } from "../../../../variables/MakeVar";

interface Iterm {
    term?: string;
}

export const ViewRequestList = () => {
    const navigate = useNavigate();
    const { method, take, page } = useParams();
    const location = useLocation();
    const state = location.state as Iterm;

    let takeInput = 15;
    let pageInput = 1;
    let methodInput = "All";
    let searchTerm: string | undefined;

    if (take) {
        takeInput = Number(take);
    }

    if (page) {
        pageInput = Number(page);
    }

    if (method) {
        methodInput = method;
    }

    if (state === null || state === undefined) {
        searchTerm = "";
    } else {
        searchTerm = state.term;
    }

    const {
        data: requestData,
        refetch: requestRefetch,
        error: requestError,
    } = useQueryFindManyRequestAdminQuery({
        variables: {
            input: {
                take: takeInput,
                page: pageInput,
                method: methodInput,
                term: searchTerm,
            },
        },
    });
    const { data: countData } = useQueryCountRequestAdminQuery({
        variables: {
            input: {
                method: methodInput,
                term: searchTerm,
            },
        },
    });

    const [changeRequestStatusAdmin] = useMutationChangeRequestStatusAdminMutation();
    const [deleteRequestAdmin] = useMutationDeleteRequestAdminMutation();

    const handler = {
        onChangePage: (page: number) => {
            navigate(`/request/${methodInput}/${takeInput}/${page}`);
        },
        onChangeRequestStatus: (value: ChangeRequestStatusAdminInput) => {
            changeRequestStatusAdmin({
                variables: {
                    input: {
                        ...value,
                    },
                },
            })
                .then(() => {
                    return requestRefetch({
                        input: {
                            take: takeInput,
                            page: pageInput,
                            method: methodInput,
                            term: searchTerm,
                        },
                    });
                })
                .catch(() => {
                    ErrorCatchUtil({
                        [ErrorConstants.default]: e => {
                            return SwalUtil.ok({
                                title: e,
                                icon: "error",
                            });
                        },
                    });
                });
        },
        onDeleteRequest: (requestId: number) => {
            SwalUtil.yn({
                title: "정말 삭제하시겠습니까?",
                text: "삭제할 경우 복구가 불가능합니다.",
                icon: "warning",
            })
                .then(() => {
                    return deleteRequestAdmin({
                        variables: {
                            input: {
                                requestId,
                            },
                        },
                    });
                })
                .then(() => {
                    return SwalUtil.ok({
                        title: "작업요청이 삭제 되었습니다.",
                        icon: "success",
                    });
                })
                .then(() => {
                    return requestRefetch();
                })
                .catch(() => {
                    ErrorCatchUtil({
                        [ErrorConstants.notPermission]: () => {
                            return SwalUtil.ok({
                                title: "권한이 없습니다.",
                                icon: "error",
                            });
                        },
                        [ErrorConstants.default]: e => {
                            return SwalUtil.ok({
                                title: e,
                                icon: "error",
                            });
                        },
                    });
                });
        },
    };

    return (
        <>
            <LimitWidthContainer>
                <TitleBox title="작업요청" />
                <div className="bg-lightPaper dark:bg-darkPaper p-5">
                    <RequestSearchContainer
                        methodInput={methodInput}
                        originSearchTerm={searchTerm}
                        takeInput={takeInput}
                    />

                    {requestError && <NoDataBox />}

                    {requestData && (
                        <>
                            <RequestListContainer
                                requestData={requestData}
                                onChangeRequestStatus={handler.onChangeRequestStatus}
                                onDeleteRequest={handler.onDeleteRequest}
                            />
                            <div className="my-10" />
                        </>
                    )}

                    {countData && (
                        <ViewPaginationBox
                            total={countData.countRequestAdmin}
                            size={takeInput}
                            page={pageInput}
                            onChange={handler.onChangePage}
                        />
                    )}
                </div>
            </LimitWidthContainer>
        </>
    );
};

const RequestSearchContainer = (props: {
    originSearchTerm: string | undefined;
    methodInput: string;
    takeInput: number;
}) => {
    const navigate = useNavigate();

    let searchTerm = "";

    const handler = {
        onMethod: (value: string) => {
            navigate(`/request/${value}/${props.takeInput}/1`, {
                state: { term: props.originSearchTerm },
            });
        },
        onSearch: (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            navigate(`/request/${props.methodInput}/${props.takeInput}/1`, {
                state: { term: searchTerm },
            });
        },
    };

    return (
        <>
            <div className="w-full flex justify-between items-center mb-5">
                <div className="flex items-center gap-5">
                    <ToggleButtonGroup color="primary" size={"small"} value={props.methodInput}>
                        <ToggleButton
                            className="w-[90px]"
                            value={"All"}
                            onClick={(event, value) => {
                                handler.onMethod(value);
                            }}>
                            전체
                        </ToggleButton>
                        <ToggleButton
                            className="w-[90px]"
                            value={RequestStatus.Requested}
                            onClick={(event, value) => {
                                handler.onMethod(value);
                            }}>
                            요청중
                        </ToggleButton>
                        <ToggleButton
                            className="w-[90px]"
                            value={RequestStatus.Proceeding}
                            onClick={(event, value) => {
                                handler.onMethod(value);
                            }}>
                            처리중
                        </ToggleButton>
                        <ToggleButton
                            className="w-[90px]"
                            value={RequestStatus.Solved}
                            onClick={(event, value) => {
                                handler.onMethod(value);
                            }}>
                            완료
                        </ToggleButton>
                    </ToggleButtonGroup>
                    <form
                        onSubmit={event => {
                            handler.onSearch(event);
                        }}>
                        <TextField
                            variant="outlined"
                            color="primary"
                            type="text"
                            label="도메인 검색"
                            size="small"
                            defaultValue={props.originSearchTerm}
                            onChange={value => {
                                searchTerm = value.target.value;
                            }}
                        />
                    </form>
                </div>
                <div className="flex items-center">
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            navigate(`/request/create`);
                        }}>
                        추가
                    </Button>
                </div>
            </div>
        </>
    );
};

const RequestListContainer = (props: {
    requestData: QueryFindManyRequestAdminQuery | undefined;
    onChangeRequestStatus: OnChangeValue<ChangeRequestStatusAdminInput>;
    onDeleteRequest: OnChangeNumber;
}) => {
    return (
        <>
            <div className="bg-lightBackground dark:bg-darkBackground rounded text-sm h-[35px] items-center flex">
                <div className="w-[10%] flex justify-center">상품종류</div>
                <div className="w-[15%] flex justify-center">도메인</div>
                <div className="w-[25%] flex justify-center">요청사항</div>
                <div className="w-[8%] flex justify-center">등록일자</div>
                <div className="w-[8%] flex justify-center">갱신일자</div>
                <div className="w-[7%] flex justify-center">등록자</div>
                <div className="w-[7%] flex justify-center">처리자</div>
                <div className="w-[7%] flex justify-center">상태</div>
                <div className="w-[13%] flex justify-center">기능</div>
            </div>
            {props.requestData &&
                props.requestData.findManyRequestAdmin.map((value, index) => (
                    <div key={index} className="w-full flex h-[60px] border-b items-center">
                        <div className="w-[10%] flex justify-center">
                            <ProductTypeText productType={value.productType} />
                        </div>
                        <div className="w-[15%] flex justify-center text-sm">{value.domainUrl}</div>
                        <div className="w-[25%] text-sm px-2">{value.subject}</div>
                        <div className="w-[8%] flex justify-center">
                            {moment(value.createdAt).locale("ko").format("YYYY-MM-DD")}
                        </div>
                        <div className="w-[8%] flex justify-center">
                            {value.requestStatus !== RequestStatus.Requested &&
                                moment(value.updatedAt).locale("ko").format("YYYY-MM-DD")}
                        </div>
                        <div className="w-[7%] flex justify-center">{value.writer}</div>
                        <div className="w-[7%] flex justify-center">{value.completer}</div>
                        <div className="w-[7%] flex justify-center">
                            <RequestStatusText requestStatus={value.requestStatus} />
                        </div>
                        <div className="w-[13%] flex justify-between">
                            <Tooltip title="수정" arrow>
                                <IconButton
                                    color="primary"
                                    onClick={() => {
                                        return;
                                    }}>
                                    <FontAwesomeIcon icon={faPen} size="sm" />
                                </IconButton>
                            </Tooltip>
                            {value.requestStatus !== RequestStatus.Proceeding && (
                                <Tooltip title="처리중" arrow>
                                    <IconButton
                                        color="primary"
                                        onClick={() => {
                                            props.onChangeRequestStatus({
                                                completer: UserVars.userInfo()?.name || "",
                                                requestId: value.id,
                                                requestStatus: RequestStatus.Proceeding,
                                            });
                                        }}>
                                        <FontAwesomeIcon icon={faSpinner} size="sm" />
                                    </IconButton>
                                </Tooltip>
                            )}
                            {value.requestStatus !== RequestStatus.Solved && (
                                <Tooltip title="완료" arrow>
                                    <IconButton
                                        color="primary"
                                        onClick={() => {
                                            props.onChangeRequestStatus({
                                                completer: UserVars.userInfo()?.name || "",
                                                requestId: value.id,
                                                requestStatus: RequestStatus.Solved,
                                            });
                                        }}>
                                        <FontAwesomeIcon icon={faCircleCheck} size="sm" />
                                    </IconButton>
                                </Tooltip>
                            )}
                            <Tooltip title="삭제" arrow>
                                <IconButton
                                    color="primary"
                                    onClick={() => {
                                        props.onDeleteRequest(value.id);
                                    }}>
                                    <FontAwesomeIcon icon={faTrash} size="sm" />
                                </IconButton>
                            </Tooltip>
                        </div>
                    </div>
                ))}
        </>
    );
};
