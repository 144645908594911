import { ErrorResponse } from "@apollo/client/link/error";
import { SystemVars } from "../../variables/MakeVar";
import { SwalUtil } from "../swal/SwalUtil";
import { GraphQLError } from "graphql";
import { ErrorConstants } from "../../variables/ErrorConstants";
import { SessionUtil } from "../session/SessionUtil";

export type ErrorReactorType = { [key: string]: (reason: any) => void };
type Catch = (e: ErrorResponse) => void;

export const ErrorCatchUtil = (reactor: ErrorReactorType): Catch => {
    return e => {
        MainCatcher(e, reactor);
    };
};

export const MainCatcher = (e: any, reactor: ErrorReactorType) => {
    SystemVars.loadingView(false);

    if (!Boolean(e)) {
        SwalUtil.ok({
            title: "실패하였습니다.",
            icon: "error",
        });
        return;
    }

    // eslint-disable-next-line prefer-const
    let { networkError, graphQLErrors } = e;

    if (e instanceof GraphQLError) {
        graphQLErrors = [e];
    }

    if (networkError) {
        SwalUtil.ok({
            title: "접속 에러",
            text: "서버와 접속이 불안정합니다. 잠시 후에 다시 시도하여 주십시오.",
            icon: "error",
        });
        return;
    }

    if (!graphQLErrors) {
        return;
    }

    if (!reactor) {
        return;
    }

    let runMainCatcher = true;

    for (const graphQLError of graphQLErrors) {
        if (reactor.hasOwnProperty(graphQLError.message)) {
            runMainCatcher = false;
            reactor[graphQLError.message](e);
            continue;
        }

        if (graphQLError.message === ErrorConstants.needLogin) {
            continue;
        }

        if (graphQLError.message === ErrorConstants.swalCancel) {
            continue;
        }

        if ((graphQLError.message as string).startsWith("jwt")) {
            SessionUtil.clearSession();
            break;
        }

        if (runMainCatcher && reactor.hasOwnProperty(ErrorConstants.default)) {
            reactor[ErrorConstants.default](e);
        } else if (runMainCatcher) {
            SwalUtil.ok({
                title: e.message,
                icon: "error",
            });
        }
    }
};
