import React from "react";
import { HostingType } from "../../../graphql/operation";

export const HostingTypeText = (props: { hostingType: HostingType }) => {
    let hostingType = "";

    if (props.hostingType === HostingType.LinuxPro) {
        hostingType = "리눅스 프로";
    } else if (props.hostingType === HostingType.LinuxPremium) {
        hostingType = "리눅스 프리미엄";
    } else if (props.hostingType === HostingType.WindowsBasic) {
        hostingType = "윈도우 베이직";
    } else if (props.hostingType === HostingType.WindowsPro) {
        hostingType = "윈도우 프로";
    } else if (props.hostingType === HostingType.Server) {
        hostingType = "리눅스 서버";
    } else if (props.hostingType === HostingType.GoldenShop) {
        hostingType = "골든샵";
    } else {
        hostingType = "리눅스 베이직";
    }

    return <>{hostingType}</>;
};
