import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { INavBar } from "../ViewLayoutAuth";
import { Menu } from "@mui/material";

export const ViewLayoutAuthHeader = (props: { NavBar: INavBar[] }) => {
    const navigate = useNavigate();

    return (
        <>
            <div className="w-full max-w-[1200px] mx-auto flex h-[60px] border rounded-xl border-lightBorder dark:border-darkBorder bg-lightPaper dark:bg-darkPaper items-center px-10">
                <div
                    className="w-[25%]"
                    onClick={() => {
                        navigate("/");
                    }}>
                    Goldennet Administrator
                </div>
                <div className="w-[75%] flex justify-start gap-8">
                    {props.NavBar.map((value, index) => (
                        <NavBarContainer NavBar={value} key={index} />
                    ))}
                </div>
            </div>
        </>
    );
};

const NavBarContainer = (props: { NavBar: INavBar }) => {
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handler = {
        onMainNavigate: (value: string) => {
            navigate(value);
        },
        handleClose: () => {
            setAnchorEl(null);
        },
    };

    return (
        <>
            <div
                className="cursor-pointer"
                onMouseOver={event => {
                    setAnchorEl(event.currentTarget);
                }}
                onClick={() => {
                    if (props.NavBar.path) {
                        handler.onMainNavigate(props.NavBar.path);
                    }
                }}>
                {props.NavBar.title}
            </div>
            {props.NavBar.sub && (
                <Menu
                    open={open}
                    anchorEl={anchorEl}
                    disableScrollLock={false}
                    onClose={handler.handleClose}
                    MenuListProps={{
                        onMouseLeave: () => {
                            setAnchorEl(null);
                        },
                    }}>
                    <div className="grid gap-5 px-2 py-5">
                        {props.NavBar.sub.map((value, index) => (
                            <div
                                key={index}
                                className="flex h-[30px] w-[120px] cursor-pointer items-center rounded pl-5 hover:bg-gray-50 dark:hover:bg-gray-500"
                                onClick={() => {
                                    setAnchorEl(null);
                                    if (value.path) {
                                        navigate(value.path);
                                    }
                                }}>
                                {value.title}
                            </div>
                        ))}
                    </div>
                </Menu>
            )}
        </>
    );
};
