import React from "react";
import { CircularProgress } from "@mui/material";

export const ViewLoading = () => {
    return (
        <div className="flex h-screen w-full animate-loadingView items-center justify-center">
            <div className="flex h-16 w-16 items-center justify-center rounded-xl bg-indigo-500">
                <CircularProgress sx={{ color: "#ffffff" }} />
            </div>
        </div>
    );
};
