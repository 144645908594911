import React, { useState } from "react";
import { TitleBox } from "../../../components/titleBox/TitleBox";
import { LimitWidthContainer } from "../../../components/limitWidthContainer/LimitWidthContainer";
import { InputLine } from "../../../components/inputLine/InputLine";
import { MakeCsrInput, useQueryMakeCsrLazyQuery } from "../../../../graphql/operation";
import { Button, TextField } from "@mui/material";
import { SwalUtil } from "../../../../utils/swal/SwalUtil";
import { OnVoidFunction } from "../../../../types/ArgsInterface";

export const ViewCertificateMakeCSR = () => {
    const [makeCsrLazyQuery] = useQueryMakeCsrLazyQuery();

    const initInput: MakeCsrInput = {
        c: "KR",
        cn: "",
        l: "Seocho-gu",
        o: "goldennet",
        ou: "goldennet",
        st: "Seoul-si",
    };
    const initOutput: MakeCSROutput = {
        csr: "",
        key: "",
    };

    const [inputData, setInputData] = useState<MakeCsrInput>(initInput);
    const [outputData, setOutputData] = useState(initOutput);

    const handler = {
        onMake: () => {
            if (inputData.cn === "") {
                return SwalUtil.ok({
                    title: "도메인이 입력되지 않았습니다.",
                    icon: "error",
                });
            }

            makeCsrLazyQuery({
                variables: {
                    input: {
                        ...inputData,
                    },
                },
            })
                .then(value => {
                    if (value.data) {
                        const { csr, key } = JSON.parse(value.data.makeCSR);
                        setOutputData({ csr, key });
                    }
                })
                .catch();
        },
    };

    return (
        <>
            <LimitWidthContainer>
                <TitleBox title="보안인증서 - CSR 생성" />
                <div className="bg-lightPaper dark:bg-darkPaper p-5">
                    <div className="flex gap-5">
                        <InputContainer
                            inputData={inputData}
                            setInputData={setInputData}
                            onMake={handler.onMake}
                        />
                        <OutputContainer outputData={outputData} domain={inputData.cn} />
                    </div>
                </div>
            </LimitWidthContainer>
        </>
    );
};

const InputContainer = (props: {
    inputData: MakeCsrInput;
    setInputData: React.Dispatch<React.SetStateAction<MakeCsrInput>>;
    onMake: OnVoidFunction;
}) => {
    return (
        <div className="flex flex-col justify-start w-[50%]">
            <InputLine title={"도메인"}>
                <TextField
                    variant="outlined"
                    label="도메인"
                    color="primary"
                    fullWidth
                    size="small"
                    type="text"
                    value={props.inputData.cn}
                    onChange={value => {
                        props.setInputData({
                            ...props.inputData,
                            cn: value.currentTarget.value,
                        });
                    }}
                />
            </InputLine>
            <InputLine title={"국가코드"}>
                <TextField
                    variant="outlined"
                    label="국가코드"
                    color="primary"
                    fullWidth
                    size="small"
                    type="text"
                    value={props.inputData.c}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={value => {
                        props.setInputData({
                            ...props.inputData,
                            c: value.currentTarget.value,
                        });
                    }}
                />
            </InputLine>
            <InputLine title={"광역시/도"}>
                <TextField
                    variant="outlined"
                    label="광역시/도"
                    color="primary"
                    fullWidth
                    size="small"
                    type="text"
                    value={props.inputData.st}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={value => {
                        props.setInputData({
                            ...props.inputData,
                            st: value.currentTarget.value,
                        });
                    }}
                />
            </InputLine>
            <InputLine title={"시/군/구"}>
                <TextField
                    variant="outlined"
                    label="시/군/구"
                    color="primary"
                    fullWidth
                    size="small"
                    type="text"
                    value={props.inputData.l}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={value => {
                        props.setInputData({
                            ...props.inputData,
                            l: value.currentTarget.value,
                        });
                    }}
                />
            </InputLine>
            <InputLine title={"기관명"}>
                <TextField
                    variant="outlined"
                    label="기관명"
                    color="primary"
                    fullWidth
                    size="small"
                    type="text"
                    value={props.inputData.o}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={value => {
                        props.setInputData({
                            ...props.inputData,
                            o: value.currentTarget.value,
                        });
                    }}
                />
            </InputLine>
            <InputLine title={"부서명"}>
                <TextField
                    variant="outlined"
                    label="부서명"
                    color="primary"
                    fullWidth
                    size="small"
                    type="text"
                    value={props.inputData.ou}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={value => {
                        props.setInputData({
                            ...props.inputData,
                            ou: value.currentTarget.value,
                        });
                    }}
                />
            </InputLine>

            <div className="my-10" />
            <div className="flex w-full justify-center gap-10">
                <Button
                    variant="contained"
                    color="primary"
                    className="w-[120px]"
                    onClick={props.onMake}>
                    CSR생성
                </Button>
            </div>
        </div>
    );
};

const OutputContainer = (props: { outputData: MakeCSROutput; domain: string }) => {
    const handler = {
        onCSRDownload: () => {
            const hiddenElement = document.createElement('a');
            hiddenElement.href = 'data:attachment/text,' + encodeURI(props.outputData.csr);
            hiddenElement.target = '_blank';
            hiddenElement.download = `${props.domain}.csr`;
            hiddenElement.click();
            hiddenElement.remove();
        },
        onKEYDownload: () => {
            const hiddenElement = document.createElement('a');
            hiddenElement.href = 'data:attachment/text,' + encodeURI(props.outputData.key);
            hiddenElement.target = '_blank';
            hiddenElement.download = `${props.domain}.key`;
            hiddenElement.click();
            hiddenElement.remove();
        },
    };
    return (
        <div className="flex flex-col justify-start w-[50%]">
            <TextField
                multiline
                variant="outlined"
                label="CSR"
                color="primary"
                fullWidth
                size="small"
                type="text"
                inputProps={{ readOnly: true, sx: { fontSize: 9 } }}
                value={props.outputData.csr}
                minRows={12}
                maxRows={12}
                sx={{marginBottom: 2}}
            />
            <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={handler.onCSRDownload}
                disabled={!props.outputData.csr}>
                CSR 다운로드
            </Button>
            <div className="py-2" />
            <TextField
                multiline
                variant="outlined"
                label="KEY"
                color="primary"
                fullWidth
                size="small"
                type="text"
                inputProps={{ readOnly: true, sx: { fontSize: 9 } }}
                value={props.outputData.key}
                minRows={12}
                maxRows={12}
                sx={{marginBottom: 2}}
            />
            <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={handler.onKEYDownload}
                disabled={!props.outputData.key}>
                KEY 다운로드
            </Button>
        </div>
    );
};

type MakeCSROutput = {
    csr: string;
    key: string;
};
