import React from "react";
import DaumPostCode from "react-daum-postcode";
import { Address } from "react-daum-postcode/lib/loadPostcode";
import { OnChangeValue, OnVoidFunction } from "../../types/ArgsInterface";
import { Dialog, DialogTitle } from "@mui/material";

export const DaumPostCodeModal = (props: {
    open: boolean;
    onChangeAddress: OnChangeValue<Address>;
    onClose: OnVoidFunction;
}) => {
    const handler = {
        onComplete: (data: Address) => {
            props.onClose();
            props.onChangeAddress(data);
        },
    };

    return (
        <>
            <Dialog open={props.open} onClose={props.onClose}>
                <DialogTitle>주소 검색</DialogTitle>
                <div className="w-[300px] lg:w-[600px] min-h-[444px]">
                    <DaumPostCode
                        onComplete={handler.onComplete}
                        autoClose={false}
                        className="min-h-[444px]"
                        style={{ height: 444 }}
                    />
                </div>
            </Dialog>
        </>
    );
};
