import { UserVars } from "../../variables/MakeVar";
import { User } from "../../graphql/operation";

export class SessionUtil {
    static apiKey = "api-token";

    static getSession() {
        return localStorage.getItem(this.apiKey);
    }

    static clearSession() {
        UserVars.isLoggedIn(false);
        UserVars.userInfo(undefined);
        localStorage.removeItem(this.apiKey);
    }

    static setSession(token: string) {
        localStorage.setItem(this.apiKey, token);
        UserVars.isLoggedIn(true);
        UserVars.authToken(token);
    }

    static refreshSession(userInfo: User) {
        UserVars.userInfo(userInfo);
        UserVars.isLoggedIn(true);
    }
}
