import React from "react";
import { MenuItem, TextField } from "@mui/material";
import { DomainType } from "../../../graphql/operation";
import { OnChangeValue } from "../../../types/ArgsInterface";

export const DomainTypeSelect = (props: {
    domainType: DomainType;
    onChangeState: OnChangeValue<DomainType>;
    readOnly?: boolean;
}) => {
    return (
        <>
            <TextField
                variant="outlined"
                label="도메인 종류"
                color="primary"
                fullWidth
                select
                value={props.domainType}
                size="small"
                onChange={value => {
                    let domainType: DomainType;
                    if (value.target.value === "Kr") {
                        domainType = DomainType.Kr;
                    } else if (value.target.value === "Biz") {
                        domainType = DomainType.Biz;
                    } else if (value.target.value === "Org") {
                        domainType = DomainType.Org;
                    } else if (value.target.value === "Com") {
                        domainType = DomainType.Com;
                    } else if (value.target.value === "Net") {
                        domainType = DomainType.Net;
                    } else if (value.target.value === "Xyz") {
                        domainType = DomainType.Xyz;
                    } else if (value.target.value === "OrKr") {
                        domainType = DomainType.OrKr;
                    } else if (value.target.value === "PeKr") {
                        domainType = DomainType.PeKr;
                    } else {
                        domainType = DomainType.CoKr;
                    }
                    props.onChangeState(domainType);
                }}>
                <MenuItem value={DomainType.Com}>.com</MenuItem>
                <MenuItem value={DomainType.CoKr}>.co.kr</MenuItem>
                <MenuItem value={DomainType.Kr}>.kr</MenuItem>
                <MenuItem value={DomainType.Biz}>.biz</MenuItem>
                <MenuItem value={DomainType.Net}>.net</MenuItem>
                <MenuItem value={DomainType.Biz}>.biz</MenuItem>
                <MenuItem value={DomainType.Org}>.org</MenuItem>
                <MenuItem value={DomainType.Xyz}>.xyz</MenuItem>
                <MenuItem value={DomainType.OrKr}>.or.kr</MenuItem>
                <MenuItem value={DomainType.PeKr}>.pe.kr</MenuItem>
                <MenuItem value={DomainType.AcKr}>.ac.kr</MenuItem>
            </TextField>
        </>
    );
};
