import React, { useState } from "react";
import { TitleBox } from "../../../../components/titleBox/TitleBox";
import { Button, IconButton, Tooltip } from "@mui/material";
import {
    ISearchCertificate,
    SearchCertificateModal,
} from "../../../../components/searchCertificateModal/SearchCertificateModal";
import { useNavigate } from "react-router-dom";
import {
    Exact,
    FindFirstUserAdminInput,
    QueryFindFirstUserAdminQuery,
    useMutationConnectCertificateAdminMutation,
} from "../../../../../graphql/operation";
import { SwalUtil } from "../../../../../utils/swal/SwalUtil";
import { ErrorCatchUtil } from "../../../../../utils/errorCatch/ErrorCatchUtil";
import { ErrorConstants } from "../../../../../variables/ErrorConstants";
import { ApolloQueryResult } from "@apollo/client";
import NumberFormat from "react-number-format";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faBookOpen,
    faMessage,
    faMoneyBillTransfer,
    faPen,
} from "@fortawesome/free-solid-svg-icons";
import { CertificateTypeText } from "../../../../components/certificateTypeText/CertificateTypeText";
import { ProductActiveBadge } from "../../../../components/productActiveBadge/ProductActiveBadge";

export const ViewUserProductCertificate = (props: {
    userData: QueryFindFirstUserAdminQuery;
    userRefetch: (
        variables?: Partial<Exact<{ input: FindFirstUserAdminInput }>> | undefined,
    ) => Promise<ApolloQueryResult<QueryFindFirstUserAdminQuery>>;
}) => {
    const navigate = useNavigate();
    const [openModal, setOpenModal] = useState<boolean>(false);

    const [connectCertificateAdmin] = useMutationConnectCertificateAdminMutation();

    const handler = {
        onApplyCertificate: (value: ISearchCertificate) => {
            setOpenModal(false);
            SwalUtil.yn({
                title: "선택한 보안인증서를 회원과 연결하시겠습니까?",
                text: value.certificateDomainUrl,
                icon: "question",
            })
                .then(() => {
                    return connectCertificateAdmin({
                        variables: {
                            input: {
                                userId: props.userData.findFirstUserAdmin.id,
                                certificateId: value.certificateId,
                            },
                        },
                    });
                })
                .then(() => {
                    return SwalUtil.ok({
                        title: "상품 연결이 완료되었습니다.",
                        icon: "success",
                    });
                })
                .then(() => {
                    return props.userRefetch({
                        input: {
                            userId: props.userData.findFirstUserAdmin.id,
                        },
                    });
                })
                .catch(
                    ErrorCatchUtil({
                        [ErrorConstants.notPermission]: () => {
                            return SwalUtil.ok({
                                title: "권한이 없습니다.",
                                icon: "error",
                            });
                        },
                        [ErrorConstants.alreadyUser]: () => {
                            return SwalUtil.ok({
                                title: "이미 연결된 사용자가 있는 상품입니다.",
                                icon: "error",
                            });
                        },
                        [ErrorConstants.default]: e => {
                            return SwalUtil.ok({
                                title: e,
                                icon: "error",
                            });
                        },
                    }),
                );
        },
        handleModalOpen: () => {
            setOpenModal(true);
        },
        handleModalClose: () => {
            setOpenModal(false);
        },
        onCreate: () => {
            navigate(`/certificate/create/${props.userData.findFirstUserAdmin.id}`);
        },
    };
    return (
        <>
            <div className="flex justify-between mb-5">
                <div className="flex-1">
                    <TitleBox title="보안인증서" notBottomMargin={true} />
                </div>
                <div className="flex gap-5">
                    <Button
                        variant="contained"
                        size="small"
                        className="h-[30px]"
                        onClick={handler.onCreate}>
                        상품추가
                    </Button>
                    <Button
                        variant="contained"
                        size="small"
                        className="h-[30px]"
                        onClick={handler.handleModalOpen}>
                        상품연결
                    </Button>
                </div>
            </div>
            <div className="bg-lightBackground dark:bg-darkBackground flex items-center w-full h-[30px]">
                <div className="w-[24%]">도메인명</div>
                <div className="w-[7%]">상태</div>
                <div className="w-[11%]">상품</div>
                <div className="w-[13%]">상품금액</div>
                <div className="w-[13%]">마지막 결제일</div>
                <div className="w-[13%]">다음 결제일</div>
                <div className="w-[19%]">기능</div>
            </div>
            {props.userData.findFirstUserAdmin.certificates.length === 0 && (
                <div className="h-[100px] flex justify-center items-center">
                    등록된 상품이 없습니다.
                </div>
            )}
            {props.userData.findFirstUserAdmin.certificates.map((value, index) => (
                <div key={index} className="w-full flex h-[45px] border-b items-center">
                    {value && (
                        <>
                            <div className="w-[24%]">{value.domainUrl}</div>
                            <div className="w-[7%]">
                                <ProductActiveBadge productActive={value.productActive} />
                            </div>
                            <div className="w-[11%] truncate">
                                <CertificateTypeText type={value.type} />
                            </div>
                            <div className="w-[13%]">
                                <NumberFormat
                                    value={value.price}
                                    displayType="text"
                                    thousandSeparator=","
                                    suffix=" 원"
                                />
                            </div>
                            <div className="w-[13%]">
                                {value.lastBillingDate
                                    ? moment(value.lastBillingDate)
                                          .locale("ko")
                                          .format("YYYY-MM-DD")
                                    : "-"}
                            </div>
                            <div className="w-[13%]">
                                {moment(value.nextBillingDate).locale("ko").format("YYYY-MM-DD")}
                            </div>
                            <div className="w-[19%] flex justify-between">
                                <Tooltip title={"메세지"} arrow>
                                    <IconButton color="primary">
                                        <FontAwesomeIcon icon={faMessage} size="sm" />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="상세보기">
                                    <IconButton
                                        color="primary"
                                        onClick={() => {
                                            navigate(`/certificate/detail/${value.id}`);
                                        }}>
                                        <FontAwesomeIcon icon={faBookOpen} size="sm" />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="수정" arrow>
                                    <IconButton
                                        color="primary"
                                        onClick={() => {
                                            navigate(`/certificate/update/${value.id}`);
                                        }}>
                                        <FontAwesomeIcon icon={faPen} size="sm" />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="납부등록" arrow>
                                    <IconButton
                                        color="primary"
                                        onClick={() => {
                                            navigate(`/payment/create/certificate/${value.id}`);
                                        }}>
                                        <FontAwesomeIcon icon={faMoneyBillTransfer} size="sm" />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </>
                    )}
                </div>
            ))}

            <SearchCertificateModal
                open={openModal}
                onClose={handler.handleModalClose}
                onApplyCertificate={handler.onApplyCertificate}
            />
        </>
    );
};
