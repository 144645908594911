import React from "react";
import { Pagination } from "@mui/material";

export const ViewPaginationBox = (props: {
    page: number;
    size: number;
    total: number;
    onChange: (page: number) => void;
}) => {
    let count = Math.floor(props.total / props.size);
    count += props.total % props.size === 0 ? 0 : 1;

    return (
        <>
            <div className="w-full flex justify-center">
                <Pagination
                    color="primary"
                    count={count}
                    page={props.page}
                    shape="rounded"
                    onChange={(event, page) => {
                        window.scrollTo(0, 0);
                        props.onChange(page);
                    }}
                />
            </div>
        </>
    );
};
