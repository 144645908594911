import React from "react";
import { Button, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { OnChangeValue, OnVoidFunction } from "../../../../types/ArgsInterface";
import { LoginAdminInput, useMutationLoginAdminMutation } from "../../../../graphql/operation";
import { GraphQLError } from "graphql";
import { ErrorConstants } from "../../../../variables/ErrorConstants";
import { UserVars } from "../../../../variables/MakeVar";
import { SessionUtil } from "../../../../utils/session/SessionUtil";
import { SwalUtil } from "../../../../utils/swal/SwalUtil";
import { ErrorCatchUtil } from "../../../../utils/errorCatch/ErrorCatchUtil";
import { useForm } from "react-hook-form";

export const ViewAuthSignIn = () => {
    const navigate = useNavigate();

    const [loginAdminMutation] = useMutationLoginAdminMutation();
    const handler = {
        onLogIn: (inputValue: LoginAdminInput) => {
            loginAdminMutation({
                variables: {
                    input: {
                        username: inputValue.username,
                        password: inputValue.password,
                    },
                },
            })
                .then(res => {
                    if (!res.data) {
                        throw new GraphQLError(ErrorConstants.notFoundUser);
                    }
                    UserVars.authToken(res.data.loginAdmin);
                    return SessionUtil.setSession(res.data.loginAdmin);
                })
                .then(() => {
                    navigate("/");
                })
                .catch(
                    ErrorCatchUtil({
                        [ErrorConstants.notPermission]: () => {
                            return SwalUtil.ok({
                                title: "권한이 없습니다.",
                                icon: "error",
                            });
                        },
                        [ErrorConstants.notFoundUser]: () => {
                            return SwalUtil.ok({
                                title: "사용자를 찾을 수 없습니다.",
                                icon: "error",
                            });
                        },
                        [ErrorConstants.wrongInput]: () => {
                            return SwalUtil.ok({
                                title: "입력값이 잘못되었습니다.",
                                icon: "error",
                            });
                        },
                        [ErrorConstants.default]: e => {
                            return SwalUtil.ok({
                                title: e,
                                icon: "error",
                            });
                        },
                    }),
                );
        },
        onSignUp: () => {
            navigate("/auth/signup");
        },
    };

    return (
        <>
            <div className="w-full h-screen flex justify-center items-center bg-lightBackground dark:bg-darkBackground">
                <SignInBox onSignUp={handler.onSignUp} onLogIn={handler.onLogIn} />
            </div>
        </>
    );
};

const SignInBox = (props: {
    onSignUp: OnVoidFunction;
    onLogIn: OnChangeValue<LoginAdminInput>;
}) => {
    const {
        register,
        getValues,
        handleSubmit,
        formState: { isValid, errors },
    } = useForm<LoginAdminInput>({ mode: "onBlur" });

    const handler = {
        onLogin: () => {
            props.onLogIn(getValues());
        },
    };

    return (
        <>
            <form
                className="w-full mx-5 lg:mx-0 max-w-[500px] bg-lightPaper dark:bg-darkPaper border p-5 rounded flex flex-col gap-5"
                onSubmit={handleSubmit(handler.onLogin)}>
                <div className="text-xl font-bold mb-10">Goldennet Administrator - 관리자 로그인</div>
                    <TextField
                        {...register("username", {
                            required: "사용자명이 입력되지 않았습니다.",
                        })}
                        color="primary"
                        variant="outlined"
                        label="사용자명"
                        error={Boolean(errors.username)}
                        helperText={
                            errors.username ? errors.username.message : "사용자명을 입력해주세요."
                        }
                        type="text"
                        fullWidth
                        size="small"
                    />
                    <TextField
                        {...register("password", {
                            required: "비밀번호가 입력되지 않았습니다.",
                            minLength: {
                                value: 8,
                                message: "비밀번호는 최소 8자리 이상 입력해주세요.",
                            },
                        })}
                        color="primary"
                        variant="outlined"
                        label="비밀번호"
                        error={Boolean(errors.password)}
                        helperText={
                            errors.password ? errors.password.message : "비밀번호를 입력해주세요."
                        }
                        type="password"
                        fullWidth
                        size="small"
                    />
                <div className="mb-5" />
                <Button variant="contained" color="primary" fullWidth disabled={!isValid} type="submit">
                    Sign In
                </Button>
                <Button variant="contained" color="secondary" fullWidth onClick={props.onSignUp}>
                    Sign Up
                </Button>
            </form>
        </>
    );
};
