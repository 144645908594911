import React from "react";
import { LimitWidthContainer } from "../../components/limitWidthContainer/LimitWidthContainer";

export const ViewIndex = () => {
    return (
        <LimitWidthContainer>
            <div className="w-full h-[300px] bg-lightPaper dark:bg-darkPaper border rounded flex justify-center items-center">
                인덱스 페이지는 생성 중입니다.
            </div>
        </LimitWidthContainer>
    );
};
