import withReactContent from "sweetalert2-react-content";
import Swal, { SweetAlertIcon, SweetAlertInput } from "sweetalert2";
import { GraphQLError } from "graphql";
import { ErrorConstants } from "../../variables/ErrorConstants";

type inputDefinitionType = {
    label: string;
    idName: string;
    type: SweetAlertInput;
    placeholder: string;
};

type inputResultType = {
    [key: string]: number | string;
};

export class SwalUtil {
    private static swal = withReactContent(Swal);

    static ok(params: { title: string; text?: string; icon: SweetAlertIcon }) {
        return this.swal
            .fire({
                title: params.title,
                text: params.text,
                icon: params.icon,
                confirmButtonText: "확인",
            })
            .then();
    }

    static yn(params: { title: string; text?: string; icon: SweetAlertIcon }) {
        return this.swal
            .fire({
                title: params.title,
                text: params.text,
                icon: params.icon,
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: "확인",
                cancelButtonText: "취소",
            })
            .then(res => {
                if (res.isConfirmed) {
                    return;
                } else {
                    throw new GraphQLError(ErrorConstants.swalCancel);
                }
            });
    }

    static error() {
        return this.swal
            .fire({
                title: "실패 하였습니다. 다시 시도하여 주십시오.",
                icon: "error",
                confirmButtonText: "확인",
            })
            .then();
    }

    static async input(
        title: string,
        text: string,
        inputs: inputDefinitionType[],
    ): Promise<inputResultType> {
        let html = `<div class="flex flex-col w-full">
                        <div class="swal2-html-container">${text}</div>`;
        for (const input of inputs) {
            html += `<div class="flex flex-col lg:flex-row justify-center lg:justify-between items-center w-full">
                        <label for="swal2-${input.idName}" class="swal2-input-label lg:w-[40%]">${input.label}</label>
                        <input class="swal2-input lg:w-[60%] w-full text-base" id="swal2-${input.idName}" type="${input.type}" placeholder="${input.placeholder}" />
                    </div>`;
        }
        html += `</div>`;

        const swalValue = await this.swal.fire({
            title,
            focusConfirm: false,
            html,
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "확인",
            cancelButtonText: "취소",
            preConfirm: () => {
                const inputValue: inputResultType = {};
                for (const input of inputs) {
                    const value = window.document.getElementById(
                        `swal2-${input.idName}`,
                    ) as HTMLInputElement;
                    inputValue[input.idName.toString()] = value.value;
                }
                return inputValue;
            },
        });

        if (swalValue.dismiss !== Swal.DismissReason.cancel && swalValue.value) {
            return swalValue.value;
        } else {
            throw new GraphQLError(ErrorConstants.swalCancel);
        }
    }
}
