import React from "react";
import { ProductActive } from "../../../graphql/operation";

export const ProductActiveBadge = (props: { productActive: ProductActive }) => {
    if (props.productActive === ProductActive.Pause) {
        return (
            <>
                <span className="text-yellow-500">정지</span>
            </>
        );
    } else if (props.productActive === ProductActive.Stop) {
        return <span className="text-red-500">만료</span>;
    } else if (props.productActive === ProductActive.Delete) {
        return <span className="text-gray-500">삭제</span>;
    } else {
        return <span className="text-green-500">정상</span>;
    }
};
