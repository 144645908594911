import React from "react";

export const NoDataBox = () => {
    return (
        <>
            <div className="w-full min-h-[300px] flex items-center justify-center">
                데이터가 없습니다.
            </div>
        </>
    );
}
