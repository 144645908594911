import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { TitleBox } from "../../../components/titleBox/TitleBox";
import { InputLine } from "../../../components/inputLine/InputLine";
import { LimitWidthContainer } from "../../../components/limitWidthContainer/LimitWidthContainer";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import {
    CreateDomainAdminInput,
    DomainType,
    Period,
    RegisteredCompany,
    useMutationCreateDomainAdminMutation,
} from "../../../../graphql/operation";
import { Button, Checkbox, TextField } from "@mui/material";
import { DomainTypeSelect } from "../../../components/domainTypeSelect/DomainTypeSelect";
import { RegisteredCompanySelect } from "../../../components/RegisteredCompanySelect/RegisteredCompanySelect";
import moment from "moment";
import { PeriodSelect } from "../../../components/PeriodSelect/PeriodSelect";
import NumberFormat from "react-number-format";
import { SwalUtil } from "../../../../utils/swal/SwalUtil";
import { ErrorCatchUtil } from "../../../../utils/errorCatch/ErrorCatchUtil";
import { ErrorConstants } from "../../../../variables/ErrorConstants";
import { EditorBox } from "../../../components/editorBox/EditorBox";

export const ViewDomainCreate = () => {
    const navigate = useNavigate();
    const { userId } = useParams();

    let useUserId: number | undefined;
    if (userId !== undefined) {
        useUserId = Number(userId);
    } else {
        useUserId = undefined;
    }

    const initDomainData: CreateDomainAdminInput = {
        checkRequest: false,
        domainType: DomainType.CoKr,
        domainUrl: "",
        memo: "",
        period: Period.Annual,
        price: 0,
        registeredCompany: RegisteredCompany.HostingKr,
        createdAt: moment(),
        nextBillingDate: moment().locale("ko").add(1, "year").add(-1, "day"),
    };

    const [inputData, setInputData] = useState<CreateDomainAdminInput>(initDomainData);
    const [inputMemo, setInputMemo] = useState<string>("");
    const [createDomainAdmin] = useMutationCreateDomainAdminMutation();

    const handler = {
        onCreate: () => {
            createDomainAdmin({
                variables: {
                    input: {
                        ...inputData,
                        memo: inputMemo,
                        userId: useUserId,
                    },
                },
            })
                .then(() => {
                    return SwalUtil.ok({
                        title: "상품 등록이 완료되었습니다.",
                        icon: "success",
                    });
                })
                .then(() => {
                    navigate(-1);
                })
                .catch(
                    ErrorCatchUtil({
                        [ErrorConstants.notPermission]: () => {
                            return SwalUtil.ok({
                                title: "권한이 없습니다.",
                                icon: "error",
                            });
                        },
                        [ErrorConstants.default]: e => {
                            return SwalUtil.ok({
                                title: e,
                                icon: "error",
                            });
                        },
                    }),
                );
        },
        onCancel: () => {
            navigate(-1);
        },
    };

    return (
        <>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <LimitWidthContainer>
                    <TitleBox title="도메인 - 추가" />
                    <div className="bg-lightPaper dark:bg-darkPaper p-5">
                        <InputLine title={"도메인"}>
                            <TextField
                                variant="outlined"
                                label="도메인"
                                color="primary"
                                fullWidth
                                size="small"
                                onChange={value => {
                                    setInputData({
                                        ...inputData,
                                        domainUrl: value.currentTarget.value,
                                    });
                                }}
                            />
                        </InputLine>
                        <InputLine title="도메인 종류">
                            <DomainTypeSelect
                                domainType={inputData.domainType}
                                onChangeState={value => {
                                    setInputData({
                                        ...inputData,
                                        domainType: value,
                                    });
                                }}
                            />
                        </InputLine>
                        <InputLine title="등록기관">
                            <RegisteredCompanySelect
                                registeredCompany={inputData.registeredCompany}
                                onChangeState={value => {
                                    setInputData({
                                        ...inputData,
                                        registeredCompany: value,
                                    });
                                }}
                            />
                        </InputLine>
                        <InputLine title="납부주기">
                            <PeriodSelect
                                onChangeState={value => {
                                    setInputData({
                                        ...inputData,
                                        period: value,
                                    });
                                }}
                                period={inputData.period}
                            />
                        </InputLine>
                        <InputLine title="시작일">
                            <MobileDatePicker
                                onChange={value => {
                                    setInputData({
                                        ...inputData,
                                        createdAt: moment(value).utc().toISOString(),
                                        nextBillingDate: moment(value)
                                            .utc()
                                            .add("+1", "y")
                                            .add(-1, "day")
                                            .toISOString(),
                                        lastBillingDate: moment(value).utc().toISOString(),
                                    });
                                }}
                                value={inputData.createdAt}
                                renderInput={params => (
                                    <TextField fullWidth size="small" {...params} />
                                )}
                            />
                        </InputLine>
                        <InputLine title="다음결제일">
                            <MobileDatePicker
                                onChange={value => {
                                    setInputData({
                                        ...inputData,
                                        nextBillingDate: moment(value).utc().toISOString(),
                                    });
                                }}
                                value={inputData.nextBillingDate}
                                renderInput={params => (
                                    <TextField fullWidth size="small" {...params} />
                                )}
                            />
                        </InputLine>
                        <InputLine title="가격">
                            <NumberFormat
                                thousandSeparator={","}
                                suffix=" 원"
                                customInput={TextField}
                                fullWidth
                                size="small"
                                onValueChange={value => {
                                    setInputData({
                                        ...inputData,
                                        price: Number(value.value),
                                    });
                                }}
                            />
                        </InputLine>
                        <InputLine title={"작업요청 등록"}>
                            <Checkbox
                                checked={inputData.checkRequest}
                                color="primary"
                                onChange={value => {
                                    setInputData({
                                        ...inputData,
                                        checkRequest: value.target.checked,
                                    });
                                }}
                                inputProps={{ "aria-label": "작업요청 등록" }}
                            />{" "}
                            작업요청 등록
                        </InputLine>
                        <InputLine title="메모">
                            <EditorBox
                                defaultValue={inputMemo}
                                onChange={value => {
                                    setInputMemo(value);
                                }}
                            />
                        </InputLine>

                        <div className="my-10" />
                        <div className="flex w-full justify-center gap-10">
                            <Button
                                variant="contained"
                                color="primary"
                                className="w-[120px]"
                                onClick={handler.onCreate}>
                                상품등록
                            </Button>
                            <Button
                                variant="contained"
                                color="secondary"
                                className="w-[120px]"
                                onClick={handler.onCancel}>
                                취소
                            </Button>
                        </div>
                    </div>
                </LimitWidthContainer>
            </LocalizationProvider>
        </>
    );
};
