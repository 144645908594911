import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
    CreateRequestAdminInput,
    ProductType,
    RequestStatus,
    useMutationCreateRequestAdminMutation,
} from "../../../../graphql/operation";
import { SwalUtil } from "../../../../utils/swal/SwalUtil";
import { ErrorCatchUtil } from "../../../../utils/errorCatch/ErrorCatchUtil";
import { ErrorConstants } from "../../../../variables/ErrorConstants";
import { TitleBox } from "../../../components/titleBox/TitleBox";
import { LimitWidthContainer } from "../../../components/limitWidthContainer/LimitWidthContainer";
import { Button, TextField } from "@mui/material";
import { InputLine } from "../../../components/inputLine/InputLine";
import { UserVars } from "../../../../variables/MakeVar";
import { ProductTypeSelect } from "../../../components/productTypeSelect/ProductTypeSelect";
import { EditorBox } from "../../../components/editorBox/EditorBox";

export const ViewRequestCreate = () => {
    const navigate = useNavigate();

    const initRequestData: CreateRequestAdminInput = {
        content: "",
        domainUrl: "",
        productType: ProductType.Domain,
        requestStatus: RequestStatus.Requested,
        subject: "",
        writer: UserVars.userInfo.name,
    };

    const [inputData, setInputData] = useState<CreateRequestAdminInput>(initRequestData);
    const [inputMemo, setInputMemo] = useState<string>("");
    const [createRequestAdmin] = useMutationCreateRequestAdminMutation();

    const handler = {
        onCreate: () => {
            createRequestAdmin({
                variables: {
                    input: {
                        ...inputData,
                        content: inputMemo,
                    },
                },
            })
                .then(() => {
                    return SwalUtil.ok({
                        title: "작업요청 등록이 완료되었습니다.",
                        icon: "success",
                    });
                })
                .then(() => {
                    navigate(-1);
                })
                .catch(() => {
                    ErrorCatchUtil({
                        [ErrorConstants.notPermission]: () => {
                            return SwalUtil.ok({
                                title: "권한이 없습니다.",
                                icon: "error",
                            });
                        },
                        [ErrorConstants.default]: e => {
                            return SwalUtil.ok({
                                title: e,
                                icon: "error",
                            });
                        },
                    });
                });
        },
        onCancel: () => {
            navigate(-1);
        },
        onChangeProductType: (value: ProductType) => {
            setInputData({
                ...inputData,
                productType: value,
            });
        },
    };
    return (
        <>
            <LimitWidthContainer>
                <TitleBox title="작업요청 - 추가" />
                <div className="bg-lightPaper dark:bg-darkPaper p-5">
                    <InputLine title={"도메인"}>
                        <TextField
                            variant="outlined"
                            label="도메인"
                            color="primary"
                            fullWidth
                            size="small"
                            onChange={value => {
                                setInputData({
                                    ...inputData,
                                    domainUrl: value.currentTarget.value,
                                });
                            }}
                        />
                    </InputLine>
                    <InputLine title="상품종류">
                        <ProductTypeSelect
                            productType={inputData.productType}
                            onChangeState={handler.onChangeProductType}
                        />
                    </InputLine>
                    <InputLine title="요청사항">
                        <TextField
                            variant="outlined"
                            label="요청사항"
                            color="primary"
                            fullWidth
                            size="small"
                            onChange={value => {
                                setInputData({
                                    ...inputData,
                                    subject: value.currentTarget.value,
                                });
                            }}
                        />
                    </InputLine>
                    <InputLine title="메모">
                        <EditorBox
                            defaultValue={inputMemo}
                            onChange={value => {
                                setInputMemo(value);
                            }}
                        />
                    </InputLine>
                    <div className="my-10" />
                    <div className="flex w-full justify-center gap-10">
                        <Button
                            variant="contained"
                            color="primary"
                            className="w-[120px]"
                            onClick={handler.onCreate}>
                            작업요청 등록
                        </Button>
                        <Button
                            variant="contained"
                            color="secondary"
                            className="w-[120px]"
                            onClick={handler.onCancel}>
                            취소
                        </Button>
                    </div>
                </div>
            </LimitWidthContainer>
        </>
    );
};
