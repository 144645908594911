import React from "react";
import { ReceiptsStatus } from "../../../graphql/operation";

export const ReceiptsStatusText = (props: { receiptsStatus: ReceiptsStatus }) => {
    let value = "";
    if (props.receiptsStatus === ReceiptsStatus.Request) {
        value = "발행요청";
    } else if (props.receiptsStatus === ReceiptsStatus.Pre) {
        value = "선발행";
    } else if (props.receiptsStatus === ReceiptsStatus.Issued) {
        value = "발행완료";
    } else {
        value = "발행없음";
    }

    return <>{value}</>;
};
