import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { TitleBox } from "../../../components/titleBox/TitleBox";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { LimitWidthContainer } from "../../../components/limitWidthContainer/LimitWidthContainer";
import {
    DomainType,
    Period,
    ProductActive,
    RegisteredCompany,
    UpdateDomainAdminInput,
    useMutationUpdateDomainAdminMutation,
    useQueryFindFirstDomainAdminQuery,
} from "../../../../graphql/operation";
import { Button, TextField, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { InputLine } from "../../../components/inputLine/InputLine";
import { RegisteredCompanySelect } from "../../../components/RegisteredCompanySelect/RegisteredCompanySelect";
import moment from "moment";
import NumberFormat from "react-number-format";
import { SwalUtil } from "../../../../utils/swal/SwalUtil";
import { ErrorCatchUtil } from "../../../../utils/errorCatch/ErrorCatchUtil";
import { ErrorConstants } from "../../../../variables/ErrorConstants";
import { PeriodSelect } from "../../../components/PeriodSelect/PeriodSelect";
import { DomainTypeSelect } from "../../../components/domainTypeSelect/DomainTypeSelect";
import { EditorBox } from "../../../components/editorBox/EditorBox";

export const ViewDomainUpdate = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    const initInputData: UpdateDomainAdminInput = {
        productActive: ProductActive.Active,
        domainType: DomainType.CoKr,
        domainUrl: "",
        id: 0,
        period: Period.Annual,
        price: 0,
        registeredCompany: RegisteredCompany.HostingKr,
    };

    const [inputData, setInputData] = useState<UpdateDomainAdminInput>(initInputData);
    const [inputMemo, setInputMemo] = useState<string>("");
    const { data: domainData } = useQueryFindFirstDomainAdminQuery({
        variables: {
            input: {
                id: Number(id),
            },
        },
    });
    const [updateDomainAdmin] = useMutationUpdateDomainAdminMutation();

    useEffect(() => {
        if (domainData) {
            setInputData({
                productActive: domainData.findFirstDomainAdmin.productActive,
                createdAt: domainData.findFirstDomainAdmin.createdAt,
                domainType: domainData.findFirstDomainAdmin.domainType,
                domainUrl: domainData.findFirstDomainAdmin.domainUrl,
                id: domainData.findFirstDomainAdmin.id,
                lastBillingDate: domainData.findFirstDomainAdmin.lastBillingDate,
                memo: domainData.findFirstDomainAdmin.memo,
                nextBillingDate: domainData.findFirstDomainAdmin.nextBillingDate,
                period: domainData.findFirstDomainAdmin.period,
                price: domainData.findFirstDomainAdmin.price,
                registeredCompany: domainData.findFirstDomainAdmin.registeredCompany,
                userId: domainData.findFirstDomainAdmin.user?.id,
            });
            setInputMemo(domainData.findFirstDomainAdmin.memo);
        }
    }, [domainData]);

    const handler = {
        onUpdate: () => {
            updateDomainAdmin({
                variables: {
                    input: {
                        ...inputData,
                        memo: inputMemo,
                    },
                },
            })
                .then(() => {
                    return SwalUtil.ok({
                        title: "상품 정보가 수정되었습니다.",
                        icon: "success",
                    });
                })
                .then(() => {
                    navigate(-1);
                })
                .catch(
                    ErrorCatchUtil({
                        [ErrorConstants.notPermission]: () => {
                            return SwalUtil.ok({
                                title: "권한이 없습니다.",
                                icon: "error",
                            });
                        },
                        [ErrorConstants.noData]: () => {
                            return SwalUtil.ok({
                                title: "수정하려는 상품이 없습니다.",
                                icon: "error",
                            });
                        },
                        [ErrorConstants.default]: e => {
                            return SwalUtil.ok({
                                title: e,
                                icon: "error",
                            });
                        },
                    }),
                );
        },
        onCancel: () => {
            navigate(-1);
        },
    };

    return (
        <>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <LimitWidthContainer>
                    <TitleBox title="도메인 - 수정" />
                    <div className="bg-lightPaper dark:bg-darkPaper p-5">
                        <InputLine title={"도메인"}>
                            <TextField
                                variant="outlined"
                                label="도메인"
                                color="primary"
                                fullWidth
                                size="small"
                                value={inputData.domainUrl}
                                onChange={value => {
                                    setInputData({
                                        ...inputData,
                                        domainUrl: value.currentTarget.value,
                                    });
                                }}
                            />
                        </InputLine>
                        <InputLine title="도메인 종류">
                            <DomainTypeSelect
                                domainType={inputData.domainType}
                                onChangeState={value => {
                                    setInputData({
                                        ...inputData,
                                        domainType: value,
                                    });
                                }}
                            />
                        </InputLine>
                        <InputLine title={"등록기관"}>
                            <RegisteredCompanySelect
                                registeredCompany={inputData.registeredCompany}
                                onChangeState={value => {
                                    setInputData({
                                        ...inputData,
                                        registeredCompany: value,
                                    });
                                }}
                            />
                        </InputLine>
                        <InputLine title="시작일">
                            <MobileDatePicker
                                onChange={value => {
                                    setInputData({
                                        ...inputData,
                                        createdAt: moment(value).utc().toISOString(),
                                    });
                                }}
                                value={inputData.createdAt}
                                renderInput={params => (
                                    <TextField fullWidth size="small" {...params} />
                                )}
                            />
                        </InputLine>
                        <InputLine title="납부주기">
                            <PeriodSelect
                                period={inputData.period}
                                onChangeState={value => {
                                    setInputData({
                                        ...inputData,
                                        period: value,
                                    });
                                }}
                            />
                        </InputLine>
                        <InputLine title="다음결제일">
                            <MobileDatePicker
                                onChange={value => {
                                    setInputData({
                                        ...inputData,
                                        nextBillingDate: moment(value).utc().toISOString(),
                                    });
                                }}
                                value={inputData.nextBillingDate}
                                renderInput={params => (
                                    <TextField fullWidth size="small" {...params} />
                                )}
                            />
                        </InputLine>
                        <InputLine title="가격">
                            <NumberFormat
                                thousandSeparator={","}
                                suffix=" 원"
                                customInput={TextField}
                                fullWidth
                                size="small"
                                value={inputData.price}
                                onValueChange={value => {
                                    setInputData({
                                        ...inputData,
                                        price: Number(value.value),
                                    });
                                }}
                            />
                        </InputLine>
                        <InputLine title="메모">
                            <EditorBox
                                defaultValue={inputMemo}
                                onChange={value => {
                                    setInputMemo(value);
                                }}
                            />
                        </InputLine>
                        <InputLine title="상태">
                            <ToggleButtonGroup
                                color="primary"
                                size={"small"}
                                value={inputData.productActive}>
                                <ToggleButton
                                    className="w-[90px]"
                                    value={ProductActive.Active}
                                    onClick={(event, value: ProductActive) => {
                                        setInputData({
                                            ...inputData,
                                            productActive: value,
                                        });
                                    }}>
                                    정상
                                </ToggleButton>
                                <ToggleButton
                                    value={ProductActive.Pause}
                                    className="w-[90px]"
                                    onClick={(event, value: ProductActive) => {
                                        setInputData({
                                            ...inputData,
                                            productActive: value,
                                        });
                                    }}>
                                    정지
                                </ToggleButton>
                                <ToggleButton
                                    value={ProductActive.Stop}
                                    className="w-[90px]"
                                    onClick={(event, value: ProductActive) => {
                                        setInputData({
                                            ...inputData,
                                            productActive: value,
                                        });
                                    }}>
                                    만료
                                </ToggleButton>
                                <ToggleButton
                                    value={ProductActive.Delete}
                                    className="w-[90px]"
                                    onClick={(event, value: ProductActive) => {
                                        setInputData({
                                            ...inputData,
                                            productActive: value,
                                        });
                                    }}>
                                    삭제
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </InputLine>
                        <div className="my-10" />
                        <div className="flex w-full justify-center gap-10">
                            <Button
                                variant="contained"
                                color="primary"
                                className="w-[120px]"
                                onClick={handler.onUpdate}>
                                상품수정
                            </Button>
                            <Button
                                variant="contained"
                                color="secondary"
                                className="w-[120px]"
                                onClick={handler.onCancel}>
                                취소
                            </Button>
                        </div>
                    </div>
                </LimitWidthContainer>
            </LocalizationProvider>
        </>
    );
};
