import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
    QueryFindManyUserAdminQuery,
    useQueryCountUserAdminQuery,
    useQueryFindManyUserAdminQuery,
} from "../../../../graphql/operation";
import { LimitWidthContainer } from "../../../components/limitWidthContainer/LimitWidthContainer";
import { TitleBox } from "../../../components/titleBox/TitleBox";
import {
    Button,
    IconButton,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    Tooltip,
} from "@mui/material";
import { NoDataBox } from "../../../components/noDataBox/NoDataBox";
import { ViewPaginationBox } from "../../../components/paginationBox/ViewPagenationBox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBookOpen } from "@fortawesome/free-solid-svg-icons";

interface ITerm {
    term?: string;
}

export const ViewUserList = () => {
    const navigate = useNavigate();
    const { method, take, page } = useParams();
    const location = useLocation();
    const state = location.state as ITerm;

    let takeInput = 15;
    let pageInput = 1;
    let methodInput = "createdAt";
    let searchTerm: string | undefined;

    if (take) {
        takeInput = Number(take);
    }

    if (page) {
        pageInput = Number(page);
    }

    if (method) {
        methodInput = method;
    }

    if (state === null || state === undefined) {
        searchTerm = "";
    } else {
        searchTerm = state.term;
    }

    const { data: userData, loading: userLoading } = useQueryFindManyUserAdminQuery({
        variables: {
            input: {
                take: takeInput,
                page: pageInput,
                method: methodInput,
                term: searchTerm,
            },
        },
    });

    const { data: countData } = useQueryCountUserAdminQuery({
        variables: {
            input: {
                method: methodInput,
                term: searchTerm,
            },
        },
    });

    const handler = {
        onChangePage: (page: number) => {
            navigate(`/user/${methodInput}/${takeInput}/${page}`, {
                state: { term: searchTerm },
            });
        },
    };

    return (
        <>
            <LimitWidthContainer>
                <TitleBox title={`회원 ${countData && `(총 ${countData.countUserAdmin}개)`}`} />
                {!userLoading && (
                    <div className="bg-lightPaper dark:bg-darkPaper p-5">
                        <SearchContainer
                            originSearchTerm={searchTerm}
                            takeInput={takeInput}
                            pageInput={pageInput}
                            methodInput={methodInput}
                        />
                        {userData && userData.findManyUserAdmin.length === 0 && <NoDataBox />}

                        {userData && userData.findManyUserAdmin.length > 0 && (
                            <>
                                <ListContainer userData={userData} />
                                <div className="my-10" />
                            </>
                        )}

                        {countData && (
                            <ViewPaginationBox
                                total={countData.countUserAdmin}
                                size={takeInput}
                                page={pageInput}
                                onChange={handler.onChangePage}
                            />
                        )}
                    </div>
                )}
            </LimitWidthContainer>
        </>
    );
};

const SearchContainer = (props: {
    originSearchTerm: string | undefined;
    takeInput: number;
    pageInput: number;
    methodInput: string;
}) => {
    const navigate = useNavigate();
    const { method } = useParams();

    let searchTerm = "";

    const handler = {
        onMethod: (value: string) => {
            navigate(`/user/${value}/${props.takeInput}/1`, {
                state: { term: props.originSearchTerm },
            });
        },
        onSearch: (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            navigate(`/user/${method}/${props.takeInput}/1`, {
                state: { term: searchTerm },
            });
        },
    };

    return (
        <>
            <div className="w-full flex justify-between items-center mb-5">
                <div className="flex items-center gap-5">
                    <ToggleButtonGroup color="primary" size={"small"}>
                        <ToggleButton
                            className="w-[90px]"
                            value={"createdAt"}
                            onClick={(event, value) => {
                                handler.onMethod(value);
                            }}>
                            등록일자순
                        </ToggleButton>
                        <ToggleButton
                            className="w-[90px]"
                            value={"active"}
                            onClick={(event, value) => {
                                handler.onMethod(value);
                            }}>
                            활성화순
                        </ToggleButton>
                    </ToggleButtonGroup>
                    <form
                        onSubmit={event => {
                            handler.onSearch(event);
                        }}>
                        <TextField
                            variant="outlined"
                            color="primary"
                            type="text"
                            label="검색"
                            size="small"
                            defaultValue={props.originSearchTerm}
                            onChange={value => {
                                searchTerm = value.target.value;
                            }}
                        />
                    </form>
                </div>
                <div className="flex items-center">
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            navigate("/user/create");
                        }}>
                        추가
                    </Button>
                </div>
            </div>
        </>
    );
};

const ListContainer = (props: { userData: QueryFindManyUserAdminQuery | undefined }) => {
    const navigate = useNavigate();

    return (
        <>
            <div className="bg-lightBackground dark:bg-darkBackground rounded text-sm h-[35px] items-center flex">
                <div className="w-[17.5%]">사용자명</div>
                <div className="w-[17.5%]">이름</div>
                <div className="w-[22.5%]">이메일</div>
                <div className="w-[15%]">전화번호</div>
                <div className="w-[7.5%]">도메인</div>
                <div className="w-[7.5%]">호스팅</div>
                <div className="w-[7.5%]">보안인증서</div>
                <div className="w-[5%]">기능</div>
            </div>
            {props.userData &&
                props.userData.findManyUserAdmin.map((value, index) => (
                    <div
                        key={index}
                        className="w-full flex h-[45px] border-b items-center cursor-pointer">
                        <div
                            className="w-[17.5%] truncate"
                            onClick={() => {
                                navigate(`/user/detail/${value.id}`);
                            }}>
                            {value.username}
                        </div>
                        <div className="w-[17.5%] truncate px-2">{value.name}</div>
                        <div className="w-[22.5%]">
                            <Tooltip title={value.email}>
                                <div className="w-full truncate px-2">{value.email}</div>
                            </Tooltip>
                        </div>
                        <div className="w-[15%]">{value.phoneNumber}</div>
                        <div className="w-[7.5%]">
                            {value.domains.length ? value.domains.length : 0}개
                        </div>
                        <div className="w-[7.5%]">
                            {value.hostings.length ? value.hostings.length : 0}개
                        </div>
                        <div className="w-[7.5%]">
                            {value.certificates.length ? value.certificates.length : 0}개
                        </div>
                        <div className="w-[5%] flex justify-between">
                            <Tooltip title="상세보기">
                                <IconButton
                                    color="primary"
                                    onClick={() => {
                                        navigate(`/user/detail/${value.id}`);
                                    }}>
                                    <FontAwesomeIcon icon={faBookOpen} size="sm" />
                                </IconButton>
                            </Tooltip>
                        </div>
                    </div>
                ))}
        </>
    );
};
