import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
    QueryFindManyHostingAdminQuery,
    useMutationSendBillingEmailHostingAdminMutation,
    useQueryCountHostingAdminQuery,
    useQueryFindManyHostingAdminQuery,
} from "../../../../graphql/operation";
import { TitleBox } from "../../../components/titleBox/TitleBox";
import { SearchContainer } from "../../../components/searchContainer/SearchContainer";
import { NoDataBox } from "../../../components/noDataBox/NoDataBox";
import { LimitWidthContainer } from "../../../components/limitWidthContainer/LimitWidthContainer";
import { ViewPaginationBox } from "../../../components/paginationBox/ViewPagenationBox";
import NumberFormat from "react-number-format";
import moment from "moment";
import { IconButton, Tooltip } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faBookOpen,
    faMessage,
    faMoneyBillTransfer,
    faPen,
} from "@fortawesome/free-solid-svg-icons";
import { RegisteredCompanyText } from "../../../components/RegisteredCompanyText/RegisteredCompanyText";
import { SwalUtil } from "../../../../utils/swal/SwalUtil";
import { ErrorCatchUtil } from "../../../../utils/errorCatch/ErrorCatchUtil";
import { ErrorConstants } from "../../../../variables/ErrorConstants";
import { ProductActiveBadge } from "../../../components/productActiveBadge/ProductActiveBadge";

interface ITerm {
    term?: string;
}

export const ViewHostingList = () => {
    const navigate = useNavigate();
    const { method, take, page } = useParams();
    const location = useLocation();
    const state = location.state as ITerm;

    let takeInput = 15;
    let pageInput = 1;
    let methodInput = "createdAt";
    let searchTerm: string | undefined;

    if (take) {
        takeInput = Number(take);
    }

    if (page) {
        pageInput = Number(page);
    }

    if (method) {
        methodInput = method;
    }

    if (state === null || state === undefined) {
        searchTerm = "";
    } else {
        searchTerm = state.term;
    }

    const { data: hostingData, error: hostingError } = useQueryFindManyHostingAdminQuery({
        variables: {
            input: {
                take: takeInput,
                page: pageInput,
                method: methodInput,
                term: searchTerm,
            },
        },
    });

    const { data: countData } = useQueryCountHostingAdminQuery({
        variables: {
            input: {
                method: methodInput,
                term: searchTerm,
            },
        },
    });

    const handler = {
        onChangePage: (page: number) => {
            navigate(`/hosting/${methodInput}/${takeInput}/${page}`, {
                state: { term: searchTerm },
            });
        },
    };

    return (
        <>
            <LimitWidthContainer>
                <TitleBox
                    title={`호스팅 ${countData && `(총 ${countData.countHostingAdmin}개)`}`}
                />
                <div className="bg-lightPaper dark:bg-darkPaper p-5">
                    <SearchContainer
                        originSearchTerm={searchTerm}
                        takeInput={takeInput}
                        pageInput={pageInput}
                        methodInput={methodInput}
                        productType={"hosting"}
                    />
                    {hostingError && <NoDataBox />}

                    {hostingData && hostingData.findManyHostingAdmin.length > 0 && (
                        <>
                            <ListContainer hostingData={hostingData} />
                            <div className="my-10" />
                        </>
                    )}

                    {countData && (
                        <ViewPaginationBox
                            page={pageInput}
                            size={takeInput}
                            total={countData.countHostingAdmin}
                            onChange={handler.onChangePage}
                        />
                    )}
                </div>
            </LimitWidthContainer>
        </>
    );
};

const ListContainer = (props: { hostingData: QueryFindManyHostingAdminQuery | undefined }) => {
    const navigate = useNavigate();

    const [sendBillingEmailHostingAdmin] = useMutationSendBillingEmailHostingAdminMutation();

    const handler = {
        onSendEmail: (productId: number) => {
            sendBillingEmailHostingAdmin({
                variables: {
                    input: {
                        hostingId: productId,
                    },
                },
            })
                .then(() =>
                    SwalUtil.ok({
                        title: "메일 발송이 완료되었습니다.",
                        icon: "success",
                    }),
                )
                .catch(
                    ErrorCatchUtil({
                        [ErrorConstants.enoughDate]: () => {
                            return SwalUtil.ok({
                                title: "납기일이 도래하지 않았습니다.",
                                icon: "error",
                            });
                        },
                        [ErrorConstants.default]: e => {
                            return SwalUtil.ok({
                                title: e,
                                icon: "error",
                            });
                        },
                    }),
                );
        },
    };

    return (
        <>
            <div className="bg-lightBackground dark:bg-darkBackground rounded text-sm h-[35px] items-center flex">
                <div className="w-[22.5%]">도메인명</div>
                <div className="w-[7.5%]">상태</div>
                <div className="w-[10.5%]">등록기관</div>
                <div className="w-[12.5%]">상품금액</div>
                <div className="w-[11.5%]">시작일</div>
                <div className="w-[11.5%]">마지막 결제일</div>
                <div className="w-[11.5%]">다음 결제일</div>
                <div className="w-[12.5%]">기능</div>
            </div>
            {props.hostingData &&
                props.hostingData.findManyHostingAdmin.map((value, index) => (
                    <div
                        key={index}
                        className="w-full flex h-[45px] border-b items-center cursor-pointer">
                        <div
                            className="w-[22.5%]"
                            onClick={() => {
                                navigate(`/hosting/detail/${value.id}`);
                            }}>
                            {value.domainUrl}
                        </div>
                        <div className="w-[7.5%]">
                            <ProductActiveBadge productActive={value.productActive} />
                        </div>
                        <div className="w-[10.5%]">
                            <RegisteredCompanyText registeredCompany={value.registeredCompany} />
                        </div>
                        <div className="w-[12.5%]">
                            <NumberFormat
                                value={value.price}
                                displayType="text"
                                thousandSeparator=","
                                suffix=" 원"
                            />
                        </div>
                        <div className="w-[11.5%]">
                            {moment(value.createdAt).locale("ko").format("YYYY-MM-DD")}
                        </div>
                        <div className="w-[11.5%]">
                            {value.lastBillingDate
                                ? moment(value.lastBillingDate).locale("ko").format("YYYY-MM-DD")
                                : "-"}
                        </div>
                        <div className="w-[11.5%]">
                            {moment(value.nextBillingDate).locale("ko").format("YYYY-MM-DD")}
                        </div>
                        <div className="w-[12.5%] flex justify-between">
                            <Tooltip title={"메일발송"} arrow>
                                <IconButton
                                    color="primary"
                                    onClick={() => {
                                        handler.onSendEmail(value.id);
                                    }}>
                                    <FontAwesomeIcon icon={faMessage} size="sm" />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="상세보기">
                                <IconButton
                                    color="primary"
                                    onClick={() => {
                                        navigate(`/hosting/detail/${value.id}`);
                                    }}>
                                    <FontAwesomeIcon icon={faBookOpen} size="sm" />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="수정" arrow>
                                <IconButton
                                    color="primary"
                                    onClick={() => {
                                        navigate(`/hosting/update/${value.id}`);
                                    }}>
                                    <FontAwesomeIcon icon={faPen} size="sm" />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="납부등록" arrow>
                                <IconButton
                                    color="primary"
                                    onClick={() => {
                                        navigate(`/payment/create/hosting/${value.id}`);
                                    }}>
                                    <FontAwesomeIcon icon={faMoneyBillTransfer} size="sm" />
                                </IconButton>
                            </Tooltip>
                        </div>
                    </div>
                ))}
        </>
    );
};
