import React, { ReactNode } from "react";
import { ViewLayoutAuthHeader } from "./header/ViewLayoutAuthHeader";

export const ViewLayoutAuth = (props: { children: ReactNode }) => {
    return (
        <>
            <div className="bg-lightBackground dark:bg-darkBackground min-h-screen py-5">
                <ViewLayoutAuthHeader NavBar={NavBar} />
                <div className="mt-10" />
                <div>{props.children}</div>
            </div>
        </>
    );
};

const NavBar: INavBar[] = [
    {
        title: "작업요청",
        path: "/request",
    },
    {
        title: "입출금내역",
        path: "/payment",
        sub: [
            { title: "입출금내역", path: "/payment" },
            { title: "납부대상목록", path: "/payment/imminent" },
        ],
    },
    {
        title: "회원",
        path: "/user",
    },
    {
        title: "도메인",
        path: "/domain",
    },
    {
        title: "호스팅",
        path: "/hosting",
    },
    {
        title: "보안인증서",
        path: "/certificate",
        sub: [
            { title: "보안인증서", path: "/certificate" },
            { title: "CSR 생성", path: "/certificate/makeCSR" },
        ],
    },
    {
        title: "디자인",
    },
    {
        title: "이메일",
        path: "/email",
    },
    {
        title: "SMS",
    },
    {
        title: "1:1문의",
        path: "/question",
    },
    {
        title: "관리자",
    },
];

export type INavBar = {
    title: string;
    path?: string;
    sub?: INavBar[];
};
