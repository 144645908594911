import React from "react";
import { Receipts } from "../../../graphql/operation";
import { MenuItem, TextField } from "@mui/material";
import { OnChangeValue } from "../../../types/ArgsInterface";

export const ReceiptsSelect = (props: {
    receipts: Receipts;
    onChangeState: OnChangeValue<Receipts>;
}) => {
    return (
        <>
            <TextField
                variant="outlined"
                label="영수증 종류"
                color="primary"
                fullWidth
                select
                value={props.receipts}
                size="small"
                onChange={value => {
                    let receipts: Receipts;
                    if (value.target.value === "Company") {
                        receipts = Receipts.Company;
                    } else if (value.target.value === "Cash") {
                        receipts = Receipts.Cash;
                    } else {
                        receipts = Receipts.None;
                    }
                    props.onChangeState(receipts);
                }}>
                <MenuItem value={Receipts.Company}>세금계산서</MenuItem>
                <MenuItem value={Receipts.Cash}>현금영수증</MenuItem>
                <MenuItem value={Receipts.None}>발행없음</MenuItem>
            </TextField>
        </>
    );
};
