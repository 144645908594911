import React from "react";
import { RegisteredCompany } from "../../../graphql/operation";
import { MenuItem, TextField } from "@mui/material";
import { OnChangeValue } from "../../../types/ArgsInterface";

export const RegisteredCompanySelect = (props: {
    registeredCompany: RegisteredCompany;
    onChangeState: OnChangeValue<RegisteredCompany>;
    readOnly?: boolean;
}) => {
    return (
        <>
            <TextField
                variant="outlined"
                label="등록기관"
                color="primary"
                fullWidth
                select
                value={props.registeredCompany}
                size="small"
                onChange={value => {
                    let registeredCompany: RegisteredCompany;
                    if (value.target.value === "HostingKr") {
                        registeredCompany = RegisteredCompany.HostingKr;
                    } else if (value.target.value === "A2Hosting") {
                        registeredCompany = RegisteredCompany.A2Hosting;
                    } else if (value.target.value === "Hostinger") {
                        registeredCompany = RegisteredCompany.Hostinger;
                    } else if (value.target.value === "Hostinger") {
                        registeredCompany = RegisteredCompany.GoDaddy;
                    } else if (value.target.value === "Gabia") {
                        registeredCompany = RegisteredCompany.Gabia;
                    } else if (value.target.value === "Sectigo") {
                        registeredCompany = RegisteredCompany.Sectigo;
                    } else if (value.target.value === "Cafe24") {
                        registeredCompany = RegisteredCompany.Cafe24;
                    } else if (value.target.value === "Etc") {
                        registeredCompany = RegisteredCompany.Etc;
                    } else {
                        registeredCompany = RegisteredCompany.Goldennet;
                    }
                    props.onChangeState(registeredCompany);
                }}>
                <MenuItem value={RegisteredCompany.Goldennet}>골든넷</MenuItem>
                <MenuItem value={RegisteredCompany.HostingKr}>호스팅KR</MenuItem>
                <MenuItem value={RegisteredCompany.A2Hosting}>A2Hosting</MenuItem>
                <MenuItem value={RegisteredCompany.Hostinger}>Hostinger</MenuItem>
                <MenuItem value={RegisteredCompany.GoDaddy}>GoDaddy</MenuItem>
                <MenuItem value={RegisteredCompany.Gabia}>가비아</MenuItem>
                <MenuItem value={RegisteredCompany.Sectigo}>Sectigo</MenuItem>
                <MenuItem value={RegisteredCompany.Cafe24}>카페24</MenuItem>
                <MenuItem value={RegisteredCompany.Etc}>ETC</MenuItem>
            </TextField>
        </>
    );
};
