import { makeVar } from "@apollo/client";
import { User } from "../graphql/operation";
import { ThemeType } from "../provider/themeSync/ThemeSyncProvider";

export const SystemVars = {
    loadingView: makeVar<boolean>(false),
};

export const UserVars = {
    isLoggedIn: makeVar<boolean>(false),
    userInfo: makeVar<User | undefined>(undefined),
    authToken: makeVar<string | undefined>(undefined),
};

export const LayoutVars = {
    isMobile: makeVar<boolean>(true),
    isBackDrop: makeVar<boolean>(false),
    isSideBar: makeVar<boolean>(false),
    darkMode: makeVar<ThemeType>("light"),
};
