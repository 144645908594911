import React from "react";
import { CertificateType } from "../../../graphql/operation";

export const CertificateTypeText = (props: { type: CertificateType }) => {
    let value = "";

    if (props.type === CertificateType.EssentialsWildcard) {
        value = "E.Wildcard";
    } else {
        value = "Essentials";
    }

    return <>{value}</>;
};
