import React, { Fragment } from "react";
import { OnChangeNumber, OnChangeValue } from "../../../types/ArgsInterface";
import { useQuerySearchCertificateLazyQuery } from "../../../graphql/operation";
import { Dialog, DialogTitle, TextField } from "@mui/material";

export const SearchCertificateModal = (props: {
    open: boolean;
    onClose: OnChangeNumber;
    onApplyCertificate: OnChangeValue<ISearchCertificate>;
}) => {
    const [
        searchCertificateLazyQuery,
        {
            data: searchCertificateData,
            loading: searchCertificateLoading,
            error: searchCertificateError,
        },
    ] = useQuerySearchCertificateLazyQuery();

    const handler = {
        onSearch: async (value: string) => {
            await searchCertificateLazyQuery({
                variables: {
                    input: {
                        searchValue: value,
                    },
                },
            });
        },
    };

    return (
        <>
            <Dialog open={props.open} onClose={props.onClose}>
                <DialogTitle>보안인증서 상품 검색</DialogTitle>
                <div className="flex w-full w-[300px] flex-col px-5 lg:w-[600px]">
                    <div className="flex w-full justify-between">
                        <TextField
                            fullWidth
                            placeholder="보안인증서 도메인 검색"
                            onChange={value => {
                                handler.onSearch(value.currentTarget.value);
                            }}
                        />
                    </div>
                </div>
                <div className="h-[500px] overflow-y-auto overflow-x-hidden px-5">
                    {!searchCertificateLoading && searchCertificateError && (
                        <div className="flex h-[500px] w-full items-center justify-center">
                            No Search Data.
                        </div>
                    )}
                    {!searchCertificateLoading &&
                        searchCertificateData &&
                        searchCertificateData.searchCertificateAdmin.length === 0 && (
                            <div className="flex h-[500px] w-full items-center justify-center">
                                No Search Data.
                            </div>
                        )}

                    {!searchCertificateLoading &&
                        !searchCertificateError &&
                        searchCertificateData &&
                        searchCertificateData.searchCertificateAdmin.map((value, index) => (
                            <Fragment key={index}>
                                {value && (
                                    <div
                                        className="flex-start flex items-center border-b py-3 pl-5"
                                        onClick={() => {
                                            props.onApplyCertificate({
                                                certificateId: value.id,
                                                certificateDomainUrl: value.domainUrl,
                                            });
                                        }}>
                                        {value.domainUrl}
                                    </div>
                                )}
                            </Fragment>
                        ))}
                </div>
            </Dialog>
        </>
    );
};

export interface ISearchCertificate {
    certificateId: number;
    certificateDomainUrl: string;
}
