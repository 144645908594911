import React, { useEffect, useState } from "react";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { TitleBox } from "../../../../components/titleBox/TitleBox";
import { LimitWidthContainer } from "../../../../components/limitWidthContainer/LimitWidthContainer";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { Button, Divider, TextField } from "@mui/material";
import { InputLine } from "../../../../components/inputLine/InputLine";
import {
    PaymentMethod,
    ProductType,
    Receipts,
    ReceiptsStatus,
    UpdatePaymentForCertificateAdminInput,
    useMutationUpdatePaymentForCertificateAdminMutation,
    useQueryFindFirstCompanyAdminLazyQuery,
    useQueryFindFirstPaymentAdminQuery,
} from "../../../../../graphql/operation";
import { useNavigate, useParams } from "react-router-dom";
import { ProductTypeSelect } from "../../../../components/productTypeSelect/ProductTypeSelect";
import NumberFormat from "react-number-format";
import { PaymentMethodSelect } from "../../../../components/paymentMethodSelect/PaymentMethodSelect";
import moment from "moment";
import { ReceiptsSelect } from "../../../../components/receiptsSelect/ReceiptsSelect";
import { ReceiptsStatusSelect } from "../../../../components/receiptsStatusSelect/ReceiptsStatusSelect";
import { GraphQLError } from "graphql";
import { ErrorConstants } from "../../../../../variables/ErrorConstants";
import { ErrorCatchUtil } from "../../../../../utils/errorCatch/ErrorCatchUtil";
import { SwalUtil } from "../../../../../utils/swal/SwalUtil";
import { DaumPostCodeModal } from "../../../../components/daumPostCodeModal";
import { Address } from "react-daum-postcode";
import { EditorBox } from "../../../../components/editorBox/EditorBox";

export const ViewPaymentUpdateCertificate = () => {
    const navigate = useNavigate();
    const { paymentId } = useParams();

    const initPaymentData: UpdatePaymentForCertificateAdminInput = {
        paymentId: Number(paymentId),
        receipts: Receipts.None,
        receiptsStatus: ReceiptsStatus.None,
        domainUrl: "",
        memo: "",
        paymentMethod: PaymentMethod.WithoutBank,
        price: 0,
        type: ProductType.Certificate,
        certificateId: 0,
    };

    const [openModal, setOpenModal] = useState<boolean>(false);
    const [inputData, setInputData] =
        useState<UpdatePaymentForCertificateAdminInput>(initPaymentData);
    const [inputMemo, setInputMemo] = useState<string>("");

    const { data: paymentData } = useQueryFindFirstPaymentAdminQuery({
        variables: {
            input: {
                paymentId: Number(paymentId),
            },
        },
    });
    const [findFirstCompanyAdminLazyQuery] = useQueryFindFirstCompanyAdminLazyQuery();
    const [updatePaymentForCertificateAdmin] =
        useMutationUpdatePaymentForCertificateAdminMutation();

    useEffect(() => {
        if (paymentData) {
            setInputData({
                ...inputData,
                certificateId: paymentData.findFirstPaymentAdmin.certificate?.id || 0,
                domainUrl: paymentData.findFirstPaymentAdmin.domainUrl,
                type: paymentData.findFirstPaymentAdmin.type,
                price: paymentData.findFirstPaymentAdmin.price,
                paymentMethod: paymentData.findFirstPaymentAdmin.paymentMethod,
                depositor: paymentData.findFirstPaymentAdmin.depositor,
                memo: paymentData.findFirstPaymentAdmin.memo,
                createdAt: paymentData.findFirstPaymentAdmin.createdAt,
                nextBillingDate: paymentData.findFirstPaymentAdmin.certificate?.nextBillingDate,
                lastBillingDate: paymentData.findFirstPaymentAdmin.certificate?.lastBillingDate,
                phoneNumber: paymentData.findFirstPaymentAdmin.phoneNumber,
                receipts: paymentData.findFirstPaymentAdmin.receipts,
                receiptsStatus: paymentData.findFirstPaymentAdmin.receiptsStatus,
                registrationNumber: paymentData.findFirstPaymentAdmin.company?.registrationNumber,
                zipCode: paymentData.findFirstPaymentAdmin.company?.zipCode,
                address1: paymentData.findFirstPaymentAdmin.company?.address1,
                address2: paymentData.findFirstPaymentAdmin.company?.address2,
                businessSector: paymentData.findFirstPaymentAdmin.company?.businessSector,
                businessStatus: paymentData.findFirstPaymentAdmin.company?.businessStatus,
                ceo: paymentData.findFirstPaymentAdmin.company?.ceo,
                companyName: paymentData.findFirstPaymentAdmin.company?.companyName,
                email: paymentData.findFirstPaymentAdmin.company?.email,
                registrationFile: paymentData.findFirstPaymentAdmin.company?.registrationFile,
            });
            setInputMemo(paymentData.findFirstPaymentAdmin.memo);
        }
    }, [paymentData]);

    const handler = {
        onCancel: () => {
            navigate(-1);
        },
        onChangeProductType: (value: ProductType) => {
            setInputData({
                ...inputData,
                type: value,
            });
        },
        onChangeReceipts: (value: Receipts) => {
            setInputData({
                ...inputData,
                receipts: value,
            });
        },
        onChangeReceiptsStatus: (value: ReceiptsStatus) => {
            setInputData({
                ...inputData,
                receiptsStatus: value,
            });
        },
        onChangePaymentMethod: (value: PaymentMethod) => {
            setInputData({
                ...inputData,
                paymentMethod: value,
            });
        },
        onChangeAddress: (data: Address) => {
            let fullAddress = data.address;
            let extraAddress = "";

            if (data.addressType === "R") {
                if (data.bname !== "") {
                    extraAddress += data.bname;
                }
                if (data.buildingName !== "") {
                    if (data.buildingName !== "") {
                        extraAddress +=
                            extraAddress !== "" ? `, ${data.buildingName}` : data.buildingName;
                    }
                    fullAddress += extraAddress !== "" ? ` (${extraAddress})` : "";
                }
            }

            setInputData({
                ...inputData,
                zipCode: data.zonecode,
                address1: fullAddress,
                address2: "",
            });
        },
        onLoadPhoneNumber: () => {
            if (paymentData && paymentData.findFirstPaymentAdmin.user) {
                setInputData({
                    ...inputData,
                    phoneNumber: paymentData.findFirstPaymentAdmin.user.phoneNumber,
                });
            }
        },
        handleModalOpen: () => {
            setOpenModal(true);
        },
        handleModalClose: () => {
            setOpenModal(false);
        },
        onLoadCompany: () => {
            if (
                !paymentData ||
                !paymentData.findFirstPaymentAdmin.user ||
                !paymentData.findFirstPaymentAdmin.user.company
            ) {
                throw new GraphQLError(ErrorConstants.noData);
            }

            findFirstCompanyAdminLazyQuery({
                variables: {
                    input: {
                        registrationNumber:
                            paymentData.findFirstPaymentAdmin.user.company.registrationNumber,
                    },
                },
            })
                .then(({ data }) => {
                    if (data) {
                        setInputData({
                            ...inputData,
                            registrationNumber: data.findFirstCompanyAdmin.registrationNumber,
                            companyName: data.findFirstCompanyAdmin.companyName,
                            ceo: data.findFirstCompanyAdmin.ceo,
                            businessStatus: data.findFirstCompanyAdmin.businessStatus,
                            businessSector: data.findFirstCompanyAdmin.businessSector,
                            zipCode: data.findFirstCompanyAdmin.zipCode,
                            address1: data.findFirstCompanyAdmin.address1,
                            address2: data.findFirstCompanyAdmin.address2,
                            email: data.findFirstCompanyAdmin.email,
                            registrationFile: data.findFirstCompanyAdmin.registrationFile,
                        });
                    }
                })
                .catch(
                    ErrorCatchUtil({
                        [ErrorConstants.notPermission]: () => {
                            return SwalUtil.ok({
                                title: "이미 존재하는 회원 아이디입니다.",
                                icon: "error",
                            });
                        },
                        [ErrorConstants.noData]: () => {
                            return SwalUtil.ok({
                                title: "등록된 사업자 정보가 없습니다.",
                                icon: "error",
                            });
                        },
                        [ErrorConstants.default]: e => {
                            return SwalUtil.ok({
                                title: e,
                                icon: "error",
                            });
                        },
                    }),
                );
        },
        onUpdate: () => {
            updatePaymentForCertificateAdmin({
                variables: {
                    input: {
                        ...inputData,
                        memo: inputMemo,
                    },
                },
            })
                .then(() => {
                    return SwalUtil.ok({
                        title: "납부 정보가 정상적으로 수정되었습니다.",
                        icon: "success",
                    });
                })
                .then(() => {
                    navigate(-1);
                })
                .catch(
                    ErrorCatchUtil({
                        [ErrorConstants.notPermission]: () => {
                            return SwalUtil.ok({
                                title: "권한이 없습니다.",
                                icon: "error",
                            });
                        },
                        [ErrorConstants.noData]: () => {
                            return SwalUtil.ok({
                                title: "등록된 납부정보가 없습니다.",
                                icon: "error",
                            });
                        },
                        [ErrorConstants.default]: e => {
                            return SwalUtil.ok({
                                title: e,
                                icon: "error",
                            });
                        },
                    }),
                );
        },
    };

    return (
        <>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <LimitWidthContainer>
                    <TitleBox title="납부정보 수정 - 보안인증서" />
                    <div className="bg-lightPaper dark:bg-darkPaper p-5">
                        <InputLine title="도메인">
                            <TextField
                                variant="outlined"
                                label="도메인"
                                color="primary"
                                fullWidth
                                size="small"
                                inputProps={{ readOnly: true }}
                                value={inputData.domainUrl}
                                onChange={value => {
                                    setInputData({
                                        ...inputData,
                                        domainUrl: value.currentTarget.value,
                                    });
                                }}
                            />
                        </InputLine>
                        <InputLine title="상품종류">
                            <ProductTypeSelect
                                productType={inputData.type}
                                onChangeState={handler.onChangeProductType}
                                readOnly={true}
                            />
                        </InputLine>
                        <InputLine title="금액">
                            <NumberFormat
                                thousandSeparator={","}
                                suffix=" 원"
                                customInput={TextField}
                                fullWidth
                                label="금액"
                                size="small"
                                value={inputData.price}
                                onValueChange={value => {
                                    setInputData({
                                        ...inputData,
                                        price: Number(value.value),
                                    });
                                }}
                            />
                        </InputLine>
                        <InputLine title="결제방법">
                            <PaymentMethodSelect
                                paymentMethod={inputData.paymentMethod}
                                onChangeState={handler.onChangePaymentMethod}
                            />
                        </InputLine>
                        <InputLine title="입금자명">
                            <TextField
                                variant="outlined"
                                label="입금자명"
                                color="primary"
                                fullWidth
                                size="small"
                                value={inputData.depositor || ""}
                                onChange={value => {
                                    setInputData({
                                        ...inputData,
                                        depositor: value.currentTarget.value,
                                    });
                                }}
                            />
                        </InputLine>
                        <InputLine title="메모">
                            <EditorBox
                                defaultValue={inputMemo}
                                onChange={value => {
                                    setInputMemo(value);
                                }}
                            />
                        </InputLine>
                        <InputLine title="납부일">
                            <MobileDatePicker
                                onChange={value => {
                                    setInputData({
                                        ...inputData,
                                        createdAt: moment(value).utc().toISOString(),
                                        lastBillingDate: moment(value).utc().toISOString(),
                                    });
                                }}
                                value={inputData.createdAt || undefined}
                                renderInput={params => (
                                    <TextField fullWidth size="small" {...params} />
                                )}
                            />
                        </InputLine>
                        <InputLine title="다음 납부일">
                            <MobileDatePicker
                                onChange={value => {
                                    setInputData({
                                        ...inputData,
                                        nextBillingDate: moment(value).utc().toISOString(),
                                    });
                                }}
                                value={inputData.nextBillingDate || undefined}
                                renderInput={params => (
                                    <TextField fullWidth size="small" {...params} />
                                )}
                            />
                        </InputLine>
                        <InputLine title="영수증">
                            <ReceiptsSelect
                                receipts={inputData.receipts}
                                onChangeState={handler.onChangeReceipts}
                            />
                        </InputLine>
                        <InputLine title="발행 상태">
                            <ReceiptsStatusSelect
                                receiptsStatus={inputData.receiptsStatus}
                                onChangeState={handler.onChangeReceiptsStatus}
                            />
                        </InputLine>
                        {inputData.receipts === Receipts.Company && (
                            <>
                                <Divider className="my-5" />
                                <InputLine title="사업자등록번호">
                                    {paymentData?.findFirstPaymentAdmin.user?.company
                                        ?.registrationNumber && (
                                        <Button
                                            variant="contained"
                                            sx={{ width: 150 }}
                                            onClick={handler.onLoadCompany}>
                                            회원정보불러오기
                                        </Button>
                                    )}
                                    <TextField
                                        variant="outlined"
                                        label="사업자등록번호"
                                        color="primary"
                                        fullWidth
                                        size="small"
                                        value={inputData.registrationNumber || ""}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={value => {
                                            setInputData({
                                                ...inputData,
                                                registrationNumber: value.currentTarget.value,
                                            });
                                        }}
                                    />
                                </InputLine>
                                <InputLine title="대표자">
                                    <TextField
                                        variant="outlined"
                                        label="대표자"
                                        color="primary"
                                        fullWidth
                                        size="small"
                                        type="text"
                                        value={inputData.ceo}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={value => {
                                            setInputData({
                                                ...inputData,
                                                ceo: value.currentTarget.value,
                                            });
                                        }}
                                    />
                                </InputLine>
                                <InputLine title="업종">
                                    <TextField
                                        variant="outlined"
                                        label="업종"
                                        color="primary"
                                        fullWidth
                                        size="small"
                                        type="text"
                                        value={inputData.businessStatus}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={value => {
                                            setInputData({
                                                ...inputData,
                                                businessStatus: value.currentTarget.value,
                                            });
                                        }}
                                    />
                                </InputLine>
                                <InputLine title="업태">
                                    <TextField
                                        variant="outlined"
                                        label="업태"
                                        color="primary"
                                        fullWidth
                                        size="small"
                                        type="text"
                                        value={inputData.businessSector}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={value => {
                                            setInputData({
                                                ...inputData,
                                                businessSector: value.currentTarget.value,
                                            });
                                        }}
                                    />
                                </InputLine>
                                <InputLine title="주소">
                                    <TextField
                                        variant="outlined"
                                        label="우편번호"
                                        color="primary"
                                        className="w-[200px]"
                                        size="small"
                                        type="text"
                                        value={inputData.zipCode}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{ readOnly: true }}
                                        onClick={handler.handleModalOpen}
                                    />
                                    <TextField
                                        variant="outlined"
                                        label="주소"
                                        color="primary"
                                        fullWidth
                                        size="small"
                                        type="text"
                                        value={inputData.address1}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{ readOnly: true }}
                                        onClick={handler.handleModalOpen}
                                    />
                                    <TextField
                                        variant="outlined"
                                        label="나머지 주소"
                                        color="primary"
                                        fullWidth
                                        size="small"
                                        type="text"
                                        value={inputData.address2}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={value => {
                                            setInputData({
                                                ...inputData,
                                                address2: value.currentTarget.value,
                                            });
                                        }}
                                    />
                                </InputLine>
                                <InputLine title="이메일">
                                    <TextField
                                        variant="outlined"
                                        label="이메일"
                                        color="primary"
                                        fullWidth
                                        size="small"
                                        type="text"
                                        value={inputData.email}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={value => {
                                            setInputData({
                                                ...inputData,
                                                email: value.currentTarget.value,
                                            });
                                        }}
                                    />
                                </InputLine>
                                <InputLine title="사업자등록증 첨부">
                                    <TextField
                                        variant="outlined"
                                        label="사업자등록증 첨부"
                                        color="primary"
                                        fullWidth
                                        size="small"
                                        type="text"
                                        value={inputData.registrationFile}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{ readOnly: true }}
                                    />
                                </InputLine>
                            </>
                        )}
                        {inputData.receipts === Receipts.Cash && (
                            <>
                                <Divider className="my-5" />
                                <InputLine title="현금영수증 전화번호">
                                    {paymentData &&
                                        paymentData.findFirstPaymentAdmin.user?.phoneNumber && (
                                            <Button
                                                variant="contained"
                                                sx={{ width: 150 }}
                                                onClick={handler.onLoadPhoneNumber}>
                                                회원정보불러오기
                                            </Button>
                                        )}
                                    <TextField
                                        variant="outlined"
                                        label="현금영수증 전화번호"
                                        color="primary"
                                        fullWidth
                                        size="small"
                                        type="text"
                                        value={inputData.phoneNumber}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{ readOnly: true }}
                                    />
                                </InputLine>
                            </>
                        )}
                        <div className="my-10" />
                        <div className="flex w-full justify-center gap-10">
                            <Button
                                variant="contained"
                                color="primary"
                                className="w-[120px]"
                                onClick={handler.onUpdate}>
                                납부정보변경
                            </Button>
                            <Button
                                variant="contained"
                                color="secondary"
                                className="w-[120px]"
                                onClick={handler.onCancel}>
                                취소
                            </Button>
                        </div>
                    </div>
                </LimitWidthContainer>
            </LocalizationProvider>

            <DaumPostCodeModal
                open={openModal}
                onChangeAddress={handler.onChangeAddress}
                onClose={handler.handleModalClose}
            />
        </>
    );
};
