import { ThemeOptions } from "@mui/material";
import ColorConstants from "../../variables/ColorConstants.json";

export class ThemeUtil {
    static light(): ThemeOptions {
        return {
            palette: {
                mode: "light",
                primary: {
                    main: ColorConstants.lightPrimaryMain,
                    light: ColorConstants.lightPrimaryLight,
                    dark: ColorConstants.lightPrimaryDark,
                    contrastText: ColorConstants.lightPrimaryContrast,
                },
                background: {
                    default: ColorConstants.lightBackground,
                    paper: ColorConstants.lightPaper,
                },
                text: {
                    primary: ColorConstants.lightText,
                    secondary: "rgba(0,0,0,0.54)",
                    disabled: ColorConstants.lightDisabledText,
                },
                secondary: {
                    main: ColorConstants.secondaryMain,
                    light: ColorConstants.secondaryLight,
                    dark: ColorConstants.secondaryDark,
                    contrastText: "#ffffff",
                },
                divider: ColorConstants.lightBorder,
            },
            typography: {
                fontFamily: "GmarketSans",
            },
        };
    }

    static dark(): ThemeOptions {
        return {
            palette: {
                mode: "dark",
                primary: {
                    main: ColorConstants.darkPrimaryMain,
                    light: ColorConstants.darkPrimaryLight,
                    dark: ColorConstants.darkPrimaryDark,
                    contrastText: ColorConstants.darkPrimaryContrast,
                },
                background: {
                    default: ColorConstants.darkBackground,
                    paper: ColorConstants.darkPaper,
                },
                text: {
                    primary: ColorConstants.darkPrimaryText,
                    secondary: "rgba(255,255,255,0.7)",
                    disabled: "rgba(255,255,255,0.5)",
                },
                secondary: {
                    main: ColorConstants.secondaryMain,
                    light: ColorConstants.secondaryLight,
                    dark: ColorConstants.secondaryDark,
                    contrastText: "#ffffff",
                },
                divider: ColorConstants.darkBorder,
            },
            typography: {
                fontFamily: "S-CoreDream",
            },
        };
    }
}
