import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
    CreatePaymentForCertificateAdminInput,
    PaymentMethod,
    ProductType,
    Receipts,
    ReceiptsStatus,
    useMutationCreatePaymentForCertificateAdminMutation,
    useQueryFindFirstCertificateAdminQuery,
    useQueryFindFirstCompanyAdminLazyQuery,
} from "../../../../../graphql/operation";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { TitleBox } from "../../../../components/titleBox/TitleBox";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { LimitWidthContainer } from "../../../../components/limitWidthContainer/LimitWidthContainer";
import { InputLine } from "../../../../components/inputLine/InputLine";
import { Button, Checkbox, Divider, TextField } from "@mui/material";
import { ProductTypeSelect } from "../../../../components/productTypeSelect/ProductTypeSelect";
import NumberFormat from "react-number-format";
import { PaymentMethodSelect } from "../../../../components/paymentMethodSelect/PaymentMethodSelect";
import moment from "moment";
import { SwalUtil } from "../../../../../utils/swal/SwalUtil";
import { ErrorCatchUtil } from "../../../../../utils/errorCatch/ErrorCatchUtil";
import { ErrorConstants } from "../../../../../variables/ErrorConstants";
import { ReceiptsSelect } from "../../../../components/receiptsSelect/ReceiptsSelect";
import { ReceiptsStatusSelect } from "../../../../components/receiptsStatusSelect/ReceiptsStatusSelect";
import { GraphQLError } from "graphql";
import { Address } from "react-daum-postcode";
import { DaumPostCodeModal } from "../../../../components/daumPostCodeModal";
import { EditorBox } from "../../../../components/editorBox/EditorBox";

export const ViewPaymentCreateCertificate = () => {
    const navigate = useNavigate();
    const { certificateId } = useParams();

    const initPaymentData: CreatePaymentForCertificateAdminInput = {
        checkRequest: false,
        receipts: Receipts.None,
        receiptsStatus: ReceiptsStatus.None,
        domainUrl: "",
        memo: "",
        paymentMethod: PaymentMethod.WithoutBank,
        price: 0,
        type: ProductType.Certificate,
        certificateId: Number(certificateId),
    };

    const [openModal, setOpenModal] = useState<boolean>(false);
    const [inputData, setInputData] =
        useState<CreatePaymentForCertificateAdminInput>(initPaymentData);
    const [inputMemo, setInputMemo] = useState<string>("");

    const { data: certificateData } = useQueryFindFirstCertificateAdminQuery({
        variables: {
            input: {
                id: Number(certificateId),
            },
        },
    });

    const [findFirstCompanyAdminLazyQuery] = useQueryFindFirstCompanyAdminLazyQuery();

    useEffect(() => {
        if (certificateData) {
            setInputData({
                ...inputData,
                domainUrl: certificateData.findFirstCertificateAdmin.domainUrl,
                price: certificateData.findFirstCertificateAdmin.price,
                userId: certificateData.findFirstCertificateAdmin.userId,
                nextBillingDate: certificateData.findFirstCertificateAdmin.nextBillingDate,
            });
        }
    }, [certificateData]);

    const [createPaymentForCertificateAdmin] =
        useMutationCreatePaymentForCertificateAdminMutation();

    const handler = {
        onChangeProductType: (value: ProductType) => {
            setInputData({
                ...inputData,
                type: value,
            });
        },
        onChangePaymentMethod: (value: PaymentMethod) => {
            setInputData({
                ...inputData,
                paymentMethod: value,
            });
        },
        onChangeReceipts: (value: Receipts) => {
            setInputData({
                ...inputData,
                receipts: value,
            });
        },
        onChangeReceiptsStatus: (value: ReceiptsStatus) => {
            setInputData({
                ...inputData,
                receiptsStatus: value,
            });
        },
        onCancel: () => {
            navigate(-1);
        },
        handleModalOpen: () => {
            setOpenModal(true);
        },
        handleModalClose: () => {
            setOpenModal(false);
        },
        onChangeAddress: (data: Address) => {
            let fullAddress = data.address;
            let extraAddress = "";

            if (data.addressType === "R") {
                if (data.bname !== "") {
                    extraAddress += data.bname;
                }
                if (data.buildingName !== "") {
                    if (data.buildingName !== "") {
                        extraAddress +=
                            extraAddress !== "" ? `, ${data.buildingName}` : data.buildingName;
                    }
                    fullAddress += extraAddress !== "" ? ` (${extraAddress})` : "";
                }
            }

            setInputData({
                ...inputData,
                zipCode: data.zonecode,
                address1: fullAddress,
                address2: "",
            });
        },
        onSave: () => {
            createPaymentForCertificateAdmin({
                variables: {
                    input: {
                        ...inputData,
                        memo: inputMemo,
                    },
                },
            })
                .then(() => {
                    return SwalUtil.ok({
                        title: "납부 정보가 정상적으로 등록되었습니다.",
                        icon: "success",
                    });
                })
                .then(() => {
                    navigate(-1);
                })
                .catch(() => {
                    ErrorCatchUtil({
                        [ErrorConstants.notPermission]: () => {
                            return SwalUtil.ok({
                                title: "권한이 없습니다.",
                                icon: "error",
                            });
                        },
                        [ErrorConstants.default]: e => {
                            return SwalUtil.ok({
                                title: e,
                                icon: "error",
                            });
                        },
                    });
                });
        },
        onLoadCompany: () => {
            if (
                !certificateData ||
                !certificateData.findFirstCertificateAdmin.user ||
                !certificateData.findFirstCertificateAdmin.user.company
            ) {
                throw new GraphQLError(ErrorConstants.noData);
            }

            findFirstCompanyAdminLazyQuery({
                variables: {
                    input: {
                        registrationNumber:
                            certificateData.findFirstCertificateAdmin.user.company
                                .registrationNumber,
                    },
                },
            })
                .then(({ data }) => {
                    if (data) {
                        setInputData({
                            ...inputData,
                            registrationNumber: data.findFirstCompanyAdmin.registrationNumber,
                            companyName: data.findFirstCompanyAdmin.companyName,
                            ceo: data.findFirstCompanyAdmin.ceo,
                            businessStatus: data.findFirstCompanyAdmin.businessStatus,
                            businessSector: data.findFirstCompanyAdmin.businessSector,
                            zipCode: data.findFirstCompanyAdmin.zipCode,
                            address1: data.findFirstCompanyAdmin.address1,
                            address2: data.findFirstCompanyAdmin.address2,
                            email: data.findFirstCompanyAdmin.email,
                            registrationFile: data.findFirstCompanyAdmin.registrationFile,
                        });
                    }
                })
                .catch(() => {
                    ErrorCatchUtil({
                        [ErrorConstants.notPermission]: () => {
                            return SwalUtil.ok({
                                title: "이미 존재하는 회원 아이디입니다.",
                                icon: "error",
                            });
                        },
                        [ErrorConstants.noData]: () => {
                            return SwalUtil.ok({
                                title: "등록된 사업자 정보가 없습니다.",
                                icon: "error",
                            });
                        },
                        [ErrorConstants.default]: e => {
                            return SwalUtil.ok({
                                title: e,
                                icon: "error",
                            });
                        },
                    });
                });
        },
        onLoadPhoneNumber: () => {
            if (certificateData && certificateData.findFirstCertificateAdmin.user) {
                setInputData({
                    ...inputData,
                    phoneNumber: certificateData.findFirstCertificateAdmin.user.phoneNumber,
                });
            }
        },
    };

    return (
        <>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <LimitWidthContainer>
                    <TitleBox title="납부정보 추가 - 보안인증서" />
                    <div className="bg-lightPaper dark:bg-darkPaper p-5">
                        <InputLine title="도메인">
                            <TextField
                                variant="outlined"
                                label="도메인"
                                color="primary"
                                fullWidth
                                size="small"
                                inputProps={{ readOnly: true }}
                                value={inputData.domainUrl}
                                onChange={value => {
                                    setInputData({
                                        ...inputData,
                                        domainUrl: value.currentTarget.value,
                                    });
                                }}
                            />
                        </InputLine>
                        <InputLine title="상품종류">
                            <ProductTypeSelect
                                productType={inputData.type}
                                onChangeState={handler.onChangeProductType}
                                readOnly={true}
                            />
                        </InputLine>
                        <InputLine title="금액">
                            <NumberFormat
                                thousandSeparator={","}
                                suffix=" 원"
                                customInput={TextField}
                                fullWidth
                                label="금액"
                                size="small"
                                value={inputData.price}
                                onValueChange={value => {
                                    setInputData({
                                        ...inputData,
                                        price: Number(value.value),
                                    });
                                }}
                            />
                        </InputLine>
                        <InputLine title="결제방법">
                            <PaymentMethodSelect
                                paymentMethod={inputData.paymentMethod}
                                onChangeState={handler.onChangePaymentMethod}
                            />
                        </InputLine>
                        <InputLine title="입금자명">
                            <TextField
                                variant="outlined"
                                label="입금자명"
                                color="primary"
                                fullWidth
                                size="small"
                                value={inputData.depositor || ""}
                                onChange={value => {
                                    setInputData({
                                        ...inputData,
                                        depositor: value.currentTarget.value,
                                    });
                                }}
                            />
                        </InputLine>
                        <InputLine title="메모">
                            <EditorBox
                                defaultValue={inputMemo}
                                onChange={value => {
                                    setInputMemo(value);
                                }}
                            />
                        </InputLine>
                        <InputLine title="납부일">
                            <MobileDatePicker
                                onChange={value => {
                                    setInputData({
                                        ...inputData,
                                        createdAt: moment(value).utc().toISOString(),
                                        lastBillingDate: moment(value).utc().toISOString(),
                                    });
                                }}
                                value={inputData.createdAt || undefined}
                                renderInput={params => (
                                    <TextField fullWidth size="small" {...params} />
                                )}
                            />
                        </InputLine>
                        <InputLine title="다음 납부일">
                            <MobileDatePicker
                                onChange={value => {
                                    setInputData({
                                        ...inputData,
                                        nextBillingDate: moment(value).utc().toISOString(),
                                    });
                                }}
                                value={inputData.nextBillingDate || undefined}
                                renderInput={params => (
                                    <TextField fullWidth size="small" {...params} />
                                )}
                            />
                        </InputLine>
                        <InputLine title={"작업요청 등록"}>
                            <Checkbox
                                checked={inputData.checkRequest}
                                color="primary"
                                onChange={value => {
                                    setInputData({
                                        ...inputData,
                                        checkRequest: value.target.checked,
                                    });
                                }}
                                inputProps={{ "aria-label": "작업요청 등록" }}
                            />{" "}
                            작업요청 등록
                        </InputLine>
                        <InputLine title="영수증">
                            <ReceiptsSelect
                                receipts={inputData.receipts}
                                onChangeState={handler.onChangeReceipts}
                            />
                        </InputLine>
                        <InputLine title="발행 상태">
                            <ReceiptsStatusSelect
                                receiptsStatus={inputData.receiptsStatus}
                                onChangeState={handler.onChangeReceiptsStatus}
                            />
                        </InputLine>
                        {inputData.receipts === Receipts.Company && (
                            <>
                                <Divider className="my-5" />
                                <InputLine title="사업자등록번호">
                                    {certificateData?.findFirstCertificateAdmin.user?.company
                                        ?.registrationNumber && (
                                        <Button
                                            variant="contained"
                                            sx={{ width: 150 }}
                                            onClick={handler.onLoadCompany}>
                                            회원정보불러오기
                                        </Button>
                                    )}
                                    <TextField
                                        variant="outlined"
                                        label="사업자등록번호"
                                        color="primary"
                                        fullWidth
                                        size="small"
                                        value={inputData.registrationNumber || ""}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={value => {
                                            setInputData({
                                                ...inputData,
                                                registrationNumber: value.currentTarget.value,
                                            });
                                        }}
                                    />
                                </InputLine>
                                <InputLine title="대표자">
                                    <TextField
                                        variant="outlined"
                                        label="대표자"
                                        color="primary"
                                        fullWidth
                                        size="small"
                                        type="text"
                                        value={inputData.ceo}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={value => {
                                            setInputData({
                                                ...inputData,
                                                ceo: value.currentTarget.value,
                                            });
                                        }}
                                    />
                                </InputLine>
                                <InputLine title="업종">
                                    <TextField
                                        variant="outlined"
                                        label="업종"
                                        color="primary"
                                        fullWidth
                                        size="small"
                                        type="text"
                                        value={inputData.businessStatus}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={value => {
                                            setInputData({
                                                ...inputData,
                                                businessStatus: value.currentTarget.value,
                                            });
                                        }}
                                    />
                                </InputLine>
                                <InputLine title="업태">
                                    <TextField
                                        variant="outlined"
                                        label="업태"
                                        color="primary"
                                        fullWidth
                                        size="small"
                                        type="text"
                                        value={inputData.businessSector}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={value => {
                                            setInputData({
                                                ...inputData,
                                                businessSector: value.currentTarget.value,
                                            });
                                        }}
                                    />
                                </InputLine>
                                <InputLine title="주소">
                                    <TextField
                                        variant="outlined"
                                        label="우편번호"
                                        color="primary"
                                        className="w-[200px]"
                                        size="small"
                                        type="text"
                                        value={inputData.zipCode}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{ readOnly: true }}
                                        onClick={handler.handleModalOpen}
                                    />
                                    <TextField
                                        variant="outlined"
                                        label="주소"
                                        color="primary"
                                        fullWidth
                                        size="small"
                                        type="text"
                                        value={inputData.address1}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{ readOnly: true }}
                                        onClick={handler.handleModalOpen}
                                    />
                                    <TextField
                                        variant="outlined"
                                        label="나머지 주소"
                                        color="primary"
                                        fullWidth
                                        size="small"
                                        type="text"
                                        value={inputData.address2}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={value => {
                                            setInputData({
                                                ...inputData,
                                                address2: value.currentTarget.value,
                                            });
                                        }}
                                    />
                                </InputLine>
                                <InputLine title="이메일">
                                    <TextField
                                        variant="outlined"
                                        label="이메일"
                                        color="primary"
                                        fullWidth
                                        size="small"
                                        type="text"
                                        value={inputData.email}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={value => {
                                            setInputData({
                                                ...inputData,
                                                email: value.currentTarget.value,
                                            });
                                        }}
                                    />
                                </InputLine>
                                <InputLine title="사업자등록증 첨부">
                                    <TextField
                                        variant="outlined"
                                        label="사업자등록증 첨부"
                                        color="primary"
                                        fullWidth
                                        size="small"
                                        type="text"
                                        value={inputData.address2}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{ readOnly: true }}
                                    />
                                </InputLine>
                            </>
                        )}
                        {inputData.receipts === Receipts.Cash && (
                            <>
                                <Divider className="my-5" />
                                <InputLine title="현금영수증 전화번호">
                                    {certificateData?.findFirstCertificateAdmin.user
                                        ?.phoneNumber && (
                                        <Button
                                            variant="contained"
                                            sx={{ width: 150 }}
                                            onClick={handler.onLoadPhoneNumber}>
                                            회원정보불러오기
                                        </Button>
                                    )}
                                    <TextField
                                        variant="outlined"
                                        label="현금영수증 전화번호"
                                        color="primary"
                                        fullWidth
                                        size="small"
                                        type="text"
                                        value={inputData.phoneNumber}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{ readOnly: true }}
                                    />
                                </InputLine>
                            </>
                        )}
                        <div className="my-10" />
                        <div className="flex w-full justify-center gap-10">
                            <Button
                                variant="contained"
                                color="primary"
                                className="w-[120px]"
                                onClick={handler.onSave}>
                                납부등록
                            </Button>
                            <Button
                                variant="contained"
                                color="secondary"
                                className="w-[120px]"
                                onClick={handler.onCancel}>
                                취소
                            </Button>
                        </div>
                    </div>
                </LimitWidthContainer>
            </LocalizationProvider>

            <DaumPostCodeModal
                open={openModal}
                onChangeAddress={handler.onChangeAddress}
                onClose={handler.handleModalClose}
            />
        </>
    );
};
