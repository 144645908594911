import React from "react";
import { useNavigate } from "react-router-dom";
import { CreateAdminInput, useMutationCreateAdminMutation } from "../../../../graphql/operation";
import { useForm } from "react-hook-form";
import { OnChangeValue, OnVoidFunction } from "../../../../types/ArgsInterface";
import { SwalUtil } from "../../../../utils/swal/SwalUtil";
import { ErrorCatchUtil } from "../../../../utils/errorCatch/ErrorCatchUtil";
import { ErrorConstants } from "../../../../variables/ErrorConstants";
import { Button, TextField } from "@mui/material";

export const ViewAuthSignUp = () => {
    const navigate = useNavigate();

    const [createAdminMutation] = useMutationCreateAdminMutation();

    const handler = {
        onCreate: (inputValue: ICreateAdminInput) => {
            if (inputValue.password !== inputValue.confirm) {
                return SwalUtil.ok({
                    title: "비밀번호가 일치하지 않습니다.",
                    icon: "error",
                });
            }

            createAdminMutation({
                variables: {
                    input: {
                        username: inputValue.username,
                        password: inputValue.password,
                        name: inputValue.name,
                        nickName: inputValue.nickName,
                        phoneNumber: inputValue.phoneNumber,
                        email: inputValue.email,
                    },
                },
            })
                .then(() => {
                    return SwalUtil.ok({
                        title: "회원 가입이 완료되었습니다.",
                        text: "관리자 검토 후 로그인이 가능합니다.",
                        icon: "success",
                    });
                })
                .then(() => {
                    navigate("/");
                })
                .catch(
                    ErrorCatchUtil({
                        [ErrorConstants.alreadyUser]: () => {
                            return SwalUtil.ok({
                                title: "이미 존재하는 사용자입니다.",
                                icon: "error",
                            });
                        },
                        [ErrorConstants.default]: e => {
                            return SwalUtil.ok({
                                title: e,
                                icon: "error",
                            });
                        },
                    }),
                );
        },
        onLogin: () => {
            navigate("/auth/signin");
        },
    };

    return (
        <>
            <div className="w-full h-screen flex justify-center items-center bg-lightBackground dark:bg-darkBackground">
                <SignUpBox onCreate={handler.onCreate} onLogin={handler.onLogin} />
            </div>
        </>
    );
};

const SignUpBox = (props: {
    onCreate: OnChangeValue<ICreateAdminInput>;
    onLogin: OnVoidFunction;
}) => {
    const {
        register,
        getValues,
        handleSubmit,
        watch,
        formState: { isValid, errors },
    } = useForm<ICreateAdminInput>({ mode: "onBlur" });

    const handler = {
        onCreate: () => {
            if (!isValid) {
                return SwalUtil.ok({
                    title: "입력값을 확인해주세요.",
                    icon: "error",
                });
            }

            props.onCreate(getValues());
        },
    };

    return (
        <>
            <form
                className="w-full mx-5 lg:mx-0 max-w-[500px] bg-lightPaper dark:bg-darkPaper border p-5 rounded flex flex-col gap-5"
                onSubmit={handleSubmit(handler.onCreate)}>
                <div className="text-xl font-bold mb-10">
                    Goldennet Administrator - 관리자 회원가입
                </div>
                <TextField
                    {...register("username", {
                        required: "사용자명이 입력되지 않았습니다.",
                    })}
                    fullWidth
                    color="primary"
                    variant="outlined"
                    label="사용자명"
                    size="small"
                    type="text"
                    helperText={
                        errors.username ? errors.username.message : "사용자명을 입력해주세요."
                    }
                    error={Boolean(errors.username)}
                />
                <TextField
                    {...register("password", {
                        required: "The password has not been entered.",
                        minLength: {
                            value: 8,
                            message: "비밀번호는 최소 8자리 이상 입력해주세요.",
                        },
                    })}
                    fullWidth
                    color="primary"
                    variant="outlined"
                    label="비밀번호"
                    size="small"
                    type="password"
                    helperText={
                        errors.password
                            ? errors.password.message
                            : "비밀번호는 최소 8자리 이상 입력해주세요."
                    }
                    error={Boolean(errors.password)}
                />
                <TextField
                    {...register("confirm", {
                        required: "The password has not been entered.",
                        minLength: {
                            value: 8,
                            message: "비밀번호는 최소 8자리 이상 입력해주세요.",
                        },
                        validate: value =>
                            value === watch("password") || "입력한 비밀번호가 일치하지 않습니다.",
                    })}
                    fullWidth
                    color="primary"
                    variant="outlined"
                    label="비밀번호 확인"
                    size="small"
                    type="password"
                    helperText={
                        errors.confirm
                            ? errors.confirm.message
                            : "비밀번호를 다시 한 번 입력해주세요."
                    }
                    error={Boolean(errors.confirm)}
                />
                <TextField
                    {...register("nickName", {
                        required: "닉네임이 입력되지 않았습니다.",
                    })}
                    fullWidth
                    color="primary"
                    variant="outlined"
                    label="닉네임"
                    size="small"
                    type="text"
                    helperText={
                        errors.nickName
                            ? errors.nickName.message
                            : "답변글 등에 사용할 닉네임을 입력해주세요."
                    }
                    error={Boolean(errors.nickName)}
                />
                <TextField
                    {...register("name", {
                        required: "이름을 입력해주세요.",
                    })}
                    fullWidth
                    color="primary"
                    variant="outlined"
                    label="이름"
                    size="small"
                    type="text"
                    helperText={errors.name ? errors.name.message : "이름을 입력해주세요."}
                    error={Boolean(errors.name)}
                />
                <TextField
                    {...register("phoneNumber", {
                        required: "전화번호를 입력해주세요.",
                    })}
                    fullWidth
                    color="primary"
                    variant="outlined"
                    label="전화번호"
                    size="small"
                    type="text"
                    helperText={
                        errors.phoneNumber ? errors.phoneNumber.message : "전화번호를 입력해주세요."
                    }
                    error={Boolean(errors.phoneNumber)}
                />
                <TextField
                    {...register("email", {
                        required: "이메일을 입력해주세요.",
                    })}
                    fullWidth
                    color="primary"
                    variant="outlined"
                    label="이메일"
                    size="small"
                    type="email"
                    helperText={
                        errors.phoneNumber ? errors.phoneNumber.message : "이메일을 입력해주세요."
                    }
                    error={Boolean(errors.phoneNumber)}
                />
                <div className="mb-5" />
                <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    disabled={!isValid}
                    type="submit">
                    Sign Up
                </Button>
                <Button variant="contained" color="secondary" fullWidth onClick={props.onLogin}>
                    Sign In
                </Button>
            </form>
        </>
    );
};

interface ICreateAdminInput extends CreateAdminInput {
    confirm: string;
}
