import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
    QueryFindManyQuestionForAllAdminQuery,
    QuestionStatus,
    useQueryCountQuestionForAllAdminQuery,
    useQueryFindManyQuestionForAllAdminQuery,
} from "../../../../graphql/operation";
import { TitleBox } from "../../../components/titleBox/TitleBox";
import { NoDataBox } from "../../../components/noDataBox/NoDataBox";
import { LimitWidthContainer } from "../../../components/limitWidthContainer/LimitWidthContainer";
import { ProductTypeText } from "../../../components/productTypeText/ProductTypeText";
import { TextField, ToggleButton, ToggleButtonGroup } from "@mui/material";

interface ITerm {
    term?: string;
}

export const ViewQuestionList = () => {
    const navigate = useNavigate();
    const { method, take, page } = useParams();
    const location = useLocation();
    const state = location.state as ITerm;

    let takeInput = 15;
    let pageInput = 1;
    let methodInput = "All";
    let searchTerm: string | undefined;

    if (take) {
        takeInput = Number(take);
    }

    if (page) {
        pageInput = Number(page);
    }

    if (method) {
        methodInput = method;
    }

    if (state === null || state === undefined) {
        searchTerm = "";
    } else {
        searchTerm = state.term;
    }

    const { data: questionData, error: QuestionError } = useQueryFindManyQuestionForAllAdminQuery({
        variables: {
            input: {
                take: takeInput,
                page: pageInput,
                method: methodInput,
                term: searchTerm,
            },
        },
    });

    const { data: countData } = useQueryCountQuestionForAllAdminQuery({
        variables: {
            input: {
                method: methodInput,
                term: searchTerm,
            },
        },
    });

    const handler = {
        onChangePage: (page: number) => {
            navigate(`/question/${methodInput}/${takeInput}/${page}`);
        },
    };

    return (
        <>
            <LimitWidthContainer>
                <TitleBox title="1:1문의" />
                <div className="bg-lightPaper dark:bg-darkPaper p-5">
                    <QuestionSearchContainer
                        originSearchTerm={searchTerm}
                        takeInput={takeInput}
                        pageInput={pageInput}
                        methodInput={methodInput}
                    />
                    {QuestionError && <NoDataBox />}

                    {questionData && questionData.findManyQuestionForAllAdmin.length > 0 && (
                        <>
                            <ListContainer questionData={questionData} />
                            <div className="my-10" />
                        </>
                    )}
                </div>
            </LimitWidthContainer>
        </>
    );
};

const QuestionSearchContainer = (props: {
    originSearchTerm: string | undefined;
    takeInput: number;
    pageInput: number;
    methodInput: string;
}) => {
    const navigate = useNavigate();
    const { method } = useParams();

    let searchTerm = "";

    const handler = {
        onMethod: (value: string) => {
            navigate(`/question/${value}/${props.takeInput}/1`, {
                state: { term: props.originSearchTerm },
            });
        },
        onSearch: (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            navigate(`/question/${method}/${props.takeInput}/1`, {
                state: { term: searchTerm },
            });
        },
    };

    return (
        <>
            <div className="w-full flex justify-between items-center mb-5">
                <div className="flex items-center gap-5">
                    <ToggleButtonGroup color="primary" size={"small"} value={props.methodInput}>
                        <ToggleButton
                            className="w-[90px]"
                            value={"All"}
                            onClick={(event, value) => {
                                handler.onMethod(value);
                            }}>
                            전체
                        </ToggleButton>
                        <ToggleButton
                            className="w-[90px]"
                            value={QuestionStatus.Requested}
                            onClick={(event, value) => {
                                handler.onMethod(value);
                            }}>
                            문의
                        </ToggleButton>
                        <ToggleButton
                            className="w-[90px]"
                            value={QuestionStatus.Proceeding}
                            onClick={(event, value) => {
                                handler.onMethod(value);
                            }}>
                            처리중
                        </ToggleButton>
                    </ToggleButtonGroup>
                    <form
                        onSubmit={event => {
                            handler.onSearch(event);
                        }}>
                        <TextField
                            variant="outlined"
                            color="primary"
                            type="text"
                            label="도메인 검색"
                            size="small"
                            defaultValue={props.originSearchTerm}
                            onChange={value => {
                                searchTerm = value.target.value;
                            }}
                        />
                    </form>
                </div>
            </div>
        </>
    );
};

const ListContainer = (props: {
    questionData: QueryFindManyQuestionForAllAdminQuery | undefined;
}) => {
    const navigate = useNavigate();

    return (
        <>
            <div className="bg-lightBackground dark:bg-darkBackground rounded text-sm h-[35px] items-center flex">
                <div className="w-[12.5%]">도메인명</div>
                <div className="w-[7.5%]">상품</div>
                <div className="w-[60%]">문의 제목</div>
                <div className="w-[7.5%]">상태</div>
                <div className="w-[12.5%]">기능</div>
            </div>
            {props.questionData &&
                props.questionData.findManyQuestionForAllAdmin.map((value, index) => (
                    <div key={index} className="w-full flex h-[45px] border-b items-center">
                        <div className="w-[12.5]">{value.domainUrl}</div>
                        <div className="w-[7.5%]">
                            <ProductTypeText productType={value.productType} />
                        </div>
                        <div className="w-[60%] truncate">{value.subject}</div>
                        <div className="w-[7.5%]">상태값</div>
                        <div className="w-[12.5%] flex justify-between">기능아이콘</div>
                    </div>
                ))}
        </>
    );
};
