import React, { ReactNode } from "react";

export const InputLine = (props: { title: string; children: ReactNode }) => {
    return (
        <>
            <div className="flex w-full items-center py-5">
                <div className="flex w-[100px] lg:w-[30%] items-center">{props.title}</div>
                <div className="flex flex-1 gap-5 relative items-center">{props.children}</div>
            </div>
        </>
    );
};
