import React from "react";
import { LimitWidthContainer } from "../../../components/limitWidthContainer/LimitWidthContainer";
import { TitleBox } from "../../../components/titleBox/TitleBox";
import {
    ProductType,
    useMutationSendBillingEmailCertificateAdminMutation,
    useMutationSendBillingEmailDomainAdminMutation,
    useMutationSendBillingEmailEmailAdminMutation,
    useMutationSendBillingEmailHostingAdminMutation,
    useQueryFindManyPaymentRequestAdminQuery,
} from "../../../../graphql/operation";
import { NoDataBox } from "../../../components/noDataBox/NoDataBox";
import moment from "moment";
import { NumberFormatText } from "../../../components/numberFormatText/NumberFormatText";
import { ProductTypeText } from "../../../components/productTypeText/ProductTypeText";
import { IconButton, Tooltip } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMessage, faMoneyBillTransfer, faPen, faServer } from "@fortawesome/free-solid-svg-icons";
import { SwalUtil } from "../../../../utils/swal/SwalUtil";
import { ErrorCatchUtil } from "../../../../utils/errorCatch/ErrorCatchUtil";
import { ErrorConstants } from "../../../../variables/ErrorConstants";
import { useNavigate } from "react-router-dom";

export const ViewPaymentImminent = () => {
    const navigate = useNavigate();
    const { data: paymentRequestData } = useQueryFindManyPaymentRequestAdminQuery();

    const [sendBillingEmailHostingAdmin] = useMutationSendBillingEmailHostingAdminMutation();
    const [sendBillingEmailEmailAdmin] = useMutationSendBillingEmailEmailAdminMutation();
    const [sendBillingEmailDomainAdmin] = useMutationSendBillingEmailDomainAdminMutation();
    const [sendBillingEmailCertificateAdmin] =
        useMutationSendBillingEmailCertificateAdminMutation();

    const handler = {
        onSendEmail: (productType: ProductType, productId: number) => {
            if (productType === ProductType.Hosting) {
                sendBillingEmailHostingAdmin({
                    variables: {
                        input: {
                            hostingId: productId,
                        },
                    },
                })
                    .then(() =>
                        SwalUtil.ok({
                            title: "메일 발송이 완료되었습니다.",
                            icon: "success",
                        }),
                    )
                    .catch(
                        ErrorCatchUtil({
                            [ErrorConstants.enoughDate]: () => {
                                return SwalUtil.ok({
                                    title: "납기일이 도래하지 않았습니다.",
                                    icon: "error",
                                });
                            },
                            [ErrorConstants.default]: e => {
                                return SwalUtil.ok({
                                    title: e,
                                    icon: "error",
                                });
                            },
                        }),
                    );
            } else if (productType === ProductType.Email) {
                sendBillingEmailEmailAdmin({
                    variables: {
                        input: {
                            emailId: productId,
                        },
                    },
                })
                    .then(() =>
                        SwalUtil.ok({
                            title: "메일 발송이 완료되었습니다.",
                            icon: "success",
                        }),
                    )
                    .catch(
                        ErrorCatchUtil({
                            [ErrorConstants.enoughDate]: () => {
                                return SwalUtil.ok({
                                    title: "납기일이 도래하지 않았습니다.",
                                    icon: "error",
                                });
                            },
                            [ErrorConstants.default]: e => {
                                return SwalUtil.ok({
                                    title: e,
                                    icon: "error",
                                });
                            },
                        }),
                    );
            } else if (productType === ProductType.Domain) {
                sendBillingEmailDomainAdmin({
                    variables: {
                        input: {
                            domainId: productId,
                        },
                    },
                })
                    .then(() =>
                        SwalUtil.ok({
                            title: "메일 발송이 완료되었습니다.",
                            icon: "success",
                        }),
                    )
                    .catch(
                        ErrorCatchUtil({
                            [ErrorConstants.enoughDate]: () => {
                                return SwalUtil.ok({
                                    title: "납기일이 도래하지 않았습니다.",
                                    icon: "error",
                                });
                            },
                            [ErrorConstants.default]: e => {
                                return SwalUtil.ok({
                                    title: e,
                                    icon: "error",
                                });
                            },
                        }),
                    );
            } else if (productType === ProductType.Certificate) {
                sendBillingEmailCertificateAdmin({
                    variables: {
                        input: {
                            certificateId: productId,
                        },
                    },
                })
                    .then(() =>
                        SwalUtil.ok({
                            title: "메일 발송이 완료되었습니다.",
                            icon: "success",
                        }),
                    )
                    .catch(
                        ErrorCatchUtil({
                            [ErrorConstants.enoughDate]: () => {
                                return SwalUtil.ok({
                                    title: "납기일이 도래하지 않았습니다.",
                                    icon: "error",
                                });
                            },
                            [ErrorConstants.default]: e => {
                                return SwalUtil.ok({
                                    title: e,
                                    icon: "error",
                                });
                            },
                        }),
                    );
            }
        },
    };

    return (
        <>
            <LimitWidthContainer>
                <TitleBox title={"납부대상목록"} />
                <div className="bg-lightPaper dark:bg-darkPaper p-5">
                    <div className="flex py-2 bg-lightBackground dark:bg-darkBackground">
                        <div className="w-[15%] flex justify-center">납부예정일자</div>
                        <div className="w-[15%] flex justify-center">상품종류</div>
                        <div className="w-[20%] flex justify-center">최소결제금액</div>
                        <div className="w-[15%] flex justify-center text-sm truncate">사용자</div>
                        <div className="w-[20%] flex justify-center text-sm truncate">도메인</div>
                        <div className="w-[15%] flex justify-center">기능</div>
                    </div>
                    {!paymentRequestData && <NoDataBox />}
                    {paymentRequestData &&
                        paymentRequestData.findManyPaymentRequestAdmin.map((value, index) => (
                            <div key={index} className="flex py-2 border-b">
                                {value && (
                                    <>
                                        <div className="w-[15%] flex justify-center items-center">
                                            {moment(value.paymentDate)
                                                .locale("ko")
                                                .format("YYYY-MM-DD")}
                                        </div>
                                        <div className="w-[15%] flex justify-center items-center">
                                            <ProductTypeText productType={value.productType} />
                                        </div>
                                        <div className="w-[20%] flex justify-center items-center">
                                            <NumberFormatText value={value.price} />
                                        </div>
                                        <div className="w-[15%] flex justify-center items-center text-sm truncate">
                                            {value.username || "(사용자없음)"}
                                        </div>
                                        <div className="w-[20%] flex justify-center items-center text-sm truncate">
                                            {value.domainUrl}
                                        </div>
                                        <div className="w-[15%] flex justify-center">
                                            <Tooltip title={"메일발송"} arrow>
                                                <IconButton
                                                    color="primary"
                                                    onClick={() => {
                                                        handler.onSendEmail(
                                                            value.productType,
                                                            value.productId,
                                                        );
                                                    }}>
                                                    <FontAwesomeIcon icon={faMessage} size="sm" />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="납부등록" arrow>
                                                <IconButton
                                                    color="primary"
                                                    onClick={() => {
                                                        navigate(
                                                            `/payment/create/${value.productType.toLowerCase()}/${
                                                                value.productId
                                                            }`,
                                                        );
                                                    }}>
                                                    <FontAwesomeIcon
                                                        icon={faMoneyBillTransfer}
                                                        size="sm"
                                                    />
                                                </IconButton>
                                            </Tooltip>
                                            <div className="w-[1px] border-r" />
                                            <Tooltip title="상품수정" arrow>
                                                <IconButton
                                                    color="primary"
                                                    onClick={() => {
                                                        navigate(
                                                            `/${value.productType.toLowerCase()}/update/${
                                                                value.productId
                                                            }`,
                                                        );
                                                    }}>
                                                    <FontAwesomeIcon icon={faPen} size="sm" />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="상품상세보기" arrow>
                                                <IconButton
                                                    color="primary"
                                                    onClick={() => {
                                                        navigate(
                                                            `/${value.productType.toLowerCase()}/detail/${
                                                                value.productId
                                                            }`,
                                                        );
                                                    }}>
                                                    <FontAwesomeIcon icon={faServer} size="sm" />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                    </>
                                )}
                            </div>
                        ))}
                </div>
            </LimitWidthContainer>
        </>
    );
};
