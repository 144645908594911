import React from "react";
import { Period } from "../../../graphql/operation";
import { OnChangeValue } from "../../../types/ArgsInterface";
import { MenuItem, TextField } from "@mui/material";

export const PeriodSelect = (props: {
    period: Period;
    onChangeState: OnChangeValue<Period>;
    readOnly?: boolean;
}) => {
    return (
        <TextField
            variant="outlined"
            label="납부주기"
            color="primary"
            fullWidth
            select
            value={props.period}
            size="small"
            onChange={value => {
                let period: Period;
                if (value.target.value === "Annual") {
                    period = Period.Annual;
                } else if (value.target.value === "TwoYear") {
                    period = Period.TwoYear;
                } else if (value.target.value === "ThreeYear") {
                    period = Period.ThreeYear;
                } else if (value.target.value === "FiveYear") {
                    period = Period.FiveYear;
                } else if (value.target.value === "TenYear") {
                    period = Period.TenYear;
                } else if (value.target.value === "Semi") {
                    period = Period.Semi;
                } else if (value.target.value === "Three") {
                    period = Period.Three;
                } else {
                    period = Period.Monthly;
                }
                props.onChangeState(period);
            }}>
            <MenuItem value={Period.TenYear}>10년납</MenuItem>
            <MenuItem value={Period.FiveYear}>5년납</MenuItem>
            <MenuItem value={Period.ThreeYear}>3년납</MenuItem>
            <MenuItem value={Period.TwoYear}>2년납</MenuItem>
            <MenuItem value={Period.Annual}>연납</MenuItem>
            <MenuItem value={Period.Semi}>반년납</MenuItem>
            <MenuItem value={Period.Three}>3개월납</MenuItem>
            <MenuItem value={Period.Monthly}>월납</MenuItem>
        </TextField>
    );
};
