import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { LimitWidthContainer } from "../../../components/limitWidthContainer/LimitWidthContainer";
import { TitleBox } from "../../../components/titleBox/TitleBox";
import {
    QueryFindFirstPaymentAdminQuery,
    Receipts,
    useQueryFindFirstPaymentAdminQuery,
} from "../../../../graphql/operation";
import { NoDataBox } from "../../../components/noDataBox/NoDataBox";
import { LeftUserContainer } from "../../../components/leftUserContainer/LeftUserContainer";
import { Button, Divider } from "@mui/material";
import { InputLine } from "../../../components/inputLine/InputLine";
import { ProductTypeText } from "../../../components/productTypeText/ProductTypeText";
import NumberFormat from "react-number-format";
import { PaymentMethodText } from "../../../components/paymentMethodText/PaymentMethodText";
import moment from "moment";
import { ReceiptsText } from "../../../components/receiptsText/ReceiptsText";
import { ReceiptsStatusText } from "../../../components/receiptsStatusText/ReceiptsStatusText";
import { OnVoidFunction } from "../../../../types/ArgsInterface";

export const ViewPaymentDetail = () => {
    const navigate = useNavigate();
    const { paymentId } = useParams();

    const {
        data: paymentData,
        loading: paymentLoading,
        error: PaymentError,
    } = useQueryFindFirstPaymentAdminQuery({
        variables: {
            input: {
                paymentId: Number(paymentId),
            },
        },
    });

    const handler = {
        onNavigateModify: () => {
            navigate(`/payment/update/${paymentData?.findFirstPaymentAdmin.type}/${paymentId}`);
        },
    };

    return (
        <>
            <LimitWidthContainer>
                <TitleBox title="납부정보" />

                {!paymentData && PaymentError && (
                    <div className="bg-lightPaper dark:bg-darkPaper p-5 rounded">
                        <NoDataBox />
                    </div>
                )}

                {!paymentLoading && paymentData && (
                    <div className="w-full flex justify-between gap-10">
                        <div className="w-[300px]">
                            <div className="bg-lightPaper dark:bg-darkPaper p-5  rounded w-full">
                                <LeftUserContainer
                                    userId={paymentData.findFirstPaymentAdmin.user?.id}
                                />
                            </div>
                        </div>
                        <div className="w-[900px]">
                            <div className="w-full bg-lightPaper dark:bg-darkPaper p-5 rounded">
                                <PaymentDetailContainer
                                    paymentData={paymentData}
                                    onNavigateModify={handler.onNavigateModify}
                                />
                            </div>
                        </div>
                    </div>
                )}
            </LimitWidthContainer>
        </>
    );
};

const PaymentDetailContainer = (props: {
    paymentData: QueryFindFirstPaymentAdminQuery;
    onNavigateModify: OnVoidFunction;
}) => {
    return (
        <>
            {!props.paymentData && <NoDataBox />}
            {props.paymentData && (
                <>
                    <div className="w-full flex justify-between">
                        <div className="flex-1">
                            <TitleBox title="상세정보" notBottomMargin={true} />
                        </div>
                        <div className="flex items-center gap-5">
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={props.onNavigateModify}>
                                정보수정
                            </Button>
                            {props.paymentData.findFirstPaymentAdmin.user?.id ? (
                                <Button variant="contained" color="warning">
                                    연결해제
                                </Button>
                            ) : (
                                <Button variant="contained" color="primary">
                                    회원연결
                                </Button>
                            )}
                        </div>
                    </div>
                    <InputLine title="도메인">
                        {props.paymentData.findFirstPaymentAdmin.domainUrl}
                    </InputLine>
                    <InputLine title="상품종류">
                        <ProductTypeText
                            productType={props.paymentData.findFirstPaymentAdmin.type}
                        />
                    </InputLine>
                    <InputLine title="금액">
                        <NumberFormat
                            displayType="text"
                            thousandSeparator=","
                            suffix=" 원"
                            value={props.paymentData.findFirstPaymentAdmin.price}
                        />
                    </InputLine>
                    <InputLine title="결제방법">
                        <PaymentMethodText
                            paymentMethod={props.paymentData.findFirstPaymentAdmin.paymentMethod}
                        />
                    </InputLine>
                    <InputLine title="입금자명">
                        {props.paymentData.findFirstPaymentAdmin.depositor}
                    </InputLine>
                    <InputLine title="납부일">
                        {moment(props.paymentData.findFirstPaymentAdmin.createdAt)
                            .locale("ko")
                            .format("YYYY-MM-DD")}
                    </InputLine>

                    <Divider className="my-5" />
                    <InputLine title="영수증">
                        <ReceiptsText receipts={props.paymentData.findFirstPaymentAdmin.receipts} />
                    </InputLine>
                    <InputLine title="발행상태">
                        <ReceiptsStatusText
                            receiptsStatus={props.paymentData.findFirstPaymentAdmin.receiptsStatus}
                        />
                    </InputLine>
                    {props.paymentData.findFirstPaymentAdmin.receipts === Receipts.Company && (
                        <>
                            <PaymentDetailReceiptsCompanyContainer
                                paymentData={props.paymentData}
                            />
                        </>
                    )}
                    <Divider className="my-5" />

                    <div
                        className="w-full max-h-[100px] border p-5 mt-5 overflow-y-auto"
                        dangerouslySetInnerHTML={{
                            __html: props.paymentData.findFirstPaymentAdmin.memo
                                ? props.paymentData.findFirstPaymentAdmin.memo
                                : "메모없음",
                        }}></div>
                </>
            )}
        </>
    );
};

const PaymentDetailReceiptsCompanyContainer = (props: {
    paymentData: QueryFindFirstPaymentAdminQuery;
}) => {
    return (
        <>
            {props.paymentData.findFirstPaymentAdmin.company && (
                <>
                    <InputLine title="사업자등록번호">
                        {props.paymentData.findFirstPaymentAdmin.company.registrationNumber}
                    </InputLine>
                    <InputLine title="대표자">
                        {props.paymentData.findFirstPaymentAdmin.company.ceo}
                    </InputLine>
                    <InputLine title="업종">
                        {props.paymentData.findFirstPaymentAdmin.company.businessStatus}
                    </InputLine>
                    <InputLine title="업태">
                        {props.paymentData.findFirstPaymentAdmin.company.businessSector}
                    </InputLine>
                    <InputLine title="주소">
                        ({props.paymentData.findFirstPaymentAdmin.company.zipCode}){" "}
                        {props.paymentData.findFirstPaymentAdmin.company.address1}{" "}
                        {props.paymentData.findFirstPaymentAdmin.company.address2}
                    </InputLine>
                    <InputLine title="이메일">
                        {props.paymentData.findFirstPaymentAdmin.company.email}
                    </InputLine>
                    <InputLine title="첨부파일">
                        {props.paymentData.findFirstPaymentAdmin.company.registrationFile}
                    </InputLine>
                </>
            )}
        </>
    );
};
