import React from "react";
import { PaymentMethod } from "../../../graphql/operation";

export const PaymentMethodText = (props: { paymentMethod: PaymentMethod }) => {
    let value = "";
    if (props.paymentMethod === PaymentMethod.Card) {
        value = "카드결제";
    } else if (props.paymentMethod === PaymentMethod.WithBank) {
        value = "계좌이체";
    } else if (props.paymentMethod === PaymentMethod.VirtualBank) {
        value = "가상계좌";
    } else if (props.paymentMethod === PaymentMethod.Mobile) {
        value = "휴대폰결제";
    } else {
        value = "무통장입금";
    }

    return <>{value}</>;
};
