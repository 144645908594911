import { useReactiveVar } from "@apollo/client";
import React, { ReactNode } from "react";
import { LayoutVars } from "../../../variables/MakeVar";

export const ViewBackDrop = (props: { children?: ReactNode; touch?: boolean }) => {
    const isBackDrop = useReactiveVar(LayoutVars.isBackDrop);
    const touch = !!props.touch;

    return (
        <div
            className={`backdrop ${isBackDrop ? "backdrop-blur-sm" : ""} ${
                touch ? "touch-none" : ""
            }`}>
            {props.children}
        </div>
    );
};
