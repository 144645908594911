import React, { useState } from "react";
import {
    Exact,
    FindFirstUserAdminInput,
    HostingType,
    Period,
    ProductActive,
    QueryFindFirstUserAdminQuery,
    RegisteredCompany,
    useMutationConnectHostingAdminMutation,
} from "../../../../../graphql/operation";
import { ApolloQueryResult } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { TitleBox } from "../../../../components/titleBox/TitleBox";
import { Button, IconButton, Tooltip } from "@mui/material";
import { RegisteredCompanyText } from "../../../../components/RegisteredCompanyText/RegisteredCompanyText";
import NumberFormat from "react-number-format";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faBookOpen,
    faMessage,
    faMoneyBillTransfer,
    faPen,
} from "@fortawesome/free-solid-svg-icons";
import {
    ISearchHosting,
    SearchHostingModal,
} from "../../../../components/searchHostingModal/SearchHostingModal";
import { SwalUtil } from "../../../../../utils/swal/SwalUtil";
import { ErrorCatchUtil } from "../../../../../utils/errorCatch/ErrorCatchUtil";
import { ErrorConstants } from "../../../../../variables/ErrorConstants";
import { ProductActiveBadge } from "../../../../components/productActiveBadge/ProductActiveBadge";

export const ViewUserProductHosting = (props: {
    userData: QueryFindFirstUserAdminQuery;
    userRefetch: (
        variables?: Partial<Exact<{ input: FindFirstUserAdminInput }>> | undefined,
    ) => Promise<ApolloQueryResult<QueryFindFirstUserAdminQuery>>;
}) => {
    const navigate = useNavigate();
    const [openModal, setOpenModal] = useState<boolean>(false);

    const [connectHostingAdmin] = useMutationConnectHostingAdminMutation();
    const handler = {
        handleModalOpen: () => {
            setOpenModal(true);
        },
        handleModalClose: () => {
            setOpenModal(false);
        },
        onCreate: () => {
            navigate(`/hosting/create/${props.userData.findFirstUserAdmin.id}`);
        },
        onApplyHosting: (value: ISearchHosting) => {
            setOpenModal(false);
            SwalUtil.yn({
                title: "선택한 호스팅을 회원과 연결하시겠습니까?",
                text: value.hostingDomainUrl,
                icon: "question",
            })
                .then(() => {
                    return connectHostingAdmin({
                        variables: {
                            input: {
                                userId: props.userData.findFirstUserAdmin.id,
                                hostingId: value.hostingId,
                            },
                        },
                    });
                })
                .then(() => {
                    return SwalUtil.ok({
                        title: "상품 연결이 완료되었습니다.",
                        icon: "success",
                    });
                })
                .then(() => {
                    return props.userRefetch({
                        input: {
                            userId: props.userData.findFirstUserAdmin.id,
                        },
                    });
                })
                .catch(
                    ErrorCatchUtil({
                        [ErrorConstants.notPermission]: () => {
                            return SwalUtil.ok({
                                title: "권한이 없습니다.",
                                icon: "error",
                            });
                        },
                        [ErrorConstants.alreadyUser]: () => {
                            return SwalUtil.ok({
                                title: "이미 연결된 사용자가 있는 상품입니다.",
                                icon: "error",
                            });
                        },
                        [ErrorConstants.default]: e => {
                            return SwalUtil.ok({
                                title: e,
                                icon: "error",
                            });
                        },
                    }),
                );
        },
    };

    return (
        <>
            <div className="flex justify-between mb-5">
                <TitleBox title="호스팅" notBottomMargin={true} />
                <div className="flex gap-5">
                    <Button
                        variant="contained"
                        size="small"
                        className="h-[30px]"
                        onClick={handler.onCreate}>
                        상품추가
                    </Button>
                    <Button
                        variant="contained"
                        size="small"
                        className="h-[30px]"
                        onClick={handler.handleModalOpen}>
                        상품연결
                    </Button>
                </div>
            </div>
            <div className="bg-lightBackground dark:bg-darkBackground flex items-center w-full h-[30px]">
                <div className="w-[24%]">도메인명</div>
                <div className="w-[7%]">상태</div>
                <div className="w-[11%]">등록기관</div>
                <div className="w-[13%]">상품금액</div>
                <div className="w-[13%]">마지막 결제일</div>
                <div className="w-[13%]">다음 결제일</div>
                <div className="w-[19%]">기능</div>
            </div>
            {props.userData.findFirstUserAdmin.hostings.length === 0 && (
                <div className="h-[100px] flex justify-center items-center">
                    등록된 상품이 없습니다.
                </div>
            )}
            {props.userData.findFirstUserAdmin.hostings.map((value, index) => (
                <div key={index} className="w-full flex h-[45px] border-b items-center">
                    {value && <HostingLine hosting={value} />}
                </div>
            ))}

            <SearchHostingModal
                open={openModal}
                onClose={handler.handleModalClose}
                onApplyHosting={handler.onApplyHosting}
            />
        </>
    );
};

const HostingLine = (props: { hosting: IHostingLine }) => {
    const navigate = useNavigate();

    return (
        <>
            <div className="w-[24%]">{props.hosting.domainUrl}</div>
            <div className="w-[7%]">
                <ProductActiveBadge productActive={props.hosting.productActive} />
            </div>
            <div className="w-[11%]">
                <RegisteredCompanyText registeredCompany={props.hosting.registeredCompany} />
            </div>
            <div className="w-[13%]">
                <NumberFormat
                    value={props.hosting.price}
                    displayType="text"
                    thousandSeparator=","
                    suffix=" 원"
                />
            </div>
            <div className="w-[13%]">
                {props.hosting.lastBillingDate
                    ? moment(props.hosting.lastBillingDate).locale("ko").format("YYYY-MM-DD")
                    : "-"}
            </div>
            <div className="w-[13%]">
                {moment(props.hosting.nextBillingDate).locale("ko").format("YYYY-MM-DD")}
            </div>
            <div className="w-[19%] flex justify-between">
                <Tooltip title={"메세지"} arrow>
                    <IconButton color="primary">
                        <FontAwesomeIcon icon={faMessage} size="sm" />
                    </IconButton>
                </Tooltip>
                <Tooltip title="상세보기">
                    <IconButton
                        color="primary"
                        onClick={() => {
                            navigate(`/hosting/detail/${props.hosting.id}`);
                        }}>
                        <FontAwesomeIcon icon={faBookOpen} size="sm" />
                    </IconButton>
                </Tooltip>
                <Tooltip title="수정" arrow>
                    <IconButton
                        color="primary"
                        onClick={() => {
                            navigate(`/hosting/update/${props.hosting.id}`);
                        }}>
                        <FontAwesomeIcon icon={faPen} size="sm" />
                    </IconButton>
                </Tooltip>
                <Tooltip title="납부등록" arrow>
                    <IconButton
                        color="primary"
                        onClick={() => {
                            navigate(`/payment/create/hosting/${props.hosting.id}`);
                        }}>
                        <FontAwesomeIcon icon={faMoneyBillTransfer} size="sm" />
                    </IconButton>
                </Tooltip>
            </div>
        </>
    );
};

interface IHostingLine {
    __typename?: "Hosting";
    productActive: ProductActive;
    createdAt?: any | null;
    domainUrl: string;
    hostingType: HostingType;
    id: number;
    lastBillingDate?: any | null;
    nextBillingDate?: any | null;
    period: Period;
    price: number;
    registeredCompany: RegisteredCompany;
    memo: string;
}
