import React from "react";
import { ProductType } from "../../../graphql/operation";

export const ProductTypeText = (props: { productType: ProductType }) => {
    let value = "";
    if (props.productType === ProductType.Certificate) {
        value = "보안인증서";
    } else if (props.productType === ProductType.Domain) {
        value = "도메인";
    } else if (props.productType === ProductType.Design) {
        value = "디자인";
    } else if (props.productType === ProductType.Sms) {
        value = "SMS";
    } else if (props.productType === ProductType.Email) {
        value = "이메일";
    } else if (props.productType === ProductType.Etc) {
        value = "ETC";
    } else {
        value = "호스팅";
    }

    return <>{value}</>;
};
