import React from "react";
import { ProductActive } from "../../../graphql/operation";

export const ProductActiveText = (props: { productActive: ProductActive }) => {
    let value = "";
    if (props.productActive === ProductActive.Pause) {
        value = "정지";
    } else if (props.productActive === ProductActive.Stop) {
        value = "만료";
    } else if (props.productActive === ProductActive.Delete) {
        value = "삭제";
    } else {
        value = "정상";
    }

    return <>{value}</>;
};
