import React from "react";
import ReactDOM from "react-dom/client";
import "./styles/common.scss";
import { App } from "./views/App";
import {ApolloProvider} from "@apollo/client";
import {ApolloUtil} from "./utils/apollo/ApolloUtil";
import { HelmetProvider } from "react-helmet-async";
import {BrowserRouter} from "react-router-dom";
import {ResizeProvider} from "./provider/resize/ResizeProvider";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
    <ApolloProvider client={ApolloUtil.client}>
        <HelmetProvider>
            <BrowserRouter>
                <ResizeProvider>
                    <App />
                </ResizeProvider>
            </BrowserRouter>
        </HelmetProvider>
    </ApolloProvider>
);
