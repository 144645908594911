import React from "react";
import {Receipts, ReceiptsStatus} from "../../../graphql/operation";
import {MenuItem, TextField} from "@mui/material";
import {OnChangeValue} from "../../../types/ArgsInterface";

export const ReceiptsStatusSelect = (props: {
    receiptsStatus: ReceiptsStatus;
    onChangeState: OnChangeValue<ReceiptsStatus>;
}) => {
    return (
        <>
            <TextField
                variant="outlined"
                label="발행 상태"
                color="primary"
                fullWidth
                select
                value={props.receiptsStatus}
                size="small"
                onChange={value => {
                    let receiptsStatus: ReceiptsStatus;
                    if (value.target.value === "Request") {
                        receiptsStatus = ReceiptsStatus.Request;
                    } else if (value.target.value === ReceiptsStatus.Pre) {
                        receiptsStatus = ReceiptsStatus.Pre;
                    } else if (value.target.value === ReceiptsStatus.Issued) {
                        receiptsStatus = ReceiptsStatus.Issued;
                    } else {
                        receiptsStatus = ReceiptsStatus.None;
                    }
                    props.onChangeState(receiptsStatus);
                }}>
                <MenuItem value={ReceiptsStatus.Request}>발행요청</MenuItem>
                <MenuItem value={ReceiptsStatus.Pre}>선발행</MenuItem>
                <MenuItem value={ReceiptsStatus.Issued}>발행</MenuItem>
                <MenuItem value={Receipts.None}>발행없음</MenuItem>
            </TextField>
        </>
    );
};
