import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
    UpdateUserAdminInput,
    useMutationUpdateUserAdminMutation,
    useQueryFindFirstUserAdminQuery,
    UserType,
} from "../../../../../graphql/operation";
import { TitleBox } from "../../../../components/titleBox/TitleBox";
import { LeftUserContainer } from "../../../../components/leftUserContainer/LeftUserContainer";
import { LimitWidthContainer } from "../../../../components/limitWidthContainer/LimitWidthContainer";
import { UserTypeSelect } from "../../../../components/userTypeSelect/UserTypeSelect";
import { InputLine } from "../../../../components/inputLine/InputLine";
import { OnChangeValue, OnVoidFunction } from "../../../../../types/ArgsInterface";
import { Button, Checkbox, Divider, TextField } from "@mui/material";
import { DaumPostCodeModal } from "../../../../components/daumPostCodeModal";
import { Address } from "react-daum-postcode";
import { SwalUtil } from "../../../../../utils/swal/SwalUtil";
import { ErrorCatchUtil } from "../../../../../utils/errorCatch/ErrorCatchUtil";
import { ErrorConstants } from "../../../../../variables/ErrorConstants";

export const ViewUserUpdateInfo = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    const initInputData: UpdateUserAdminInput = {
        applyReceiveMail: false,
        applyReceiveSMS: false,
        email: "",
        name: "",
        phoneNumber: "",
        active: false,
        userId: Number(id),
        userType: UserType.Personal,
    };

    const {
        data: userData,
        loading: userLoading,
        error: userError,
    } = useQueryFindFirstUserAdminQuery({
        variables: {
            input: {
                userId: Number(id),
            },
        },
    });

    const [inputData, setInputData] = useState<UpdateUserAdminInput>(initInputData);
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [updateUserAdminMutation] = useMutationUpdateUserAdminMutation();

    useEffect(() => {
        if (userData) {
            setInputData({
                ...inputData,
                userType: userData.findFirstUserAdmin.userType,
                name: userData.findFirstUserAdmin.name,
                email: userData.findFirstUserAdmin.email,
                phoneNumber: userData.findFirstUserAdmin.phoneNumber,
                active: userData.findFirstUserAdmin.active,
                applyReceiveMail: userData.findFirstUserAdmin.applyReceiveMail,
                applyReceiveSMS: userData.findFirstUserAdmin.applyReceiveSMS,
                registrationNumber: userData.findFirstUserAdmin.company?.registrationNumber,
                ceo: userData.findFirstUserAdmin.company?.ceo,
                businessStatus: userData.findFirstUserAdmin.company?.businessStatus,
                businessSector: userData.findFirstUserAdmin.company?.businessSector,
                zipCode: userData.findFirstUserAdmin.company?.zipCode,
                address1: userData.findFirstUserAdmin.company?.address1,
                address2: userData.findFirstUserAdmin.company?.address2,
                registrationFile: userData.findFirstUserAdmin.company?.registrationFile,
            });
        }
    }, [userData]);

    const handler = {
        onUpdate: () => {
            if (inputData.userType === UserType.Company && !inputData.registrationNumber) {
                return SwalUtil.ok({
                    title: "사업자 정보 입력은 필수입니다.",
                    icon: "error",
                });
            }

            updateUserAdminMutation({
                variables: {
                    input: {
                        ...inputData,
                    },
                },
            })
                .then(() => {
                    return SwalUtil.ok({
                        title: "회원 정보 변경이 완료되었습니다.",
                        icon: "success",
                    });
                })
                .then(() => {
                    navigate(-1);
                })
                .catch(() => {
                    ErrorCatchUtil({
                        [ErrorConstants.notPermission]: () => {
                            return SwalUtil.ok({
                                title: "이미 존재하는 회원 아이디입니다.",
                                icon: "error",
                            });
                        },
                        [ErrorConstants.default]: e => {
                            return SwalUtil.ok({
                                title: e,
                                icon: "error",
                            });
                        },
                    });
                });
        },
        onCancel: () => {
            navigate(-1);
        },
        onChangeUserType: (value: UserType) => {
            setInputData({
                ...inputData,
                userType: value,
            });
        },
        handleModalOpen: () => {
            setOpenModal(true);
        },
        handleModalClose: () => {
            setOpenModal(false);
        },
        onChangeAddress: (data: Address) => {
            let fullAddress = data.address;
            let extraAddress = "";

            if (data.addressType === "R") {
                if (data.bname !== "") {
                    extraAddress += data.bname;
                }
                if (data.buildingName !== "") {
                    if (data.buildingName !== "") {
                        extraAddress +=
                            extraAddress !== "" ? `, ${data.buildingName}` : data.buildingName;
                    }
                    fullAddress += extraAddress !== "" ? ` (${extraAddress})` : "";
                }
            }

            setInputData({
                ...inputData,
                zipCode: data.zonecode,
                address1: fullAddress,
                address2: "",
            });
        },
    };

    return (
        <>
            <LimitWidthContainer>
                <TitleBox title="회원" />
                {!userLoading && userError && (
                    <div className="bg-lightPaper dark:bg-darkPaper p-5 rounded">
                        <div className="w-full min-h-[300px]">데이터가 없습니다.</div>
                    </div>
                )}

                {!userLoading && userData && (
                    <div className="w-full flex justify-between gap-10">
                        <div className="w-[300px]">
                            <div className="bg-lightPaper dark:bg-darkPaper p-5  rounded w-full">
                                <LeftUserContainer userId={userData.findFirstUserAdmin.id} />
                            </div>
                        </div>
                        <div className="w-[900px]">
                            <div className="w-full bg-lightPaper dark:bg-darkPaper p-5 rounded">
                                <UserUpdateInfoContainer
                                    inputData={inputData}
                                    setInputData={setInputData}
                                    onChangeUserType={handler.onChangeUserType}
                                    handleModalOpen={handler.handleModalOpen}
                                />
                                <div className="my-10" />
                                <div className="flex w-full justify-center gap-10">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        className="w-[120px]"
                                        onClick={handler.onUpdate}>
                                        회원정보수정
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        className="w-[120px]"
                                        onClick={handler.onCancel}>
                                        취소
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </LimitWidthContainer>

            <DaumPostCodeModal
                open={openModal}
                onChangeAddress={handler.onChangeAddress}
                onClose={handler.handleModalClose}
            />
        </>
    );
};

const UserUpdateInfoContainer = (props: {
    inputData: UpdateUserAdminInput;
    setInputData: React.Dispatch<React.SetStateAction<UpdateUserAdminInput>>;
    onChangeUserType: OnChangeValue<UserType>;
    handleModalOpen: OnVoidFunction;
}) => {
    return (
        <>
            <TitleBox title="회원 정보 변경" notBottomMargin={true} />
            <InputLine title="회원종류">
                <UserTypeSelect
                    userType={props.inputData.userType}
                    onChangeState={props.onChangeUserType}
                />
            </InputLine>
            <InputLine title="이름 (상호)">
                <TextField
                    variant="outlined"
                    label="이름 (상호)"
                    color="primary"
                    fullWidth
                    size="small"
                    type="text"
                    value={props.inputData.name}
                    onChange={value => {
                        props.setInputData({
                            ...props.inputData,
                            name: value.currentTarget.value,
                        });
                    }}
                />
            </InputLine>
            <InputLine title="이메일">
                <TextField
                    variant="outlined"
                    label="이메일"
                    color="primary"
                    fullWidth
                    size="small"
                    type="email"
                    value={props.inputData.email}
                    onChange={value => {
                        props.setInputData({
                            ...props.inputData,
                            email: value.currentTarget.value,
                        });
                    }}
                />
            </InputLine>
            <InputLine title="전화번호">
                <TextField
                    variant="outlined"
                    label="전화번호"
                    color="primary"
                    fullWidth
                    size="small"
                    type="tel"
                    value={props.inputData.phoneNumber}
                    onChange={value => {
                        props.setInputData({
                            ...props.inputData,
                            phoneNumber: value.currentTarget.value,
                        });
                    }}
                />
            </InputLine>
            {props.inputData.userType === UserType.Company && (
                <>
                    <Divider className="w-full my-5" />
                    <InputLine title="사업자등록번호">
                        <TextField
                            variant="outlined"
                            label="사업자등록번호"
                            color="primary"
                            fullWidth
                            size="small"
                            type="text"
                            value={props.inputData.registrationNumber}
                            onChange={value => {
                                props.setInputData({
                                    ...props.inputData,
                                    registrationNumber: value.currentTarget.value,
                                });
                            }}
                        />
                    </InputLine>
                    <InputLine title="대표자">
                        <TextField
                            variant="outlined"
                            label="대표자"
                            color="primary"
                            fullWidth
                            size="small"
                            type="text"
                            value={props.inputData.ceo}
                            onChange={value => {
                                props.setInputData({
                                    ...props.inputData,
                                    ceo: value.currentTarget.value,
                                });
                            }}
                        />
                    </InputLine>
                    <InputLine title="업종">
                        <TextField
                            variant="outlined"
                            label="업종"
                            color="primary"
                            fullWidth
                            size="small"
                            type="text"
                            value={props.inputData.businessStatus}
                            onChange={value => {
                                props.setInputData({
                                    ...props.inputData,
                                    businessStatus: value.currentTarget.value,
                                });
                            }}
                        />
                    </InputLine>
                    <InputLine title="업태">
                        <TextField
                            variant="outlined"
                            label="업태"
                            color="primary"
                            fullWidth
                            size="small"
                            type="text"
                            value={props.inputData.businessSector}
                            onChange={value => {
                                props.setInputData({
                                    ...props.inputData,
                                    businessSector: value.currentTarget.value,
                                });
                            }}
                        />
                    </InputLine>
                    <InputLine title="주소">
                        <TextField
                            variant="outlined"
                            label="우편번호"
                            color="primary"
                            className="w-[200px]"
                            size="small"
                            type="text"
                            value={props.inputData.zipCode}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputProps={{ readOnly: true }}
                            onClick={props.handleModalOpen}
                        />
                        <TextField
                            variant="outlined"
                            label="주소"
                            color="primary"
                            fullWidth
                            size="small"
                            type="text"
                            value={props.inputData.address1}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputProps={{ readOnly: true }}
                            onClick={props.handleModalOpen}
                        />
                        <TextField
                            variant="outlined"
                            label="나머지 주소"
                            color="primary"
                            fullWidth
                            size="small"
                            type="text"
                            value={props.inputData.address2}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={value => {
                                props.setInputData({
                                    ...props.inputData,
                                    address2: value.currentTarget.value,
                                });
                            }}
                        />
                    </InputLine>
                    <InputLine title="사업자등록증 첨부">
                        <TextField
                            variant="outlined"
                            label="사업자등록증 첨부"
                            color="primary"
                            fullWidth
                            size="small"
                            type="text"
                            value={props.inputData.registrationFile}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputProps={{ readOnly: true }}
                        />
                    </InputLine>
                    <Divider className="w-full my-5" />
                </>
            )}
            <InputLine title="메일링서비스">
                <Checkbox
                    checked={props.inputData.applyReceiveMail}
                    onChange={value => {
                        props.setInputData({
                            ...props.inputData,
                            applyReceiveMail: value.target.checked,
                        });
                    }}
                />{" "}
                메일 수신 허용
            </InputLine>
            <InputLine title="SMS 수신">
                <Checkbox
                    checked={props.inputData.applyReceiveSMS}
                    onChange={value => {
                        props.setInputData({
                            ...props.inputData,
                            applyReceiveSMS: value.target.checked,
                        });
                    }}
                />{" "}
                SMS 수신 허용
            </InputLine>
        </>
    );
};
