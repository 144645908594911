import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: string;
    String: string;
    Boolean: boolean;
    Int: number;
    Float: number;
    DateTime: any;
    Upload: any;
};

export type Certificate = {
    __typename?: "Certificate";
    createdAt?: Maybe<Scalars["DateTime"]>;
    domainUrl: Scalars["String"];
    id: Scalars["Int"];
    lastBillingDate?: Maybe<Scalars["DateTime"]>;
    memo: Scalars["String"];
    nextBillingDate?: Maybe<Scalars["DateTime"]>;
    payments: Array<Maybe<Payment>>;
    period: Period;
    price: Scalars["Int"];
    productActive: ProductActive;
    questions: Array<Maybe<Question>>;
    registeredCompany: RegisteredCompany;
    type: CertificateType;
    updatedAt?: Maybe<Scalars["DateTime"]>;
    user?: Maybe<User>;
    userId?: Maybe<Scalars["Int"]>;
};

export enum CertificateType {
    Essentials = "Essentials",
    EssentialsWildcard = "EssentialsWildcard",
}

export type ChangeRequestStatusAdminInput = {
    completer: Scalars["String"];
    requestId: Scalars["Int"];
    requestStatus: RequestStatus;
};

export type Company = {
    __typename?: "Company";
    address1: Scalars["String"];
    address2: Scalars["String"];
    businessSector: Scalars["String"];
    businessStatus: Scalars["String"];
    ceo: Scalars["String"];
    companyName: Scalars["String"];
    createdAt: Scalars["DateTime"];
    email: Scalars["String"];
    id: Scalars["Int"];
    payments: Array<Maybe<Payment>>;
    registrationFile: Scalars["String"];
    registrationNumber: Scalars["String"];
    updatedAt?: Maybe<Scalars["DateTime"]>;
    user?: Maybe<User>;
    userId?: Maybe<Scalars["Int"]>;
    zipCode: Scalars["String"];
};

export type ConnectCertificateAdminInput = {
    certificateId: Scalars["Int"];
    userId: Scalars["Int"];
};

export type ConnectDomainAdminInput = {
    domainId: Scalars["Int"];
    userId: Scalars["Int"];
};

export type ConnectEmailAdminInput = {
    emailId: Scalars["Int"];
    userId: Scalars["Int"];
};

export type ConnectHostingAdminInput = {
    hostingId: Scalars["Int"];
    userId: Scalars["Int"];
};

export type CountCertificateAdminInput = {
    method?: InputMaybe<Scalars["String"]>;
    term?: InputMaybe<Scalars["String"]>;
};

export type CountDomainAdminInput = {
    method?: InputMaybe<Scalars["String"]>;
    term?: InputMaybe<Scalars["String"]>;
};

export type CountEmailAccountAdminInput = {
    emailId: Scalars["Int"];
};

export type CountEmailAdminInput = {
    method?: InputMaybe<Scalars["String"]>;
    term?: InputMaybe<Scalars["String"]>;
};

export type CountHostingAdminInput = {
    method?: InputMaybe<Scalars["String"]>;
    term?: InputMaybe<Scalars["String"]>;
};

export type CountPaymentForCertificateAdminInput = {
    certificateId: Scalars["Int"];
};

export type CountPaymentForDomainAdminInput = {
    domainId: Scalars["Int"];
};

export type CountPaymentForEmailAdminInput = {
    emailId: Scalars["Int"];
};

export type CountPaymentForHostingAdminInput = {
    hostingId: Scalars["Int"];
};

export type CountPaymentForUserAdminInput = {
    userId: Scalars["Int"];
};

export type CountQuestionForAllAdminInput = {
    method?: InputMaybe<Scalars["String"]>;
    term?: InputMaybe<Scalars["String"]>;
};

export type CountRequestAdminInput = {
    method?: InputMaybe<Scalars["String"]>;
    term?: InputMaybe<Scalars["String"]>;
};

export type CountUserAdminInput = {
    method?: InputMaybe<Scalars["String"]>;
    term?: InputMaybe<Scalars["String"]>;
};

export type CreateAdminInput = {
    email: Scalars["String"];
    name: Scalars["String"];
    nickName: Scalars["String"];
    password: Scalars["String"];
    phoneNumber: Scalars["String"];
    username: Scalars["String"];
};

export type CreateCertificateAdminInput = {
    checkRequest: Scalars["Boolean"];
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    domainUrl: Scalars["String"];
    lastBillingDate?: InputMaybe<Scalars["DateTime"]>;
    memo: Scalars["String"];
    nextBillingDate?: InputMaybe<Scalars["DateTime"]>;
    period: Period;
    price: Scalars["Int"];
    registeredCompany: RegisteredCompany;
    type: CertificateType;
    userId?: InputMaybe<Scalars["Int"]>;
};

export type CreateDomainAdminInput = {
    checkRequest: Scalars["Boolean"];
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    domainType: DomainType;
    domainUrl: Scalars["String"];
    lastBillingDate?: InputMaybe<Scalars["DateTime"]>;
    memo: Scalars["String"];
    nextBillingDate?: InputMaybe<Scalars["DateTime"]>;
    period: Period;
    price: Scalars["Int"];
    registeredCompany: RegisteredCompany;
    userId?: InputMaybe<Scalars["Int"]>;
};

export type CreateEmailAccountAdminInput = {
    account: Scalars["String"];
    checkRequest: Scalars["Boolean"];
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    emailId: Scalars["Int"];
    freeQuota: Scalars["Int"];
    memo: Scalars["String"];
    quota: Scalars["Int"];
};

export type CreateEmailAdminInput = {
    checkRequest: Scalars["Boolean"];
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    domainUrl: Scalars["String"];
    lastBillingDate?: InputMaybe<Scalars["DateTime"]>;
    memo: Scalars["String"];
    nextBillingDate?: InputMaybe<Scalars["DateTime"]>;
    period: Period;
    registeredCompany: RegisteredCompany;
    userId?: InputMaybe<Scalars["Int"]>;
};

export type CreateHostingAdminInput = {
    addStorage: Scalars["Int"];
    checkRequest: Scalars["Boolean"];
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    domainUrl: Scalars["String"];
    ftpId: Scalars["String"];
    ftpIp: Scalars["String"];
    ftpPassword: Scalars["String"];
    hostingType: HostingType;
    lastBillingDate?: InputMaybe<Scalars["DateTime"]>;
    memo: Scalars["String"];
    mysqlDatabase: Scalars["String"];
    mysqlId: Scalars["String"];
    mysqlPassword: Scalars["String"];
    nextBillingDate?: InputMaybe<Scalars["DateTime"]>;
    period: Period;
    price: Scalars["Int"];
    registeredCompany: RegisteredCompany;
    userId?: InputMaybe<Scalars["Int"]>;
};

export type CreatePaymentForCertificateAdminInput = {
    address1?: InputMaybe<Scalars["String"]>;
    address2?: InputMaybe<Scalars["String"]>;
    businessSector?: InputMaybe<Scalars["String"]>;
    businessStatus?: InputMaybe<Scalars["String"]>;
    ceo?: InputMaybe<Scalars["String"]>;
    certificateId: Scalars["Int"];
    checkRequest: Scalars["Boolean"];
    companyName?: InputMaybe<Scalars["String"]>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    depositor?: InputMaybe<Scalars["String"]>;
    domainUrl: Scalars["String"];
    email?: InputMaybe<Scalars["String"]>;
    lastBillingDate?: InputMaybe<Scalars["DateTime"]>;
    memo: Scalars["String"];
    nextBillingDate?: InputMaybe<Scalars["DateTime"]>;
    paymentMethod: PaymentMethod;
    phoneNumber?: InputMaybe<Scalars["String"]>;
    price: Scalars["Int"];
    receipts: Receipts;
    receiptsStatus: ReceiptsStatus;
    registrationFile?: InputMaybe<Scalars["String"]>;
    registrationNumber?: InputMaybe<Scalars["String"]>;
    type: ProductType;
    userId?: InputMaybe<Scalars["Int"]>;
    zipCode?: InputMaybe<Scalars["String"]>;
};

export type CreatePaymentForDomainAdminInput = {
    address1?: InputMaybe<Scalars["String"]>;
    address2?: InputMaybe<Scalars["String"]>;
    businessSector?: InputMaybe<Scalars["String"]>;
    businessStatus?: InputMaybe<Scalars["String"]>;
    ceo?: InputMaybe<Scalars["String"]>;
    checkRequest: Scalars["Boolean"];
    companyName?: InputMaybe<Scalars["String"]>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    depositor?: InputMaybe<Scalars["String"]>;
    domainId: Scalars["Int"];
    domainUrl: Scalars["String"];
    email?: InputMaybe<Scalars["String"]>;
    lastBillingDate?: InputMaybe<Scalars["DateTime"]>;
    memo: Scalars["String"];
    nextBillingDate?: InputMaybe<Scalars["DateTime"]>;
    paymentMethod: PaymentMethod;
    phoneNumber?: InputMaybe<Scalars["String"]>;
    price: Scalars["Int"];
    receipts: Receipts;
    receiptsStatus: ReceiptsStatus;
    registrationFile?: InputMaybe<Scalars["String"]>;
    registrationNumber?: InputMaybe<Scalars["String"]>;
    type: ProductType;
    userId?: InputMaybe<Scalars["Int"]>;
    zipCode?: InputMaybe<Scalars["String"]>;
};

export type CreatePaymentForEmailAdminInput = {
    address1?: InputMaybe<Scalars["String"]>;
    address2?: InputMaybe<Scalars["String"]>;
    businessSector?: InputMaybe<Scalars["String"]>;
    businessStatus?: InputMaybe<Scalars["String"]>;
    ceo?: InputMaybe<Scalars["String"]>;
    checkRequest: Scalars["Boolean"];
    companyName?: InputMaybe<Scalars["String"]>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    depositor?: InputMaybe<Scalars["String"]>;
    domainUrl: Scalars["String"];
    email?: InputMaybe<Scalars["String"]>;
    emailId: Scalars["Int"];
    lastBillingDate?: InputMaybe<Scalars["DateTime"]>;
    memo: Scalars["String"];
    nextBillingDate?: InputMaybe<Scalars["DateTime"]>;
    paymentMethod: PaymentMethod;
    phoneNumber?: InputMaybe<Scalars["String"]>;
    price: Scalars["Int"];
    receipts: Receipts;
    receiptsStatus: ReceiptsStatus;
    registrationFile?: InputMaybe<Scalars["String"]>;
    registrationNumber?: InputMaybe<Scalars["String"]>;
    type: ProductType;
    userId?: InputMaybe<Scalars["Int"]>;
    zipCode?: InputMaybe<Scalars["String"]>;
};

export type CreatePaymentForEtcAdminInput = {
    address1?: InputMaybe<Scalars["String"]>;
    address2?: InputMaybe<Scalars["String"]>;
    businessSector?: InputMaybe<Scalars["String"]>;
    businessStatus?: InputMaybe<Scalars["String"]>;
    ceo?: InputMaybe<Scalars["String"]>;
    companyName?: InputMaybe<Scalars["String"]>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    depositor?: InputMaybe<Scalars["String"]>;
    domainUrl: Scalars["String"];
    email?: InputMaybe<Scalars["String"]>;
    lastBillingDate?: InputMaybe<Scalars["DateTime"]>;
    memo: Scalars["String"];
    nextBillingDate?: InputMaybe<Scalars["DateTime"]>;
    paymentMethod: PaymentMethod;
    phoneNumber?: InputMaybe<Scalars["String"]>;
    price: Scalars["Int"];
    receipts: Receipts;
    receiptsStatus: ReceiptsStatus;
    registrationFile?: InputMaybe<Scalars["String"]>;
    registrationNumber?: InputMaybe<Scalars["String"]>;
    type: ProductType;
    userId?: InputMaybe<Scalars["Int"]>;
    zipCode?: InputMaybe<Scalars["String"]>;
};

export type CreatePaymentForHostingAdminInput = {
    address1?: InputMaybe<Scalars["String"]>;
    address2?: InputMaybe<Scalars["String"]>;
    businessSector?: InputMaybe<Scalars["String"]>;
    businessStatus?: InputMaybe<Scalars["String"]>;
    ceo?: InputMaybe<Scalars["String"]>;
    companyName?: InputMaybe<Scalars["String"]>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    depositor?: InputMaybe<Scalars["String"]>;
    domainUrl: Scalars["String"];
    email?: InputMaybe<Scalars["String"]>;
    hostingId: Scalars["Int"];
    lastBillingDate?: InputMaybe<Scalars["DateTime"]>;
    memo: Scalars["String"];
    nextBillingDate?: InputMaybe<Scalars["DateTime"]>;
    paymentMethod: PaymentMethod;
    phoneNumber?: InputMaybe<Scalars["String"]>;
    price: Scalars["Int"];
    receipts: Receipts;
    receiptsStatus: ReceiptsStatus;
    registrationFile?: InputMaybe<Scalars["String"]>;
    registrationNumber?: InputMaybe<Scalars["String"]>;
    type: ProductType;
    userId?: InputMaybe<Scalars["Int"]>;
    zipCode?: InputMaybe<Scalars["String"]>;
};

export type CreateQuestionAdminInput = {
    certificateId?: InputMaybe<Scalars["Int"]>;
    content: Scalars["String"];
    designId?: InputMaybe<Scalars["Int"]>;
    domainId?: InputMaybe<Scalars["Int"]>;
    domainUrl: Scalars["String"];
    hostingId?: InputMaybe<Scalars["Int"]>;
    productType: ProductType;
    questionStatus: QuestionStatus;
    smsId?: InputMaybe<Scalars["Int"]>;
    subject: Scalars["String"];
    userId?: InputMaybe<Scalars["Int"]>;
    writer?: InputMaybe<Scalars["String"]>;
};

export type CreateRequestAdminInput = {
    content: Scalars["String"];
    domainUrl: Scalars["String"];
    productType: ProductType;
    requestStatus: RequestStatus;
    subject: Scalars["String"];
    writer: Scalars["String"];
};

export type CreateUserInput = {
    address1?: InputMaybe<Scalars["String"]>;
    address2?: InputMaybe<Scalars["String"]>;
    applyReceiveMail: Scalars["Boolean"];
    applyReceiveSMS: Scalars["Boolean"];
    businessSector?: InputMaybe<Scalars["String"]>;
    businessStatus?: InputMaybe<Scalars["String"]>;
    ceo?: InputMaybe<Scalars["String"]>;
    email: Scalars["String"];
    name: Scalars["String"];
    password: Scalars["String"];
    phoneNumber: Scalars["String"];
    registrationFile?: InputMaybe<Scalars["String"]>;
    registrationNumber?: InputMaybe<Scalars["String"]>;
    userType: UserType;
    username: Scalars["String"];
    zipCode?: InputMaybe<Scalars["String"]>;
};

export type DeleteEmailAccountAdminInput = {
    emailAccountId: Scalars["Int"];
};

export type DeletePaymentInput = {
    paymentId: Scalars["Int"];
};

export type DeleteRequestAdminInput = {
    requestId: Scalars["Int"];
};

export type Design = {
    __typename?: "Design";
    createdAt?: Maybe<Scalars["DateTime"]>;
    designRequests: Array<Maybe<DesignRequest>>;
    domainUrl: Scalars["String"];
    id: Scalars["Int"];
    memo: Scalars["String"];
    questions: Array<Maybe<Question>>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
    user?: Maybe<User>;
    userId?: Maybe<Scalars["Int"]>;
};

export type DesignRequest = {
    __typename?: "DesignRequest";
    createdAt?: Maybe<Scalars["DateTime"]>;
    design: Design;
    designId: Scalars["Int"];
    domainUrl: Scalars["String"];
    id: Scalars["Int"];
    memo: Scalars["String"];
    payment?: Maybe<Payment>;
    paymentId?: Maybe<Scalars["Int"]>;
    price: Scalars["Int"];
    subject: Scalars["String"];
    updatedAt?: Maybe<Scalars["DateTime"]>;
    user?: Maybe<User>;
    userId?: Maybe<Scalars["Int"]>;
};

export type Domain = {
    __typename?: "Domain";
    createdAt?: Maybe<Scalars["DateTime"]>;
    domainType: DomainType;
    domainUrl: Scalars["String"];
    id: Scalars["Int"];
    lastBillingDate?: Maybe<Scalars["DateTime"]>;
    memo: Scalars["String"];
    nextBillingDate?: Maybe<Scalars["DateTime"]>;
    payments: Array<Maybe<Payment>>;
    period: Period;
    price: Scalars["Int"];
    productActive: ProductActive;
    questions: Array<Maybe<Question>>;
    registeredCompany: RegisteredCompany;
    updatedAt?: Maybe<Scalars["DateTime"]>;
    user?: Maybe<User>;
    userId?: Maybe<Scalars["Int"]>;
};

export enum DomainType {
    AcKr = "AcKr",
    Biz = "Biz",
    CoKr = "CoKr",
    Com = "Com",
    Kr = "Kr",
    Net = "Net",
    OrKr = "OrKr",
    Org = "Org",
    PeKr = "PeKr",
    Xyz = "Xyz",
}

export type Email = {
    __typename?: "Email";
    createdAt?: Maybe<Scalars["DateTime"]>;
    domainUrl: Scalars["String"];
    emailAccount: Array<Maybe<EmailAccount>>;
    id: Scalars["Int"];
    lastBillingDate?: Maybe<Scalars["DateTime"]>;
    memo: Scalars["String"];
    nextBillingDate?: Maybe<Scalars["DateTime"]>;
    payments: Array<Maybe<Payment>>;
    period: Period;
    price: Scalars["Int"];
    productActive: ProductActive;
    questions: Array<Maybe<Question>>;
    registeredCompany: RegisteredCompany;
    updatedAt?: Maybe<Scalars["DateTime"]>;
    user?: Maybe<User>;
    userId?: Maybe<Scalars["Int"]>;
};

export type EmailAccount = {
    __typename?: "EmailAccount";
    account: Scalars["String"];
    active: Scalars["Boolean"];
    createdAt?: Maybe<Scalars["DateTime"]>;
    domainUrl: Scalars["String"];
    email?: Maybe<Email>;
    emailId?: Maybe<Scalars["Int"]>;
    freeQuota: Scalars["Int"];
    id: Scalars["Int"];
    memo: Scalars["String"];
    quota: Scalars["Int"];
    updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type FindFirstCertificateAdminInput = {
    id: Scalars["Int"];
};

export type FindFirstCompanyAdminInput = {
    registrationNumber: Scalars["String"];
};

export type FindFirstDomainAdminInput = {
    id: Scalars["Int"];
};

export type FindFirstEmailAccountAdminInput = {
    id: Scalars["Int"];
};

export type FindFirstEmailAdminInput = {
    id: Scalars["Int"];
};

export type FindFirstHostingAdminInput = {
    id: Scalars["Int"];
};

export type FindFirstPaymentAdmin = {
    paymentId: Scalars["Int"];
};

export type FindFirstUserAdminInput = {
    userId: Scalars["Int"];
};

export type FindManyCertificateInput = {
    method?: InputMaybe<Scalars["String"]>;
    page?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    term?: InputMaybe<Scalars["String"]>;
};

export type FindManyDomainAdminInput = {
    method?: InputMaybe<Scalars["String"]>;
    page?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    term?: InputMaybe<Scalars["String"]>;
};

export type FindManyEmailAccountAdminInput = {
    emailId?: InputMaybe<Scalars["Int"]>;
    page?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    term?: InputMaybe<Scalars["String"]>;
};

export type FindManyEmailAdminInput = {
    method?: InputMaybe<Scalars["String"]>;
    page?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    term?: InputMaybe<Scalars["String"]>;
};

export type FindManyHostingAdminInput = {
    method?: InputMaybe<Scalars["String"]>;
    page?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    term?: InputMaybe<Scalars["String"]>;
};

export type FindManyPaymentAdmin = {
    searchDate: Scalars["String"];
};

export type FindManyPaymentForCertificateAdmin = {
    certificateId: Scalars["Int"];
    page?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
};

export type FindManyPaymentForDomainAdminInput = {
    domainId: Scalars["Int"];
    page?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
};

export type FindManyPaymentForEmailAdminInput = {
    emailId: Scalars["Int"];
    page?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
};

export type FindManyPaymentForHostingAdminInput = {
    hostingId: Scalars["Int"];
    page?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
};

export type FindManyPaymentForUserAdminInput = {
    page?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    userId: Scalars["Int"];
};

export type FindManyQuestionForAllAdminInput = {
    method?: InputMaybe<Scalars["String"]>;
    page?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    term?: InputMaybe<Scalars["String"]>;
};

export type FindManyRequestAdminInput = {
    method?: InputMaybe<Scalars["String"]>;
    page?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    term?: InputMaybe<Scalars["String"]>;
};

export type FindManyUserAdminInput = {
    method?: InputMaybe<Scalars["String"]>;
    page?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    term?: InputMaybe<Scalars["String"]>;
};

export type Hosting = {
    __typename?: "Hosting";
    createdAt?: Maybe<Scalars["DateTime"]>;
    domainUrl: Scalars["String"];
    hostingInfo?: Maybe<HostingInfo>;
    hostingType: HostingType;
    id: Scalars["Int"];
    lastBillingDate?: Maybe<Scalars["DateTime"]>;
    memo: Scalars["String"];
    nextBillingDate?: Maybe<Scalars["DateTime"]>;
    payments: Array<Maybe<Payment>>;
    period: Period;
    price: Scalars["Int"];
    productActive: ProductActive;
    questions: Array<Maybe<Question>>;
    registeredCompany: RegisteredCompany;
    updatedAt?: Maybe<Scalars["DateTime"]>;
    user?: Maybe<User>;
    userId?: Maybe<Scalars["Int"]>;
};

export type HostingInfo = {
    __typename?: "HostingInfo";
    addStorage: Scalars["Int"];
    createdAt?: Maybe<Scalars["DateTime"]>;
    domainUrl: Scalars["String"];
    ftpId: Scalars["String"];
    ftpIp: Scalars["String"];
    ftpPassword: Scalars["String"];
    hosting?: Maybe<Hosting>;
    hostingId?: Maybe<Scalars["Int"]>;
    id: Scalars["Int"];
    mysqlDatabase: Scalars["String"];
    mysqlId: Scalars["String"];
    mysqlPassword: Scalars["String"];
    updatedAt?: Maybe<Scalars["DateTime"]>;
};

export enum HostingType {
    GoldenShop = "GoldenShop",
    LinuxBasic = "LinuxBasic",
    LinuxBasicPlus = "LinuxBasicPlus",
    LinuxPremium = "LinuxPremium",
    LinuxPremiumPlus = "LinuxPremiumPlus",
    LinuxPro = "LinuxPro",
    LinuxProPlus = "LinuxProPlus",
    Server = "Server",
    WindowsBasic = "WindowsBasic",
    WindowsPro = "WindowsPro",
}

export type MakeCsrInput = {
    c: Scalars["String"];
    cn: Scalars["String"];
    l: Scalars["String"];
    o: Scalars["String"];
    ou: Scalars["String"];
    st: Scalars["String"];
};

export type Mutation = {
    __typename?: "Mutation";
    changeRequestStatusAdmin: Scalars["Boolean"];
    connectCertificateAdmin: Scalars["Boolean"];
    connectDomainAdmin: Scalars["Boolean"];
    connectEmailAdmin: Scalars["Boolean"];
    connectHostingAdmin: Scalars["Boolean"];
    createAdmin: Scalars["Boolean"];
    createCertificateAdmin: Scalars["Boolean"];
    createDomainAdmin: Scalars["Boolean"];
    createEmailAccountAdmin: Scalars["Boolean"];
    createEmailAdmin: Scalars["Boolean"];
    createHostingAdmin: Scalars["Boolean"];
    createPaymentForCertificateAdmin: Scalars["Boolean"];
    createPaymentForDomainAdmin: Scalars["Boolean"];
    createPaymentForEmailAdmin: Scalars["Boolean"];
    createPaymentForEtcAdmin: Scalars["Boolean"];
    createPaymentForHostingAdmin: Scalars["Boolean"];
    createQuestionAdmin: Scalars["Boolean"];
    createRequestAdmin: Scalars["Boolean"];
    createUser: Scalars["Boolean"];
    deleteEmailAccountAdmin: Scalars["Boolean"];
    deletePaymentAdmin: Scalars["Boolean"];
    deleteRequestAdmin: Scalars["Boolean"];
    loginAdmin: Scalars["String"];
    sendBillingEmailCertificateAdmin: Scalars["Boolean"];
    sendBillingEmailDomainAdmin: Scalars["Boolean"];
    sendBillingEmailEmailAdmin: Scalars["Boolean"];
    sendBillingEmailHostingAdmin: Scalars["Boolean"];
    updateCertificateAdmin: Scalars["Boolean"];
    updateDomainAdmin: Scalars["Boolean"];
    updateEmailAccountAdmin: Scalars["Boolean"];
    updateEmailAdmin: Scalars["Boolean"];
    updateHostingAdmin: Scalars["Boolean"];
    updatePaymentForCertificateAdmin: Scalars["Boolean"];
    updatePaymentForDomainAdmin: Scalars["Boolean"];
    updatePaymentForEmailAdmin: Scalars["Boolean"];
    updatePaymentForEtcAdmin: Scalars["Boolean"];
    updatePaymentForHostingAdmin: Scalars["Boolean"];
    updateRequestAdmin: Scalars["Boolean"];
    updateUserAdmin: Scalars["Boolean"];
};

export type MutationChangeRequestStatusAdminArgs = {
    input: ChangeRequestStatusAdminInput;
};

export type MutationConnectCertificateAdminArgs = {
    input: ConnectCertificateAdminInput;
};

export type MutationConnectDomainAdminArgs = {
    input: ConnectDomainAdminInput;
};

export type MutationConnectEmailAdminArgs = {
    input: ConnectEmailAdminInput;
};

export type MutationConnectHostingAdminArgs = {
    input: ConnectHostingAdminInput;
};

export type MutationCreateAdminArgs = {
    input: CreateAdminInput;
};

export type MutationCreateCertificateAdminArgs = {
    input: CreateCertificateAdminInput;
};

export type MutationCreateDomainAdminArgs = {
    input: CreateDomainAdminInput;
};

export type MutationCreateEmailAccountAdminArgs = {
    input: CreateEmailAccountAdminInput;
};

export type MutationCreateEmailAdminArgs = {
    input: CreateEmailAdminInput;
};

export type MutationCreateHostingAdminArgs = {
    input: CreateHostingAdminInput;
};

export type MutationCreatePaymentForCertificateAdminArgs = {
    input: CreatePaymentForCertificateAdminInput;
};

export type MutationCreatePaymentForDomainAdminArgs = {
    input: CreatePaymentForDomainAdminInput;
};

export type MutationCreatePaymentForEmailAdminArgs = {
    input: CreatePaymentForEmailAdminInput;
};

export type MutationCreatePaymentForEtcAdminArgs = {
    input: CreatePaymentForEtcAdminInput;
};

export type MutationCreatePaymentForHostingAdminArgs = {
    input: CreatePaymentForHostingAdminInput;
};

export type MutationCreateQuestionAdminArgs = {
    input: CreateQuestionAdminInput;
};

export type MutationCreateRequestAdminArgs = {
    input: CreateRequestAdminInput;
};

export type MutationCreateUserArgs = {
    input: CreateUserInput;
};

export type MutationDeleteEmailAccountAdminArgs = {
    input: DeleteEmailAccountAdminInput;
};

export type MutationDeletePaymentAdminArgs = {
    input: DeletePaymentInput;
};

export type MutationDeleteRequestAdminArgs = {
    input: DeleteRequestAdminInput;
};

export type MutationLoginAdminArgs = {
    input: LoginAdminInput;
};

export type MutationSendBillingEmailCertificateAdminArgs = {
    input: SendBillingEmailCertificateAdminInput;
};

export type MutationSendBillingEmailDomainAdminArgs = {
    input: SendBillingEmailDomainAdminInput;
};

export type MutationSendBillingEmailEmailAdminArgs = {
    input: SendBillingEmailEmailAdminInput;
};

export type MutationSendBillingEmailHostingAdminArgs = {
    input: SendBillingEmailHostingAdminInput;
};

export type MutationUpdateCertificateAdminArgs = {
    input: UpdateCertificateAdminInput;
};

export type MutationUpdateDomainAdminArgs = {
    input: UpdateDomainAdminInput;
};

export type MutationUpdateEmailAccountAdminArgs = {
    input: UpdateEmailAccountAdminInput;
};

export type MutationUpdateEmailAdminArgs = {
    input: UpdateEmailAdminInput;
};

export type MutationUpdateHostingAdminArgs = {
    input: UpdateHostingAdminInput;
};

export type MutationUpdatePaymentForCertificateAdminArgs = {
    input: UpdatePaymentForCertificateAdminInput;
};

export type MutationUpdatePaymentForDomainAdminArgs = {
    input: UpdatePaymentForDomainAdminInput;
};

export type MutationUpdatePaymentForEmailAdminArgs = {
    input: UpdatePaymentForEmailAdminInput;
};

export type MutationUpdatePaymentForEtcAdminArgs = {
    input: UpdatePaymentForEtcAdminInput;
};

export type MutationUpdatePaymentForHostingAdminArgs = {
    input: UpdatePaymentForHostingAdminInput;
};

export type MutationUpdateRequestAdminArgs = {
    input: UpdateRequestAdminInput;
};

export type MutationUpdateUserAdminArgs = {
    input: UpdateUserAdminInput;
};

export type Payment = {
    __typename?: "Payment";
    certificate?: Maybe<Certificate>;
    certificateId?: Maybe<Scalars["Int"]>;
    company?: Maybe<Company>;
    companyId?: Maybe<Scalars["Int"]>;
    createdAt?: Maybe<Scalars["DateTime"]>;
    depositor?: Maybe<Scalars["String"]>;
    designRequest?: Maybe<DesignRequest>;
    designRequestId?: Maybe<Scalars["Int"]>;
    domain?: Maybe<Domain>;
    domainId?: Maybe<Scalars["Int"]>;
    domainUrl: Scalars["String"];
    email?: Maybe<Email>;
    emailId?: Maybe<Scalars["Int"]>;
    hosting?: Maybe<Hosting>;
    hostingId?: Maybe<Scalars["Int"]>;
    id: Scalars["Int"];
    memo: Scalars["String"];
    paymentMethod: PaymentMethod;
    phoneNumber?: Maybe<Scalars["String"]>;
    price: Scalars["Int"];
    receipts: Receipts;
    receiptsStatus: ReceiptsStatus;
    sms?: Maybe<Sms>;
    smsId?: Maybe<Scalars["Int"]>;
    type: ProductType;
    updatedAt?: Maybe<Scalars["DateTime"]>;
    user?: Maybe<User>;
    userId?: Maybe<Scalars["Int"]>;
};

export enum PaymentMethod {
    Card = "Card",
    Mobile = "Mobile",
    VirtualBank = "VirtualBank",
    WithBank = "WithBank",
    WithoutBank = "WithoutBank",
}

export type PaymentRequest = {
    __typename?: "PaymentRequest";
    domainUrl: Scalars["String"];
    id: Scalars["Int"];
    paymentDate: Scalars["DateTime"];
    price: Scalars["Int"];
    productId: Scalars["Int"];
    productType: ProductType;
    userId?: Maybe<Scalars["Int"]>;
    username?: Maybe<Scalars["String"]>;
};

export enum Period {
    Annual = "Annual",
    FiveYear = "FiveYear",
    Monthly = "Monthly",
    Semi = "Semi",
    TenYear = "TenYear",
    Three = "Three",
    ThreeYear = "ThreeYear",
    TwoYear = "TwoYear",
}

export enum ProductActive {
    Active = "Active",
    Delete = "Delete",
    Pause = "Pause",
    Stop = "Stop",
}

export enum ProductType {
    Certificate = "Certificate",
    Design = "Design",
    Domain = "Domain",
    Etc = "ETC",
    Email = "Email",
    Hosting = "Hosting",
    Sms = "SMS",
}

export type Query = {
    __typename?: "Query";
    countCertificateAdmin: Scalars["Int"];
    countDomainAdmin: Scalars["Int"];
    countEmailAccountAdmin: Scalars["Int"];
    countEmailAdmin: Scalars["Int"];
    countHostingAdmin: Scalars["Int"];
    countPaymentForCertificateAdmin: Scalars["Int"];
    countPaymentForDomainAdmin: Scalars["Int"];
    countPaymentForEmailAdmin: Scalars["Int"];
    countPaymentForHostingAdmin: Scalars["Int"];
    countPaymentForUserAdmin: Scalars["Int"];
    countQuestionForAllAdmin: Scalars["Int"];
    countRequestAdmin: Scalars["Int"];
    countUserAdmin: Scalars["Int"];
    findFirstCertificateAdmin: Certificate;
    findFirstCompanyAdmin: Company;
    findFirstDomainAdmin: Domain;
    findFirstEmailAccountAdmin: EmailAccount;
    findFirstEmailAdmin: Email;
    findFirstHostingAdmin: Hosting;
    findFirstPaymentAdmin: Payment;
    findFirstUserAdmin: User;
    findManyCertificate: Array<Certificate>;
    findManyDomainAdmin: Array<Domain>;
    findManyEmailAccountAdmin: Array<Maybe<EmailAccount>>;
    findManyEmailAdmin: Array<Email>;
    findManyHostingAdmin: Array<Hosting>;
    findManyPaymentAdmin: Array<Maybe<Payment>>;
    findManyPaymentForCertificateAdmin: Array<Maybe<Payment>>;
    findManyPaymentForDomainAdmin: Array<Maybe<Payment>>;
    findManyPaymentForEmailAdmin: Array<Maybe<Payment>>;
    findManyPaymentForHostingAdmin: Array<Maybe<Payment>>;
    findManyPaymentForUserAdmin: Array<Maybe<Payment>>;
    findManyPaymentRequestAdmin: Array<Maybe<PaymentRequest>>;
    findManyQuestionForAllAdmin: Array<Question>;
    findManyRequestAdmin: Array<Request>;
    findManyUserAdmin: Array<User>;
    makeCSR: Scalars["String"];
    me: User;
    searchCertificateAdmin: Array<Maybe<Certificate>>;
    searchDomainAdmin: Array<Maybe<Domain>>;
    searchEmailAdmin: Array<Maybe<Email>>;
    searchHostingAdmin: Array<Maybe<Hosting>>;
    summaryPaymentAdmin: SummaryPayment;
};

export type QueryCountCertificateAdminArgs = {
    input: CountCertificateAdminInput;
};

export type QueryCountDomainAdminArgs = {
    input: CountDomainAdminInput;
};

export type QueryCountEmailAccountAdminArgs = {
    input: CountEmailAccountAdminInput;
};

export type QueryCountEmailAdminArgs = {
    input: CountEmailAdminInput;
};

export type QueryCountHostingAdminArgs = {
    input: CountHostingAdminInput;
};

export type QueryCountPaymentForCertificateAdminArgs = {
    input: CountPaymentForCertificateAdminInput;
};

export type QueryCountPaymentForDomainAdminArgs = {
    input: CountPaymentForDomainAdminInput;
};

export type QueryCountPaymentForEmailAdminArgs = {
    input: CountPaymentForEmailAdminInput;
};

export type QueryCountPaymentForHostingAdminArgs = {
    input: CountPaymentForHostingAdminInput;
};

export type QueryCountPaymentForUserAdminArgs = {
    input: CountPaymentForUserAdminInput;
};

export type QueryCountQuestionForAllAdminArgs = {
    input: CountQuestionForAllAdminInput;
};

export type QueryCountRequestAdminArgs = {
    input: CountRequestAdminInput;
};

export type QueryCountUserAdminArgs = {
    input: CountUserAdminInput;
};

export type QueryFindFirstCertificateAdminArgs = {
    input: FindFirstCertificateAdminInput;
};

export type QueryFindFirstCompanyAdminArgs = {
    input: FindFirstCompanyAdminInput;
};

export type QueryFindFirstDomainAdminArgs = {
    input: FindFirstDomainAdminInput;
};

export type QueryFindFirstEmailAccountAdminArgs = {
    input: FindFirstEmailAccountAdminInput;
};

export type QueryFindFirstEmailAdminArgs = {
    input: FindFirstEmailAdminInput;
};

export type QueryFindFirstHostingAdminArgs = {
    input: FindFirstHostingAdminInput;
};

export type QueryFindFirstPaymentAdminArgs = {
    input: FindFirstPaymentAdmin;
};

export type QueryFindFirstUserAdminArgs = {
    input: FindFirstUserAdminInput;
};

export type QueryFindManyCertificateArgs = {
    input: FindManyCertificateInput;
};

export type QueryFindManyDomainAdminArgs = {
    input: FindManyDomainAdminInput;
};

export type QueryFindManyEmailAccountAdminArgs = {
    input: FindManyEmailAccountAdminInput;
};

export type QueryFindManyEmailAdminArgs = {
    input: FindManyEmailAdminInput;
};

export type QueryFindManyHostingAdminArgs = {
    input: FindManyHostingAdminInput;
};

export type QueryFindManyPaymentAdminArgs = {
    input: FindManyPaymentAdmin;
};

export type QueryFindManyPaymentForCertificateAdminArgs = {
    input: FindManyPaymentForCertificateAdmin;
};

export type QueryFindManyPaymentForDomainAdminArgs = {
    input: FindManyPaymentForDomainAdminInput;
};

export type QueryFindManyPaymentForEmailAdminArgs = {
    input: FindManyPaymentForEmailAdminInput;
};

export type QueryFindManyPaymentForHostingAdminArgs = {
    input: FindManyPaymentForHostingAdminInput;
};

export type QueryFindManyPaymentForUserAdminArgs = {
    input: FindManyPaymentForUserAdminInput;
};

export type QueryFindManyQuestionForAllAdminArgs = {
    input: FindManyQuestionForAllAdminInput;
};

export type QueryFindManyRequestAdminArgs = {
    input: FindManyRequestAdminInput;
};

export type QueryFindManyUserAdminArgs = {
    input: FindManyUserAdminInput;
};

export type QueryMakeCsrArgs = {
    input: MakeCsrInput;
};

export type QuerySearchCertificateAdminArgs = {
    input: SearchCertificateAdminInput;
};

export type QuerySearchDomainAdminArgs = {
    input: SearchDomainAdminInput;
};

export type QuerySearchEmailAdminArgs = {
    input: SearchEmailAdminInput;
};

export type QuerySearchHostingAdminArgs = {
    input: SearchHostingAdminInput;
};

export type QuerySummaryPaymentAdminArgs = {
    input: SummaryPaymentAdminInput;
};

export type Question = {
    __typename?: "Question";
    certificate?: Maybe<Certificate>;
    certificateId?: Maybe<Scalars["Int"]>;
    createdAt?: Maybe<Scalars["DateTime"]>;
    design?: Maybe<Design>;
    designId?: Maybe<Scalars["Int"]>;
    domain?: Maybe<Domain>;
    domainId?: Maybe<Scalars["Int"]>;
    domainUrl: Scalars["String"];
    email?: Maybe<Email>;
    emailId?: Maybe<Scalars["Int"]>;
    hosting?: Maybe<Hosting>;
    hostingId?: Maybe<Scalars["Int"]>;
    id: Scalars["Int"];
    posts: Array<QuestionPost>;
    productType: ProductType;
    questionStatus: QuestionStatus;
    sms?: Maybe<Sms>;
    smsId?: Maybe<Scalars["Int"]>;
    subject: Scalars["String"];
    updatedAt?: Maybe<Scalars["DateTime"]>;
    user?: Maybe<User>;
    userId?: Maybe<Scalars["Int"]>;
    writer?: Maybe<Scalars["String"]>;
};

export type QuestionPost = {
    __typename?: "QuestionPost";
    content: Scalars["String"];
    createdAt?: Maybe<Scalars["DateTime"]>;
    id: Scalars["Int"];
    question?: Maybe<Question>;
    questionId?: Maybe<Scalars["Int"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
    writer?: Maybe<Scalars["String"]>;
};

export enum QuestionStatus {
    Proceeding = "Proceeding",
    Requested = "Requested",
    Solved = "Solved",
}

export enum Receipts {
    Cash = "Cash",
    Company = "Company",
    None = "None",
}

export enum ReceiptsStatus {
    Issued = "Issued",
    None = "None",
    Pre = "Pre",
    Request = "Request",
}

export enum RegisteredCompany {
    A2Hosting = "A2Hosting",
    Cafe24 = "Cafe24",
    Etc = "ETC",
    Gabia = "Gabia",
    GoDaddy = "GoDaddy",
    Goldennet = "Goldennet",
    HostingKr = "HostingKR",
    Hostinger = "Hostinger",
    Sectigo = "Sectigo",
}

export type Request = {
    __typename?: "Request";
    completer?: Maybe<Scalars["String"]>;
    content: Scalars["String"];
    createdAt?: Maybe<Scalars["DateTime"]>;
    domainUrl: Scalars["String"];
    id: Scalars["Int"];
    productType: ProductType;
    requestStatus: RequestStatus;
    subject: Scalars["String"];
    updatedAt?: Maybe<Scalars["DateTime"]>;
    writer: Scalars["String"];
};

export enum RequestStatus {
    Proceeding = "Proceeding",
    Requested = "Requested",
    Solved = "Solved",
}

export type SearchCertificateAdminInput = {
    searchValue: Scalars["String"];
};

export type SearchDomainAdminInput = {
    searchValue: Scalars["String"];
};

export type SearchEmailAdminInput = {
    searchValue: Scalars["String"];
};

export type SearchHostingAdminInput = {
    searchValue: Scalars["String"];
};

export type SendBillingEmailCertificateAdminInput = {
    certificateId: Scalars["Int"];
};

export type SendBillingEmailDomainAdminInput = {
    domainId: Scalars["Int"];
};

export type SendBillingEmailEmailAdminInput = {
    emailId: Scalars["Int"];
};

export type SendBillingEmailHostingAdminInput = {
    hostingId: Scalars["Int"];
};

export type Sms = {
    __typename?: "Sms";
    active: Scalars["Boolean"];
    createdAt?: Maybe<Scalars["DateTime"]>;
    id: Scalars["Int"];
    memo: Scalars["String"];
    payments: Array<Maybe<Payment>>;
    questions: Array<Maybe<Question>>;
    registeredCompany: RegisteredCompany;
    smsApiKey: Scalars["String"];
    smsId: Scalars["String"];
    smsInfo?: Maybe<SmsInfo>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
    user?: Maybe<User>;
    userId?: Maybe<Scalars["Int"]>;
};

export type SmsInfo = {
    __typename?: "SmsInfo";
    balance: Scalars["Int"];
    createdAt?: Maybe<Scalars["DateTime"]>;
    id: Scalars["Int"];
    sms: Sms;
    smsId: Scalars["Int"];
    unitPrice: Scalars["Int"];
    updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type SummaryPayment = {
    __typename?: "SummaryPayment";
    cash: SummaryPaymentSub;
    checkOut: SummaryPaymentSub;
    total: SummaryPaymentSub;
};

export type SummaryPaymentAdminInput = {
    searchDate: Scalars["String"];
};

export type SummaryPaymentSub = {
    __typename?: "SummaryPaymentSub";
    certificate: Scalars["Int"];
    design: Scalars["Int"];
    domain: Scalars["Int"];
    email: Scalars["Int"];
    etc: Scalars["Int"];
    hosting: Scalars["Int"];
    sms: Scalars["Int"];
    total: Scalars["Int"];
};

export type UpdateCertificateAdminInput = {
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    domainUrl: Scalars["String"];
    id: Scalars["Int"];
    lastBillingDate?: InputMaybe<Scalars["DateTime"]>;
    memo?: InputMaybe<Scalars["String"]>;
    nextBillingDate?: InputMaybe<Scalars["DateTime"]>;
    period: Period;
    price: Scalars["Int"];
    productActive: ProductActive;
    registeredCompany: RegisteredCompany;
    type: CertificateType;
    userId?: InputMaybe<Scalars["Int"]>;
};

export type UpdateDomainAdminInput = {
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    domainType: DomainType;
    domainUrl: Scalars["String"];
    id: Scalars["Int"];
    lastBillingDate?: InputMaybe<Scalars["DateTime"]>;
    memo?: InputMaybe<Scalars["String"]>;
    nextBillingDate?: InputMaybe<Scalars["DateTime"]>;
    period: Period;
    price: Scalars["Int"];
    productActive: ProductActive;
    registeredCompany: RegisteredCompany;
    userId?: InputMaybe<Scalars["Int"]>;
};

export type UpdateEmailAccountAdminInput = {
    account: Scalars["String"];
    checkRequest: Scalars["Boolean"];
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    emailId: Scalars["Int"];
    freeQuota: Scalars["Int"];
    id: Scalars["Int"];
    memo: Scalars["String"];
    quota: Scalars["Int"];
};

export type UpdateEmailAdminInput = {
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    domainUrl: Scalars["String"];
    id: Scalars["Int"];
    lastBillingDate?: InputMaybe<Scalars["DateTime"]>;
    memo?: InputMaybe<Scalars["String"]>;
    nextBillingDate?: InputMaybe<Scalars["DateTime"]>;
    period: Period;
    productActive: ProductActive;
    registeredCompany: RegisteredCompany;
    userId?: InputMaybe<Scalars["Int"]>;
};

export type UpdateHostingAdminInput = {
    addStorage?: InputMaybe<Scalars["Int"]>;
    checkInfo: Scalars["Boolean"];
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    domainUrl: Scalars["String"];
    ftpId?: InputMaybe<Scalars["String"]>;
    ftpIp?: InputMaybe<Scalars["String"]>;
    ftpPassword?: InputMaybe<Scalars["String"]>;
    hostingType: HostingType;
    id: Scalars["Int"];
    lastBillingDate?: InputMaybe<Scalars["DateTime"]>;
    memo?: InputMaybe<Scalars["String"]>;
    mysqlDatabase?: InputMaybe<Scalars["String"]>;
    mysqlId?: InputMaybe<Scalars["String"]>;
    mysqlPassword?: InputMaybe<Scalars["String"]>;
    nextBillingDate?: InputMaybe<Scalars["DateTime"]>;
    period: Period;
    price: Scalars["Int"];
    productActive: ProductActive;
    registeredCompany: RegisteredCompany;
    userId?: InputMaybe<Scalars["Int"]>;
};

export type UpdatePaymentForCertificateAdminInput = {
    address1?: InputMaybe<Scalars["String"]>;
    address2?: InputMaybe<Scalars["String"]>;
    businessSector?: InputMaybe<Scalars["String"]>;
    businessStatus?: InputMaybe<Scalars["String"]>;
    ceo?: InputMaybe<Scalars["String"]>;
    certificateId: Scalars["Int"];
    companyName?: InputMaybe<Scalars["String"]>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    depositor?: InputMaybe<Scalars["String"]>;
    domainUrl: Scalars["String"];
    email?: InputMaybe<Scalars["String"]>;
    lastBillingDate?: InputMaybe<Scalars["DateTime"]>;
    memo: Scalars["String"];
    nextBillingDate?: InputMaybe<Scalars["DateTime"]>;
    paymentId: Scalars["Int"];
    paymentMethod: PaymentMethod;
    phoneNumber?: InputMaybe<Scalars["String"]>;
    price: Scalars["Int"];
    receipts: Receipts;
    receiptsStatus: ReceiptsStatus;
    registrationFile?: InputMaybe<Scalars["String"]>;
    registrationNumber?: InputMaybe<Scalars["String"]>;
    type: ProductType;
    userId?: InputMaybe<Scalars["Int"]>;
    zipCode?: InputMaybe<Scalars["String"]>;
};

export type UpdatePaymentForDomainAdminInput = {
    address1?: InputMaybe<Scalars["String"]>;
    address2?: InputMaybe<Scalars["String"]>;
    businessSector?: InputMaybe<Scalars["String"]>;
    businessStatus?: InputMaybe<Scalars["String"]>;
    ceo?: InputMaybe<Scalars["String"]>;
    companyName?: InputMaybe<Scalars["String"]>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    depositor?: InputMaybe<Scalars["String"]>;
    domainId: Scalars["Int"];
    domainUrl: Scalars["String"];
    email?: InputMaybe<Scalars["String"]>;
    lastBillingDate?: InputMaybe<Scalars["DateTime"]>;
    memo: Scalars["String"];
    nextBillingDate?: InputMaybe<Scalars["DateTime"]>;
    paymentId: Scalars["Int"];
    paymentMethod: PaymentMethod;
    phoneNumber?: InputMaybe<Scalars["String"]>;
    price: Scalars["Int"];
    receipts: Receipts;
    receiptsStatus: ReceiptsStatus;
    registrationFile?: InputMaybe<Scalars["String"]>;
    registrationNumber?: InputMaybe<Scalars["String"]>;
    type: ProductType;
    userId?: InputMaybe<Scalars["Int"]>;
    zipCode?: InputMaybe<Scalars["String"]>;
};

export type UpdatePaymentForEmailAdminInput = {
    address1?: InputMaybe<Scalars["String"]>;
    address2?: InputMaybe<Scalars["String"]>;
    businessSector?: InputMaybe<Scalars["String"]>;
    businessStatus?: InputMaybe<Scalars["String"]>;
    ceo?: InputMaybe<Scalars["String"]>;
    companyName?: InputMaybe<Scalars["String"]>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    depositor?: InputMaybe<Scalars["String"]>;
    domainUrl: Scalars["String"];
    email?: InputMaybe<Scalars["String"]>;
    emailId: Scalars["Int"];
    lastBillingDate?: InputMaybe<Scalars["DateTime"]>;
    memo: Scalars["String"];
    nextBillingDate?: InputMaybe<Scalars["DateTime"]>;
    paymentId: Scalars["Int"];
    paymentMethod: PaymentMethod;
    phoneNumber?: InputMaybe<Scalars["String"]>;
    price: Scalars["Int"];
    receipts: Receipts;
    receiptsStatus: ReceiptsStatus;
    registrationFile?: InputMaybe<Scalars["String"]>;
    registrationNumber?: InputMaybe<Scalars["String"]>;
    type: ProductType;
    userId?: InputMaybe<Scalars["Int"]>;
    zipCode?: InputMaybe<Scalars["String"]>;
};

export type UpdatePaymentForEtcAdminInput = {
    address1?: InputMaybe<Scalars["String"]>;
    address2?: InputMaybe<Scalars["String"]>;
    businessSector?: InputMaybe<Scalars["String"]>;
    businessStatus?: InputMaybe<Scalars["String"]>;
    ceo?: InputMaybe<Scalars["String"]>;
    companyName?: InputMaybe<Scalars["String"]>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    depositor?: InputMaybe<Scalars["String"]>;
    domainUrl: Scalars["String"];
    email?: InputMaybe<Scalars["String"]>;
    lastBillingDate?: InputMaybe<Scalars["DateTime"]>;
    memo: Scalars["String"];
    nextBillingDate?: InputMaybe<Scalars["DateTime"]>;
    paymentId: Scalars["Int"];
    paymentMethod: PaymentMethod;
    phoneNumber?: InputMaybe<Scalars["String"]>;
    price: Scalars["Int"];
    receipts: Receipts;
    receiptsStatus: ReceiptsStatus;
    registrationFile?: InputMaybe<Scalars["String"]>;
    registrationNumber?: InputMaybe<Scalars["String"]>;
    type: ProductType;
    userId?: InputMaybe<Scalars["Int"]>;
    zipCode?: InputMaybe<Scalars["String"]>;
};

export type UpdatePaymentForHostingAdminInput = {
    address1?: InputMaybe<Scalars["String"]>;
    address2?: InputMaybe<Scalars["String"]>;
    businessSector?: InputMaybe<Scalars["String"]>;
    businessStatus?: InputMaybe<Scalars["String"]>;
    ceo?: InputMaybe<Scalars["String"]>;
    companyName?: InputMaybe<Scalars["String"]>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    depositor?: InputMaybe<Scalars["String"]>;
    domainUrl: Scalars["String"];
    email?: InputMaybe<Scalars["String"]>;
    hostingId: Scalars["Int"];
    lastBillingDate?: InputMaybe<Scalars["DateTime"]>;
    memo: Scalars["String"];
    nextBillingDate?: InputMaybe<Scalars["DateTime"]>;
    paymentId: Scalars["Int"];
    paymentMethod: PaymentMethod;
    phoneNumber?: InputMaybe<Scalars["String"]>;
    price: Scalars["Int"];
    receipts: Receipts;
    receiptsStatus: ReceiptsStatus;
    registrationFile?: InputMaybe<Scalars["String"]>;
    registrationNumber?: InputMaybe<Scalars["String"]>;
    type: ProductType;
    userId?: InputMaybe<Scalars["Int"]>;
    zipCode?: InputMaybe<Scalars["String"]>;
};

export type UpdateRequestAdminInput = {
    completer: Scalars["String"];
    content: Scalars["String"];
    requestId: Scalars["Int"];
    requestStatus: RequestStatus;
    subject: Scalars["String"];
};

export type UpdateUserAdminInput = {
    active: Scalars["Boolean"];
    address1?: InputMaybe<Scalars["String"]>;
    address2?: InputMaybe<Scalars["String"]>;
    applyReceiveMail: Scalars["Boolean"];
    applyReceiveSMS: Scalars["Boolean"];
    businessSector?: InputMaybe<Scalars["String"]>;
    businessStatus?: InputMaybe<Scalars["String"]>;
    ceo?: InputMaybe<Scalars["String"]>;
    email: Scalars["String"];
    name: Scalars["String"];
    phoneNumber: Scalars["String"];
    registrationFile?: InputMaybe<Scalars["String"]>;
    registrationNumber?: InputMaybe<Scalars["String"]>;
    userId: Scalars["Int"];
    userType: UserType;
    zipCode?: InputMaybe<Scalars["String"]>;
};

export type User = {
    __typename?: "User";
    active: Scalars["Boolean"];
    applyReceiveMail: Scalars["Boolean"];
    applyReceiveSMS: Scalars["Boolean"];
    authLevel: Scalars["Int"];
    certificates: Array<Maybe<Certificate>>;
    company?: Maybe<Company>;
    createdAt?: Maybe<Scalars["DateTime"]>;
    designs: Array<Maybe<Design>>;
    domains: Array<Maybe<Domain>>;
    email: Scalars["String"];
    emails: Array<Maybe<Email>>;
    hostings: Array<Maybe<Hosting>>;
    id: Scalars["Int"];
    memo: Scalars["String"];
    name: Scalars["String"];
    nickName: Scalars["String"];
    password: Scalars["String"];
    payments: Array<Maybe<Payment>>;
    phoneNumber: Scalars["String"];
    questions: Array<Maybe<Question>>;
    smss: Array<Maybe<Sms>>;
    updateAt?: Maybe<Scalars["DateTime"]>;
    userType: UserType;
    username: Scalars["String"];
};

export enum UserType {
    Company = "Company",
    Personal = "Personal",
}

export type LoginAdminInput = {
    password: Scalars["String"];
    username: Scalars["String"];
};

export type CertificatePartsFragment = {
    __typename?: "Certificate";
    domainUrl: string;
    createdAt?: any | null;
    id: number;
    type: CertificateType;
    period: Period;
    price: number;
    productActive: ProductActive;
    registeredCompany: RegisteredCompany;
    lastBillingDate?: any | null;
    nextBillingDate?: any | null;
    userId?: number | null;
    memo: string;
};

export type CompanyPartsFragment = {
    __typename?: "Company";
    id: number;
    createdAt: any;
    registrationNumber: string;
    companyName: string;
    ceo: string;
    businessStatus: string;
    businessSector: string;
    zipCode: string;
    address1: string;
    address2: string;
    email: string;
    registrationFile: string;
};

export type DomainPartsFragment = {
    __typename?: "Domain";
    productActive: ProductActive;
    createdAt?: any | null;
    domainUrl: string;
    domainType: DomainType;
    id: number;
    lastBillingDate?: any | null;
    nextBillingDate?: any | null;
    period: Period;
    price: number;
    registeredCompany: RegisteredCompany;
    memo: string;
};

export type EmailAccountPartsFragment = {
    __typename?: "EmailAccount";
    account: string;
    active: boolean;
    createdAt?: any | null;
    domainUrl: string;
    id: number;
    memo: string;
    quota: number;
    freeQuota: number;
};

export type EmailPartsFragment = {
    __typename?: "Email";
    productActive: ProductActive;
    createdAt?: any | null;
    domainUrl: string;
    id: number;
    lastBillingDate?: any | null;
    memo: string;
    nextBillingDate?: any | null;
    period: Period;
    price: number;
    registeredCompany: RegisteredCompany;
    emailAccount: Array<{
        __typename?: "EmailAccount";
        account: string;
        active: boolean;
        createdAt?: any | null;
        domainUrl: string;
        id: number;
        memo: string;
        quota: number;
        freeQuota: number;
    } | null>;
};

export type HostingInfoPartsFragment = {
    __typename?: "HostingInfo";
    id: number;
    domainUrl: string;
    ftpIp: string;
    ftpId: string;
    ftpPassword: string;
    mysqlDatabase: string;
    mysqlId: string;
    mysqlPassword: string;
    addStorage: number;
};

export type HostingPartsFragment = {
    __typename?: "Hosting";
    productActive: ProductActive;
    createdAt?: any | null;
    domainUrl: string;
    hostingType: HostingType;
    id: number;
    lastBillingDate?: any | null;
    nextBillingDate?: any | null;
    period: Period;
    price: number;
    registeredCompany: RegisteredCompany;
    memo: string;
};

export type PaymentPartsFragment = {
    __typename?: "Payment";
    createdAt?: any | null;
    depositor?: string | null;
    domainUrl: string;
    id: number;
    memo: string;
    paymentMethod: PaymentMethod;
    price: number;
    type: ProductType;
    receipts: Receipts;
    receiptsStatus: ReceiptsStatus;
    phoneNumber?: string | null;
};

export type PaymentRequestPartsFragment = {
    __typename?: "PaymentRequest";
    domainUrl: string;
    id: number;
    paymentDate: any;
    price: number;
    productType: ProductType;
    userId?: number | null;
    username?: string | null;
    productId: number;
};

export type QuestionPartsFragment = {
    __typename?: "Question";
    id: number;
    createdAt?: any | null;
    subject: string;
    productType: ProductType;
    questionStatus: QuestionStatus;
    writer?: string | null;
    domainUrl: string;
    userId?: number | null;
    user?: {
        __typename?: "User";
        id: number;
        name: string;
        username: string;
        nickName: string;
        userType: UserType;
        email: string;
        phoneNumber: string;
        authLevel: number;
        active: boolean;
        applyReceiveMail: boolean;
        applyReceiveSMS: boolean;
        memo: string;
    } | null;
};

export type RequestPartsFragment = {
    __typename?: "Request";
    completer?: string | null;
    content: string;
    createdAt?: any | null;
    domainUrl: string;
    id: number;
    productType: ProductType;
    requestStatus: RequestStatus;
    subject: string;
    updatedAt?: any | null;
    writer: string;
};

export type SummaryPaymentSubPartsFragment = {
    __typename?: "SummaryPaymentSub";
    total: number;
    domain: number;
    hosting: number;
    certificate: number;
    email: number;
    design: number;
    sms: number;
    etc: number;
};

export type UserPartsFragment = {
    __typename?: "User";
    id: number;
    name: string;
    username: string;
    nickName: string;
    userType: UserType;
    email: string;
    phoneNumber: string;
    authLevel: number;
    active: boolean;
    applyReceiveMail: boolean;
    applyReceiveSMS: boolean;
    memo: string;
};

export type MutationCreateUserMutationVariables = Exact<{
    input: CreateUserInput;
}>;

export type MutationCreateUserMutation = { __typename?: "Mutation"; createUser: boolean };

export type MutationCreateAdminMutationVariables = Exact<{
    input: CreateAdminInput;
}>;

export type MutationCreateAdminMutation = { __typename?: "Mutation"; createAdmin: boolean };

export type MutationLoginAdminMutationVariables = Exact<{
    input: LoginAdminInput;
}>;

export type MutationLoginAdminMutation = { __typename?: "Mutation"; loginAdmin: string };

export type MutationCreateCertificateAdminMutationVariables = Exact<{
    input: CreateCertificateAdminInput;
}>;

export type MutationCreateCertificateAdminMutation = {
    __typename?: "Mutation";
    createCertificateAdmin: boolean;
};

export type MutationConnectCertificateAdminMutationVariables = Exact<{
    input: ConnectCertificateAdminInput;
}>;

export type MutationConnectCertificateAdminMutation = {
    __typename?: "Mutation";
    connectCertificateAdmin: boolean;
};

export type MutationUpdateCertificateAdminMutationVariables = Exact<{
    input: UpdateCertificateAdminInput;
}>;

export type MutationUpdateCertificateAdminMutation = {
    __typename?: "Mutation";
    updateCertificateAdmin: boolean;
};

export type MutationCreatePaymentForCertificateAdminMutationVariables = Exact<{
    input: CreatePaymentForCertificateAdminInput;
}>;

export type MutationCreatePaymentForCertificateAdminMutation = {
    __typename?: "Mutation";
    createPaymentForCertificateAdmin: boolean;
};

export type MutationUpdateUserAdminMutationVariables = Exact<{
    input: UpdateUserAdminInput;
}>;

export type MutationUpdateUserAdminMutation = { __typename?: "Mutation"; updateUserAdmin: boolean };

export type MutationDeletePaymentAdminMutationVariables = Exact<{
    input: DeletePaymentInput;
}>;

export type MutationDeletePaymentAdminMutation = {
    __typename?: "Mutation";
    deletePaymentAdmin: boolean;
};

export type MutationUpdatePaymentForCertificateAdminMutationVariables = Exact<{
    input: UpdatePaymentForCertificateAdminInput;
}>;

export type MutationUpdatePaymentForCertificateAdminMutation = {
    __typename?: "Mutation";
    updatePaymentForCertificateAdmin: boolean;
};

export type MutationCreateDomainAdminMutationVariables = Exact<{
    input: CreateDomainAdminInput;
}>;

export type MutationCreateDomainAdminMutation = {
    __typename?: "Mutation";
    createDomainAdmin: boolean;
};

export type MutationConnectDomainAdminMutationVariables = Exact<{
    input: ConnectDomainAdminInput;
}>;

export type MutationConnectDomainAdminMutation = {
    __typename?: "Mutation";
    connectDomainAdmin: boolean;
};

export type MutationUpdateDomainAdminMutationVariables = Exact<{
    input: UpdateDomainAdminInput;
}>;

export type MutationUpdateDomainAdminMutation = {
    __typename?: "Mutation";
    updateDomainAdmin: boolean;
};

export type MutationCreatePaymentForDomainAdminMutationVariables = Exact<{
    input: CreatePaymentForDomainAdminInput;
}>;

export type MutationCreatePaymentForDomainAdminMutation = {
    __typename?: "Mutation";
    createPaymentForDomainAdmin: boolean;
};

export type MutationUpdatePaymentForDomainAdminMutationVariables = Exact<{
    input: UpdatePaymentForDomainAdminInput;
}>;

export type MutationUpdatePaymentForDomainAdminMutation = {
    __typename?: "Mutation";
    updatePaymentForDomainAdmin: boolean;
};

export type MutationCreateHostingAdminMutationVariables = Exact<{
    input: CreateHostingAdminInput;
}>;

export type MutationCreateHostingAdminMutation = {
    __typename?: "Mutation";
    createHostingAdmin: boolean;
};

export type MutationConnectHostingAdminMutationVariables = Exact<{
    input: ConnectHostingAdminInput;
}>;

export type MutationConnectHostingAdminMutation = {
    __typename?: "Mutation";
    connectHostingAdmin: boolean;
};

export type MutationUpdateHostingAdminMutationVariables = Exact<{
    input: UpdateHostingAdminInput;
}>;

export type MutationUpdateHostingAdminMutation = {
    __typename?: "Mutation";
    updateHostingAdmin: boolean;
};

export type MutationCreatePaymentForHostingAdminMutationVariables = Exact<{
    input: CreatePaymentForHostingAdminInput;
}>;

export type MutationCreatePaymentForHostingAdminMutation = {
    __typename?: "Mutation";
    createPaymentForHostingAdmin: boolean;
};

export type MutationUpdatePaymentForHostingAdminMutationVariables = Exact<{
    input: UpdatePaymentForHostingAdminInput;
}>;

export type MutationUpdatePaymentForHostingAdminMutation = {
    __typename?: "Mutation";
    updatePaymentForHostingAdmin: boolean;
};

export type MutationUpdatePaymentForEmailAdminMutationVariables = Exact<{
    input: UpdatePaymentForEmailAdminInput;
}>;

export type MutationUpdatePaymentForEmailAdminMutation = {
    __typename?: "Mutation";
    updatePaymentForEmailAdmin: boolean;
};

export type MutationUpdatePaymentForEtcAdminMutationVariables = Exact<{
    input: UpdatePaymentForEtcAdminInput;
}>;

export type MutationUpdatePaymentForEtcAdminMutation = {
    __typename?: "Mutation";
    updatePaymentForEtcAdmin: boolean;
};

export type MutationCreatePaymentForEtcAdminMutationVariables = Exact<{
    input: CreatePaymentForEtcAdminInput;
}>;

export type MutationCreatePaymentForEtcAdminMutation = {
    __typename?: "Mutation";
    createPaymentForEtcAdmin: boolean;
};

export type MutationCreatePaymentForEmailAdminMutationVariables = Exact<{
    input: CreatePaymentForEmailAdminInput;
}>;

export type MutationCreatePaymentForEmailAdminMutation = {
    __typename?: "Mutation";
    createPaymentForEmailAdmin: boolean;
};

export type MutationCreateRequestAdminMutationVariables = Exact<{
    input: CreateRequestAdminInput;
}>;

export type MutationCreateRequestAdminMutation = {
    __typename?: "Mutation";
    createRequestAdmin: boolean;
};

export type MutationUpdateRequestAdminMutationVariables = Exact<{
    input: UpdateRequestAdminInput;
}>;

export type MutationUpdateRequestAdminMutation = {
    __typename?: "Mutation";
    updateRequestAdmin: boolean;
};

export type MutationChangeRequestStatusAdminMutationVariables = Exact<{
    input: ChangeRequestStatusAdminInput;
}>;

export type MutationChangeRequestStatusAdminMutation = {
    __typename?: "Mutation";
    changeRequestStatusAdmin: boolean;
};

export type MutationDeleteRequestAdminMutationVariables = Exact<{
    input: DeleteRequestAdminInput;
}>;

export type MutationDeleteRequestAdminMutation = {
    __typename?: "Mutation";
    deleteRequestAdmin: boolean;
};

export type MutationConnectEmailAdminMutationVariables = Exact<{
    input: ConnectEmailAdminInput;
}>;

export type MutationConnectEmailAdminMutation = {
    __typename?: "Mutation";
    connectEmailAdmin: boolean;
};

export type MutationCreateEmailAdminMutationVariables = Exact<{
    input: CreateEmailAdminInput;
}>;

export type MutationCreateEmailAdminMutation = {
    __typename?: "Mutation";
    createEmailAdmin: boolean;
};

export type MutationUpdateEmailAdminMutationVariables = Exact<{
    input: UpdateEmailAdminInput;
}>;

export type MutationUpdateEmailAdminMutation = {
    __typename?: "Mutation";
    updateEmailAdmin: boolean;
};

export type MutationCreateEmailAccountAdminMutationVariables = Exact<{
    input: CreateEmailAccountAdminInput;
}>;

export type MutationCreateEmailAccountAdminMutation = {
    __typename?: "Mutation";
    createEmailAccountAdmin: boolean;
};

export type MutationUpdateEmailAccountAdminMutationVariables = Exact<{
    input: UpdateEmailAccountAdminInput;
}>;

export type MutationUpdateEmailAccountAdminMutation = {
    __typename?: "Mutation";
    updateEmailAccountAdmin: boolean;
};

export type MutationDeleteEmailAccountAdminMutationVariables = Exact<{
    input: DeleteEmailAccountAdminInput;
}>;

export type MutationDeleteEmailAccountAdminMutation = {
    __typename?: "Mutation";
    deleteEmailAccountAdmin: boolean;
};

export type MutationSendBillingEmailHostingAdminMutationVariables = Exact<{
    input: SendBillingEmailHostingAdminInput;
}>;

export type MutationSendBillingEmailHostingAdminMutation = {
    __typename?: "Mutation";
    sendBillingEmailHostingAdmin: boolean;
};

export type MutationSendBillingEmailEmailAdminMutationVariables = Exact<{
    input: SendBillingEmailEmailAdminInput;
}>;

export type MutationSendBillingEmailEmailAdminMutation = {
    __typename?: "Mutation";
    sendBillingEmailEmailAdmin: boolean;
};

export type MutationSendBillingEmailDomainAdminMutationVariables = Exact<{
    input: SendBillingEmailDomainAdminInput;
}>;

export type MutationSendBillingEmailDomainAdminMutation = {
    __typename?: "Mutation";
    sendBillingEmailDomainAdmin: boolean;
};

export type MutationSendBillingEmailCertificateAdminMutationVariables = Exact<{
    input: SendBillingEmailCertificateAdminInput;
}>;

export type MutationSendBillingEmailCertificateAdminMutation = {
    __typename?: "Mutation";
    sendBillingEmailCertificateAdmin: boolean;
};

export type QueryMeQueryVariables = Exact<{ [key: string]: never }>;

export type QueryMeQuery = {
    __typename?: "Query";
    me: {
        __typename?: "User";
        createdAt?: any | null;
        id: number;
        name: string;
        username: string;
        nickName: string;
        userType: UserType;
        email: string;
        phoneNumber: string;
        authLevel: number;
        active: boolean;
        applyReceiveMail: boolean;
        applyReceiveSMS: boolean;
        memo: string;
    };
};

export type QueryFindManyCertificateQueryVariables = Exact<{
    input: FindManyCertificateInput;
}>;

export type QueryFindManyCertificateQuery = {
    __typename?: "Query";
    findManyCertificate: Array<{
        __typename?: "Certificate";
        domainUrl: string;
        createdAt?: any | null;
        id: number;
        type: CertificateType;
        period: Period;
        price: number;
        productActive: ProductActive;
        registeredCompany: RegisteredCompany;
        lastBillingDate?: any | null;
        nextBillingDate?: any | null;
        userId?: number | null;
        memo: string;
    }>;
};

export type QueryCountCertificateAdminQueryVariables = Exact<{
    input: CountCertificateAdminInput;
}>;

export type QueryCountCertificateAdminQuery = {
    __typename?: "Query";
    countCertificateAdmin: number;
};

export type QueryFindFirstCertificateAdminQueryVariables = Exact<{
    input: FindFirstCertificateAdminInput;
}>;

export type QueryFindFirstCertificateAdminQuery = {
    __typename?: "Query";
    findFirstCertificateAdmin: {
        __typename?: "Certificate";
        domainUrl: string;
        createdAt?: any | null;
        id: number;
        type: CertificateType;
        period: Period;
        price: number;
        productActive: ProductActive;
        registeredCompany: RegisteredCompany;
        lastBillingDate?: any | null;
        nextBillingDate?: any | null;
        userId?: number | null;
        memo: string;
        user?: {
            __typename?: "User";
            id: number;
            name: string;
            username: string;
            nickName: string;
            userType: UserType;
            email: string;
            phoneNumber: string;
            authLevel: number;
            active: boolean;
            applyReceiveMail: boolean;
            applyReceiveSMS: boolean;
            memo: string;
            company?: {
                __typename?: "Company";
                id: number;
                createdAt: any;
                registrationNumber: string;
                companyName: string;
                ceo: string;
                businessStatus: string;
                businessSector: string;
                zipCode: string;
                address1: string;
                address2: string;
                email: string;
                registrationFile: string;
            } | null;
        } | null;
    };
};

export type QueryFindManyUserAdminQueryVariables = Exact<{
    input: FindManyUserAdminInput;
}>;

export type QueryFindManyUserAdminQuery = {
    __typename?: "Query";
    findManyUserAdmin: Array<{
        __typename?: "User";
        id: number;
        name: string;
        username: string;
        nickName: string;
        userType: UserType;
        email: string;
        phoneNumber: string;
        authLevel: number;
        active: boolean;
        applyReceiveMail: boolean;
        applyReceiveSMS: boolean;
        memo: string;
        domains: Array<{ __typename?: "Domain"; domainUrl: string } | null>;
        certificates: Array<{ __typename?: "Certificate"; domainUrl: string } | null>;
        hostings: Array<{ __typename?: "Hosting"; domainUrl: string } | null>;
        emails: Array<{ __typename?: "Email"; domainUrl: string } | null>;
        payments: Array<{ __typename?: "Payment"; price: number } | null>;
    }>;
};

export type QueryCountUserAdminQueryVariables = Exact<{
    input: CountUserAdminInput;
}>;

export type QueryCountUserAdminQuery = { __typename?: "Query"; countUserAdmin: number };

export type QuerySearchCertificateQueryVariables = Exact<{
    input: SearchCertificateAdminInput;
}>;

export type QuerySearchCertificateQuery = {
    __typename?: "Query";
    searchCertificateAdmin: Array<{
        __typename?: "Certificate";
        domainUrl: string;
        createdAt?: any | null;
        id: number;
        type: CertificateType;
        period: Period;
        price: number;
        productActive: ProductActive;
        registeredCompany: RegisteredCompany;
        lastBillingDate?: any | null;
        nextBillingDate?: any | null;
        userId?: number | null;
        memo: string;
    } | null>;
};

export type QueryFindFirstUserAdminQueryVariables = Exact<{
    input: FindFirstUserAdminInput;
}>;

export type QueryFindFirstUserAdminQuery = {
    __typename?: "Query";
    findFirstUserAdmin: {
        __typename?: "User";
        id: number;
        name: string;
        username: string;
        nickName: string;
        userType: UserType;
        email: string;
        phoneNumber: string;
        authLevel: number;
        active: boolean;
        applyReceiveMail: boolean;
        applyReceiveSMS: boolean;
        memo: string;
        certificates: Array<{
            __typename?: "Certificate";
            domainUrl: string;
            createdAt?: any | null;
            id: number;
            type: CertificateType;
            period: Period;
            price: number;
            productActive: ProductActive;
            registeredCompany: RegisteredCompany;
            lastBillingDate?: any | null;
            nextBillingDate?: any | null;
            userId?: number | null;
            memo: string;
        } | null>;
        domains: Array<{
            __typename?: "Domain";
            productActive: ProductActive;
            createdAt?: any | null;
            domainUrl: string;
            domainType: DomainType;
            id: number;
            lastBillingDate?: any | null;
            nextBillingDate?: any | null;
            period: Period;
            price: number;
            registeredCompany: RegisteredCompany;
            memo: string;
        } | null>;
        hostings: Array<{
            __typename?: "Hosting";
            productActive: ProductActive;
            createdAt?: any | null;
            domainUrl: string;
            hostingType: HostingType;
            id: number;
            lastBillingDate?: any | null;
            nextBillingDate?: any | null;
            period: Period;
            price: number;
            registeredCompany: RegisteredCompany;
            memo: string;
        } | null>;
        emails: Array<{
            __typename?: "Email";
            productActive: ProductActive;
            createdAt?: any | null;
            domainUrl: string;
            id: number;
            lastBillingDate?: any | null;
            memo: string;
            nextBillingDate?: any | null;
            period: Period;
            price: number;
            registeredCompany: RegisteredCompany;
            emailAccount: Array<{
                __typename?: "EmailAccount";
                account: string;
                active: boolean;
                createdAt?: any | null;
                domainUrl: string;
                id: number;
                memo: string;
                quota: number;
                freeQuota: number;
            } | null>;
        } | null>;
        company?: {
            __typename?: "Company";
            id: number;
            createdAt: any;
            registrationNumber: string;
            companyName: string;
            ceo: string;
            businessStatus: string;
            businessSector: string;
            zipCode: string;
            address1: string;
            address2: string;
            email: string;
            registrationFile: string;
        } | null;
    };
};

export type QueryFindManyPaymentForCertificateAdminQueryVariables = Exact<{
    input: FindManyPaymentForCertificateAdmin;
}>;

export type QueryFindManyPaymentForCertificateAdminQuery = {
    __typename?: "Query";
    findManyPaymentForCertificateAdmin: Array<{
        __typename?: "Payment";
        certificateId?: number | null;
        depositor?: string | null;
        createdAt?: any | null;
        domainUrl: string;
        id: number;
        memo: string;
        paymentMethod: PaymentMethod;
        price: number;
        type: ProductType;
        receipts: Receipts;
        receiptsStatus: ReceiptsStatus;
        phoneNumber?: string | null;
    } | null>;
};

export type QueryCountPaymentForCertificateAdminQueryVariables = Exact<{
    input: CountPaymentForCertificateAdminInput;
}>;

export type QueryCountPaymentForCertificateAdminQuery = {
    __typename?: "Query";
    countPaymentForCertificateAdmin: number;
};

export type QueryFindFirstCompanyAdminQueryVariables = Exact<{
    input: FindFirstCompanyAdminInput;
}>;

export type QueryFindFirstCompanyAdminQuery = {
    __typename?: "Query";
    findFirstCompanyAdmin: {
        __typename?: "Company";
        id: number;
        createdAt: any;
        registrationNumber: string;
        companyName: string;
        ceo: string;
        businessStatus: string;
        businessSector: string;
        zipCode: string;
        address1: string;
        address2: string;
        email: string;
        registrationFile: string;
    };
};

export type QueryFindFirstPaymentAdminQueryVariables = Exact<{
    input: FindFirstPaymentAdmin;
}>;

export type QueryFindFirstPaymentAdminQuery = {
    __typename?: "Query";
    findFirstPaymentAdmin: {
        __typename?: "Payment";
        createdAt?: any | null;
        depositor?: string | null;
        domainUrl: string;
        id: number;
        memo: string;
        paymentMethod: PaymentMethod;
        price: number;
        type: ProductType;
        receipts: Receipts;
        receiptsStatus: ReceiptsStatus;
        phoneNumber?: string | null;
        user?: {
            __typename?: "User";
            id: number;
            name: string;
            username: string;
            nickName: string;
            userType: UserType;
            email: string;
            phoneNumber: string;
            authLevel: number;
            active: boolean;
            applyReceiveMail: boolean;
            applyReceiveSMS: boolean;
            memo: string;
            company?: {
                __typename?: "Company";
                id: number;
                createdAt: any;
                registrationNumber: string;
                companyName: string;
                ceo: string;
                businessStatus: string;
                businessSector: string;
                zipCode: string;
                address1: string;
                address2: string;
                email: string;
                registrationFile: string;
            } | null;
        } | null;
        certificate?: {
            __typename?: "Certificate";
            domainUrl: string;
            createdAt?: any | null;
            id: number;
            type: CertificateType;
            period: Period;
            price: number;
            productActive: ProductActive;
            registeredCompany: RegisteredCompany;
            lastBillingDate?: any | null;
            nextBillingDate?: any | null;
            userId?: number | null;
            memo: string;
        } | null;
        domain?: {
            __typename?: "Domain";
            productActive: ProductActive;
            createdAt?: any | null;
            domainUrl: string;
            domainType: DomainType;
            id: number;
            lastBillingDate?: any | null;
            nextBillingDate?: any | null;
            period: Period;
            price: number;
            registeredCompany: RegisteredCompany;
            memo: string;
        } | null;
        hosting?: {
            __typename?: "Hosting";
            productActive: ProductActive;
            createdAt?: any | null;
            domainUrl: string;
            hostingType: HostingType;
            id: number;
            lastBillingDate?: any | null;
            nextBillingDate?: any | null;
            period: Period;
            price: number;
            registeredCompany: RegisteredCompany;
            memo: string;
        } | null;
        email?: {
            __typename?: "Email";
            productActive: ProductActive;
            createdAt?: any | null;
            domainUrl: string;
            id: number;
            lastBillingDate?: any | null;
            memo: string;
            nextBillingDate?: any | null;
            period: Period;
            price: number;
            registeredCompany: RegisteredCompany;
            emailAccount: Array<{
                __typename?: "EmailAccount";
                account: string;
                active: boolean;
                createdAt?: any | null;
                domainUrl: string;
                id: number;
                memo: string;
                quota: number;
                freeQuota: number;
            } | null>;
        } | null;
        company?: {
            __typename?: "Company";
            id: number;
            createdAt: any;
            registrationNumber: string;
            companyName: string;
            ceo: string;
            businessStatus: string;
            businessSector: string;
            zipCode: string;
            address1: string;
            address2: string;
            email: string;
            registrationFile: string;
        } | null;
    };
};

export type QueryMakeCsrQueryVariables = Exact<{
    input: MakeCsrInput;
}>;

export type QueryMakeCsrQuery = { __typename?: "Query"; makeCSR: string };

export type QueryFindManyDomainAdminQueryVariables = Exact<{
    input: FindManyDomainAdminInput;
}>;

export type QueryFindManyDomainAdminQuery = {
    __typename?: "Query";
    findManyDomainAdmin: Array<{
        __typename?: "Domain";
        productActive: ProductActive;
        createdAt?: any | null;
        domainUrl: string;
        domainType: DomainType;
        id: number;
        lastBillingDate?: any | null;
        nextBillingDate?: any | null;
        period: Period;
        price: number;
        registeredCompany: RegisteredCompany;
        memo: string;
    }>;
};

export type QueryCountDomainAdminQueryVariables = Exact<{
    input: CountDomainAdminInput;
}>;

export type QueryCountDomainAdminQuery = { __typename?: "Query"; countDomainAdmin: number };

export type QuerySearchDomainAdminQueryVariables = Exact<{
    input: SearchDomainAdminInput;
}>;

export type QuerySearchDomainAdminQuery = {
    __typename?: "Query";
    searchDomainAdmin: Array<{
        __typename?: "Domain";
        productActive: ProductActive;
        createdAt?: any | null;
        domainUrl: string;
        domainType: DomainType;
        id: number;
        lastBillingDate?: any | null;
        nextBillingDate?: any | null;
        period: Period;
        price: number;
        registeredCompany: RegisteredCompany;
        memo: string;
    } | null>;
};

export type QueryFindFirstDomainAdminQueryVariables = Exact<{
    input: FindFirstDomainAdminInput;
}>;

export type QueryFindFirstDomainAdminQuery = {
    __typename?: "Query";
    findFirstDomainAdmin: {
        __typename?: "Domain";
        userId?: number | null;
        productActive: ProductActive;
        createdAt?: any | null;
        domainUrl: string;
        domainType: DomainType;
        id: number;
        lastBillingDate?: any | null;
        nextBillingDate?: any | null;
        period: Period;
        price: number;
        registeredCompany: RegisteredCompany;
        memo: string;
        user?: {
            __typename?: "User";
            id: number;
            name: string;
            username: string;
            nickName: string;
            userType: UserType;
            email: string;
            phoneNumber: string;
            authLevel: number;
            active: boolean;
            applyReceiveMail: boolean;
            applyReceiveSMS: boolean;
            memo: string;
            company?: {
                __typename?: "Company";
                id: number;
                createdAt: any;
                registrationNumber: string;
                companyName: string;
                ceo: string;
                businessStatus: string;
                businessSector: string;
                zipCode: string;
                address1: string;
                address2: string;
                email: string;
                registrationFile: string;
            } | null;
        } | null;
    };
};

export type QueryFindManyPaymentForDomainAdminQueryVariables = Exact<{
    input: FindManyPaymentForDomainAdminInput;
}>;

export type QueryFindManyPaymentForDomainAdminQuery = {
    __typename?: "Query";
    findManyPaymentForDomainAdmin: Array<{
        __typename?: "Payment";
        domainId?: number | null;
        depositor?: string | null;
        createdAt?: any | null;
        domainUrl: string;
        id: number;
        memo: string;
        paymentMethod: PaymentMethod;
        price: number;
        type: ProductType;
        receipts: Receipts;
        receiptsStatus: ReceiptsStatus;
        phoneNumber?: string | null;
    } | null>;
};

export type QueryCountPaymentForDomainAdminQueryVariables = Exact<{
    input: CountPaymentForDomainAdminInput;
}>;

export type QueryCountPaymentForDomainAdminQuery = {
    __typename?: "Query";
    countPaymentForDomainAdmin: number;
};

export type QueryFindManyHostingAdminQueryVariables = Exact<{
    input: FindManyHostingAdminInput;
}>;

export type QueryFindManyHostingAdminQuery = {
    __typename?: "Query";
    findManyHostingAdmin: Array<{
        __typename?: "Hosting";
        productActive: ProductActive;
        createdAt?: any | null;
        domainUrl: string;
        hostingType: HostingType;
        id: number;
        lastBillingDate?: any | null;
        nextBillingDate?: any | null;
        period: Period;
        price: number;
        registeredCompany: RegisteredCompany;
        memo: string;
    }>;
};

export type QueryCountHostingAdminQueryVariables = Exact<{
    input: CountHostingAdminInput;
}>;

export type QueryCountHostingAdminQuery = { __typename?: "Query"; countHostingAdmin: number };

export type QuerySearchHostingAdminQueryVariables = Exact<{
    input: SearchHostingAdminInput;
}>;

export type QuerySearchHostingAdminQuery = {
    __typename?: "Query";
    searchHostingAdmin: Array<{
        __typename?: "Hosting";
        productActive: ProductActive;
        createdAt?: any | null;
        domainUrl: string;
        hostingType: HostingType;
        id: number;
        lastBillingDate?: any | null;
        nextBillingDate?: any | null;
        period: Period;
        price: number;
        registeredCompany: RegisteredCompany;
        memo: string;
    } | null>;
};

export type QueryFindFirstHostingAdminQueryVariables = Exact<{
    input: FindFirstHostingAdminInput;
}>;

export type QueryFindFirstHostingAdminQuery = {
    __typename?: "Query";
    findFirstHostingAdmin: {
        __typename?: "Hosting";
        userId?: number | null;
        productActive: ProductActive;
        createdAt?: any | null;
        domainUrl: string;
        hostingType: HostingType;
        id: number;
        lastBillingDate?: any | null;
        nextBillingDate?: any | null;
        period: Period;
        price: number;
        registeredCompany: RegisteredCompany;
        memo: string;
        user?: {
            __typename?: "User";
            id: number;
            name: string;
            username: string;
            nickName: string;
            userType: UserType;
            email: string;
            phoneNumber: string;
            authLevel: number;
            active: boolean;
            applyReceiveMail: boolean;
            applyReceiveSMS: boolean;
            memo: string;
            company?: {
                __typename?: "Company";
                id: number;
                createdAt: any;
                registrationNumber: string;
                companyName: string;
                ceo: string;
                businessStatus: string;
                businessSector: string;
                zipCode: string;
                address1: string;
                address2: string;
                email: string;
                registrationFile: string;
            } | null;
        } | null;
        hostingInfo?: {
            __typename?: "HostingInfo";
            id: number;
            domainUrl: string;
            ftpIp: string;
            ftpId: string;
            ftpPassword: string;
            mysqlDatabase: string;
            mysqlId: string;
            mysqlPassword: string;
            addStorage: number;
        } | null;
    };
};

export type QueryFindManyPaymentForHostingAdminQueryVariables = Exact<{
    input: FindManyPaymentForHostingAdminInput;
}>;

export type QueryFindManyPaymentForHostingAdminQuery = {
    __typename?: "Query";
    findManyPaymentForHostingAdmin: Array<{
        __typename?: "Payment";
        hostingId?: number | null;
        depositor?: string | null;
        createdAt?: any | null;
        domainUrl: string;
        id: number;
        memo: string;
        paymentMethod: PaymentMethod;
        price: number;
        type: ProductType;
        receipts: Receipts;
        receiptsStatus: ReceiptsStatus;
        phoneNumber?: string | null;
    } | null>;
};

export type QueryCountPaymentForHostingAdminQueryVariables = Exact<{
    input: CountPaymentForHostingAdminInput;
}>;

export type QueryCountPaymentForHostingAdminQuery = {
    __typename?: "Query";
    countPaymentForHostingAdmin: number;
};

export type QueryFindManyPaymentAdminQueryVariables = Exact<{
    input: FindManyPaymentAdmin;
}>;

export type QueryFindManyPaymentAdminQuery = {
    __typename?: "Query";
    findManyPaymentAdmin: Array<{
        __typename?: "Payment";
        createdAt?: any | null;
        depositor?: string | null;
        domainUrl: string;
        id: number;
        memo: string;
        paymentMethod: PaymentMethod;
        price: number;
        type: ProductType;
        receipts: Receipts;
        receiptsStatus: ReceiptsStatus;
        phoneNumber?: string | null;
        domain?: {
            __typename?: "Domain";
            productActive: ProductActive;
            createdAt?: any | null;
            domainUrl: string;
            domainType: DomainType;
            id: number;
            lastBillingDate?: any | null;
            nextBillingDate?: any | null;
            period: Period;
            price: number;
            registeredCompany: RegisteredCompany;
            memo: string;
        } | null;
        hosting?: {
            __typename?: "Hosting";
            productActive: ProductActive;
            createdAt?: any | null;
            domainUrl: string;
            hostingType: HostingType;
            id: number;
            lastBillingDate?: any | null;
            nextBillingDate?: any | null;
            period: Period;
            price: number;
            registeredCompany: RegisteredCompany;
            memo: string;
        } | null;
        certificate?: {
            __typename?: "Certificate";
            domainUrl: string;
            createdAt?: any | null;
            id: number;
            type: CertificateType;
            period: Period;
            price: number;
            productActive: ProductActive;
            registeredCompany: RegisteredCompany;
            lastBillingDate?: any | null;
            nextBillingDate?: any | null;
            userId?: number | null;
            memo: string;
        } | null;
        email?: {
            __typename?: "Email";
            productActive: ProductActive;
            createdAt?: any | null;
            domainUrl: string;
            id: number;
            lastBillingDate?: any | null;
            memo: string;
            nextBillingDate?: any | null;
            period: Period;
            price: number;
            registeredCompany: RegisteredCompany;
            emailAccount: Array<{
                __typename?: "EmailAccount";
                account: string;
                active: boolean;
                createdAt?: any | null;
                domainUrl: string;
                id: number;
                memo: string;
                quota: number;
                freeQuota: number;
            } | null>;
        } | null;
        user?: {
            __typename?: "User";
            id: number;
            name: string;
            username: string;
            nickName: string;
            userType: UserType;
            email: string;
            phoneNumber: string;
            authLevel: number;
            active: boolean;
            applyReceiveMail: boolean;
            applyReceiveSMS: boolean;
            memo: string;
        } | null;
    } | null>;
};

export type QuerySummaryPaymentAdminQueryVariables = Exact<{
    input: SummaryPaymentAdminInput;
}>;

export type QuerySummaryPaymentAdminQuery = {
    __typename?: "Query";
    summaryPaymentAdmin: {
        __typename?: "SummaryPayment";
        checkOut: {
            __typename?: "SummaryPaymentSub";
            total: number;
            domain: number;
            hosting: number;
            certificate: number;
            email: number;
            design: number;
            sms: number;
            etc: number;
        };
        cash: {
            __typename?: "SummaryPaymentSub";
            total: number;
            domain: number;
            hosting: number;
            certificate: number;
            email: number;
            design: number;
            sms: number;
            etc: number;
        };
        total: {
            __typename?: "SummaryPaymentSub";
            total: number;
            domain: number;
            hosting: number;
            certificate: number;
            email: number;
            design: number;
            sms: number;
            etc: number;
        };
    };
};

export type QueryFindManyRequestAdminQueryVariables = Exact<{
    input: FindManyRequestAdminInput;
}>;

export type QueryFindManyRequestAdminQuery = {
    __typename?: "Query";
    findManyRequestAdmin: Array<{
        __typename?: "Request";
        completer?: string | null;
        content: string;
        createdAt?: any | null;
        domainUrl: string;
        id: number;
        productType: ProductType;
        requestStatus: RequestStatus;
        subject: string;
        updatedAt?: any | null;
        writer: string;
    }>;
};

export type QueryCountRequestAdminQueryVariables = Exact<{
    input: CountRequestAdminInput;
}>;

export type QueryCountRequestAdminQuery = { __typename?: "Query"; countRequestAdmin: number };

export type QueryFindManyQuestionForAllAdminQueryVariables = Exact<{
    input: FindManyQuestionForAllAdminInput;
}>;

export type QueryFindManyQuestionForAllAdminQuery = {
    __typename?: "Query";
    findManyQuestionForAllAdmin: Array<{
        __typename?: "Question";
        id: number;
        createdAt?: any | null;
        subject: string;
        productType: ProductType;
        questionStatus: QuestionStatus;
        writer?: string | null;
        domainUrl: string;
        userId?: number | null;
        user?: {
            __typename?: "User";
            id: number;
            name: string;
            username: string;
            nickName: string;
            userType: UserType;
            email: string;
            phoneNumber: string;
            authLevel: number;
            active: boolean;
            applyReceiveMail: boolean;
            applyReceiveSMS: boolean;
            memo: string;
        } | null;
    }>;
};

export type QueryCountQuestionForAllAdminQueryVariables = Exact<{
    input: CountQuestionForAllAdminInput;
}>;

export type QueryCountQuestionForAllAdminQuery = {
    __typename?: "Query";
    countQuestionForAllAdmin: number;
};

export type QueryFindManyPaymentForUserAdminQueryVariables = Exact<{
    input: FindManyPaymentForUserAdminInput;
}>;

export type QueryFindManyPaymentForUserAdminQuery = {
    __typename?: "Query";
    findManyPaymentForUserAdmin: Array<{
        __typename?: "Payment";
        domainId?: number | null;
        hostingId?: number | null;
        certificateId?: number | null;
        designRequestId?: number | null;
        smsId?: number | null;
        createdAt?: any | null;
        depositor?: string | null;
        domainUrl: string;
        id: number;
        memo: string;
        paymentMethod: PaymentMethod;
        price: number;
        type: ProductType;
        receipts: Receipts;
        receiptsStatus: ReceiptsStatus;
        phoneNumber?: string | null;
    } | null>;
};

export type QueryCountPaymentForUserAdminQueryVariables = Exact<{
    input: CountPaymentForUserAdminInput;
}>;

export type QueryCountPaymentForUserAdminQuery = {
    __typename?: "Query";
    countPaymentForUserAdmin: number;
};

export type CountPaymentForUserAdminQueryVariables = Exact<{
    input: CountPaymentForUserAdminInput;
}>;

export type CountPaymentForUserAdminQuery = {
    __typename?: "Query";
    countPaymentForUserAdmin: number;
};

export type QueryFindManyPaymentRequestAdminQueryVariables = Exact<{ [key: string]: never }>;

export type QueryFindManyPaymentRequestAdminQuery = {
    __typename?: "Query";
    findManyPaymentRequestAdmin: Array<{
        __typename?: "PaymentRequest";
        domainUrl: string;
        id: number;
        paymentDate: any;
        price: number;
        productType: ProductType;
        userId?: number | null;
        username?: string | null;
        productId: number;
    } | null>;
};

export type QuerySearchEmailAdminQueryVariables = Exact<{
    input: SearchEmailAdminInput;
}>;

export type QuerySearchEmailAdminQuery = {
    __typename?: "Query";
    searchEmailAdmin: Array<{
        __typename?: "Email";
        productActive: ProductActive;
        createdAt?: any | null;
        domainUrl: string;
        id: number;
        lastBillingDate?: any | null;
        memo: string;
        nextBillingDate?: any | null;
        period: Period;
        price: number;
        registeredCompany: RegisteredCompany;
        emailAccount: Array<{
            __typename?: "EmailAccount";
            account: string;
            active: boolean;
            createdAt?: any | null;
            domainUrl: string;
            id: number;
            memo: string;
            quota: number;
            freeQuota: number;
        } | null>;
    } | null>;
};

export type QueryFindManyEmailAdminQueryVariables = Exact<{
    input: FindManyEmailAdminInput;
}>;

export type QueryFindManyEmailAdminQuery = {
    __typename?: "Query";
    findManyEmailAdmin: Array<{
        __typename?: "Email";
        productActive: ProductActive;
        createdAt?: any | null;
        domainUrl: string;
        id: number;
        lastBillingDate?: any | null;
        memo: string;
        nextBillingDate?: any | null;
        period: Period;
        price: number;
        registeredCompany: RegisteredCompany;
        emailAccount: Array<{
            __typename?: "EmailAccount";
            account: string;
            active: boolean;
            createdAt?: any | null;
            domainUrl: string;
            id: number;
            memo: string;
            quota: number;
            freeQuota: number;
        } | null>;
    }>;
};

export type QueryCountEmailAdminQueryVariables = Exact<{
    input: CountEmailAdminInput;
}>;

export type QueryCountEmailAdminQuery = { __typename?: "Query"; countEmailAdmin: number };

export type QueryFindManyPaymentForEmailAdminQueryVariables = Exact<{
    input: FindManyPaymentForEmailAdminInput;
}>;

export type QueryFindManyPaymentForEmailAdminQuery = {
    __typename?: "Query";
    findManyPaymentForEmailAdmin: Array<{
        __typename?: "Payment";
        createdAt?: any | null;
        depositor?: string | null;
        domainUrl: string;
        id: number;
        memo: string;
        paymentMethod: PaymentMethod;
        price: number;
        type: ProductType;
        receipts: Receipts;
        receiptsStatus: ReceiptsStatus;
        phoneNumber?: string | null;
    } | null>;
};

export type QueryCountPaymentForEmailAdminQueryVariables = Exact<{
    input: CountPaymentForEmailAdminInput;
}>;

export type QueryCountPaymentForEmailAdminQuery = {
    __typename?: "Query";
    countPaymentForEmailAdmin: number;
};

export type QueryFindFirstEmailAdminQueryVariables = Exact<{
    input: FindFirstEmailAdminInput;
}>;

export type QueryFindFirstEmailAdminQuery = {
    __typename?: "Query";
    findFirstEmailAdmin: {
        __typename?: "Email";
        userId?: number | null;
        productActive: ProductActive;
        createdAt?: any | null;
        domainUrl: string;
        id: number;
        lastBillingDate?: any | null;
        memo: string;
        nextBillingDate?: any | null;
        period: Period;
        price: number;
        registeredCompany: RegisteredCompany;
        user?: {
            __typename?: "User";
            id: number;
            name: string;
            username: string;
            nickName: string;
            userType: UserType;
            email: string;
            phoneNumber: string;
            authLevel: number;
            active: boolean;
            applyReceiveMail: boolean;
            applyReceiveSMS: boolean;
            memo: string;
            company?: {
                __typename?: "Company";
                id: number;
                createdAt: any;
                registrationNumber: string;
                companyName: string;
                ceo: string;
                businessStatus: string;
                businessSector: string;
                zipCode: string;
                address1: string;
                address2: string;
                email: string;
                registrationFile: string;
            } | null;
        } | null;
        emailAccount: Array<{
            __typename?: "EmailAccount";
            account: string;
            active: boolean;
            createdAt?: any | null;
            domainUrl: string;
            id: number;
            memo: string;
            quota: number;
            freeQuota: number;
        } | null>;
    };
};

export type QueryFindManyEmailAccountAdminQueryVariables = Exact<{
    input: FindManyEmailAccountAdminInput;
}>;

export type QueryFindManyEmailAccountAdminQuery = {
    __typename?: "Query";
    findManyEmailAccountAdmin: Array<{
        __typename?: "EmailAccount";
        account: string;
        active: boolean;
        createdAt?: any | null;
        domainUrl: string;
        id: number;
        memo: string;
        quota: number;
        freeQuota: number;
    } | null>;
};

export type QueryCountEmailAccountAdminQueryVariables = Exact<{
    input: CountEmailAccountAdminInput;
}>;

export type QueryCountEmailAccountAdminQuery = {
    __typename?: "Query";
    countEmailAccountAdmin: number;
};

export type QueryFindFirstEmailAccountAdminQueryVariables = Exact<{
    input: FindFirstEmailAccountAdminInput;
}>;

export type QueryFindFirstEmailAccountAdminQuery = {
    __typename?: "Query";
    findFirstEmailAccountAdmin: {
        __typename?: "EmailAccount";
        account: string;
        active: boolean;
        createdAt?: any | null;
        domainUrl: string;
        id: number;
        memo: string;
        quota: number;
        freeQuota: number;
        email?: {
            __typename?: "Email";
            productActive: ProductActive;
            createdAt?: any | null;
            domainUrl: string;
            id: number;
            lastBillingDate?: any | null;
            memo: string;
            nextBillingDate?: any | null;
            period: Period;
            price: number;
            registeredCompany: RegisteredCompany;
            emailAccount: Array<{
                __typename?: "EmailAccount";
                account: string;
                active: boolean;
                createdAt?: any | null;
                domainUrl: string;
                id: number;
                memo: string;
                quota: number;
                freeQuota: number;
            } | null>;
        } | null;
    };
};

export type QueryFindManyAndSummaryPaymentAdminQueryVariables = Exact<{
    findManyPaymentAdmin: FindManyPaymentAdmin;
    summaryPaymentAdmin: SummaryPaymentAdminInput;
}>;

export type QueryFindManyAndSummaryPaymentAdminQuery = {
    __typename?: "Query";
    findManyPaymentAdmin: Array<{
        __typename?: "Payment";
        createdAt?: any | null;
        depositor?: string | null;
        domainUrl: string;
        id: number;
        memo: string;
        paymentMethod: PaymentMethod;
        price: number;
        type: ProductType;
        receipts: Receipts;
        receiptsStatus: ReceiptsStatus;
        phoneNumber?: string | null;
        user?: {
            __typename?: "User";
            id: number;
            name: string;
            username: string;
            nickName: string;
            userType: UserType;
            email: string;
            phoneNumber: string;
            authLevel: number;
            active: boolean;
            applyReceiveMail: boolean;
            applyReceiveSMS: boolean;
            memo: string;
        } | null;
    } | null>;
    summaryPaymentAdmin: {
        __typename?: "SummaryPayment";
        checkOut: {
            __typename?: "SummaryPaymentSub";
            total: number;
            domain: number;
            hosting: number;
            certificate: number;
            email: number;
            design: number;
            sms: number;
            etc: number;
        };
        cash: {
            __typename?: "SummaryPaymentSub";
            total: number;
            domain: number;
            hosting: number;
            certificate: number;
            email: number;
            design: number;
            sms: number;
            etc: number;
        };
        total: {
            __typename?: "SummaryPaymentSub";
            total: number;
            domain: number;
            hosting: number;
            certificate: number;
            email: number;
            design: number;
            sms: number;
            etc: number;
        };
    };
};

export const CertificatePartsFragmentDoc = gql`
    fragment CertificateParts on Certificate {
        domainUrl
        createdAt
        id
        type
        period
        price
        productActive
        registeredCompany
        lastBillingDate
        nextBillingDate
        userId
        memo
    }
`;
export const CompanyPartsFragmentDoc = gql`
    fragment CompanyParts on Company {
        id
        createdAt
        registrationNumber
        companyName
        ceo
        businessStatus
        businessSector
        zipCode
        address1
        address2
        email
        registrationFile
    }
`;
export const DomainPartsFragmentDoc = gql`
    fragment DomainParts on Domain {
        productActive
        createdAt
        domainUrl
        domainType
        id
        lastBillingDate
        nextBillingDate
        period
        price
        registeredCompany
        memo
    }
`;
export const EmailAccountPartsFragmentDoc = gql`
    fragment EmailAccountParts on EmailAccount {
        account
        active
        createdAt
        domainUrl
        id
        memo
        quota
        freeQuota
    }
`;
export const EmailPartsFragmentDoc = gql`
    fragment EmailParts on Email {
        productActive
        createdAt
        domainUrl
        emailAccount {
            ...EmailAccountParts
        }
        id
        lastBillingDate
        memo
        nextBillingDate
        period
        price
        registeredCompany
    }
    ${EmailAccountPartsFragmentDoc}
`;
export const HostingInfoPartsFragmentDoc = gql`
    fragment HostingInfoParts on HostingInfo {
        id
        domainUrl
        ftpIp
        ftpId
        ftpPassword
        mysqlDatabase
        mysqlId
        mysqlPassword
        addStorage
    }
`;
export const HostingPartsFragmentDoc = gql`
    fragment HostingParts on Hosting {
        productActive
        createdAt
        domainUrl
        hostingType
        id
        lastBillingDate
        nextBillingDate
        period
        price
        registeredCompany
        memo
    }
`;
export const PaymentPartsFragmentDoc = gql`
    fragment PaymentParts on Payment {
        createdAt
        depositor
        domainUrl
        id
        memo
        paymentMethod
        price
        type
        receipts
        receiptsStatus
        phoneNumber
    }
`;
export const PaymentRequestPartsFragmentDoc = gql`
    fragment PaymentRequestParts on PaymentRequest {
        domainUrl
        id
        paymentDate
        price
        productType
        userId
        username
        productId
    }
`;
export const UserPartsFragmentDoc = gql`
    fragment UserParts on User {
        id
        name
        username
        nickName
        userType
        email
        phoneNumber
        authLevel
        active
        applyReceiveMail
        applyReceiveSMS
        memo
    }
`;
export const QuestionPartsFragmentDoc = gql`
    fragment QuestionParts on Question {
        id
        createdAt
        subject
        productType
        questionStatus
        writer
        domainUrl
        user {
            ...UserParts
        }
        userId
    }
    ${UserPartsFragmentDoc}
`;
export const RequestPartsFragmentDoc = gql`
    fragment RequestParts on Request {
        completer
        content
        createdAt
        domainUrl
        id
        productType
        requestStatus
        subject
        updatedAt
        writer
    }
`;
export const SummaryPaymentSubPartsFragmentDoc = gql`
    fragment SummaryPaymentSubParts on SummaryPaymentSub {
        total
        domain
        hosting
        certificate
        email
        design
        sms
        etc
    }
`;
export const MutationCreateUserDocument = gql`
    mutation mutationCreateUser($input: CreateUserInput!) {
        createUser(input: $input)
    }
`;
export type MutationCreateUserMutationFn = Apollo.MutationFunction<
    MutationCreateUserMutation,
    MutationCreateUserMutationVariables
>;

/**
 * __useMutationCreateUserMutation__
 *
 * To run a mutation, you first call `useMutationCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMutationCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mutationCreateUserMutation, { data, loading, error }] = useMutationCreateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMutationCreateUserMutation(
    baseOptions?: Apollo.MutationHookOptions<
        MutationCreateUserMutation,
        MutationCreateUserMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<MutationCreateUserMutation, MutationCreateUserMutationVariables>(
        MutationCreateUserDocument,
        options,
    );
}
export type MutationCreateUserMutationHookResult = ReturnType<typeof useMutationCreateUserMutation>;
export type MutationCreateUserMutationResult = Apollo.MutationResult<MutationCreateUserMutation>;
export type MutationCreateUserMutationOptions = Apollo.BaseMutationOptions<
    MutationCreateUserMutation,
    MutationCreateUserMutationVariables
>;
export const MutationCreateAdminDocument = gql`
    mutation mutationCreateAdmin($input: CreateAdminInput!) {
        createAdmin(input: $input)
    }
`;
export type MutationCreateAdminMutationFn = Apollo.MutationFunction<
    MutationCreateAdminMutation,
    MutationCreateAdminMutationVariables
>;

/**
 * __useMutationCreateAdminMutation__
 *
 * To run a mutation, you first call `useMutationCreateAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMutationCreateAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mutationCreateAdminMutation, { data, loading, error }] = useMutationCreateAdminMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMutationCreateAdminMutation(
    baseOptions?: Apollo.MutationHookOptions<
        MutationCreateAdminMutation,
        MutationCreateAdminMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<MutationCreateAdminMutation, MutationCreateAdminMutationVariables>(
        MutationCreateAdminDocument,
        options,
    );
}
export type MutationCreateAdminMutationHookResult = ReturnType<
    typeof useMutationCreateAdminMutation
>;
export type MutationCreateAdminMutationResult = Apollo.MutationResult<MutationCreateAdminMutation>;
export type MutationCreateAdminMutationOptions = Apollo.BaseMutationOptions<
    MutationCreateAdminMutation,
    MutationCreateAdminMutationVariables
>;
export const MutationLoginAdminDocument = gql`
    mutation mutationLoginAdmin($input: loginAdminInput!) {
        loginAdmin(input: $input)
    }
`;
export type MutationLoginAdminMutationFn = Apollo.MutationFunction<
    MutationLoginAdminMutation,
    MutationLoginAdminMutationVariables
>;

/**
 * __useMutationLoginAdminMutation__
 *
 * To run a mutation, you first call `useMutationLoginAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMutationLoginAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mutationLoginAdminMutation, { data, loading, error }] = useMutationLoginAdminMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMutationLoginAdminMutation(
    baseOptions?: Apollo.MutationHookOptions<
        MutationLoginAdminMutation,
        MutationLoginAdminMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<MutationLoginAdminMutation, MutationLoginAdminMutationVariables>(
        MutationLoginAdminDocument,
        options,
    );
}
export type MutationLoginAdminMutationHookResult = ReturnType<typeof useMutationLoginAdminMutation>;
export type MutationLoginAdminMutationResult = Apollo.MutationResult<MutationLoginAdminMutation>;
export type MutationLoginAdminMutationOptions = Apollo.BaseMutationOptions<
    MutationLoginAdminMutation,
    MutationLoginAdminMutationVariables
>;
export const MutationCreateCertificateAdminDocument = gql`
    mutation mutationCreateCertificateAdmin($input: CreateCertificateAdminInput!) {
        createCertificateAdmin(input: $input)
    }
`;
export type MutationCreateCertificateAdminMutationFn = Apollo.MutationFunction<
    MutationCreateCertificateAdminMutation,
    MutationCreateCertificateAdminMutationVariables
>;

/**
 * __useMutationCreateCertificateAdminMutation__
 *
 * To run a mutation, you first call `useMutationCreateCertificateAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMutationCreateCertificateAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mutationCreateCertificateAdminMutation, { data, loading, error }] = useMutationCreateCertificateAdminMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMutationCreateCertificateAdminMutation(
    baseOptions?: Apollo.MutationHookOptions<
        MutationCreateCertificateAdminMutation,
        MutationCreateCertificateAdminMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        MutationCreateCertificateAdminMutation,
        MutationCreateCertificateAdminMutationVariables
    >(MutationCreateCertificateAdminDocument, options);
}
export type MutationCreateCertificateAdminMutationHookResult = ReturnType<
    typeof useMutationCreateCertificateAdminMutation
>;
export type MutationCreateCertificateAdminMutationResult =
    Apollo.MutationResult<MutationCreateCertificateAdminMutation>;
export type MutationCreateCertificateAdminMutationOptions = Apollo.BaseMutationOptions<
    MutationCreateCertificateAdminMutation,
    MutationCreateCertificateAdminMutationVariables
>;
export const MutationConnectCertificateAdminDocument = gql`
    mutation mutationConnectCertificateAdmin($input: ConnectCertificateAdminInput!) {
        connectCertificateAdmin(input: $input)
    }
`;
export type MutationConnectCertificateAdminMutationFn = Apollo.MutationFunction<
    MutationConnectCertificateAdminMutation,
    MutationConnectCertificateAdminMutationVariables
>;

/**
 * __useMutationConnectCertificateAdminMutation__
 *
 * To run a mutation, you first call `useMutationConnectCertificateAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMutationConnectCertificateAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mutationConnectCertificateAdminMutation, { data, loading, error }] = useMutationConnectCertificateAdminMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMutationConnectCertificateAdminMutation(
    baseOptions?: Apollo.MutationHookOptions<
        MutationConnectCertificateAdminMutation,
        MutationConnectCertificateAdminMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        MutationConnectCertificateAdminMutation,
        MutationConnectCertificateAdminMutationVariables
    >(MutationConnectCertificateAdminDocument, options);
}
export type MutationConnectCertificateAdminMutationHookResult = ReturnType<
    typeof useMutationConnectCertificateAdminMutation
>;
export type MutationConnectCertificateAdminMutationResult =
    Apollo.MutationResult<MutationConnectCertificateAdminMutation>;
export type MutationConnectCertificateAdminMutationOptions = Apollo.BaseMutationOptions<
    MutationConnectCertificateAdminMutation,
    MutationConnectCertificateAdminMutationVariables
>;
export const MutationUpdateCertificateAdminDocument = gql`
    mutation mutationUpdateCertificateAdmin($input: UpdateCertificateAdminInput!) {
        updateCertificateAdmin(input: $input)
    }
`;
export type MutationUpdateCertificateAdminMutationFn = Apollo.MutationFunction<
    MutationUpdateCertificateAdminMutation,
    MutationUpdateCertificateAdminMutationVariables
>;

/**
 * __useMutationUpdateCertificateAdminMutation__
 *
 * To run a mutation, you first call `useMutationUpdateCertificateAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMutationUpdateCertificateAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mutationUpdateCertificateAdminMutation, { data, loading, error }] = useMutationUpdateCertificateAdminMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMutationUpdateCertificateAdminMutation(
    baseOptions?: Apollo.MutationHookOptions<
        MutationUpdateCertificateAdminMutation,
        MutationUpdateCertificateAdminMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        MutationUpdateCertificateAdminMutation,
        MutationUpdateCertificateAdminMutationVariables
    >(MutationUpdateCertificateAdminDocument, options);
}
export type MutationUpdateCertificateAdminMutationHookResult = ReturnType<
    typeof useMutationUpdateCertificateAdminMutation
>;
export type MutationUpdateCertificateAdminMutationResult =
    Apollo.MutationResult<MutationUpdateCertificateAdminMutation>;
export type MutationUpdateCertificateAdminMutationOptions = Apollo.BaseMutationOptions<
    MutationUpdateCertificateAdminMutation,
    MutationUpdateCertificateAdminMutationVariables
>;
export const MutationCreatePaymentForCertificateAdminDocument = gql`
    mutation mutationCreatePaymentForCertificateAdmin(
        $input: CreatePaymentForCertificateAdminInput!
    ) {
        createPaymentForCertificateAdmin(input: $input)
    }
`;
export type MutationCreatePaymentForCertificateAdminMutationFn = Apollo.MutationFunction<
    MutationCreatePaymentForCertificateAdminMutation,
    MutationCreatePaymentForCertificateAdminMutationVariables
>;

/**
 * __useMutationCreatePaymentForCertificateAdminMutation__
 *
 * To run a mutation, you first call `useMutationCreatePaymentForCertificateAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMutationCreatePaymentForCertificateAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mutationCreatePaymentForCertificateAdminMutation, { data, loading, error }] = useMutationCreatePaymentForCertificateAdminMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMutationCreatePaymentForCertificateAdminMutation(
    baseOptions?: Apollo.MutationHookOptions<
        MutationCreatePaymentForCertificateAdminMutation,
        MutationCreatePaymentForCertificateAdminMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        MutationCreatePaymentForCertificateAdminMutation,
        MutationCreatePaymentForCertificateAdminMutationVariables
    >(MutationCreatePaymentForCertificateAdminDocument, options);
}
export type MutationCreatePaymentForCertificateAdminMutationHookResult = ReturnType<
    typeof useMutationCreatePaymentForCertificateAdminMutation
>;
export type MutationCreatePaymentForCertificateAdminMutationResult =
    Apollo.MutationResult<MutationCreatePaymentForCertificateAdminMutation>;
export type MutationCreatePaymentForCertificateAdminMutationOptions = Apollo.BaseMutationOptions<
    MutationCreatePaymentForCertificateAdminMutation,
    MutationCreatePaymentForCertificateAdminMutationVariables
>;
export const MutationUpdateUserAdminDocument = gql`
    mutation mutationUpdateUserAdmin($input: UpdateUserAdminInput!) {
        updateUserAdmin(input: $input)
    }
`;
export type MutationUpdateUserAdminMutationFn = Apollo.MutationFunction<
    MutationUpdateUserAdminMutation,
    MutationUpdateUserAdminMutationVariables
>;

/**
 * __useMutationUpdateUserAdminMutation__
 *
 * To run a mutation, you first call `useMutationUpdateUserAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMutationUpdateUserAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mutationUpdateUserAdminMutation, { data, loading, error }] = useMutationUpdateUserAdminMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMutationUpdateUserAdminMutation(
    baseOptions?: Apollo.MutationHookOptions<
        MutationUpdateUserAdminMutation,
        MutationUpdateUserAdminMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        MutationUpdateUserAdminMutation,
        MutationUpdateUserAdminMutationVariables
    >(MutationUpdateUserAdminDocument, options);
}
export type MutationUpdateUserAdminMutationHookResult = ReturnType<
    typeof useMutationUpdateUserAdminMutation
>;
export type MutationUpdateUserAdminMutationResult =
    Apollo.MutationResult<MutationUpdateUserAdminMutation>;
export type MutationUpdateUserAdminMutationOptions = Apollo.BaseMutationOptions<
    MutationUpdateUserAdminMutation,
    MutationUpdateUserAdminMutationVariables
>;
export const MutationDeletePaymentAdminDocument = gql`
    mutation mutationDeletePaymentAdmin($input: DeletePaymentInput!) {
        deletePaymentAdmin(input: $input)
    }
`;
export type MutationDeletePaymentAdminMutationFn = Apollo.MutationFunction<
    MutationDeletePaymentAdminMutation,
    MutationDeletePaymentAdminMutationVariables
>;

/**
 * __useMutationDeletePaymentAdminMutation__
 *
 * To run a mutation, you first call `useMutationDeletePaymentAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMutationDeletePaymentAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mutationDeletePaymentAdminMutation, { data, loading, error }] = useMutationDeletePaymentAdminMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMutationDeletePaymentAdminMutation(
    baseOptions?: Apollo.MutationHookOptions<
        MutationDeletePaymentAdminMutation,
        MutationDeletePaymentAdminMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        MutationDeletePaymentAdminMutation,
        MutationDeletePaymentAdminMutationVariables
    >(MutationDeletePaymentAdminDocument, options);
}
export type MutationDeletePaymentAdminMutationHookResult = ReturnType<
    typeof useMutationDeletePaymentAdminMutation
>;
export type MutationDeletePaymentAdminMutationResult =
    Apollo.MutationResult<MutationDeletePaymentAdminMutation>;
export type MutationDeletePaymentAdminMutationOptions = Apollo.BaseMutationOptions<
    MutationDeletePaymentAdminMutation,
    MutationDeletePaymentAdminMutationVariables
>;
export const MutationUpdatePaymentForCertificateAdminDocument = gql`
    mutation mutationUpdatePaymentForCertificateAdmin(
        $input: UpdatePaymentForCertificateAdminInput!
    ) {
        updatePaymentForCertificateAdmin(input: $input)
    }
`;
export type MutationUpdatePaymentForCertificateAdminMutationFn = Apollo.MutationFunction<
    MutationUpdatePaymentForCertificateAdminMutation,
    MutationUpdatePaymentForCertificateAdminMutationVariables
>;

/**
 * __useMutationUpdatePaymentForCertificateAdminMutation__
 *
 * To run a mutation, you first call `useMutationUpdatePaymentForCertificateAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMutationUpdatePaymentForCertificateAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mutationUpdatePaymentForCertificateAdminMutation, { data, loading, error }] = useMutationUpdatePaymentForCertificateAdminMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMutationUpdatePaymentForCertificateAdminMutation(
    baseOptions?: Apollo.MutationHookOptions<
        MutationUpdatePaymentForCertificateAdminMutation,
        MutationUpdatePaymentForCertificateAdminMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        MutationUpdatePaymentForCertificateAdminMutation,
        MutationUpdatePaymentForCertificateAdminMutationVariables
    >(MutationUpdatePaymentForCertificateAdminDocument, options);
}
export type MutationUpdatePaymentForCertificateAdminMutationHookResult = ReturnType<
    typeof useMutationUpdatePaymentForCertificateAdminMutation
>;
export type MutationUpdatePaymentForCertificateAdminMutationResult =
    Apollo.MutationResult<MutationUpdatePaymentForCertificateAdminMutation>;
export type MutationUpdatePaymentForCertificateAdminMutationOptions = Apollo.BaseMutationOptions<
    MutationUpdatePaymentForCertificateAdminMutation,
    MutationUpdatePaymentForCertificateAdminMutationVariables
>;
export const MutationCreateDomainAdminDocument = gql`
    mutation mutationCreateDomainAdmin($input: CreateDomainAdminInput!) {
        createDomainAdmin(input: $input)
    }
`;
export type MutationCreateDomainAdminMutationFn = Apollo.MutationFunction<
    MutationCreateDomainAdminMutation,
    MutationCreateDomainAdminMutationVariables
>;

/**
 * __useMutationCreateDomainAdminMutation__
 *
 * To run a mutation, you first call `useMutationCreateDomainAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMutationCreateDomainAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mutationCreateDomainAdminMutation, { data, loading, error }] = useMutationCreateDomainAdminMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMutationCreateDomainAdminMutation(
    baseOptions?: Apollo.MutationHookOptions<
        MutationCreateDomainAdminMutation,
        MutationCreateDomainAdminMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        MutationCreateDomainAdminMutation,
        MutationCreateDomainAdminMutationVariables
    >(MutationCreateDomainAdminDocument, options);
}
export type MutationCreateDomainAdminMutationHookResult = ReturnType<
    typeof useMutationCreateDomainAdminMutation
>;
export type MutationCreateDomainAdminMutationResult =
    Apollo.MutationResult<MutationCreateDomainAdminMutation>;
export type MutationCreateDomainAdminMutationOptions = Apollo.BaseMutationOptions<
    MutationCreateDomainAdminMutation,
    MutationCreateDomainAdminMutationVariables
>;
export const MutationConnectDomainAdminDocument = gql`
    mutation mutationConnectDomainAdmin($input: ConnectDomainAdminInput!) {
        connectDomainAdmin(input: $input)
    }
`;
export type MutationConnectDomainAdminMutationFn = Apollo.MutationFunction<
    MutationConnectDomainAdminMutation,
    MutationConnectDomainAdminMutationVariables
>;

/**
 * __useMutationConnectDomainAdminMutation__
 *
 * To run a mutation, you first call `useMutationConnectDomainAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMutationConnectDomainAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mutationConnectDomainAdminMutation, { data, loading, error }] = useMutationConnectDomainAdminMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMutationConnectDomainAdminMutation(
    baseOptions?: Apollo.MutationHookOptions<
        MutationConnectDomainAdminMutation,
        MutationConnectDomainAdminMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        MutationConnectDomainAdminMutation,
        MutationConnectDomainAdminMutationVariables
    >(MutationConnectDomainAdminDocument, options);
}
export type MutationConnectDomainAdminMutationHookResult = ReturnType<
    typeof useMutationConnectDomainAdminMutation
>;
export type MutationConnectDomainAdminMutationResult =
    Apollo.MutationResult<MutationConnectDomainAdminMutation>;
export type MutationConnectDomainAdminMutationOptions = Apollo.BaseMutationOptions<
    MutationConnectDomainAdminMutation,
    MutationConnectDomainAdminMutationVariables
>;
export const MutationUpdateDomainAdminDocument = gql`
    mutation mutationUpdateDomainAdmin($input: UpdateDomainAdminInput!) {
        updateDomainAdmin(input: $input)
    }
`;
export type MutationUpdateDomainAdminMutationFn = Apollo.MutationFunction<
    MutationUpdateDomainAdminMutation,
    MutationUpdateDomainAdminMutationVariables
>;

/**
 * __useMutationUpdateDomainAdminMutation__
 *
 * To run a mutation, you first call `useMutationUpdateDomainAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMutationUpdateDomainAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mutationUpdateDomainAdminMutation, { data, loading, error }] = useMutationUpdateDomainAdminMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMutationUpdateDomainAdminMutation(
    baseOptions?: Apollo.MutationHookOptions<
        MutationUpdateDomainAdminMutation,
        MutationUpdateDomainAdminMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        MutationUpdateDomainAdminMutation,
        MutationUpdateDomainAdminMutationVariables
    >(MutationUpdateDomainAdminDocument, options);
}
export type MutationUpdateDomainAdminMutationHookResult = ReturnType<
    typeof useMutationUpdateDomainAdminMutation
>;
export type MutationUpdateDomainAdminMutationResult =
    Apollo.MutationResult<MutationUpdateDomainAdminMutation>;
export type MutationUpdateDomainAdminMutationOptions = Apollo.BaseMutationOptions<
    MutationUpdateDomainAdminMutation,
    MutationUpdateDomainAdminMutationVariables
>;
export const MutationCreatePaymentForDomainAdminDocument = gql`
    mutation mutationCreatePaymentForDomainAdmin($input: CreatePaymentForDomainAdminInput!) {
        createPaymentForDomainAdmin(input: $input)
    }
`;
export type MutationCreatePaymentForDomainAdminMutationFn = Apollo.MutationFunction<
    MutationCreatePaymentForDomainAdminMutation,
    MutationCreatePaymentForDomainAdminMutationVariables
>;

/**
 * __useMutationCreatePaymentForDomainAdminMutation__
 *
 * To run a mutation, you first call `useMutationCreatePaymentForDomainAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMutationCreatePaymentForDomainAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mutationCreatePaymentForDomainAdminMutation, { data, loading, error }] = useMutationCreatePaymentForDomainAdminMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMutationCreatePaymentForDomainAdminMutation(
    baseOptions?: Apollo.MutationHookOptions<
        MutationCreatePaymentForDomainAdminMutation,
        MutationCreatePaymentForDomainAdminMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        MutationCreatePaymentForDomainAdminMutation,
        MutationCreatePaymentForDomainAdminMutationVariables
    >(MutationCreatePaymentForDomainAdminDocument, options);
}
export type MutationCreatePaymentForDomainAdminMutationHookResult = ReturnType<
    typeof useMutationCreatePaymentForDomainAdminMutation
>;
export type MutationCreatePaymentForDomainAdminMutationResult =
    Apollo.MutationResult<MutationCreatePaymentForDomainAdminMutation>;
export type MutationCreatePaymentForDomainAdminMutationOptions = Apollo.BaseMutationOptions<
    MutationCreatePaymentForDomainAdminMutation,
    MutationCreatePaymentForDomainAdminMutationVariables
>;
export const MutationUpdatePaymentForDomainAdminDocument = gql`
    mutation mutationUpdatePaymentForDomainAdmin($input: UpdatePaymentForDomainAdminInput!) {
        updatePaymentForDomainAdmin(input: $input)
    }
`;
export type MutationUpdatePaymentForDomainAdminMutationFn = Apollo.MutationFunction<
    MutationUpdatePaymentForDomainAdminMutation,
    MutationUpdatePaymentForDomainAdminMutationVariables
>;

/**
 * __useMutationUpdatePaymentForDomainAdminMutation__
 *
 * To run a mutation, you first call `useMutationUpdatePaymentForDomainAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMutationUpdatePaymentForDomainAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mutationUpdatePaymentForDomainAdminMutation, { data, loading, error }] = useMutationUpdatePaymentForDomainAdminMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMutationUpdatePaymentForDomainAdminMutation(
    baseOptions?: Apollo.MutationHookOptions<
        MutationUpdatePaymentForDomainAdminMutation,
        MutationUpdatePaymentForDomainAdminMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        MutationUpdatePaymentForDomainAdminMutation,
        MutationUpdatePaymentForDomainAdminMutationVariables
    >(MutationUpdatePaymentForDomainAdminDocument, options);
}
export type MutationUpdatePaymentForDomainAdminMutationHookResult = ReturnType<
    typeof useMutationUpdatePaymentForDomainAdminMutation
>;
export type MutationUpdatePaymentForDomainAdminMutationResult =
    Apollo.MutationResult<MutationUpdatePaymentForDomainAdminMutation>;
export type MutationUpdatePaymentForDomainAdminMutationOptions = Apollo.BaseMutationOptions<
    MutationUpdatePaymentForDomainAdminMutation,
    MutationUpdatePaymentForDomainAdminMutationVariables
>;
export const MutationCreateHostingAdminDocument = gql`
    mutation mutationCreateHostingAdmin($input: CreateHostingAdminInput!) {
        createHostingAdmin(input: $input)
    }
`;
export type MutationCreateHostingAdminMutationFn = Apollo.MutationFunction<
    MutationCreateHostingAdminMutation,
    MutationCreateHostingAdminMutationVariables
>;

/**
 * __useMutationCreateHostingAdminMutation__
 *
 * To run a mutation, you first call `useMutationCreateHostingAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMutationCreateHostingAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mutationCreateHostingAdminMutation, { data, loading, error }] = useMutationCreateHostingAdminMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMutationCreateHostingAdminMutation(
    baseOptions?: Apollo.MutationHookOptions<
        MutationCreateHostingAdminMutation,
        MutationCreateHostingAdminMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        MutationCreateHostingAdminMutation,
        MutationCreateHostingAdminMutationVariables
    >(MutationCreateHostingAdminDocument, options);
}
export type MutationCreateHostingAdminMutationHookResult = ReturnType<
    typeof useMutationCreateHostingAdminMutation
>;
export type MutationCreateHostingAdminMutationResult =
    Apollo.MutationResult<MutationCreateHostingAdminMutation>;
export type MutationCreateHostingAdminMutationOptions = Apollo.BaseMutationOptions<
    MutationCreateHostingAdminMutation,
    MutationCreateHostingAdminMutationVariables
>;
export const MutationConnectHostingAdminDocument = gql`
    mutation mutationConnectHostingAdmin($input: ConnectHostingAdminInput!) {
        connectHostingAdmin(input: $input)
    }
`;
export type MutationConnectHostingAdminMutationFn = Apollo.MutationFunction<
    MutationConnectHostingAdminMutation,
    MutationConnectHostingAdminMutationVariables
>;

/**
 * __useMutationConnectHostingAdminMutation__
 *
 * To run a mutation, you first call `useMutationConnectHostingAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMutationConnectHostingAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mutationConnectHostingAdminMutation, { data, loading, error }] = useMutationConnectHostingAdminMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMutationConnectHostingAdminMutation(
    baseOptions?: Apollo.MutationHookOptions<
        MutationConnectHostingAdminMutation,
        MutationConnectHostingAdminMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        MutationConnectHostingAdminMutation,
        MutationConnectHostingAdminMutationVariables
    >(MutationConnectHostingAdminDocument, options);
}
export type MutationConnectHostingAdminMutationHookResult = ReturnType<
    typeof useMutationConnectHostingAdminMutation
>;
export type MutationConnectHostingAdminMutationResult =
    Apollo.MutationResult<MutationConnectHostingAdminMutation>;
export type MutationConnectHostingAdminMutationOptions = Apollo.BaseMutationOptions<
    MutationConnectHostingAdminMutation,
    MutationConnectHostingAdminMutationVariables
>;
export const MutationUpdateHostingAdminDocument = gql`
    mutation mutationUpdateHostingAdmin($input: UpdateHostingAdminInput!) {
        updateHostingAdmin(input: $input)
    }
`;
export type MutationUpdateHostingAdminMutationFn = Apollo.MutationFunction<
    MutationUpdateHostingAdminMutation,
    MutationUpdateHostingAdminMutationVariables
>;

/**
 * __useMutationUpdateHostingAdminMutation__
 *
 * To run a mutation, you first call `useMutationUpdateHostingAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMutationUpdateHostingAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mutationUpdateHostingAdminMutation, { data, loading, error }] = useMutationUpdateHostingAdminMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMutationUpdateHostingAdminMutation(
    baseOptions?: Apollo.MutationHookOptions<
        MutationUpdateHostingAdminMutation,
        MutationUpdateHostingAdminMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        MutationUpdateHostingAdminMutation,
        MutationUpdateHostingAdminMutationVariables
    >(MutationUpdateHostingAdminDocument, options);
}
export type MutationUpdateHostingAdminMutationHookResult = ReturnType<
    typeof useMutationUpdateHostingAdminMutation
>;
export type MutationUpdateHostingAdminMutationResult =
    Apollo.MutationResult<MutationUpdateHostingAdminMutation>;
export type MutationUpdateHostingAdminMutationOptions = Apollo.BaseMutationOptions<
    MutationUpdateHostingAdminMutation,
    MutationUpdateHostingAdminMutationVariables
>;
export const MutationCreatePaymentForHostingAdminDocument = gql`
    mutation mutationCreatePaymentForHostingAdmin($input: CreatePaymentForHostingAdminInput!) {
        createPaymentForHostingAdmin(input: $input)
    }
`;
export type MutationCreatePaymentForHostingAdminMutationFn = Apollo.MutationFunction<
    MutationCreatePaymentForHostingAdminMutation,
    MutationCreatePaymentForHostingAdminMutationVariables
>;

/**
 * __useMutationCreatePaymentForHostingAdminMutation__
 *
 * To run a mutation, you first call `useMutationCreatePaymentForHostingAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMutationCreatePaymentForHostingAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mutationCreatePaymentForHostingAdminMutation, { data, loading, error }] = useMutationCreatePaymentForHostingAdminMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMutationCreatePaymentForHostingAdminMutation(
    baseOptions?: Apollo.MutationHookOptions<
        MutationCreatePaymentForHostingAdminMutation,
        MutationCreatePaymentForHostingAdminMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        MutationCreatePaymentForHostingAdminMutation,
        MutationCreatePaymentForHostingAdminMutationVariables
    >(MutationCreatePaymentForHostingAdminDocument, options);
}
export type MutationCreatePaymentForHostingAdminMutationHookResult = ReturnType<
    typeof useMutationCreatePaymentForHostingAdminMutation
>;
export type MutationCreatePaymentForHostingAdminMutationResult =
    Apollo.MutationResult<MutationCreatePaymentForHostingAdminMutation>;
export type MutationCreatePaymentForHostingAdminMutationOptions = Apollo.BaseMutationOptions<
    MutationCreatePaymentForHostingAdminMutation,
    MutationCreatePaymentForHostingAdminMutationVariables
>;
export const MutationUpdatePaymentForHostingAdminDocument = gql`
    mutation mutationUpdatePaymentForHostingAdmin($input: UpdatePaymentForHostingAdminInput!) {
        updatePaymentForHostingAdmin(input: $input)
    }
`;
export type MutationUpdatePaymentForHostingAdminMutationFn = Apollo.MutationFunction<
    MutationUpdatePaymentForHostingAdminMutation,
    MutationUpdatePaymentForHostingAdminMutationVariables
>;

/**
 * __useMutationUpdatePaymentForHostingAdminMutation__
 *
 * To run a mutation, you first call `useMutationUpdatePaymentForHostingAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMutationUpdatePaymentForHostingAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mutationUpdatePaymentForHostingAdminMutation, { data, loading, error }] = useMutationUpdatePaymentForHostingAdminMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMutationUpdatePaymentForHostingAdminMutation(
    baseOptions?: Apollo.MutationHookOptions<
        MutationUpdatePaymentForHostingAdminMutation,
        MutationUpdatePaymentForHostingAdminMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        MutationUpdatePaymentForHostingAdminMutation,
        MutationUpdatePaymentForHostingAdminMutationVariables
    >(MutationUpdatePaymentForHostingAdminDocument, options);
}
export type MutationUpdatePaymentForHostingAdminMutationHookResult = ReturnType<
    typeof useMutationUpdatePaymentForHostingAdminMutation
>;
export type MutationUpdatePaymentForHostingAdminMutationResult =
    Apollo.MutationResult<MutationUpdatePaymentForHostingAdminMutation>;
export type MutationUpdatePaymentForHostingAdminMutationOptions = Apollo.BaseMutationOptions<
    MutationUpdatePaymentForHostingAdminMutation,
    MutationUpdatePaymentForHostingAdminMutationVariables
>;
export const MutationUpdatePaymentForEmailAdminDocument = gql`
    mutation mutationUpdatePaymentForEmailAdmin($input: UpdatePaymentForEmailAdminInput!) {
        updatePaymentForEmailAdmin(input: $input)
    }
`;
export type MutationUpdatePaymentForEmailAdminMutationFn = Apollo.MutationFunction<
    MutationUpdatePaymentForEmailAdminMutation,
    MutationUpdatePaymentForEmailAdminMutationVariables
>;

/**
 * __useMutationUpdatePaymentForEmailAdminMutation__
 *
 * To run a mutation, you first call `useMutationUpdatePaymentForEmailAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMutationUpdatePaymentForEmailAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mutationUpdatePaymentForEmailAdminMutation, { data, loading, error }] = useMutationUpdatePaymentForEmailAdminMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMutationUpdatePaymentForEmailAdminMutation(
    baseOptions?: Apollo.MutationHookOptions<
        MutationUpdatePaymentForEmailAdminMutation,
        MutationUpdatePaymentForEmailAdminMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        MutationUpdatePaymentForEmailAdminMutation,
        MutationUpdatePaymentForEmailAdminMutationVariables
    >(MutationUpdatePaymentForEmailAdminDocument, options);
}
export type MutationUpdatePaymentForEmailAdminMutationHookResult = ReturnType<
    typeof useMutationUpdatePaymentForEmailAdminMutation
>;
export type MutationUpdatePaymentForEmailAdminMutationResult =
    Apollo.MutationResult<MutationUpdatePaymentForEmailAdminMutation>;
export type MutationUpdatePaymentForEmailAdminMutationOptions = Apollo.BaseMutationOptions<
    MutationUpdatePaymentForEmailAdminMutation,
    MutationUpdatePaymentForEmailAdminMutationVariables
>;
export const MutationUpdatePaymentForEtcAdminDocument = gql`
    mutation mutationUpdatePaymentForEtcAdmin($input: UpdatePaymentForEtcAdminInput!) {
        updatePaymentForEtcAdmin(input: $input)
    }
`;
export type MutationUpdatePaymentForEtcAdminMutationFn = Apollo.MutationFunction<
    MutationUpdatePaymentForEtcAdminMutation,
    MutationUpdatePaymentForEtcAdminMutationVariables
>;

/**
 * __useMutationUpdatePaymentForEtcAdminMutation__
 *
 * To run a mutation, you first call `useMutationUpdatePaymentForEtcAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMutationUpdatePaymentForEtcAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mutationUpdatePaymentForEtcAdminMutation, { data, loading, error }] = useMutationUpdatePaymentForEtcAdminMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMutationUpdatePaymentForEtcAdminMutation(
    baseOptions?: Apollo.MutationHookOptions<
        MutationUpdatePaymentForEtcAdminMutation,
        MutationUpdatePaymentForEtcAdminMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        MutationUpdatePaymentForEtcAdminMutation,
        MutationUpdatePaymentForEtcAdminMutationVariables
    >(MutationUpdatePaymentForEtcAdminDocument, options);
}
export type MutationUpdatePaymentForEtcAdminMutationHookResult = ReturnType<
    typeof useMutationUpdatePaymentForEtcAdminMutation
>;
export type MutationUpdatePaymentForEtcAdminMutationResult =
    Apollo.MutationResult<MutationUpdatePaymentForEtcAdminMutation>;
export type MutationUpdatePaymentForEtcAdminMutationOptions = Apollo.BaseMutationOptions<
    MutationUpdatePaymentForEtcAdminMutation,
    MutationUpdatePaymentForEtcAdminMutationVariables
>;
export const MutationCreatePaymentForEtcAdminDocument = gql`
    mutation mutationCreatePaymentForEtcAdmin($input: CreatePaymentForEtcAdminInput!) {
        createPaymentForEtcAdmin(input: $input)
    }
`;
export type MutationCreatePaymentForEtcAdminMutationFn = Apollo.MutationFunction<
    MutationCreatePaymentForEtcAdminMutation,
    MutationCreatePaymentForEtcAdminMutationVariables
>;

/**
 * __useMutationCreatePaymentForEtcAdminMutation__
 *
 * To run a mutation, you first call `useMutationCreatePaymentForEtcAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMutationCreatePaymentForEtcAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mutationCreatePaymentForEtcAdminMutation, { data, loading, error }] = useMutationCreatePaymentForEtcAdminMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMutationCreatePaymentForEtcAdminMutation(
    baseOptions?: Apollo.MutationHookOptions<
        MutationCreatePaymentForEtcAdminMutation,
        MutationCreatePaymentForEtcAdminMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        MutationCreatePaymentForEtcAdminMutation,
        MutationCreatePaymentForEtcAdminMutationVariables
    >(MutationCreatePaymentForEtcAdminDocument, options);
}
export type MutationCreatePaymentForEtcAdminMutationHookResult = ReturnType<
    typeof useMutationCreatePaymentForEtcAdminMutation
>;
export type MutationCreatePaymentForEtcAdminMutationResult =
    Apollo.MutationResult<MutationCreatePaymentForEtcAdminMutation>;
export type MutationCreatePaymentForEtcAdminMutationOptions = Apollo.BaseMutationOptions<
    MutationCreatePaymentForEtcAdminMutation,
    MutationCreatePaymentForEtcAdminMutationVariables
>;
export const MutationCreatePaymentForEmailAdminDocument = gql`
    mutation mutationCreatePaymentForEmailAdmin($input: CreatePaymentForEmailAdminInput!) {
        createPaymentForEmailAdmin(input: $input)
    }
`;
export type MutationCreatePaymentForEmailAdminMutationFn = Apollo.MutationFunction<
    MutationCreatePaymentForEmailAdminMutation,
    MutationCreatePaymentForEmailAdminMutationVariables
>;

/**
 * __useMutationCreatePaymentForEmailAdminMutation__
 *
 * To run a mutation, you first call `useMutationCreatePaymentForEmailAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMutationCreatePaymentForEmailAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mutationCreatePaymentForEmailAdminMutation, { data, loading, error }] = useMutationCreatePaymentForEmailAdminMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMutationCreatePaymentForEmailAdminMutation(
    baseOptions?: Apollo.MutationHookOptions<
        MutationCreatePaymentForEmailAdminMutation,
        MutationCreatePaymentForEmailAdminMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        MutationCreatePaymentForEmailAdminMutation,
        MutationCreatePaymentForEmailAdminMutationVariables
    >(MutationCreatePaymentForEmailAdminDocument, options);
}
export type MutationCreatePaymentForEmailAdminMutationHookResult = ReturnType<
    typeof useMutationCreatePaymentForEmailAdminMutation
>;
export type MutationCreatePaymentForEmailAdminMutationResult =
    Apollo.MutationResult<MutationCreatePaymentForEmailAdminMutation>;
export type MutationCreatePaymentForEmailAdminMutationOptions = Apollo.BaseMutationOptions<
    MutationCreatePaymentForEmailAdminMutation,
    MutationCreatePaymentForEmailAdminMutationVariables
>;
export const MutationCreateRequestAdminDocument = gql`
    mutation mutationCreateRequestAdmin($input: CreateRequestAdminInput!) {
        createRequestAdmin(input: $input)
    }
`;
export type MutationCreateRequestAdminMutationFn = Apollo.MutationFunction<
    MutationCreateRequestAdminMutation,
    MutationCreateRequestAdminMutationVariables
>;

/**
 * __useMutationCreateRequestAdminMutation__
 *
 * To run a mutation, you first call `useMutationCreateRequestAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMutationCreateRequestAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mutationCreateRequestAdminMutation, { data, loading, error }] = useMutationCreateRequestAdminMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMutationCreateRequestAdminMutation(
    baseOptions?: Apollo.MutationHookOptions<
        MutationCreateRequestAdminMutation,
        MutationCreateRequestAdminMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        MutationCreateRequestAdminMutation,
        MutationCreateRequestAdminMutationVariables
    >(MutationCreateRequestAdminDocument, options);
}
export type MutationCreateRequestAdminMutationHookResult = ReturnType<
    typeof useMutationCreateRequestAdminMutation
>;
export type MutationCreateRequestAdminMutationResult =
    Apollo.MutationResult<MutationCreateRequestAdminMutation>;
export type MutationCreateRequestAdminMutationOptions = Apollo.BaseMutationOptions<
    MutationCreateRequestAdminMutation,
    MutationCreateRequestAdminMutationVariables
>;
export const MutationUpdateRequestAdminDocument = gql`
    mutation mutationUpdateRequestAdmin($input: UpdateRequestAdminInput!) {
        updateRequestAdmin(input: $input)
    }
`;
export type MutationUpdateRequestAdminMutationFn = Apollo.MutationFunction<
    MutationUpdateRequestAdminMutation,
    MutationUpdateRequestAdminMutationVariables
>;

/**
 * __useMutationUpdateRequestAdminMutation__
 *
 * To run a mutation, you first call `useMutationUpdateRequestAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMutationUpdateRequestAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mutationUpdateRequestAdminMutation, { data, loading, error }] = useMutationUpdateRequestAdminMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMutationUpdateRequestAdminMutation(
    baseOptions?: Apollo.MutationHookOptions<
        MutationUpdateRequestAdminMutation,
        MutationUpdateRequestAdminMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        MutationUpdateRequestAdminMutation,
        MutationUpdateRequestAdminMutationVariables
    >(MutationUpdateRequestAdminDocument, options);
}
export type MutationUpdateRequestAdminMutationHookResult = ReturnType<
    typeof useMutationUpdateRequestAdminMutation
>;
export type MutationUpdateRequestAdminMutationResult =
    Apollo.MutationResult<MutationUpdateRequestAdminMutation>;
export type MutationUpdateRequestAdminMutationOptions = Apollo.BaseMutationOptions<
    MutationUpdateRequestAdminMutation,
    MutationUpdateRequestAdminMutationVariables
>;
export const MutationChangeRequestStatusAdminDocument = gql`
    mutation mutationChangeRequestStatusAdmin($input: ChangeRequestStatusAdminInput!) {
        changeRequestStatusAdmin(input: $input)
    }
`;
export type MutationChangeRequestStatusAdminMutationFn = Apollo.MutationFunction<
    MutationChangeRequestStatusAdminMutation,
    MutationChangeRequestStatusAdminMutationVariables
>;

/**
 * __useMutationChangeRequestStatusAdminMutation__
 *
 * To run a mutation, you first call `useMutationChangeRequestStatusAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMutationChangeRequestStatusAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mutationChangeRequestStatusAdminMutation, { data, loading, error }] = useMutationChangeRequestStatusAdminMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMutationChangeRequestStatusAdminMutation(
    baseOptions?: Apollo.MutationHookOptions<
        MutationChangeRequestStatusAdminMutation,
        MutationChangeRequestStatusAdminMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        MutationChangeRequestStatusAdminMutation,
        MutationChangeRequestStatusAdminMutationVariables
    >(MutationChangeRequestStatusAdminDocument, options);
}
export type MutationChangeRequestStatusAdminMutationHookResult = ReturnType<
    typeof useMutationChangeRequestStatusAdminMutation
>;
export type MutationChangeRequestStatusAdminMutationResult =
    Apollo.MutationResult<MutationChangeRequestStatusAdminMutation>;
export type MutationChangeRequestStatusAdminMutationOptions = Apollo.BaseMutationOptions<
    MutationChangeRequestStatusAdminMutation,
    MutationChangeRequestStatusAdminMutationVariables
>;
export const MutationDeleteRequestAdminDocument = gql`
    mutation mutationDeleteRequestAdmin($input: DeleteRequestAdminInput!) {
        deleteRequestAdmin(input: $input)
    }
`;
export type MutationDeleteRequestAdminMutationFn = Apollo.MutationFunction<
    MutationDeleteRequestAdminMutation,
    MutationDeleteRequestAdminMutationVariables
>;

/**
 * __useMutationDeleteRequestAdminMutation__
 *
 * To run a mutation, you first call `useMutationDeleteRequestAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMutationDeleteRequestAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mutationDeleteRequestAdminMutation, { data, loading, error }] = useMutationDeleteRequestAdminMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMutationDeleteRequestAdminMutation(
    baseOptions?: Apollo.MutationHookOptions<
        MutationDeleteRequestAdminMutation,
        MutationDeleteRequestAdminMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        MutationDeleteRequestAdminMutation,
        MutationDeleteRequestAdminMutationVariables
    >(MutationDeleteRequestAdminDocument, options);
}
export type MutationDeleteRequestAdminMutationHookResult = ReturnType<
    typeof useMutationDeleteRequestAdminMutation
>;
export type MutationDeleteRequestAdminMutationResult =
    Apollo.MutationResult<MutationDeleteRequestAdminMutation>;
export type MutationDeleteRequestAdminMutationOptions = Apollo.BaseMutationOptions<
    MutationDeleteRequestAdminMutation,
    MutationDeleteRequestAdminMutationVariables
>;
export const MutationConnectEmailAdminDocument = gql`
    mutation mutationConnectEmailAdmin($input: ConnectEmailAdminInput!) {
        connectEmailAdmin(input: $input)
    }
`;
export type MutationConnectEmailAdminMutationFn = Apollo.MutationFunction<
    MutationConnectEmailAdminMutation,
    MutationConnectEmailAdminMutationVariables
>;

/**
 * __useMutationConnectEmailAdminMutation__
 *
 * To run a mutation, you first call `useMutationConnectEmailAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMutationConnectEmailAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mutationConnectEmailAdminMutation, { data, loading, error }] = useMutationConnectEmailAdminMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMutationConnectEmailAdminMutation(
    baseOptions?: Apollo.MutationHookOptions<
        MutationConnectEmailAdminMutation,
        MutationConnectEmailAdminMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        MutationConnectEmailAdminMutation,
        MutationConnectEmailAdminMutationVariables
    >(MutationConnectEmailAdminDocument, options);
}
export type MutationConnectEmailAdminMutationHookResult = ReturnType<
    typeof useMutationConnectEmailAdminMutation
>;
export type MutationConnectEmailAdminMutationResult =
    Apollo.MutationResult<MutationConnectEmailAdminMutation>;
export type MutationConnectEmailAdminMutationOptions = Apollo.BaseMutationOptions<
    MutationConnectEmailAdminMutation,
    MutationConnectEmailAdminMutationVariables
>;
export const MutationCreateEmailAdminDocument = gql`
    mutation mutationCreateEmailAdmin($input: CreateEmailAdminInput!) {
        createEmailAdmin(input: $input)
    }
`;
export type MutationCreateEmailAdminMutationFn = Apollo.MutationFunction<
    MutationCreateEmailAdminMutation,
    MutationCreateEmailAdminMutationVariables
>;

/**
 * __useMutationCreateEmailAdminMutation__
 *
 * To run a mutation, you first call `useMutationCreateEmailAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMutationCreateEmailAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mutationCreateEmailAdminMutation, { data, loading, error }] = useMutationCreateEmailAdminMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMutationCreateEmailAdminMutation(
    baseOptions?: Apollo.MutationHookOptions<
        MutationCreateEmailAdminMutation,
        MutationCreateEmailAdminMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        MutationCreateEmailAdminMutation,
        MutationCreateEmailAdminMutationVariables
    >(MutationCreateEmailAdminDocument, options);
}
export type MutationCreateEmailAdminMutationHookResult = ReturnType<
    typeof useMutationCreateEmailAdminMutation
>;
export type MutationCreateEmailAdminMutationResult =
    Apollo.MutationResult<MutationCreateEmailAdminMutation>;
export type MutationCreateEmailAdminMutationOptions = Apollo.BaseMutationOptions<
    MutationCreateEmailAdminMutation,
    MutationCreateEmailAdminMutationVariables
>;
export const MutationUpdateEmailAdminDocument = gql`
    mutation mutationUpdateEmailAdmin($input: UpdateEmailAdminInput!) {
        updateEmailAdmin(input: $input)
    }
`;
export type MutationUpdateEmailAdminMutationFn = Apollo.MutationFunction<
    MutationUpdateEmailAdminMutation,
    MutationUpdateEmailAdminMutationVariables
>;

/**
 * __useMutationUpdateEmailAdminMutation__
 *
 * To run a mutation, you first call `useMutationUpdateEmailAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMutationUpdateEmailAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mutationUpdateEmailAdminMutation, { data, loading, error }] = useMutationUpdateEmailAdminMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMutationUpdateEmailAdminMutation(
    baseOptions?: Apollo.MutationHookOptions<
        MutationUpdateEmailAdminMutation,
        MutationUpdateEmailAdminMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        MutationUpdateEmailAdminMutation,
        MutationUpdateEmailAdminMutationVariables
    >(MutationUpdateEmailAdminDocument, options);
}
export type MutationUpdateEmailAdminMutationHookResult = ReturnType<
    typeof useMutationUpdateEmailAdminMutation
>;
export type MutationUpdateEmailAdminMutationResult =
    Apollo.MutationResult<MutationUpdateEmailAdminMutation>;
export type MutationUpdateEmailAdminMutationOptions = Apollo.BaseMutationOptions<
    MutationUpdateEmailAdminMutation,
    MutationUpdateEmailAdminMutationVariables
>;
export const MutationCreateEmailAccountAdminDocument = gql`
    mutation mutationCreateEmailAccountAdmin($input: CreateEmailAccountAdminInput!) {
        createEmailAccountAdmin(input: $input)
    }
`;
export type MutationCreateEmailAccountAdminMutationFn = Apollo.MutationFunction<
    MutationCreateEmailAccountAdminMutation,
    MutationCreateEmailAccountAdminMutationVariables
>;

/**
 * __useMutationCreateEmailAccountAdminMutation__
 *
 * To run a mutation, you first call `useMutationCreateEmailAccountAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMutationCreateEmailAccountAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mutationCreateEmailAccountAdminMutation, { data, loading, error }] = useMutationCreateEmailAccountAdminMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMutationCreateEmailAccountAdminMutation(
    baseOptions?: Apollo.MutationHookOptions<
        MutationCreateEmailAccountAdminMutation,
        MutationCreateEmailAccountAdminMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        MutationCreateEmailAccountAdminMutation,
        MutationCreateEmailAccountAdminMutationVariables
    >(MutationCreateEmailAccountAdminDocument, options);
}
export type MutationCreateEmailAccountAdminMutationHookResult = ReturnType<
    typeof useMutationCreateEmailAccountAdminMutation
>;
export type MutationCreateEmailAccountAdminMutationResult =
    Apollo.MutationResult<MutationCreateEmailAccountAdminMutation>;
export type MutationCreateEmailAccountAdminMutationOptions = Apollo.BaseMutationOptions<
    MutationCreateEmailAccountAdminMutation,
    MutationCreateEmailAccountAdminMutationVariables
>;
export const MutationUpdateEmailAccountAdminDocument = gql`
    mutation mutationUpdateEmailAccountAdmin($input: UpdateEmailAccountAdminInput!) {
        updateEmailAccountAdmin(input: $input)
    }
`;
export type MutationUpdateEmailAccountAdminMutationFn = Apollo.MutationFunction<
    MutationUpdateEmailAccountAdminMutation,
    MutationUpdateEmailAccountAdminMutationVariables
>;

/**
 * __useMutationUpdateEmailAccountAdminMutation__
 *
 * To run a mutation, you first call `useMutationUpdateEmailAccountAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMutationUpdateEmailAccountAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mutationUpdateEmailAccountAdminMutation, { data, loading, error }] = useMutationUpdateEmailAccountAdminMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMutationUpdateEmailAccountAdminMutation(
    baseOptions?: Apollo.MutationHookOptions<
        MutationUpdateEmailAccountAdminMutation,
        MutationUpdateEmailAccountAdminMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        MutationUpdateEmailAccountAdminMutation,
        MutationUpdateEmailAccountAdminMutationVariables
    >(MutationUpdateEmailAccountAdminDocument, options);
}
export type MutationUpdateEmailAccountAdminMutationHookResult = ReturnType<
    typeof useMutationUpdateEmailAccountAdminMutation
>;
export type MutationUpdateEmailAccountAdminMutationResult =
    Apollo.MutationResult<MutationUpdateEmailAccountAdminMutation>;
export type MutationUpdateEmailAccountAdminMutationOptions = Apollo.BaseMutationOptions<
    MutationUpdateEmailAccountAdminMutation,
    MutationUpdateEmailAccountAdminMutationVariables
>;
export const MutationDeleteEmailAccountAdminDocument = gql`
    mutation mutationDeleteEmailAccountAdmin($input: DeleteEmailAccountAdminInput!) {
        deleteEmailAccountAdmin(input: $input)
    }
`;
export type MutationDeleteEmailAccountAdminMutationFn = Apollo.MutationFunction<
    MutationDeleteEmailAccountAdminMutation,
    MutationDeleteEmailAccountAdminMutationVariables
>;

/**
 * __useMutationDeleteEmailAccountAdminMutation__
 *
 * To run a mutation, you first call `useMutationDeleteEmailAccountAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMutationDeleteEmailAccountAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mutationDeleteEmailAccountAdminMutation, { data, loading, error }] = useMutationDeleteEmailAccountAdminMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMutationDeleteEmailAccountAdminMutation(
    baseOptions?: Apollo.MutationHookOptions<
        MutationDeleteEmailAccountAdminMutation,
        MutationDeleteEmailAccountAdminMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        MutationDeleteEmailAccountAdminMutation,
        MutationDeleteEmailAccountAdminMutationVariables
    >(MutationDeleteEmailAccountAdminDocument, options);
}
export type MutationDeleteEmailAccountAdminMutationHookResult = ReturnType<
    typeof useMutationDeleteEmailAccountAdminMutation
>;
export type MutationDeleteEmailAccountAdminMutationResult =
    Apollo.MutationResult<MutationDeleteEmailAccountAdminMutation>;
export type MutationDeleteEmailAccountAdminMutationOptions = Apollo.BaseMutationOptions<
    MutationDeleteEmailAccountAdminMutation,
    MutationDeleteEmailAccountAdminMutationVariables
>;
export const MutationSendBillingEmailHostingAdminDocument = gql`
    mutation mutationSendBillingEmailHostingAdmin($input: SendBillingEmailHostingAdminInput!) {
        sendBillingEmailHostingAdmin(input: $input)
    }
`;
export type MutationSendBillingEmailHostingAdminMutationFn = Apollo.MutationFunction<
    MutationSendBillingEmailHostingAdminMutation,
    MutationSendBillingEmailHostingAdminMutationVariables
>;

/**
 * __useMutationSendBillingEmailHostingAdminMutation__
 *
 * To run a mutation, you first call `useMutationSendBillingEmailHostingAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMutationSendBillingEmailHostingAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mutationSendBillingEmailHostingAdminMutation, { data, loading, error }] = useMutationSendBillingEmailHostingAdminMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMutationSendBillingEmailHostingAdminMutation(
    baseOptions?: Apollo.MutationHookOptions<
        MutationSendBillingEmailHostingAdminMutation,
        MutationSendBillingEmailHostingAdminMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        MutationSendBillingEmailHostingAdminMutation,
        MutationSendBillingEmailHostingAdminMutationVariables
    >(MutationSendBillingEmailHostingAdminDocument, options);
}
export type MutationSendBillingEmailHostingAdminMutationHookResult = ReturnType<
    typeof useMutationSendBillingEmailHostingAdminMutation
>;
export type MutationSendBillingEmailHostingAdminMutationResult =
    Apollo.MutationResult<MutationSendBillingEmailHostingAdminMutation>;
export type MutationSendBillingEmailHostingAdminMutationOptions = Apollo.BaseMutationOptions<
    MutationSendBillingEmailHostingAdminMutation,
    MutationSendBillingEmailHostingAdminMutationVariables
>;
export const MutationSendBillingEmailEmailAdminDocument = gql`
    mutation mutationSendBillingEmailEmailAdmin($input: SendBillingEmailEmailAdminInput!) {
        sendBillingEmailEmailAdmin(input: $input)
    }
`;
export type MutationSendBillingEmailEmailAdminMutationFn = Apollo.MutationFunction<
    MutationSendBillingEmailEmailAdminMutation,
    MutationSendBillingEmailEmailAdminMutationVariables
>;

/**
 * __useMutationSendBillingEmailEmailAdminMutation__
 *
 * To run a mutation, you first call `useMutationSendBillingEmailEmailAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMutationSendBillingEmailEmailAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mutationSendBillingEmailEmailAdminMutation, { data, loading, error }] = useMutationSendBillingEmailEmailAdminMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMutationSendBillingEmailEmailAdminMutation(
    baseOptions?: Apollo.MutationHookOptions<
        MutationSendBillingEmailEmailAdminMutation,
        MutationSendBillingEmailEmailAdminMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        MutationSendBillingEmailEmailAdminMutation,
        MutationSendBillingEmailEmailAdminMutationVariables
    >(MutationSendBillingEmailEmailAdminDocument, options);
}
export type MutationSendBillingEmailEmailAdminMutationHookResult = ReturnType<
    typeof useMutationSendBillingEmailEmailAdminMutation
>;
export type MutationSendBillingEmailEmailAdminMutationResult =
    Apollo.MutationResult<MutationSendBillingEmailEmailAdminMutation>;
export type MutationSendBillingEmailEmailAdminMutationOptions = Apollo.BaseMutationOptions<
    MutationSendBillingEmailEmailAdminMutation,
    MutationSendBillingEmailEmailAdminMutationVariables
>;
export const MutationSendBillingEmailDomainAdminDocument = gql`
    mutation mutationSendBillingEmailDomainAdmin($input: SendBillingEmailDomainAdminInput!) {
        sendBillingEmailDomainAdmin(input: $input)
    }
`;
export type MutationSendBillingEmailDomainAdminMutationFn = Apollo.MutationFunction<
    MutationSendBillingEmailDomainAdminMutation,
    MutationSendBillingEmailDomainAdminMutationVariables
>;

/**
 * __useMutationSendBillingEmailDomainAdminMutation__
 *
 * To run a mutation, you first call `useMutationSendBillingEmailDomainAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMutationSendBillingEmailDomainAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mutationSendBillingEmailDomainAdminMutation, { data, loading, error }] = useMutationSendBillingEmailDomainAdminMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMutationSendBillingEmailDomainAdminMutation(
    baseOptions?: Apollo.MutationHookOptions<
        MutationSendBillingEmailDomainAdminMutation,
        MutationSendBillingEmailDomainAdminMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        MutationSendBillingEmailDomainAdminMutation,
        MutationSendBillingEmailDomainAdminMutationVariables
    >(MutationSendBillingEmailDomainAdminDocument, options);
}
export type MutationSendBillingEmailDomainAdminMutationHookResult = ReturnType<
    typeof useMutationSendBillingEmailDomainAdminMutation
>;
export type MutationSendBillingEmailDomainAdminMutationResult =
    Apollo.MutationResult<MutationSendBillingEmailDomainAdminMutation>;
export type MutationSendBillingEmailDomainAdminMutationOptions = Apollo.BaseMutationOptions<
    MutationSendBillingEmailDomainAdminMutation,
    MutationSendBillingEmailDomainAdminMutationVariables
>;
export const MutationSendBillingEmailCertificateAdminDocument = gql`
    mutation mutationSendBillingEmailCertificateAdmin(
        $input: SendBillingEmailCertificateAdminInput!
    ) {
        sendBillingEmailCertificateAdmin(input: $input)
    }
`;
export type MutationSendBillingEmailCertificateAdminMutationFn = Apollo.MutationFunction<
    MutationSendBillingEmailCertificateAdminMutation,
    MutationSendBillingEmailCertificateAdminMutationVariables
>;

/**
 * __useMutationSendBillingEmailCertificateAdminMutation__
 *
 * To run a mutation, you first call `useMutationSendBillingEmailCertificateAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMutationSendBillingEmailCertificateAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mutationSendBillingEmailCertificateAdminMutation, { data, loading, error }] = useMutationSendBillingEmailCertificateAdminMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMutationSendBillingEmailCertificateAdminMutation(
    baseOptions?: Apollo.MutationHookOptions<
        MutationSendBillingEmailCertificateAdminMutation,
        MutationSendBillingEmailCertificateAdminMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        MutationSendBillingEmailCertificateAdminMutation,
        MutationSendBillingEmailCertificateAdminMutationVariables
    >(MutationSendBillingEmailCertificateAdminDocument, options);
}
export type MutationSendBillingEmailCertificateAdminMutationHookResult = ReturnType<
    typeof useMutationSendBillingEmailCertificateAdminMutation
>;
export type MutationSendBillingEmailCertificateAdminMutationResult =
    Apollo.MutationResult<MutationSendBillingEmailCertificateAdminMutation>;
export type MutationSendBillingEmailCertificateAdminMutationOptions = Apollo.BaseMutationOptions<
    MutationSendBillingEmailCertificateAdminMutation,
    MutationSendBillingEmailCertificateAdminMutationVariables
>;
export const QueryMeDocument = gql`
    query queryMe {
        me {
            ...UserParts
            createdAt
        }
    }
    ${UserPartsFragmentDoc}
`;

/**
 * __useQueryMeQuery__
 *
 * To run a query within a React component, call `useQueryMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useQueryMeQuery(
    baseOptions?: Apollo.QueryHookOptions<QueryMeQuery, QueryMeQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<QueryMeQuery, QueryMeQueryVariables>(QueryMeDocument, options);
}
export function useQueryMeLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<QueryMeQuery, QueryMeQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<QueryMeQuery, QueryMeQueryVariables>(QueryMeDocument, options);
}
export type QueryMeQueryHookResult = ReturnType<typeof useQueryMeQuery>;
export type QueryMeLazyQueryHookResult = ReturnType<typeof useQueryMeLazyQuery>;
export type QueryMeQueryResult = Apollo.QueryResult<QueryMeQuery, QueryMeQueryVariables>;
export const QueryFindManyCertificateDocument = gql`
    query queryFindManyCertificate($input: FindManyCertificateInput!) {
        findManyCertificate(input: $input) {
            ...CertificateParts
        }
    }
    ${CertificatePartsFragmentDoc}
`;

/**
 * __useQueryFindManyCertificateQuery__
 *
 * To run a query within a React component, call `useQueryFindManyCertificateQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryFindManyCertificateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryFindManyCertificateQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useQueryFindManyCertificateQuery(
    baseOptions: Apollo.QueryHookOptions<
        QueryFindManyCertificateQuery,
        QueryFindManyCertificateQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<QueryFindManyCertificateQuery, QueryFindManyCertificateQueryVariables>(
        QueryFindManyCertificateDocument,
        options,
    );
}
export function useQueryFindManyCertificateLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        QueryFindManyCertificateQuery,
        QueryFindManyCertificateQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        QueryFindManyCertificateQuery,
        QueryFindManyCertificateQueryVariables
    >(QueryFindManyCertificateDocument, options);
}
export type QueryFindManyCertificateQueryHookResult = ReturnType<
    typeof useQueryFindManyCertificateQuery
>;
export type QueryFindManyCertificateLazyQueryHookResult = ReturnType<
    typeof useQueryFindManyCertificateLazyQuery
>;
export type QueryFindManyCertificateQueryResult = Apollo.QueryResult<
    QueryFindManyCertificateQuery,
    QueryFindManyCertificateQueryVariables
>;
export const QueryCountCertificateAdminDocument = gql`
    query queryCountCertificateAdmin($input: CountCertificateAdminInput!) {
        countCertificateAdmin(input: $input)
    }
`;

/**
 * __useQueryCountCertificateAdminQuery__
 *
 * To run a query within a React component, call `useQueryCountCertificateAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryCountCertificateAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryCountCertificateAdminQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useQueryCountCertificateAdminQuery(
    baseOptions: Apollo.QueryHookOptions<
        QueryCountCertificateAdminQuery,
        QueryCountCertificateAdminQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        QueryCountCertificateAdminQuery,
        QueryCountCertificateAdminQueryVariables
    >(QueryCountCertificateAdminDocument, options);
}
export function useQueryCountCertificateAdminLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        QueryCountCertificateAdminQuery,
        QueryCountCertificateAdminQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        QueryCountCertificateAdminQuery,
        QueryCountCertificateAdminQueryVariables
    >(QueryCountCertificateAdminDocument, options);
}
export type QueryCountCertificateAdminQueryHookResult = ReturnType<
    typeof useQueryCountCertificateAdminQuery
>;
export type QueryCountCertificateAdminLazyQueryHookResult = ReturnType<
    typeof useQueryCountCertificateAdminLazyQuery
>;
export type QueryCountCertificateAdminQueryResult = Apollo.QueryResult<
    QueryCountCertificateAdminQuery,
    QueryCountCertificateAdminQueryVariables
>;
export const QueryFindFirstCertificateAdminDocument = gql`
    query queryFindFirstCertificateAdmin($input: FindFirstCertificateAdminInput!) {
        findFirstCertificateAdmin(input: $input) {
            ...CertificateParts
            user {
                ...UserParts
                company {
                    ...CompanyParts
                }
            }
        }
    }
    ${CertificatePartsFragmentDoc}
    ${UserPartsFragmentDoc}
    ${CompanyPartsFragmentDoc}
`;

/**
 * __useQueryFindFirstCertificateAdminQuery__
 *
 * To run a query within a React component, call `useQueryFindFirstCertificateAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryFindFirstCertificateAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryFindFirstCertificateAdminQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useQueryFindFirstCertificateAdminQuery(
    baseOptions: Apollo.QueryHookOptions<
        QueryFindFirstCertificateAdminQuery,
        QueryFindFirstCertificateAdminQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        QueryFindFirstCertificateAdminQuery,
        QueryFindFirstCertificateAdminQueryVariables
    >(QueryFindFirstCertificateAdminDocument, options);
}
export function useQueryFindFirstCertificateAdminLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        QueryFindFirstCertificateAdminQuery,
        QueryFindFirstCertificateAdminQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        QueryFindFirstCertificateAdminQuery,
        QueryFindFirstCertificateAdminQueryVariables
    >(QueryFindFirstCertificateAdminDocument, options);
}
export type QueryFindFirstCertificateAdminQueryHookResult = ReturnType<
    typeof useQueryFindFirstCertificateAdminQuery
>;
export type QueryFindFirstCertificateAdminLazyQueryHookResult = ReturnType<
    typeof useQueryFindFirstCertificateAdminLazyQuery
>;
export type QueryFindFirstCertificateAdminQueryResult = Apollo.QueryResult<
    QueryFindFirstCertificateAdminQuery,
    QueryFindFirstCertificateAdminQueryVariables
>;
export const QueryFindManyUserAdminDocument = gql`
    query queryFindManyUserAdmin($input: FindManyUserAdminInput!) {
        findManyUserAdmin(input: $input) {
            ...UserParts
            domains {
                domainUrl
            }
            certificates {
                domainUrl
            }
            hostings {
                domainUrl
            }
            emails {
                domainUrl
            }
            payments {
                price
            }
        }
    }
    ${UserPartsFragmentDoc}
`;

/**
 * __useQueryFindManyUserAdminQuery__
 *
 * To run a query within a React component, call `useQueryFindManyUserAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryFindManyUserAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryFindManyUserAdminQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useQueryFindManyUserAdminQuery(
    baseOptions: Apollo.QueryHookOptions<
        QueryFindManyUserAdminQuery,
        QueryFindManyUserAdminQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<QueryFindManyUserAdminQuery, QueryFindManyUserAdminQueryVariables>(
        QueryFindManyUserAdminDocument,
        options,
    );
}
export function useQueryFindManyUserAdminLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        QueryFindManyUserAdminQuery,
        QueryFindManyUserAdminQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<QueryFindManyUserAdminQuery, QueryFindManyUserAdminQueryVariables>(
        QueryFindManyUserAdminDocument,
        options,
    );
}
export type QueryFindManyUserAdminQueryHookResult = ReturnType<
    typeof useQueryFindManyUserAdminQuery
>;
export type QueryFindManyUserAdminLazyQueryHookResult = ReturnType<
    typeof useQueryFindManyUserAdminLazyQuery
>;
export type QueryFindManyUserAdminQueryResult = Apollo.QueryResult<
    QueryFindManyUserAdminQuery,
    QueryFindManyUserAdminQueryVariables
>;
export const QueryCountUserAdminDocument = gql`
    query queryCountUserAdmin($input: CountUserAdminInput!) {
        countUserAdmin(input: $input)
    }
`;

/**
 * __useQueryCountUserAdminQuery__
 *
 * To run a query within a React component, call `useQueryCountUserAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryCountUserAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryCountUserAdminQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useQueryCountUserAdminQuery(
    baseOptions: Apollo.QueryHookOptions<
        QueryCountUserAdminQuery,
        QueryCountUserAdminQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<QueryCountUserAdminQuery, QueryCountUserAdminQueryVariables>(
        QueryCountUserAdminDocument,
        options,
    );
}
export function useQueryCountUserAdminLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        QueryCountUserAdminQuery,
        QueryCountUserAdminQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<QueryCountUserAdminQuery, QueryCountUserAdminQueryVariables>(
        QueryCountUserAdminDocument,
        options,
    );
}
export type QueryCountUserAdminQueryHookResult = ReturnType<typeof useQueryCountUserAdminQuery>;
export type QueryCountUserAdminLazyQueryHookResult = ReturnType<
    typeof useQueryCountUserAdminLazyQuery
>;
export type QueryCountUserAdminQueryResult = Apollo.QueryResult<
    QueryCountUserAdminQuery,
    QueryCountUserAdminQueryVariables
>;
export const QuerySearchCertificateDocument = gql`
    query querySearchCertificate($input: SearchCertificateAdminInput!) {
        searchCertificateAdmin(input: $input) {
            ...CertificateParts
        }
    }
    ${CertificatePartsFragmentDoc}
`;

/**
 * __useQuerySearchCertificateQuery__
 *
 * To run a query within a React component, call `useQuerySearchCertificateQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuerySearchCertificateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuerySearchCertificateQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useQuerySearchCertificateQuery(
    baseOptions: Apollo.QueryHookOptions<
        QuerySearchCertificateQuery,
        QuerySearchCertificateQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<QuerySearchCertificateQuery, QuerySearchCertificateQueryVariables>(
        QuerySearchCertificateDocument,
        options,
    );
}
export function useQuerySearchCertificateLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        QuerySearchCertificateQuery,
        QuerySearchCertificateQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<QuerySearchCertificateQuery, QuerySearchCertificateQueryVariables>(
        QuerySearchCertificateDocument,
        options,
    );
}
export type QuerySearchCertificateQueryHookResult = ReturnType<
    typeof useQuerySearchCertificateQuery
>;
export type QuerySearchCertificateLazyQueryHookResult = ReturnType<
    typeof useQuerySearchCertificateLazyQuery
>;
export type QuerySearchCertificateQueryResult = Apollo.QueryResult<
    QuerySearchCertificateQuery,
    QuerySearchCertificateQueryVariables
>;
export const QueryFindFirstUserAdminDocument = gql`
    query queryFindFirstUserAdmin($input: FindFirstUserAdminInput!) {
        findFirstUserAdmin(input: $input) {
            ...UserParts
            certificates {
                ...CertificateParts
            }
            domains {
                ...DomainParts
            }
            hostings {
                ...HostingParts
            }
            emails {
                ...EmailParts
            }
            company {
                ...CompanyParts
            }
        }
    }
    ${UserPartsFragmentDoc}
    ${CertificatePartsFragmentDoc}
    ${DomainPartsFragmentDoc}
    ${HostingPartsFragmentDoc}
    ${EmailPartsFragmentDoc}
    ${CompanyPartsFragmentDoc}
`;

/**
 * __useQueryFindFirstUserAdminQuery__
 *
 * To run a query within a React component, call `useQueryFindFirstUserAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryFindFirstUserAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryFindFirstUserAdminQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useQueryFindFirstUserAdminQuery(
    baseOptions: Apollo.QueryHookOptions<
        QueryFindFirstUserAdminQuery,
        QueryFindFirstUserAdminQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<QueryFindFirstUserAdminQuery, QueryFindFirstUserAdminQueryVariables>(
        QueryFindFirstUserAdminDocument,
        options,
    );
}
export function useQueryFindFirstUserAdminLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        QueryFindFirstUserAdminQuery,
        QueryFindFirstUserAdminQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<QueryFindFirstUserAdminQuery, QueryFindFirstUserAdminQueryVariables>(
        QueryFindFirstUserAdminDocument,
        options,
    );
}
export type QueryFindFirstUserAdminQueryHookResult = ReturnType<
    typeof useQueryFindFirstUserAdminQuery
>;
export type QueryFindFirstUserAdminLazyQueryHookResult = ReturnType<
    typeof useQueryFindFirstUserAdminLazyQuery
>;
export type QueryFindFirstUserAdminQueryResult = Apollo.QueryResult<
    QueryFindFirstUserAdminQuery,
    QueryFindFirstUserAdminQueryVariables
>;
export const QueryFindManyPaymentForCertificateAdminDocument = gql`
    query queryFindManyPaymentForCertificateAdmin($input: FindManyPaymentForCertificateAdmin!) {
        findManyPaymentForCertificateAdmin(input: $input) {
            ...PaymentParts
            certificateId
            depositor
        }
    }
    ${PaymentPartsFragmentDoc}
`;

/**
 * __useQueryFindManyPaymentForCertificateAdminQuery__
 *
 * To run a query within a React component, call `useQueryFindManyPaymentForCertificateAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryFindManyPaymentForCertificateAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryFindManyPaymentForCertificateAdminQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useQueryFindManyPaymentForCertificateAdminQuery(
    baseOptions: Apollo.QueryHookOptions<
        QueryFindManyPaymentForCertificateAdminQuery,
        QueryFindManyPaymentForCertificateAdminQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        QueryFindManyPaymentForCertificateAdminQuery,
        QueryFindManyPaymentForCertificateAdminQueryVariables
    >(QueryFindManyPaymentForCertificateAdminDocument, options);
}
export function useQueryFindManyPaymentForCertificateAdminLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        QueryFindManyPaymentForCertificateAdminQuery,
        QueryFindManyPaymentForCertificateAdminQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        QueryFindManyPaymentForCertificateAdminQuery,
        QueryFindManyPaymentForCertificateAdminQueryVariables
    >(QueryFindManyPaymentForCertificateAdminDocument, options);
}
export type QueryFindManyPaymentForCertificateAdminQueryHookResult = ReturnType<
    typeof useQueryFindManyPaymentForCertificateAdminQuery
>;
export type QueryFindManyPaymentForCertificateAdminLazyQueryHookResult = ReturnType<
    typeof useQueryFindManyPaymentForCertificateAdminLazyQuery
>;
export type QueryFindManyPaymentForCertificateAdminQueryResult = Apollo.QueryResult<
    QueryFindManyPaymentForCertificateAdminQuery,
    QueryFindManyPaymentForCertificateAdminQueryVariables
>;
export const QueryCountPaymentForCertificateAdminDocument = gql`
    query queryCountPaymentForCertificateAdmin($input: CountPaymentForCertificateAdminInput!) {
        countPaymentForCertificateAdmin(input: $input)
    }
`;

/**
 * __useQueryCountPaymentForCertificateAdminQuery__
 *
 * To run a query within a React component, call `useQueryCountPaymentForCertificateAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryCountPaymentForCertificateAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryCountPaymentForCertificateAdminQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useQueryCountPaymentForCertificateAdminQuery(
    baseOptions: Apollo.QueryHookOptions<
        QueryCountPaymentForCertificateAdminQuery,
        QueryCountPaymentForCertificateAdminQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        QueryCountPaymentForCertificateAdminQuery,
        QueryCountPaymentForCertificateAdminQueryVariables
    >(QueryCountPaymentForCertificateAdminDocument, options);
}
export function useQueryCountPaymentForCertificateAdminLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        QueryCountPaymentForCertificateAdminQuery,
        QueryCountPaymentForCertificateAdminQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        QueryCountPaymentForCertificateAdminQuery,
        QueryCountPaymentForCertificateAdminQueryVariables
    >(QueryCountPaymentForCertificateAdminDocument, options);
}
export type QueryCountPaymentForCertificateAdminQueryHookResult = ReturnType<
    typeof useQueryCountPaymentForCertificateAdminQuery
>;
export type QueryCountPaymentForCertificateAdminLazyQueryHookResult = ReturnType<
    typeof useQueryCountPaymentForCertificateAdminLazyQuery
>;
export type QueryCountPaymentForCertificateAdminQueryResult = Apollo.QueryResult<
    QueryCountPaymentForCertificateAdminQuery,
    QueryCountPaymentForCertificateAdminQueryVariables
>;
export const QueryFindFirstCompanyAdminDocument = gql`
    query queryFindFirstCompanyAdmin($input: FindFirstCompanyAdminInput!) {
        findFirstCompanyAdmin(input: $input) {
            ...CompanyParts
        }
    }
    ${CompanyPartsFragmentDoc}
`;

/**
 * __useQueryFindFirstCompanyAdminQuery__
 *
 * To run a query within a React component, call `useQueryFindFirstCompanyAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryFindFirstCompanyAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryFindFirstCompanyAdminQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useQueryFindFirstCompanyAdminQuery(
    baseOptions: Apollo.QueryHookOptions<
        QueryFindFirstCompanyAdminQuery,
        QueryFindFirstCompanyAdminQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        QueryFindFirstCompanyAdminQuery,
        QueryFindFirstCompanyAdminQueryVariables
    >(QueryFindFirstCompanyAdminDocument, options);
}
export function useQueryFindFirstCompanyAdminLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        QueryFindFirstCompanyAdminQuery,
        QueryFindFirstCompanyAdminQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        QueryFindFirstCompanyAdminQuery,
        QueryFindFirstCompanyAdminQueryVariables
    >(QueryFindFirstCompanyAdminDocument, options);
}
export type QueryFindFirstCompanyAdminQueryHookResult = ReturnType<
    typeof useQueryFindFirstCompanyAdminQuery
>;
export type QueryFindFirstCompanyAdminLazyQueryHookResult = ReturnType<
    typeof useQueryFindFirstCompanyAdminLazyQuery
>;
export type QueryFindFirstCompanyAdminQueryResult = Apollo.QueryResult<
    QueryFindFirstCompanyAdminQuery,
    QueryFindFirstCompanyAdminQueryVariables
>;
export const QueryFindFirstPaymentAdminDocument = gql`
    query queryFindFirstPaymentAdmin($input: FindFirstPaymentAdmin!) {
        findFirstPaymentAdmin(input: $input) {
            ...PaymentParts
            user {
                ...UserParts
                company {
                    ...CompanyParts
                }
            }
            certificate {
                ...CertificateParts
            }
            domain {
                ...DomainParts
            }
            hosting {
                ...HostingParts
            }
            email {
                ...EmailParts
            }
            company {
                ...CompanyParts
            }
        }
    }
    ${PaymentPartsFragmentDoc}
    ${UserPartsFragmentDoc}
    ${CompanyPartsFragmentDoc}
    ${CertificatePartsFragmentDoc}
    ${DomainPartsFragmentDoc}
    ${HostingPartsFragmentDoc}
    ${EmailPartsFragmentDoc}
`;

/**
 * __useQueryFindFirstPaymentAdminQuery__
 *
 * To run a query within a React component, call `useQueryFindFirstPaymentAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryFindFirstPaymentAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryFindFirstPaymentAdminQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useQueryFindFirstPaymentAdminQuery(
    baseOptions: Apollo.QueryHookOptions<
        QueryFindFirstPaymentAdminQuery,
        QueryFindFirstPaymentAdminQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        QueryFindFirstPaymentAdminQuery,
        QueryFindFirstPaymentAdminQueryVariables
    >(QueryFindFirstPaymentAdminDocument, options);
}
export function useQueryFindFirstPaymentAdminLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        QueryFindFirstPaymentAdminQuery,
        QueryFindFirstPaymentAdminQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        QueryFindFirstPaymentAdminQuery,
        QueryFindFirstPaymentAdminQueryVariables
    >(QueryFindFirstPaymentAdminDocument, options);
}
export type QueryFindFirstPaymentAdminQueryHookResult = ReturnType<
    typeof useQueryFindFirstPaymentAdminQuery
>;
export type QueryFindFirstPaymentAdminLazyQueryHookResult = ReturnType<
    typeof useQueryFindFirstPaymentAdminLazyQuery
>;
export type QueryFindFirstPaymentAdminQueryResult = Apollo.QueryResult<
    QueryFindFirstPaymentAdminQuery,
    QueryFindFirstPaymentAdminQueryVariables
>;
export const QueryMakeCsrDocument = gql`
    query queryMakeCSR($input: MakeCSRInput!) {
        makeCSR(input: $input)
    }
`;

/**
 * __useQueryMakeCsrQuery__
 *
 * To run a query within a React component, call `useQueryMakeCsrQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryMakeCsrQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryMakeCsrQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useQueryMakeCsrQuery(
    baseOptions: Apollo.QueryHookOptions<QueryMakeCsrQuery, QueryMakeCsrQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<QueryMakeCsrQuery, QueryMakeCsrQueryVariables>(
        QueryMakeCsrDocument,
        options,
    );
}
export function useQueryMakeCsrLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<QueryMakeCsrQuery, QueryMakeCsrQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<QueryMakeCsrQuery, QueryMakeCsrQueryVariables>(
        QueryMakeCsrDocument,
        options,
    );
}
export type QueryMakeCsrQueryHookResult = ReturnType<typeof useQueryMakeCsrQuery>;
export type QueryMakeCsrLazyQueryHookResult = ReturnType<typeof useQueryMakeCsrLazyQuery>;
export type QueryMakeCsrQueryResult = Apollo.QueryResult<
    QueryMakeCsrQuery,
    QueryMakeCsrQueryVariables
>;
export const QueryFindManyDomainAdminDocument = gql`
    query queryFindManyDomainAdmin($input: FindManyDomainAdminInput!) {
        findManyDomainAdmin(input: $input) {
            ...DomainParts
        }
    }
    ${DomainPartsFragmentDoc}
`;

/**
 * __useQueryFindManyDomainAdminQuery__
 *
 * To run a query within a React component, call `useQueryFindManyDomainAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryFindManyDomainAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryFindManyDomainAdminQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useQueryFindManyDomainAdminQuery(
    baseOptions: Apollo.QueryHookOptions<
        QueryFindManyDomainAdminQuery,
        QueryFindManyDomainAdminQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<QueryFindManyDomainAdminQuery, QueryFindManyDomainAdminQueryVariables>(
        QueryFindManyDomainAdminDocument,
        options,
    );
}
export function useQueryFindManyDomainAdminLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        QueryFindManyDomainAdminQuery,
        QueryFindManyDomainAdminQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        QueryFindManyDomainAdminQuery,
        QueryFindManyDomainAdminQueryVariables
    >(QueryFindManyDomainAdminDocument, options);
}
export type QueryFindManyDomainAdminQueryHookResult = ReturnType<
    typeof useQueryFindManyDomainAdminQuery
>;
export type QueryFindManyDomainAdminLazyQueryHookResult = ReturnType<
    typeof useQueryFindManyDomainAdminLazyQuery
>;
export type QueryFindManyDomainAdminQueryResult = Apollo.QueryResult<
    QueryFindManyDomainAdminQuery,
    QueryFindManyDomainAdminQueryVariables
>;
export const QueryCountDomainAdminDocument = gql`
    query queryCountDomainAdmin($input: CountDomainAdminInput!) {
        countDomainAdmin(input: $input)
    }
`;

/**
 * __useQueryCountDomainAdminQuery__
 *
 * To run a query within a React component, call `useQueryCountDomainAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryCountDomainAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryCountDomainAdminQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useQueryCountDomainAdminQuery(
    baseOptions: Apollo.QueryHookOptions<
        QueryCountDomainAdminQuery,
        QueryCountDomainAdminQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<QueryCountDomainAdminQuery, QueryCountDomainAdminQueryVariables>(
        QueryCountDomainAdminDocument,
        options,
    );
}
export function useQueryCountDomainAdminLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        QueryCountDomainAdminQuery,
        QueryCountDomainAdminQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<QueryCountDomainAdminQuery, QueryCountDomainAdminQueryVariables>(
        QueryCountDomainAdminDocument,
        options,
    );
}
export type QueryCountDomainAdminQueryHookResult = ReturnType<typeof useQueryCountDomainAdminQuery>;
export type QueryCountDomainAdminLazyQueryHookResult = ReturnType<
    typeof useQueryCountDomainAdminLazyQuery
>;
export type QueryCountDomainAdminQueryResult = Apollo.QueryResult<
    QueryCountDomainAdminQuery,
    QueryCountDomainAdminQueryVariables
>;
export const QuerySearchDomainAdminDocument = gql`
    query querySearchDomainAdmin($input: SearchDomainAdminInput!) {
        searchDomainAdmin(input: $input) {
            ...DomainParts
        }
    }
    ${DomainPartsFragmentDoc}
`;

/**
 * __useQuerySearchDomainAdminQuery__
 *
 * To run a query within a React component, call `useQuerySearchDomainAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuerySearchDomainAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuerySearchDomainAdminQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useQuerySearchDomainAdminQuery(
    baseOptions: Apollo.QueryHookOptions<
        QuerySearchDomainAdminQuery,
        QuerySearchDomainAdminQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<QuerySearchDomainAdminQuery, QuerySearchDomainAdminQueryVariables>(
        QuerySearchDomainAdminDocument,
        options,
    );
}
export function useQuerySearchDomainAdminLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        QuerySearchDomainAdminQuery,
        QuerySearchDomainAdminQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<QuerySearchDomainAdminQuery, QuerySearchDomainAdminQueryVariables>(
        QuerySearchDomainAdminDocument,
        options,
    );
}
export type QuerySearchDomainAdminQueryHookResult = ReturnType<
    typeof useQuerySearchDomainAdminQuery
>;
export type QuerySearchDomainAdminLazyQueryHookResult = ReturnType<
    typeof useQuerySearchDomainAdminLazyQuery
>;
export type QuerySearchDomainAdminQueryResult = Apollo.QueryResult<
    QuerySearchDomainAdminQuery,
    QuerySearchDomainAdminQueryVariables
>;
export const QueryFindFirstDomainAdminDocument = gql`
    query queryFindFirstDomainAdmin($input: FindFirstDomainAdminInput!) {
        findFirstDomainAdmin(input: $input) {
            ...DomainParts
            user {
                ...UserParts
                company {
                    ...CompanyParts
                }
            }
            userId
        }
    }
    ${DomainPartsFragmentDoc}
    ${UserPartsFragmentDoc}
    ${CompanyPartsFragmentDoc}
`;

/**
 * __useQueryFindFirstDomainAdminQuery__
 *
 * To run a query within a React component, call `useQueryFindFirstDomainAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryFindFirstDomainAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryFindFirstDomainAdminQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useQueryFindFirstDomainAdminQuery(
    baseOptions: Apollo.QueryHookOptions<
        QueryFindFirstDomainAdminQuery,
        QueryFindFirstDomainAdminQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<QueryFindFirstDomainAdminQuery, QueryFindFirstDomainAdminQueryVariables>(
        QueryFindFirstDomainAdminDocument,
        options,
    );
}
export function useQueryFindFirstDomainAdminLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        QueryFindFirstDomainAdminQuery,
        QueryFindFirstDomainAdminQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        QueryFindFirstDomainAdminQuery,
        QueryFindFirstDomainAdminQueryVariables
    >(QueryFindFirstDomainAdminDocument, options);
}
export type QueryFindFirstDomainAdminQueryHookResult = ReturnType<
    typeof useQueryFindFirstDomainAdminQuery
>;
export type QueryFindFirstDomainAdminLazyQueryHookResult = ReturnType<
    typeof useQueryFindFirstDomainAdminLazyQuery
>;
export type QueryFindFirstDomainAdminQueryResult = Apollo.QueryResult<
    QueryFindFirstDomainAdminQuery,
    QueryFindFirstDomainAdminQueryVariables
>;
export const QueryFindManyPaymentForDomainAdminDocument = gql`
    query queryFindManyPaymentForDomainAdmin($input: FindManyPaymentForDomainAdminInput!) {
        findManyPaymentForDomainAdmin(input: $input) {
            ...PaymentParts
            domainId
            depositor
        }
    }
    ${PaymentPartsFragmentDoc}
`;

/**
 * __useQueryFindManyPaymentForDomainAdminQuery__
 *
 * To run a query within a React component, call `useQueryFindManyPaymentForDomainAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryFindManyPaymentForDomainAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryFindManyPaymentForDomainAdminQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useQueryFindManyPaymentForDomainAdminQuery(
    baseOptions: Apollo.QueryHookOptions<
        QueryFindManyPaymentForDomainAdminQuery,
        QueryFindManyPaymentForDomainAdminQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        QueryFindManyPaymentForDomainAdminQuery,
        QueryFindManyPaymentForDomainAdminQueryVariables
    >(QueryFindManyPaymentForDomainAdminDocument, options);
}
export function useQueryFindManyPaymentForDomainAdminLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        QueryFindManyPaymentForDomainAdminQuery,
        QueryFindManyPaymentForDomainAdminQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        QueryFindManyPaymentForDomainAdminQuery,
        QueryFindManyPaymentForDomainAdminQueryVariables
    >(QueryFindManyPaymentForDomainAdminDocument, options);
}
export type QueryFindManyPaymentForDomainAdminQueryHookResult = ReturnType<
    typeof useQueryFindManyPaymentForDomainAdminQuery
>;
export type QueryFindManyPaymentForDomainAdminLazyQueryHookResult = ReturnType<
    typeof useQueryFindManyPaymentForDomainAdminLazyQuery
>;
export type QueryFindManyPaymentForDomainAdminQueryResult = Apollo.QueryResult<
    QueryFindManyPaymentForDomainAdminQuery,
    QueryFindManyPaymentForDomainAdminQueryVariables
>;
export const QueryCountPaymentForDomainAdminDocument = gql`
    query queryCountPaymentForDomainAdmin($input: CountPaymentForDomainAdminInput!) {
        countPaymentForDomainAdmin(input: $input)
    }
`;

/**
 * __useQueryCountPaymentForDomainAdminQuery__
 *
 * To run a query within a React component, call `useQueryCountPaymentForDomainAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryCountPaymentForDomainAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryCountPaymentForDomainAdminQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useQueryCountPaymentForDomainAdminQuery(
    baseOptions: Apollo.QueryHookOptions<
        QueryCountPaymentForDomainAdminQuery,
        QueryCountPaymentForDomainAdminQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        QueryCountPaymentForDomainAdminQuery,
        QueryCountPaymentForDomainAdminQueryVariables
    >(QueryCountPaymentForDomainAdminDocument, options);
}
export function useQueryCountPaymentForDomainAdminLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        QueryCountPaymentForDomainAdminQuery,
        QueryCountPaymentForDomainAdminQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        QueryCountPaymentForDomainAdminQuery,
        QueryCountPaymentForDomainAdminQueryVariables
    >(QueryCountPaymentForDomainAdminDocument, options);
}
export type QueryCountPaymentForDomainAdminQueryHookResult = ReturnType<
    typeof useQueryCountPaymentForDomainAdminQuery
>;
export type QueryCountPaymentForDomainAdminLazyQueryHookResult = ReturnType<
    typeof useQueryCountPaymentForDomainAdminLazyQuery
>;
export type QueryCountPaymentForDomainAdminQueryResult = Apollo.QueryResult<
    QueryCountPaymentForDomainAdminQuery,
    QueryCountPaymentForDomainAdminQueryVariables
>;
export const QueryFindManyHostingAdminDocument = gql`
    query queryFindManyHostingAdmin($input: FindManyHostingAdminInput!) {
        findManyHostingAdmin(input: $input) {
            ...HostingParts
        }
    }
    ${HostingPartsFragmentDoc}
`;

/**
 * __useQueryFindManyHostingAdminQuery__
 *
 * To run a query within a React component, call `useQueryFindManyHostingAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryFindManyHostingAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryFindManyHostingAdminQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useQueryFindManyHostingAdminQuery(
    baseOptions: Apollo.QueryHookOptions<
        QueryFindManyHostingAdminQuery,
        QueryFindManyHostingAdminQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<QueryFindManyHostingAdminQuery, QueryFindManyHostingAdminQueryVariables>(
        QueryFindManyHostingAdminDocument,
        options,
    );
}
export function useQueryFindManyHostingAdminLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        QueryFindManyHostingAdminQuery,
        QueryFindManyHostingAdminQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        QueryFindManyHostingAdminQuery,
        QueryFindManyHostingAdminQueryVariables
    >(QueryFindManyHostingAdminDocument, options);
}
export type QueryFindManyHostingAdminQueryHookResult = ReturnType<
    typeof useQueryFindManyHostingAdminQuery
>;
export type QueryFindManyHostingAdminLazyQueryHookResult = ReturnType<
    typeof useQueryFindManyHostingAdminLazyQuery
>;
export type QueryFindManyHostingAdminQueryResult = Apollo.QueryResult<
    QueryFindManyHostingAdminQuery,
    QueryFindManyHostingAdminQueryVariables
>;
export const QueryCountHostingAdminDocument = gql`
    query queryCountHostingAdmin($input: CountHostingAdminInput!) {
        countHostingAdmin(input: $input)
    }
`;

/**
 * __useQueryCountHostingAdminQuery__
 *
 * To run a query within a React component, call `useQueryCountHostingAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryCountHostingAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryCountHostingAdminQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useQueryCountHostingAdminQuery(
    baseOptions: Apollo.QueryHookOptions<
        QueryCountHostingAdminQuery,
        QueryCountHostingAdminQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<QueryCountHostingAdminQuery, QueryCountHostingAdminQueryVariables>(
        QueryCountHostingAdminDocument,
        options,
    );
}
export function useQueryCountHostingAdminLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        QueryCountHostingAdminQuery,
        QueryCountHostingAdminQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<QueryCountHostingAdminQuery, QueryCountHostingAdminQueryVariables>(
        QueryCountHostingAdminDocument,
        options,
    );
}
export type QueryCountHostingAdminQueryHookResult = ReturnType<
    typeof useQueryCountHostingAdminQuery
>;
export type QueryCountHostingAdminLazyQueryHookResult = ReturnType<
    typeof useQueryCountHostingAdminLazyQuery
>;
export type QueryCountHostingAdminQueryResult = Apollo.QueryResult<
    QueryCountHostingAdminQuery,
    QueryCountHostingAdminQueryVariables
>;
export const QuerySearchHostingAdminDocument = gql`
    query querySearchHostingAdmin($input: SearchHostingAdminInput!) {
        searchHostingAdmin(input: $input) {
            ...HostingParts
        }
    }
    ${HostingPartsFragmentDoc}
`;

/**
 * __useQuerySearchHostingAdminQuery__
 *
 * To run a query within a React component, call `useQuerySearchHostingAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuerySearchHostingAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuerySearchHostingAdminQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useQuerySearchHostingAdminQuery(
    baseOptions: Apollo.QueryHookOptions<
        QuerySearchHostingAdminQuery,
        QuerySearchHostingAdminQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<QuerySearchHostingAdminQuery, QuerySearchHostingAdminQueryVariables>(
        QuerySearchHostingAdminDocument,
        options,
    );
}
export function useQuerySearchHostingAdminLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        QuerySearchHostingAdminQuery,
        QuerySearchHostingAdminQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<QuerySearchHostingAdminQuery, QuerySearchHostingAdminQueryVariables>(
        QuerySearchHostingAdminDocument,
        options,
    );
}
export type QuerySearchHostingAdminQueryHookResult = ReturnType<
    typeof useQuerySearchHostingAdminQuery
>;
export type QuerySearchHostingAdminLazyQueryHookResult = ReturnType<
    typeof useQuerySearchHostingAdminLazyQuery
>;
export type QuerySearchHostingAdminQueryResult = Apollo.QueryResult<
    QuerySearchHostingAdminQuery,
    QuerySearchHostingAdminQueryVariables
>;
export const QueryFindFirstHostingAdminDocument = gql`
    query queryFindFirstHostingAdmin($input: FindFirstHostingAdminInput!) {
        findFirstHostingAdmin(input: $input) {
            ...HostingParts
            user {
                ...UserParts
                company {
                    ...CompanyParts
                }
            }
            hostingInfo {
                ...HostingInfoParts
            }
            userId
        }
    }
    ${HostingPartsFragmentDoc}
    ${UserPartsFragmentDoc}
    ${CompanyPartsFragmentDoc}
    ${HostingInfoPartsFragmentDoc}
`;

/**
 * __useQueryFindFirstHostingAdminQuery__
 *
 * To run a query within a React component, call `useQueryFindFirstHostingAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryFindFirstHostingAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryFindFirstHostingAdminQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useQueryFindFirstHostingAdminQuery(
    baseOptions: Apollo.QueryHookOptions<
        QueryFindFirstHostingAdminQuery,
        QueryFindFirstHostingAdminQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        QueryFindFirstHostingAdminQuery,
        QueryFindFirstHostingAdminQueryVariables
    >(QueryFindFirstHostingAdminDocument, options);
}
export function useQueryFindFirstHostingAdminLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        QueryFindFirstHostingAdminQuery,
        QueryFindFirstHostingAdminQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        QueryFindFirstHostingAdminQuery,
        QueryFindFirstHostingAdminQueryVariables
    >(QueryFindFirstHostingAdminDocument, options);
}
export type QueryFindFirstHostingAdminQueryHookResult = ReturnType<
    typeof useQueryFindFirstHostingAdminQuery
>;
export type QueryFindFirstHostingAdminLazyQueryHookResult = ReturnType<
    typeof useQueryFindFirstHostingAdminLazyQuery
>;
export type QueryFindFirstHostingAdminQueryResult = Apollo.QueryResult<
    QueryFindFirstHostingAdminQuery,
    QueryFindFirstHostingAdminQueryVariables
>;
export const QueryFindManyPaymentForHostingAdminDocument = gql`
    query queryFindManyPaymentForHostingAdmin($input: FindManyPaymentForHostingAdminInput!) {
        findManyPaymentForHostingAdmin(input: $input) {
            ...PaymentParts
            hostingId
            depositor
        }
    }
    ${PaymentPartsFragmentDoc}
`;

/**
 * __useQueryFindManyPaymentForHostingAdminQuery__
 *
 * To run a query within a React component, call `useQueryFindManyPaymentForHostingAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryFindManyPaymentForHostingAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryFindManyPaymentForHostingAdminQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useQueryFindManyPaymentForHostingAdminQuery(
    baseOptions: Apollo.QueryHookOptions<
        QueryFindManyPaymentForHostingAdminQuery,
        QueryFindManyPaymentForHostingAdminQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        QueryFindManyPaymentForHostingAdminQuery,
        QueryFindManyPaymentForHostingAdminQueryVariables
    >(QueryFindManyPaymentForHostingAdminDocument, options);
}
export function useQueryFindManyPaymentForHostingAdminLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        QueryFindManyPaymentForHostingAdminQuery,
        QueryFindManyPaymentForHostingAdminQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        QueryFindManyPaymentForHostingAdminQuery,
        QueryFindManyPaymentForHostingAdminQueryVariables
    >(QueryFindManyPaymentForHostingAdminDocument, options);
}
export type QueryFindManyPaymentForHostingAdminQueryHookResult = ReturnType<
    typeof useQueryFindManyPaymentForHostingAdminQuery
>;
export type QueryFindManyPaymentForHostingAdminLazyQueryHookResult = ReturnType<
    typeof useQueryFindManyPaymentForHostingAdminLazyQuery
>;
export type QueryFindManyPaymentForHostingAdminQueryResult = Apollo.QueryResult<
    QueryFindManyPaymentForHostingAdminQuery,
    QueryFindManyPaymentForHostingAdminQueryVariables
>;
export const QueryCountPaymentForHostingAdminDocument = gql`
    query queryCountPaymentForHostingAdmin($input: CountPaymentForHostingAdminInput!) {
        countPaymentForHostingAdmin(input: $input)
    }
`;

/**
 * __useQueryCountPaymentForHostingAdminQuery__
 *
 * To run a query within a React component, call `useQueryCountPaymentForHostingAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryCountPaymentForHostingAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryCountPaymentForHostingAdminQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useQueryCountPaymentForHostingAdminQuery(
    baseOptions: Apollo.QueryHookOptions<
        QueryCountPaymentForHostingAdminQuery,
        QueryCountPaymentForHostingAdminQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        QueryCountPaymentForHostingAdminQuery,
        QueryCountPaymentForHostingAdminQueryVariables
    >(QueryCountPaymentForHostingAdminDocument, options);
}
export function useQueryCountPaymentForHostingAdminLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        QueryCountPaymentForHostingAdminQuery,
        QueryCountPaymentForHostingAdminQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        QueryCountPaymentForHostingAdminQuery,
        QueryCountPaymentForHostingAdminQueryVariables
    >(QueryCountPaymentForHostingAdminDocument, options);
}
export type QueryCountPaymentForHostingAdminQueryHookResult = ReturnType<
    typeof useQueryCountPaymentForHostingAdminQuery
>;
export type QueryCountPaymentForHostingAdminLazyQueryHookResult = ReturnType<
    typeof useQueryCountPaymentForHostingAdminLazyQuery
>;
export type QueryCountPaymentForHostingAdminQueryResult = Apollo.QueryResult<
    QueryCountPaymentForHostingAdminQuery,
    QueryCountPaymentForHostingAdminQueryVariables
>;
export const QueryFindManyPaymentAdminDocument = gql`
    query queryFindManyPaymentAdmin($input: FindManyPaymentAdmin!) {
        findManyPaymentAdmin(input: $input) {
            ...PaymentParts
            domain {
                ...DomainParts
            }
            hosting {
                ...HostingParts
            }
            certificate {
                ...CertificateParts
            }
            email {
                ...EmailParts
            }
            user {
                ...UserParts
            }
        }
    }
    ${PaymentPartsFragmentDoc}
    ${DomainPartsFragmentDoc}
    ${HostingPartsFragmentDoc}
    ${CertificatePartsFragmentDoc}
    ${EmailPartsFragmentDoc}
    ${UserPartsFragmentDoc}
`;

/**
 * __useQueryFindManyPaymentAdminQuery__
 *
 * To run a query within a React component, call `useQueryFindManyPaymentAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryFindManyPaymentAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryFindManyPaymentAdminQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useQueryFindManyPaymentAdminQuery(
    baseOptions: Apollo.QueryHookOptions<
        QueryFindManyPaymentAdminQuery,
        QueryFindManyPaymentAdminQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<QueryFindManyPaymentAdminQuery, QueryFindManyPaymentAdminQueryVariables>(
        QueryFindManyPaymentAdminDocument,
        options,
    );
}
export function useQueryFindManyPaymentAdminLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        QueryFindManyPaymentAdminQuery,
        QueryFindManyPaymentAdminQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        QueryFindManyPaymentAdminQuery,
        QueryFindManyPaymentAdminQueryVariables
    >(QueryFindManyPaymentAdminDocument, options);
}
export type QueryFindManyPaymentAdminQueryHookResult = ReturnType<
    typeof useQueryFindManyPaymentAdminQuery
>;
export type QueryFindManyPaymentAdminLazyQueryHookResult = ReturnType<
    typeof useQueryFindManyPaymentAdminLazyQuery
>;
export type QueryFindManyPaymentAdminQueryResult = Apollo.QueryResult<
    QueryFindManyPaymentAdminQuery,
    QueryFindManyPaymentAdminQueryVariables
>;
export const QuerySummaryPaymentAdminDocument = gql`
    query querySummaryPaymentAdmin($input: SummaryPaymentAdminInput!) {
        summaryPaymentAdmin(input: $input) {
            checkOut {
                ...SummaryPaymentSubParts
            }
            cash {
                ...SummaryPaymentSubParts
            }
            total {
                ...SummaryPaymentSubParts
            }
        }
    }
    ${SummaryPaymentSubPartsFragmentDoc}
`;

/**
 * __useQuerySummaryPaymentAdminQuery__
 *
 * To run a query within a React component, call `useQuerySummaryPaymentAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuerySummaryPaymentAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuerySummaryPaymentAdminQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useQuerySummaryPaymentAdminQuery(
    baseOptions: Apollo.QueryHookOptions<
        QuerySummaryPaymentAdminQuery,
        QuerySummaryPaymentAdminQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<QuerySummaryPaymentAdminQuery, QuerySummaryPaymentAdminQueryVariables>(
        QuerySummaryPaymentAdminDocument,
        options,
    );
}
export function useQuerySummaryPaymentAdminLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        QuerySummaryPaymentAdminQuery,
        QuerySummaryPaymentAdminQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        QuerySummaryPaymentAdminQuery,
        QuerySummaryPaymentAdminQueryVariables
    >(QuerySummaryPaymentAdminDocument, options);
}
export type QuerySummaryPaymentAdminQueryHookResult = ReturnType<
    typeof useQuerySummaryPaymentAdminQuery
>;
export type QuerySummaryPaymentAdminLazyQueryHookResult = ReturnType<
    typeof useQuerySummaryPaymentAdminLazyQuery
>;
export type QuerySummaryPaymentAdminQueryResult = Apollo.QueryResult<
    QuerySummaryPaymentAdminQuery,
    QuerySummaryPaymentAdminQueryVariables
>;
export const QueryFindManyRequestAdminDocument = gql`
    query queryFindManyRequestAdmin($input: FindManyRequestAdminInput!) {
        findManyRequestAdmin(input: $input) {
            ...RequestParts
        }
    }
    ${RequestPartsFragmentDoc}
`;

/**
 * __useQueryFindManyRequestAdminQuery__
 *
 * To run a query within a React component, call `useQueryFindManyRequestAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryFindManyRequestAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryFindManyRequestAdminQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useQueryFindManyRequestAdminQuery(
    baseOptions: Apollo.QueryHookOptions<
        QueryFindManyRequestAdminQuery,
        QueryFindManyRequestAdminQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<QueryFindManyRequestAdminQuery, QueryFindManyRequestAdminQueryVariables>(
        QueryFindManyRequestAdminDocument,
        options,
    );
}
export function useQueryFindManyRequestAdminLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        QueryFindManyRequestAdminQuery,
        QueryFindManyRequestAdminQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        QueryFindManyRequestAdminQuery,
        QueryFindManyRequestAdminQueryVariables
    >(QueryFindManyRequestAdminDocument, options);
}
export type QueryFindManyRequestAdminQueryHookResult = ReturnType<
    typeof useQueryFindManyRequestAdminQuery
>;
export type QueryFindManyRequestAdminLazyQueryHookResult = ReturnType<
    typeof useQueryFindManyRequestAdminLazyQuery
>;
export type QueryFindManyRequestAdminQueryResult = Apollo.QueryResult<
    QueryFindManyRequestAdminQuery,
    QueryFindManyRequestAdminQueryVariables
>;
export const QueryCountRequestAdminDocument = gql`
    query queryCountRequestAdmin($input: CountRequestAdminInput!) {
        countRequestAdmin(input: $input)
    }
`;

/**
 * __useQueryCountRequestAdminQuery__
 *
 * To run a query within a React component, call `useQueryCountRequestAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryCountRequestAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryCountRequestAdminQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useQueryCountRequestAdminQuery(
    baseOptions: Apollo.QueryHookOptions<
        QueryCountRequestAdminQuery,
        QueryCountRequestAdminQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<QueryCountRequestAdminQuery, QueryCountRequestAdminQueryVariables>(
        QueryCountRequestAdminDocument,
        options,
    );
}
export function useQueryCountRequestAdminLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        QueryCountRequestAdminQuery,
        QueryCountRequestAdminQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<QueryCountRequestAdminQuery, QueryCountRequestAdminQueryVariables>(
        QueryCountRequestAdminDocument,
        options,
    );
}
export type QueryCountRequestAdminQueryHookResult = ReturnType<
    typeof useQueryCountRequestAdminQuery
>;
export type QueryCountRequestAdminLazyQueryHookResult = ReturnType<
    typeof useQueryCountRequestAdminLazyQuery
>;
export type QueryCountRequestAdminQueryResult = Apollo.QueryResult<
    QueryCountRequestAdminQuery,
    QueryCountRequestAdminQueryVariables
>;
export const QueryFindManyQuestionForAllAdminDocument = gql`
    query queryFindManyQuestionForAllAdmin($input: FindManyQuestionForAllAdminInput!) {
        findManyQuestionForAllAdmin(input: $input) {
            ...QuestionParts
        }
    }
    ${QuestionPartsFragmentDoc}
`;

/**
 * __useQueryFindManyQuestionForAllAdminQuery__
 *
 * To run a query within a React component, call `useQueryFindManyQuestionForAllAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryFindManyQuestionForAllAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryFindManyQuestionForAllAdminQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useQueryFindManyQuestionForAllAdminQuery(
    baseOptions: Apollo.QueryHookOptions<
        QueryFindManyQuestionForAllAdminQuery,
        QueryFindManyQuestionForAllAdminQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        QueryFindManyQuestionForAllAdminQuery,
        QueryFindManyQuestionForAllAdminQueryVariables
    >(QueryFindManyQuestionForAllAdminDocument, options);
}
export function useQueryFindManyQuestionForAllAdminLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        QueryFindManyQuestionForAllAdminQuery,
        QueryFindManyQuestionForAllAdminQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        QueryFindManyQuestionForAllAdminQuery,
        QueryFindManyQuestionForAllAdminQueryVariables
    >(QueryFindManyQuestionForAllAdminDocument, options);
}
export type QueryFindManyQuestionForAllAdminQueryHookResult = ReturnType<
    typeof useQueryFindManyQuestionForAllAdminQuery
>;
export type QueryFindManyQuestionForAllAdminLazyQueryHookResult = ReturnType<
    typeof useQueryFindManyQuestionForAllAdminLazyQuery
>;
export type QueryFindManyQuestionForAllAdminQueryResult = Apollo.QueryResult<
    QueryFindManyQuestionForAllAdminQuery,
    QueryFindManyQuestionForAllAdminQueryVariables
>;
export const QueryCountQuestionForAllAdminDocument = gql`
    query queryCountQuestionForAllAdmin($input: CountQuestionForAllAdminInput!) {
        countQuestionForAllAdmin(input: $input)
    }
`;

/**
 * __useQueryCountQuestionForAllAdminQuery__
 *
 * To run a query within a React component, call `useQueryCountQuestionForAllAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryCountQuestionForAllAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryCountQuestionForAllAdminQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useQueryCountQuestionForAllAdminQuery(
    baseOptions: Apollo.QueryHookOptions<
        QueryCountQuestionForAllAdminQuery,
        QueryCountQuestionForAllAdminQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        QueryCountQuestionForAllAdminQuery,
        QueryCountQuestionForAllAdminQueryVariables
    >(QueryCountQuestionForAllAdminDocument, options);
}
export function useQueryCountQuestionForAllAdminLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        QueryCountQuestionForAllAdminQuery,
        QueryCountQuestionForAllAdminQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        QueryCountQuestionForAllAdminQuery,
        QueryCountQuestionForAllAdminQueryVariables
    >(QueryCountQuestionForAllAdminDocument, options);
}
export type QueryCountQuestionForAllAdminQueryHookResult = ReturnType<
    typeof useQueryCountQuestionForAllAdminQuery
>;
export type QueryCountQuestionForAllAdminLazyQueryHookResult = ReturnType<
    typeof useQueryCountQuestionForAllAdminLazyQuery
>;
export type QueryCountQuestionForAllAdminQueryResult = Apollo.QueryResult<
    QueryCountQuestionForAllAdminQuery,
    QueryCountQuestionForAllAdminQueryVariables
>;
export const QueryFindManyPaymentForUserAdminDocument = gql`
    query queryFindManyPaymentForUserAdmin($input: FindManyPaymentForUserAdminInput!) {
        findManyPaymentForUserAdmin(input: $input) {
            ...PaymentParts
            domainId
            hostingId
            certificateId
            designRequestId
            smsId
        }
    }
    ${PaymentPartsFragmentDoc}
`;

/**
 * __useQueryFindManyPaymentForUserAdminQuery__
 *
 * To run a query within a React component, call `useQueryFindManyPaymentForUserAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryFindManyPaymentForUserAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryFindManyPaymentForUserAdminQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useQueryFindManyPaymentForUserAdminQuery(
    baseOptions: Apollo.QueryHookOptions<
        QueryFindManyPaymentForUserAdminQuery,
        QueryFindManyPaymentForUserAdminQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        QueryFindManyPaymentForUserAdminQuery,
        QueryFindManyPaymentForUserAdminQueryVariables
    >(QueryFindManyPaymentForUserAdminDocument, options);
}
export function useQueryFindManyPaymentForUserAdminLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        QueryFindManyPaymentForUserAdminQuery,
        QueryFindManyPaymentForUserAdminQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        QueryFindManyPaymentForUserAdminQuery,
        QueryFindManyPaymentForUserAdminQueryVariables
    >(QueryFindManyPaymentForUserAdminDocument, options);
}
export type QueryFindManyPaymentForUserAdminQueryHookResult = ReturnType<
    typeof useQueryFindManyPaymentForUserAdminQuery
>;
export type QueryFindManyPaymentForUserAdminLazyQueryHookResult = ReturnType<
    typeof useQueryFindManyPaymentForUserAdminLazyQuery
>;
export type QueryFindManyPaymentForUserAdminQueryResult = Apollo.QueryResult<
    QueryFindManyPaymentForUserAdminQuery,
    QueryFindManyPaymentForUserAdminQueryVariables
>;
export const QueryCountPaymentForUserAdminDocument = gql`
    query queryCountPaymentForUserAdmin($input: CountPaymentForUserAdminInput!) {
        countPaymentForUserAdmin(input: $input)
    }
`;

/**
 * __useQueryCountPaymentForUserAdminQuery__
 *
 * To run a query within a React component, call `useQueryCountPaymentForUserAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryCountPaymentForUserAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryCountPaymentForUserAdminQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useQueryCountPaymentForUserAdminQuery(
    baseOptions: Apollo.QueryHookOptions<
        QueryCountPaymentForUserAdminQuery,
        QueryCountPaymentForUserAdminQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        QueryCountPaymentForUserAdminQuery,
        QueryCountPaymentForUserAdminQueryVariables
    >(QueryCountPaymentForUserAdminDocument, options);
}
export function useQueryCountPaymentForUserAdminLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        QueryCountPaymentForUserAdminQuery,
        QueryCountPaymentForUserAdminQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        QueryCountPaymentForUserAdminQuery,
        QueryCountPaymentForUserAdminQueryVariables
    >(QueryCountPaymentForUserAdminDocument, options);
}
export type QueryCountPaymentForUserAdminQueryHookResult = ReturnType<
    typeof useQueryCountPaymentForUserAdminQuery
>;
export type QueryCountPaymentForUserAdminLazyQueryHookResult = ReturnType<
    typeof useQueryCountPaymentForUserAdminLazyQuery
>;
export type QueryCountPaymentForUserAdminQueryResult = Apollo.QueryResult<
    QueryCountPaymentForUserAdminQuery,
    QueryCountPaymentForUserAdminQueryVariables
>;
export const CountPaymentForUserAdminDocument = gql`
    query countPaymentForUserAdmin($input: CountPaymentForUserAdminInput!) {
        countPaymentForUserAdmin(input: $input)
    }
`;

/**
 * __useCountPaymentForUserAdminQuery__
 *
 * To run a query within a React component, call `useCountPaymentForUserAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountPaymentForUserAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountPaymentForUserAdminQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCountPaymentForUserAdminQuery(
    baseOptions: Apollo.QueryHookOptions<
        CountPaymentForUserAdminQuery,
        CountPaymentForUserAdminQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<CountPaymentForUserAdminQuery, CountPaymentForUserAdminQueryVariables>(
        CountPaymentForUserAdminDocument,
        options,
    );
}
export function useCountPaymentForUserAdminLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        CountPaymentForUserAdminQuery,
        CountPaymentForUserAdminQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        CountPaymentForUserAdminQuery,
        CountPaymentForUserAdminQueryVariables
    >(CountPaymentForUserAdminDocument, options);
}
export type CountPaymentForUserAdminQueryHookResult = ReturnType<
    typeof useCountPaymentForUserAdminQuery
>;
export type CountPaymentForUserAdminLazyQueryHookResult = ReturnType<
    typeof useCountPaymentForUserAdminLazyQuery
>;
export type CountPaymentForUserAdminQueryResult = Apollo.QueryResult<
    CountPaymentForUserAdminQuery,
    CountPaymentForUserAdminQueryVariables
>;
export const QueryFindManyPaymentRequestAdminDocument = gql`
    query queryFindManyPaymentRequestAdmin {
        findManyPaymentRequestAdmin {
            ...PaymentRequestParts
        }
    }
    ${PaymentRequestPartsFragmentDoc}
`;

/**
 * __useQueryFindManyPaymentRequestAdminQuery__
 *
 * To run a query within a React component, call `useQueryFindManyPaymentRequestAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryFindManyPaymentRequestAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryFindManyPaymentRequestAdminQuery({
 *   variables: {
 *   },
 * });
 */
export function useQueryFindManyPaymentRequestAdminQuery(
    baseOptions?: Apollo.QueryHookOptions<
        QueryFindManyPaymentRequestAdminQuery,
        QueryFindManyPaymentRequestAdminQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        QueryFindManyPaymentRequestAdminQuery,
        QueryFindManyPaymentRequestAdminQueryVariables
    >(QueryFindManyPaymentRequestAdminDocument, options);
}
export function useQueryFindManyPaymentRequestAdminLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        QueryFindManyPaymentRequestAdminQuery,
        QueryFindManyPaymentRequestAdminQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        QueryFindManyPaymentRequestAdminQuery,
        QueryFindManyPaymentRequestAdminQueryVariables
    >(QueryFindManyPaymentRequestAdminDocument, options);
}
export type QueryFindManyPaymentRequestAdminQueryHookResult = ReturnType<
    typeof useQueryFindManyPaymentRequestAdminQuery
>;
export type QueryFindManyPaymentRequestAdminLazyQueryHookResult = ReturnType<
    typeof useQueryFindManyPaymentRequestAdminLazyQuery
>;
export type QueryFindManyPaymentRequestAdminQueryResult = Apollo.QueryResult<
    QueryFindManyPaymentRequestAdminQuery,
    QueryFindManyPaymentRequestAdminQueryVariables
>;
export const QuerySearchEmailAdminDocument = gql`
    query querySearchEmailAdmin($input: SearchEmailAdminInput!) {
        searchEmailAdmin(input: $input) {
            ...EmailParts
        }
    }
    ${EmailPartsFragmentDoc}
`;

/**
 * __useQuerySearchEmailAdminQuery__
 *
 * To run a query within a React component, call `useQuerySearchEmailAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuerySearchEmailAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuerySearchEmailAdminQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useQuerySearchEmailAdminQuery(
    baseOptions: Apollo.QueryHookOptions<
        QuerySearchEmailAdminQuery,
        QuerySearchEmailAdminQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<QuerySearchEmailAdminQuery, QuerySearchEmailAdminQueryVariables>(
        QuerySearchEmailAdminDocument,
        options,
    );
}
export function useQuerySearchEmailAdminLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        QuerySearchEmailAdminQuery,
        QuerySearchEmailAdminQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<QuerySearchEmailAdminQuery, QuerySearchEmailAdminQueryVariables>(
        QuerySearchEmailAdminDocument,
        options,
    );
}
export type QuerySearchEmailAdminQueryHookResult = ReturnType<typeof useQuerySearchEmailAdminQuery>;
export type QuerySearchEmailAdminLazyQueryHookResult = ReturnType<
    typeof useQuerySearchEmailAdminLazyQuery
>;
export type QuerySearchEmailAdminQueryResult = Apollo.QueryResult<
    QuerySearchEmailAdminQuery,
    QuerySearchEmailAdminQueryVariables
>;
export const QueryFindManyEmailAdminDocument = gql`
    query queryFindManyEmailAdmin($input: FindManyEmailAdminInput!) {
        findManyEmailAdmin(input: $input) {
            ...EmailParts
            emailAccount {
                ...EmailAccountParts
            }
        }
    }
    ${EmailPartsFragmentDoc}
    ${EmailAccountPartsFragmentDoc}
`;

/**
 * __useQueryFindManyEmailAdminQuery__
 *
 * To run a query within a React component, call `useQueryFindManyEmailAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryFindManyEmailAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryFindManyEmailAdminQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useQueryFindManyEmailAdminQuery(
    baseOptions: Apollo.QueryHookOptions<
        QueryFindManyEmailAdminQuery,
        QueryFindManyEmailAdminQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<QueryFindManyEmailAdminQuery, QueryFindManyEmailAdminQueryVariables>(
        QueryFindManyEmailAdminDocument,
        options,
    );
}
export function useQueryFindManyEmailAdminLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        QueryFindManyEmailAdminQuery,
        QueryFindManyEmailAdminQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<QueryFindManyEmailAdminQuery, QueryFindManyEmailAdminQueryVariables>(
        QueryFindManyEmailAdminDocument,
        options,
    );
}
export type QueryFindManyEmailAdminQueryHookResult = ReturnType<
    typeof useQueryFindManyEmailAdminQuery
>;
export type QueryFindManyEmailAdminLazyQueryHookResult = ReturnType<
    typeof useQueryFindManyEmailAdminLazyQuery
>;
export type QueryFindManyEmailAdminQueryResult = Apollo.QueryResult<
    QueryFindManyEmailAdminQuery,
    QueryFindManyEmailAdminQueryVariables
>;
export const QueryCountEmailAdminDocument = gql`
    query queryCountEmailAdmin($input: CountEmailAdminInput!) {
        countEmailAdmin(input: $input)
    }
`;

/**
 * __useQueryCountEmailAdminQuery__
 *
 * To run a query within a React component, call `useQueryCountEmailAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryCountEmailAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryCountEmailAdminQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useQueryCountEmailAdminQuery(
    baseOptions: Apollo.QueryHookOptions<
        QueryCountEmailAdminQuery,
        QueryCountEmailAdminQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<QueryCountEmailAdminQuery, QueryCountEmailAdminQueryVariables>(
        QueryCountEmailAdminDocument,
        options,
    );
}
export function useQueryCountEmailAdminLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        QueryCountEmailAdminQuery,
        QueryCountEmailAdminQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<QueryCountEmailAdminQuery, QueryCountEmailAdminQueryVariables>(
        QueryCountEmailAdminDocument,
        options,
    );
}
export type QueryCountEmailAdminQueryHookResult = ReturnType<typeof useQueryCountEmailAdminQuery>;
export type QueryCountEmailAdminLazyQueryHookResult = ReturnType<
    typeof useQueryCountEmailAdminLazyQuery
>;
export type QueryCountEmailAdminQueryResult = Apollo.QueryResult<
    QueryCountEmailAdminQuery,
    QueryCountEmailAdminQueryVariables
>;
export const QueryFindManyPaymentForEmailAdminDocument = gql`
    query queryFindManyPaymentForEmailAdmin($input: FindManyPaymentForEmailAdminInput!) {
        findManyPaymentForEmailAdmin(input: $input) {
            ...PaymentParts
        }
    }
    ${PaymentPartsFragmentDoc}
`;

/**
 * __useQueryFindManyPaymentForEmailAdminQuery__
 *
 * To run a query within a React component, call `useQueryFindManyPaymentForEmailAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryFindManyPaymentForEmailAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryFindManyPaymentForEmailAdminQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useQueryFindManyPaymentForEmailAdminQuery(
    baseOptions: Apollo.QueryHookOptions<
        QueryFindManyPaymentForEmailAdminQuery,
        QueryFindManyPaymentForEmailAdminQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        QueryFindManyPaymentForEmailAdminQuery,
        QueryFindManyPaymentForEmailAdminQueryVariables
    >(QueryFindManyPaymentForEmailAdminDocument, options);
}
export function useQueryFindManyPaymentForEmailAdminLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        QueryFindManyPaymentForEmailAdminQuery,
        QueryFindManyPaymentForEmailAdminQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        QueryFindManyPaymentForEmailAdminQuery,
        QueryFindManyPaymentForEmailAdminQueryVariables
    >(QueryFindManyPaymentForEmailAdminDocument, options);
}
export type QueryFindManyPaymentForEmailAdminQueryHookResult = ReturnType<
    typeof useQueryFindManyPaymentForEmailAdminQuery
>;
export type QueryFindManyPaymentForEmailAdminLazyQueryHookResult = ReturnType<
    typeof useQueryFindManyPaymentForEmailAdminLazyQuery
>;
export type QueryFindManyPaymentForEmailAdminQueryResult = Apollo.QueryResult<
    QueryFindManyPaymentForEmailAdminQuery,
    QueryFindManyPaymentForEmailAdminQueryVariables
>;
export const QueryCountPaymentForEmailAdminDocument = gql`
    query queryCountPaymentForEmailAdmin($input: CountPaymentForEmailAdminInput!) {
        countPaymentForEmailAdmin(input: $input)
    }
`;

/**
 * __useQueryCountPaymentForEmailAdminQuery__
 *
 * To run a query within a React component, call `useQueryCountPaymentForEmailAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryCountPaymentForEmailAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryCountPaymentForEmailAdminQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useQueryCountPaymentForEmailAdminQuery(
    baseOptions: Apollo.QueryHookOptions<
        QueryCountPaymentForEmailAdminQuery,
        QueryCountPaymentForEmailAdminQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        QueryCountPaymentForEmailAdminQuery,
        QueryCountPaymentForEmailAdminQueryVariables
    >(QueryCountPaymentForEmailAdminDocument, options);
}
export function useQueryCountPaymentForEmailAdminLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        QueryCountPaymentForEmailAdminQuery,
        QueryCountPaymentForEmailAdminQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        QueryCountPaymentForEmailAdminQuery,
        QueryCountPaymentForEmailAdminQueryVariables
    >(QueryCountPaymentForEmailAdminDocument, options);
}
export type QueryCountPaymentForEmailAdminQueryHookResult = ReturnType<
    typeof useQueryCountPaymentForEmailAdminQuery
>;
export type QueryCountPaymentForEmailAdminLazyQueryHookResult = ReturnType<
    typeof useQueryCountPaymentForEmailAdminLazyQuery
>;
export type QueryCountPaymentForEmailAdminQueryResult = Apollo.QueryResult<
    QueryCountPaymentForEmailAdminQuery,
    QueryCountPaymentForEmailAdminQueryVariables
>;
export const QueryFindFirstEmailAdminDocument = gql`
    query queryFindFirstEmailAdmin($input: FindFirstEmailAdminInput!) {
        findFirstEmailAdmin(input: $input) {
            ...EmailParts
            user {
                ...UserParts
                company {
                    ...CompanyParts
                }
            }
            userId
            emailAccount {
                ...EmailAccountParts
            }
        }
    }
    ${EmailPartsFragmentDoc}
    ${UserPartsFragmentDoc}
    ${CompanyPartsFragmentDoc}
    ${EmailAccountPartsFragmentDoc}
`;

/**
 * __useQueryFindFirstEmailAdminQuery__
 *
 * To run a query within a React component, call `useQueryFindFirstEmailAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryFindFirstEmailAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryFindFirstEmailAdminQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useQueryFindFirstEmailAdminQuery(
    baseOptions: Apollo.QueryHookOptions<
        QueryFindFirstEmailAdminQuery,
        QueryFindFirstEmailAdminQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<QueryFindFirstEmailAdminQuery, QueryFindFirstEmailAdminQueryVariables>(
        QueryFindFirstEmailAdminDocument,
        options,
    );
}
export function useQueryFindFirstEmailAdminLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        QueryFindFirstEmailAdminQuery,
        QueryFindFirstEmailAdminQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        QueryFindFirstEmailAdminQuery,
        QueryFindFirstEmailAdminQueryVariables
    >(QueryFindFirstEmailAdminDocument, options);
}
export type QueryFindFirstEmailAdminQueryHookResult = ReturnType<
    typeof useQueryFindFirstEmailAdminQuery
>;
export type QueryFindFirstEmailAdminLazyQueryHookResult = ReturnType<
    typeof useQueryFindFirstEmailAdminLazyQuery
>;
export type QueryFindFirstEmailAdminQueryResult = Apollo.QueryResult<
    QueryFindFirstEmailAdminQuery,
    QueryFindFirstEmailAdminQueryVariables
>;
export const QueryFindManyEmailAccountAdminDocument = gql`
    query queryFindManyEmailAccountAdmin($input: FindManyEmailAccountAdminInput!) {
        findManyEmailAccountAdmin(input: $input) {
            ...EmailAccountParts
        }
    }
    ${EmailAccountPartsFragmentDoc}
`;

/**
 * __useQueryFindManyEmailAccountAdminQuery__
 *
 * To run a query within a React component, call `useQueryFindManyEmailAccountAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryFindManyEmailAccountAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryFindManyEmailAccountAdminQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useQueryFindManyEmailAccountAdminQuery(
    baseOptions: Apollo.QueryHookOptions<
        QueryFindManyEmailAccountAdminQuery,
        QueryFindManyEmailAccountAdminQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        QueryFindManyEmailAccountAdminQuery,
        QueryFindManyEmailAccountAdminQueryVariables
    >(QueryFindManyEmailAccountAdminDocument, options);
}
export function useQueryFindManyEmailAccountAdminLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        QueryFindManyEmailAccountAdminQuery,
        QueryFindManyEmailAccountAdminQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        QueryFindManyEmailAccountAdminQuery,
        QueryFindManyEmailAccountAdminQueryVariables
    >(QueryFindManyEmailAccountAdminDocument, options);
}
export type QueryFindManyEmailAccountAdminQueryHookResult = ReturnType<
    typeof useQueryFindManyEmailAccountAdminQuery
>;
export type QueryFindManyEmailAccountAdminLazyQueryHookResult = ReturnType<
    typeof useQueryFindManyEmailAccountAdminLazyQuery
>;
export type QueryFindManyEmailAccountAdminQueryResult = Apollo.QueryResult<
    QueryFindManyEmailAccountAdminQuery,
    QueryFindManyEmailAccountAdminQueryVariables
>;
export const QueryCountEmailAccountAdminDocument = gql`
    query queryCountEmailAccountAdmin($input: CountEmailAccountAdminInput!) {
        countEmailAccountAdmin(input: $input)
    }
`;

/**
 * __useQueryCountEmailAccountAdminQuery__
 *
 * To run a query within a React component, call `useQueryCountEmailAccountAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryCountEmailAccountAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryCountEmailAccountAdminQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useQueryCountEmailAccountAdminQuery(
    baseOptions: Apollo.QueryHookOptions<
        QueryCountEmailAccountAdminQuery,
        QueryCountEmailAccountAdminQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        QueryCountEmailAccountAdminQuery,
        QueryCountEmailAccountAdminQueryVariables
    >(QueryCountEmailAccountAdminDocument, options);
}
export function useQueryCountEmailAccountAdminLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        QueryCountEmailAccountAdminQuery,
        QueryCountEmailAccountAdminQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        QueryCountEmailAccountAdminQuery,
        QueryCountEmailAccountAdminQueryVariables
    >(QueryCountEmailAccountAdminDocument, options);
}
export type QueryCountEmailAccountAdminQueryHookResult = ReturnType<
    typeof useQueryCountEmailAccountAdminQuery
>;
export type QueryCountEmailAccountAdminLazyQueryHookResult = ReturnType<
    typeof useQueryCountEmailAccountAdminLazyQuery
>;
export type QueryCountEmailAccountAdminQueryResult = Apollo.QueryResult<
    QueryCountEmailAccountAdminQuery,
    QueryCountEmailAccountAdminQueryVariables
>;
export const QueryFindFirstEmailAccountAdminDocument = gql`
    query queryFindFirstEmailAccountAdmin($input: FindFirstEmailAccountAdminInput!) {
        findFirstEmailAccountAdmin(input: $input) {
            ...EmailAccountParts
            email {
                ...EmailParts
            }
        }
    }
    ${EmailAccountPartsFragmentDoc}
    ${EmailPartsFragmentDoc}
`;

/**
 * __useQueryFindFirstEmailAccountAdminQuery__
 *
 * To run a query within a React component, call `useQueryFindFirstEmailAccountAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryFindFirstEmailAccountAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryFindFirstEmailAccountAdminQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useQueryFindFirstEmailAccountAdminQuery(
    baseOptions: Apollo.QueryHookOptions<
        QueryFindFirstEmailAccountAdminQuery,
        QueryFindFirstEmailAccountAdminQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        QueryFindFirstEmailAccountAdminQuery,
        QueryFindFirstEmailAccountAdminQueryVariables
    >(QueryFindFirstEmailAccountAdminDocument, options);
}
export function useQueryFindFirstEmailAccountAdminLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        QueryFindFirstEmailAccountAdminQuery,
        QueryFindFirstEmailAccountAdminQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        QueryFindFirstEmailAccountAdminQuery,
        QueryFindFirstEmailAccountAdminQueryVariables
    >(QueryFindFirstEmailAccountAdminDocument, options);
}
export type QueryFindFirstEmailAccountAdminQueryHookResult = ReturnType<
    typeof useQueryFindFirstEmailAccountAdminQuery
>;
export type QueryFindFirstEmailAccountAdminLazyQueryHookResult = ReturnType<
    typeof useQueryFindFirstEmailAccountAdminLazyQuery
>;
export type QueryFindFirstEmailAccountAdminQueryResult = Apollo.QueryResult<
    QueryFindFirstEmailAccountAdminQuery,
    QueryFindFirstEmailAccountAdminQueryVariables
>;
export const QueryFindManyAndSummaryPaymentAdminDocument = gql`
    query queryFindManyAndSummaryPaymentAdmin(
        $findManyPaymentAdmin: FindManyPaymentAdmin!
        $summaryPaymentAdmin: SummaryPaymentAdminInput!
    ) {
        findManyPaymentAdmin(input: $findManyPaymentAdmin) {
            ...PaymentParts
            user {
                ...UserParts
            }
        }
        summaryPaymentAdmin(input: $summaryPaymentAdmin) {
            checkOut {
                ...SummaryPaymentSubParts
            }
            cash {
                ...SummaryPaymentSubParts
            }
            total {
                ...SummaryPaymentSubParts
            }
        }
    }
    ${PaymentPartsFragmentDoc}
    ${UserPartsFragmentDoc}
    ${SummaryPaymentSubPartsFragmentDoc}
`;

/**
 * __useQueryFindManyAndSummaryPaymentAdminQuery__
 *
 * To run a query within a React component, call `useQueryFindManyAndSummaryPaymentAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryFindManyAndSummaryPaymentAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryFindManyAndSummaryPaymentAdminQuery({
 *   variables: {
 *      findManyPaymentAdmin: // value for 'findManyPaymentAdmin'
 *      summaryPaymentAdmin: // value for 'summaryPaymentAdmin'
 *   },
 * });
 */
export function useQueryFindManyAndSummaryPaymentAdminQuery(
    baseOptions: Apollo.QueryHookOptions<
        QueryFindManyAndSummaryPaymentAdminQuery,
        QueryFindManyAndSummaryPaymentAdminQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        QueryFindManyAndSummaryPaymentAdminQuery,
        QueryFindManyAndSummaryPaymentAdminQueryVariables
    >(QueryFindManyAndSummaryPaymentAdminDocument, options);
}
export function useQueryFindManyAndSummaryPaymentAdminLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        QueryFindManyAndSummaryPaymentAdminQuery,
        QueryFindManyAndSummaryPaymentAdminQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        QueryFindManyAndSummaryPaymentAdminQuery,
        QueryFindManyAndSummaryPaymentAdminQueryVariables
    >(QueryFindManyAndSummaryPaymentAdminDocument, options);
}
export type QueryFindManyAndSummaryPaymentAdminQueryHookResult = ReturnType<
    typeof useQueryFindManyAndSummaryPaymentAdminQuery
>;
export type QueryFindManyAndSummaryPaymentAdminLazyQueryHookResult = ReturnType<
    typeof useQueryFindManyAndSummaryPaymentAdminLazyQuery
>;
export type QueryFindManyAndSummaryPaymentAdminQueryResult = Apollo.QueryResult<
    QueryFindManyAndSummaryPaymentAdminQuery,
    QueryFindManyAndSummaryPaymentAdminQueryVariables
>;

export interface PossibleTypesResultData {
    possibleTypes: {
        [key: string]: string[];
    };
}
const result: PossibleTypesResultData = {
    possibleTypes: {},
};
export default result;
