import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
    QueryCountPaymentForHostingAdminQuery,
    QueryFindFirstHostingAdminQuery,
    QueryFindManyPaymentForHostingAdminQuery,
    useMutationDeletePaymentAdminMutation,
    useQueryCountPaymentForHostingAdminQuery,
    useQueryFindFirstHostingAdminQuery,
    useQueryFindManyPaymentForHostingAdminQuery,
} from "../../../../graphql/operation";
import { SwalUtil } from "../../../../utils/swal/SwalUtil";
import { ErrorCatchUtil } from "../../../../utils/errorCatch/ErrorCatchUtil";
import { ErrorConstants } from "../../../../variables/ErrorConstants";
import { LimitWidthContainer } from "../../../components/limitWidthContainer/LimitWidthContainer";
import { TitleBox } from "../../../components/titleBox/TitleBox";
import { NoDataBox } from "../../../components/noDataBox/NoDataBox";
import { LeftUserContainer } from "../../../components/leftUserContainer/LeftUserContainer";
import { Button, IconButton, Tooltip } from "@mui/material";
import { InputLine } from "../../../components/inputLine/InputLine";
import { RegisteredCompanyText } from "../../../components/RegisteredCompanyText/RegisteredCompanyText";
import NumberFormat from "react-number-format";
import moment from "moment";
import { HostingTypeText } from "../../../components/hostingTypeText/HostingTypeText";
import { OnChangeNumber } from "../../../../types/ArgsInterface";
import { PaymentMethodText } from "../../../components/paymentMethodText/PaymentMethodText";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBookOpen, faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import { ProductActiveText } from "../../../components/productActiveText/ProductActiveText";

export const ViewHostingDetail = () => {
    const { id } = useParams();

    const initPagination = {
        take: 10,
        page: 1,
    };

    const [paginationData, setPaginationData] = useState(initPagination);

    const { data: hostingData, error: hostingError } = useQueryFindFirstHostingAdminQuery({
        variables: {
            input: {
                id: Number(id),
            },
        },
    });

    const { data: paymentData, refetch: paymentRefetch } =
        useQueryFindManyPaymentForHostingAdminQuery({
            variables: {
                input: {
                    hostingId: Number(id),
                },
            },
        });

    const { data: countData } = useQueryCountPaymentForHostingAdminQuery({
        variables: {
            input: {
                hostingId: Number(id),
            },
        },
    });

    const [deletePaymentAdmin] = useMutationDeletePaymentAdminMutation();

    const handler = {
        onChangePage: (page: number) => {
            setPaginationData({
                take: paginationData.take,
                page,
            });
        },
        onClickDeletePayment: (id: number) => {
            SwalUtil.yn({
                title: "정말 삭제하시겠습니까?",
                text: "복구가 불가능합니다.",
                icon: "warning",
            })
                .then(() => {
                    return deletePaymentAdmin({
                        variables: {
                            input: {
                                paymentId: id,
                            },
                        },
                    });
                })
                .then(() => {
                    return SwalUtil.ok({
                        title: "납부 정보가 삭제 되었습니다.",
                        icon: "success",
                    });
                })
                .then(() => {
                    return paymentRefetch();
                })
                .catch(
                    ErrorCatchUtil({
                        [ErrorConstants.notPermission]: () => {
                            return SwalUtil.ok({
                                title: "권한이 없습니다.",
                                icon: "error",
                            });
                        },
                        [ErrorConstants.default]: e => {
                            return SwalUtil.ok({
                                title: e,
                                icon: "error",
                            });
                        },
                    }),
                );
        },
    };

    return (
        <>
            <LimitWidthContainer>
                <TitleBox title={"호스팅"} />
                {hostingError && (
                    <div className="bg-lightPaper dark:bg-darkPaper p-5 rounded">
                        <NoDataBox />
                    </div>
                )}

                {hostingData && (
                    <div className="w-full flex justify-between gap-10">
                        <div className="w-[300px]">
                            <div className="bg-lightPaper dark:bg-darkPaper p-5  rounded w-full">
                                <LeftUserContainer
                                    userId={hostingData.findFirstHostingAdmin.userId}
                                />
                            </div>
                        </div>
                        <div className="w-[900px]">
                            <div className="w-full bg-lightPaper dark:bg-darkPaper p-5 rounded">
                                <HostingDetailContainer
                                    hostingData={hostingData}
                                    hostingId={Number(id)}
                                />
                            </div>
                            <div className="mb-10" />
                            <div className="w-full bg-lightPaper dark:bg-darkPaper p-5 rounded">
                                <HostingPaymentContainer
                                    paymentData={paymentData}
                                    countData={countData}
                                    hostingId={Number(id)}
                                    onClickDeletePayment={handler.onClickDeletePayment}
                                />
                            </div>
                        </div>
                    </div>
                )}
            </LimitWidthContainer>
        </>
    );
};

const HostingDetailContainer = (props: {
    hostingData: QueryFindFirstHostingAdminQuery | undefined;
    hostingId: number;
}) => {
    const navigate = useNavigate();

    const handler = {
        onUpdate: () => {
            navigate(`/hosting/update/${props.hostingId}`);
        },
    };

    return (
        <>
            {!props.hostingData && <NoDataBox />}
            {props.hostingData && (
                <>
                    <div className="w-full flex justify-between">
                        <div className="flex-1">
                            <TitleBox title={"상세정보"} notBottomMargin={true} />
                        </div>
                        <div className="flex items-center gap-5">
                            <Button variant="contained" color="primary" onClick={handler.onUpdate}>
                                정보수정
                            </Button>
                            {props.hostingData.findFirstHostingAdmin.userId ? (
                                <Button variant="contained" color="warning">
                                    연결해제
                                </Button>
                            ) : (
                                <Button variant="contained" color="primary">
                                    회원연결
                                </Button>
                            )}
                        </div>
                    </div>
                    <div className="w-full flex justify-between gap-5">
                        <div className="w-full">
                            <HostingDetailContainerLeft
                                hostingData={props.hostingData}
                                hostingId={props.hostingId}
                            />
                        </div>
                        <div className="w-[1px] border-r" />
                        <div className="w-full">
                            <HostingDetailContainerRight
                                hostingData={props.hostingData}
                                hostingId={props.hostingId}
                            />
                        </div>
                    </div>
                    <div
                        className="w-full max-h-[100px] border p-5 overflow-y-auto"
                        dangerouslySetInnerHTML={{
                            __html: props.hostingData.findFirstHostingAdmin.memo
                                ? props.hostingData.findFirstHostingAdmin.memo
                                : "메모없음",
                        }}></div>
                </>
            )}
        </>
    );
};

const HostingDetailContainerLeft = (props: {
    hostingData: QueryFindFirstHostingAdminQuery;
    hostingId: number;
}) => {
    return (
        <>
            <InputLine title="도메인">
                {props.hostingData.findFirstHostingAdmin.domainUrl}
            </InputLine>
            <InputLine title="등록기관">
                <RegisteredCompanyText
                    registeredCompany={props.hostingData.findFirstHostingAdmin.registeredCompany}
                />
            </InputLine>
            <InputLine title="호스팅 종류">
                <HostingTypeText
                    hostingType={props.hostingData.findFirstHostingAdmin.hostingType}
                />
            </InputLine>
            <InputLine title={"가격"}>
                <NumberFormat
                    displayType="text"
                    thousandSeparator=","
                    suffix=" 원"
                    value={props.hostingData.findFirstHostingAdmin.price}
                />
            </InputLine>
            <InputLine title="시작일">
                {props.hostingData.findFirstHostingAdmin.createdAt
                    ? moment(props.hostingData.findFirstHostingAdmin.createdAt)
                          .locale("ko")
                          .format("YYYY-MM-DD")
                    : "-"}
            </InputLine>
            <InputLine title="마지막 결제일">
                {props.hostingData.findFirstHostingAdmin.lastBillingDate
                    ? moment(props.hostingData.findFirstHostingAdmin.lastBillingDate)
                          .locale("ko")
                          .format("YYYY-MM-DD")
                    : "-"}
            </InputLine>
            <InputLine title="다음 청구일">
                {props.hostingData.findFirstHostingAdmin.nextBillingDate
                    ? moment(props.hostingData.findFirstHostingAdmin.nextBillingDate)
                          .locale("ko")
                          .format("YYYY-MM-DD")
                    : "-"}
            </InputLine>
            <InputLine title="상태">
                <ProductActiveText
                    productActive={props.hostingData.findFirstHostingAdmin.productActive}
                />
            </InputLine>
        </>
    );
};

const HostingDetailContainerRight = (props: {
    hostingData: QueryFindFirstHostingAdminQuery;
    hostingId: number;
}) => {
    return (
        <>
            <InputLine title="FTP HOST">
                {props.hostingData.findFirstHostingAdmin.hostingInfo?.ftpIp}
            </InputLine>
            <InputLine title="FTP ID">
                {props.hostingData.findFirstHostingAdmin.hostingInfo?.ftpId}
            </InputLine>
            <InputLine title="FTP Password">
                {props.hostingData.findFirstHostingAdmin.hostingInfo?.ftpPassword}
            </InputLine>
            <InputLine title="Database Name">
                {props.hostingData.findFirstHostingAdmin.hostingInfo?.mysqlDatabase}
            </InputLine>
            <InputLine title="Database ID">
                {props.hostingData.findFirstHostingAdmin.hostingInfo?.mysqlId}
            </InputLine>
            <InputLine title="Database Password">
                {props.hostingData.findFirstHostingAdmin.hostingInfo?.mysqlPassword}
            </InputLine>
        </>
    );
};

const HostingPaymentContainer = (props: {
    paymentData: QueryFindManyPaymentForHostingAdminQuery | undefined;
    countData: QueryCountPaymentForHostingAdminQuery | undefined;
    hostingId: number;
    onClickDeletePayment: OnChangeNumber;
}) => {
    const navigate = useNavigate();

    return (
        <>
            <div className="w-full flex justify-between mb-5">
                <div className="flex-1">
                    <TitleBox title="납부목록" notBottomMargin={true} />
                </div>
                <div className="flex gap-5">
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            navigate(`/payment/create/hosting/${props.hostingId}`);
                        }}>
                        납부등록
                    </Button>
                </div>
            </div>
            <div className="bg-lightBackground dark:bg-darkBackground rounded text-sm h-[35px] items-center flex">
                <div className="w-[20%]">상품금액</div>
                <div className="w-[12.5%]">결제일</div>
                <div className="w-[12.5%]">결제방법</div>
                <div className="w-[15%]">입금인</div>
                <div className="w-[25%]">메모</div>
                <div className="w-[15%]">기능</div>
            </div>
            {!props.paymentData && <NoDataBox />}
            {props.paymentData?.findManyPaymentForHostingAdmin.map((value, index) => (
                <div key={index} className="w-full flex h-[45px] border-b items-center">
                    {value && (
                        <>
                            <div className="w-[20%]">
                                <NumberFormat
                                    thousandSeparator=","
                                    suffix=" 원"
                                    displayType="text"
                                    value={value.price}
                                />
                            </div>
                            <div className="w-[12.5%]">
                                {moment(value.createdAt).locale("ko").format("YYYY-MM-DD")}
                            </div>
                            <div className="w-[12.5%]">
                                <PaymentMethodText paymentMethod={value.paymentMethod} />
                            </div>
                            <div className="w-[15%]">{value.depositor && value.depositor}</div>
                            <div
                                className="w-[25%] truncate"
                                dangerouslySetInnerHTML={{
                                    __html: value.memo,
                                }}
                            />
                            <div className="w-[15%] justify-between">
                                <Tooltip title="상세보기">
                                    <IconButton
                                        color="primary"
                                        onClick={() => {
                                            navigate(`/payment/detail/${value.id}`);
                                        }}>
                                        <FontAwesomeIcon icon={faBookOpen} size="sm" />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="수정" arrow>
                                    <IconButton
                                        color="primary"
                                        onClick={() => {
                                            navigate(`/payment/update/hosting/${value.id}`);
                                        }}>
                                        <FontAwesomeIcon icon={faPen} size="sm" />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="삭제" arrow>
                                    <IconButton
                                        color="primary"
                                        onClick={() => {
                                            props.onClickDeletePayment(value.id);
                                        }}>
                                        <FontAwesomeIcon icon={faTrash} size="sm" />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </>
                    )}
                </div>
            ))}
        </>
    );
};
