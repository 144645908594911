import React from "react";
import { ProductType } from "../../../graphql/operation";
import { MenuItem, TextField } from "@mui/material";
import { OnChangeValue } from "../../../types/ArgsInterface";

export const ProductTypeSelect = (props: {
    productType: ProductType;
    onChangeState: OnChangeValue<ProductType>;
    readOnly?: boolean;
}) => {
    return (
        <>
            <TextField
                variant="outlined"
                label="상품 종류"
                color="primary"
                fullWidth
                select
                value={props.productType}
                inputProps={{ readOnly: props.readOnly || false }}
                size="small"
                onChange={value => {
                    let type: ProductType;
                    if (value.target.value === "Domain") {
                        type = ProductType.Domain;
                    } else if (value.target.value === "Certificate") {
                        type = ProductType.Certificate;
                    } else if (value.target.value === "Sms") {
                        type = ProductType.Sms;
                    } else if (value.target.value === "Design") {
                        type = ProductType.Design;
                    } else if (value.target.value === "Email") {
                        type = ProductType.Email;
                    } else if (value.target.value === "Etc") {
                        type = ProductType.Etc;
                    } else {
                        type = ProductType.Hosting;
                    }
                    props.onChangeState(type);
                }}>
                <MenuItem value={ProductType.Hosting}>호스팅</MenuItem>
                <MenuItem value={ProductType.Domain}>도메인</MenuItem>
                <MenuItem value={ProductType.Certificate}>보안인증서</MenuItem>
                <MenuItem value={ProductType.Sms}>SMS</MenuItem>
                <MenuItem value={ProductType.Design}>디자인</MenuItem>
                <MenuItem value={ProductType.Email}>이메일</MenuItem>
                <MenuItem value={ProductType.Etc}>ETC</MenuItem>
            </TextField>
        </>
    );
};
