import { ApolloClient, ApolloLink, from, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { SystemVars, UserVars } from "../../variables/MakeVar";
import { onError } from "@apollo/client/link/error";
import { ErrorConstants } from "../../variables/ErrorConstants";
import { SwalUtil } from "../swal/SwalUtil";
import { useNavigate } from "react-router-dom";
import { createUploadLink } from "apollo-upload-client";
import { SessionUtil } from "../session/SessionUtil";

const apolloLink = from([
    setContext((operation, prevContext) => {
        return {
            headers: {
                ...prevContext.headers,
                Authorization: SessionUtil.getSession(),
            },
        };
    }),
    new ApolloLink((operation, forward) => {
        if (operation.getContext().hasOwnProperty("loadingView")) {
            SystemVars.loadingView(operation.getContext().loadingView);
        }
        // } else {
        //     SystemVars.loadingView(true);
        // }

        if (operation.getContext().hasOwnProperty("header")) {
            operation.setContext({
                header: {
                    ...operation.getContext().header,
                },
            });
        }

        return forward(operation).map(data => {
            SystemVars.loadingView(false);
            return data;
        });
    }),
    onError(error => {
        if (!error.graphQLErrors) {
            return error.forward(error.operation);
        }

        for (const graphQLError of error.graphQLErrors) {
            if (graphQLError.message === ErrorConstants.needLogin) {
                if (UserVars.isLoggedIn()) {
                    const navigate = useNavigate();
                    SwalUtil.ok({
                        title: "로그인이 필요한 서비스입니다.",
                        icon: "error",
                    }).then(() => {
                        return navigate("/");
                    });
                }
                SessionUtil.clearSession();
                break;
            }
        }

        return error.forward(error.operation);
    }),
    createUploadLink({
        uri: process.env.REACT_APP_API || "",
    }) as any,
]);

export class ApolloUtil {
    static client = new ApolloClient({
        link: apolloLink,
        connectToDevTools: true,
        cache: new InMemoryCache(),
        defaultOptions: {
            watchQuery: {
                fetchPolicy: "network-only",
                errorPolicy: "all",
                nextFetchPolicy: "network-only",
            },
            query: {
                fetchPolicy: "no-cache",
                errorPolicy: "all",
            },
            mutate: {
                fetchPolicy: "network-only",
            },
        },
    });
}
