import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
    CreateUserInput,
    useMutationCreateUserMutation,
    UserType,
} from "../../../../graphql/operation";
import { SwalUtil } from "../../../../utils/swal/SwalUtil";
import { ErrorCatchUtil } from "../../../../utils/errorCatch/ErrorCatchUtil";
import { ErrorConstants } from "../../../../variables/ErrorConstants";
import { LimitWidthContainer } from "../../../components/limitWidthContainer/LimitWidthContainer";
import { TitleBox } from "../../../components/titleBox/TitleBox";
import { Button, Checkbox, Divider, TextField } from "@mui/material";
import { InputLine } from "../../../components/inputLine/InputLine";
import { UserTypeSelect } from "../../../components/userTypeSelect/UserTypeSelect";
import { DaumPostCodeModal } from "../../../components/daumPostCodeModal";
import { Address } from "react-daum-postcode";
import { OnChangeValue, OnVoidFunction } from "../../../../types/ArgsInterface";

export const ViewUserCreate = () => {
    const navigate = useNavigate();

    const initUserData: CreateUserInput = {
        userType: UserType.Personal,
        applyReceiveMail: true,
        applyReceiveSMS: true,
        email: "",
        name: "",
        password: "",
        phoneNumber: "",
        username: "",
    };

    const [openModal, setOpenModal] = useState<boolean>(false);
    const [inputUserData, setInputUserData] = useState<CreateUserInput>(initUserData);
    const [createUserMutation] = useMutationCreateUserMutation();

    const handler = {
        onCreate: () => {
            createUserMutation({
                variables: {
                    input: {
                        ...inputUserData,
                    },
                },
            })
                .then(() => {
                    return SwalUtil.ok({
                        title: "회원 생성이 완료되었습니다.",
                        icon: "success",
                    });
                })
                .then(() => {
                    navigate("/user");
                })
                .catch(() => {
                    ErrorCatchUtil({
                        [ErrorConstants.alreadyUser]: () => {
                            return SwalUtil.ok({
                                title: "이미 존재하는 회원 아이디입니다.",
                                icon: "error",
                            });
                        },
                        [ErrorConstants.default]: e => {
                            return SwalUtil.ok({
                                title: e,
                                icon: "error",
                            });
                        },
                    });
                });
        },
        onCancel: () => {
            navigate(-1);
        },
        onChangeUserType: (value: UserType) => {
            setInputUserData({
                ...inputUserData,
                userType: value,
            });
        },
        handleModalOpen: () => {
            setOpenModal(true);
        },
        handleModalClose: () => {
            setOpenModal(false);
        },
        onChangeAddress: (data: Address) => {
            let fullAddress = data.address;
            let extraAddress = "";

            if (data.addressType === "R") {
                if (data.bname !== "") {
                    extraAddress += data.bname;
                }
                if (data.buildingName !== "") {
                    if (data.buildingName !== "") {
                        extraAddress +=
                            extraAddress !== "" ? `, ${data.buildingName}` : data.buildingName;
                    }
                    fullAddress += extraAddress !== "" ? ` (${extraAddress})` : "";
                }
            }

            setInputUserData({
                ...inputUserData,
                zipCode: data.zonecode,
                address1: fullAddress,
                address2: "",
            });
        },
    };

    return (
        <>
            <LimitWidthContainer>
                <TitleBox title="회원 - 추가" />
                <div className="bg-lightPaper dark:bg-darkPaper p-5">
                    <UserCreateContainer
                        inputData={inputUserData}
                        setInputData={setInputUserData}
                        onChangeUserType={handler.onChangeUserType}
                        handleModalOpen={handler.handleModalOpen}
                    />
                    <div className="my-10" />
                    <div className="flex w-full justify-center gap-10">
                        <Button
                            variant="contained"
                            color="primary"
                            className="w-[120px]"
                            onClick={handler.onCreate}>
                            회원등록
                        </Button>
                        <Button
                            variant="contained"
                            color="secondary"
                            className="w-[120px]"
                            onClick={handler.onCancel}>
                            취소
                        </Button>
                    </div>
                </div>
            </LimitWidthContainer>

            <DaumPostCodeModal
                open={openModal}
                onChangeAddress={handler.onChangeAddress}
                onClose={handler.handleModalClose}
            />
        </>
    );
};

const UserCreateContainer = (props: {
    inputData: CreateUserInput;
    setInputData: React.Dispatch<React.SetStateAction<CreateUserInput>>;
    onChangeUserType: OnChangeValue<UserType>;
    handleModalOpen: OnVoidFunction;
}) => {
    return (
        <>
            <InputLine title="아이디">
                <TextField
                    variant="outlined"
                    label="사용자명"
                    color="primary"
                    fullWidth
                    size="small"
                    type="text"
                    value={props.inputData.username}
                    onChange={value => {
                        props.setInputData({
                            ...props.inputData,
                            username: value.currentTarget.value,
                        });
                    }}
                />
            </InputLine>
            <InputLine title="비밀번호">
                <TextField
                    variant="outlined"
                    label="비밀번호"
                    color="primary"
                    fullWidth
                    size="small"
                    type="password"
                    onChange={value => {
                        props.setInputData({
                            ...props.inputData,
                            password: value.currentTarget.value,
                        });
                    }}
                />
            </InputLine>
            <InputLine title="회원종류">
                <UserTypeSelect
                    userType={props.inputData.userType}
                    onChangeState={props.onChangeUserType}
                />
            </InputLine>
            <InputLine title="이름 (상호)">
                <TextField
                    variant="outlined"
                    label="이름 (상호)"
                    color="primary"
                    fullWidth
                    size="small"
                    type="text"
                    value={props.inputData.name}
                    onChange={value => {
                        props.setInputData({
                            ...props.inputData,
                            name: value.currentTarget.value,
                        });
                    }}
                />
            </InputLine>
            <InputLine title="이메일">
                <TextField
                    variant="outlined"
                    label="이메일"
                    color="primary"
                    fullWidth
                    size="small"
                    type="email"
                    value={props.inputData.email}
                    onChange={value => {
                        props.setInputData({
                            ...props.inputData,
                            email: value.currentTarget.value,
                        });
                    }}
                />
            </InputLine>
            <InputLine title="전화번호">
                <TextField
                    variant="outlined"
                    label="전화번호"
                    color="primary"
                    fullWidth
                    size="small"
                    type="tel"
                    value={props.inputData.phoneNumber}
                    onChange={value => {
                        props.setInputData({
                            ...props.inputData,
                            phoneNumber: value.currentTarget.value,
                        });
                    }}
                />
            </InputLine>
            {props.inputData.userType === UserType.Company && (
                <>
                    <Divider className="w-full my-5" />
                    <InputLine title="사업자등록번호">
                        <TextField
                            variant="outlined"
                            label="사업자등록번호"
                            color="primary"
                            fullWidth
                            size="small"
                            type="text"
                            value={props.inputData.registrationNumber}
                            onChange={value => {
                                props.setInputData({
                                    ...props.inputData,
                                    registrationNumber: value.currentTarget.value,
                                });
                            }}
                        />
                    </InputLine>
                    <InputLine title="대표자">
                        <TextField
                            variant="outlined"
                            label="대표자"
                            color="primary"
                            fullWidth
                            size="small"
                            type="text"
                            value={props.inputData.ceo}
                            onChange={value => {
                                props.setInputData({
                                    ...props.inputData,
                                    ceo: value.currentTarget.value,
                                });
                            }}
                        />
                    </InputLine>
                    <InputLine title="업종">
                        <TextField
                            variant="outlined"
                            label="업종"
                            color="primary"
                            fullWidth
                            size="small"
                            type="text"
                            value={props.inputData.businessStatus}
                            onChange={value => {
                                props.setInputData({
                                    ...props.inputData,
                                    businessStatus: value.currentTarget.value,
                                });
                            }}
                        />
                    </InputLine>
                    <InputLine title="업태">
                        <TextField
                            variant="outlined"
                            label="업태"
                            color="primary"
                            fullWidth
                            size="small"
                            type="text"
                            value={props.inputData.businessSector}
                            onChange={value => {
                                props.setInputData({
                                    ...props.inputData,
                                    businessSector: value.currentTarget.value,
                                });
                            }}
                        />
                    </InputLine>
                    <InputLine title="주소">
                        <TextField
                            variant="outlined"
                            label="우편번호"
                            color="primary"
                            className="w-[200px]"
                            size="small"
                            type="text"
                            value={props.inputData.zipCode}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputProps={{ readOnly: true }}
                            onClick={props.handleModalOpen}
                        />
                        <TextField
                            variant="outlined"
                            label="주소"
                            color="primary"
                            fullWidth
                            size="small"
                            type="text"
                            value={props.inputData.address1}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputProps={{ readOnly: true }}
                            onClick={props.handleModalOpen}
                        />
                        <TextField
                            variant="outlined"
                            label="나머지 주소"
                            color="primary"
                            fullWidth
                            size="small"
                            type="text"
                            value={props.inputData.address2}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={value => {
                                props.setInputData({
                                    ...props.inputData,
                                    address2: value.currentTarget.value,
                                });
                            }}
                        />
                    </InputLine>
                    <InputLine title="사업자등록증 첨부">
                        <TextField
                            variant="outlined"
                            label="사업자등록증 첨부"
                            color="primary"
                            fullWidth
                            size="small"
                            type="text"
                            value={props.inputData.registrationFile}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputProps={{ readOnly: true }}
                        />
                    </InputLine>
                    <Divider className="w-full my-5" />
                </>
            )}
            <InputLine title="메일링서비스">
                <Checkbox
                    checked={props.inputData.applyReceiveMail}
                    onChange={value => {
                        props.setInputData({
                            ...props.inputData,
                            applyReceiveMail: value.target.checked,
                        });
                    }}
                />{" "}
                메일 수신 허용
            </InputLine>
            <InputLine title="SMS 수신">
                <Checkbox
                    checked={props.inputData.applyReceiveSMS}
                    onChange={value => {
                        props.setInputData({
                            ...props.inputData,
                            applyReceiveSMS: value.target.checked,
                        });
                    }}
                />{" "}
                SMS 수신 허용
            </InputLine>
        </>
    );
};
