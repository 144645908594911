import React, { Fragment } from "react";
import { OnChangeNumber, OnChangeValue } from "../../../types/ArgsInterface";
import { useQuerySearchHostingAdminLazyQuery } from "../../../graphql/operation";
import { Dialog, DialogTitle, TextField } from "@mui/material";

export const SearchHostingModal = (props: {
    open: boolean;
    onClose: OnChangeNumber;
    onApplyHosting: OnChangeValue<ISearchHosting>;
}) => {
    const [searchDomainLazyQuery, { data: hostingData, error: hostingError }] =
        useQuerySearchHostingAdminLazyQuery();

    const handler = {
        onSearch: async (value: string) => {
            await searchDomainLazyQuery({
                variables: {
                    input: {
                        searchValue: value,
                    },
                },
            });
        },
    };

    return (
        <>
            <Dialog open={props.open} onClose={props.onClose}>
                <DialogTitle>호스팅 상품 검색</DialogTitle>
                <div className="flex w-full w-[300px] flex-col px-5 lg:w-[600px]">
                    <div className="flex w-full justify-between">
                        <TextField
                            fullWidth
                            placeholder="도메인으로 검색"
                            onChange={async value => {
                                await handler.onSearch(value.currentTarget.value);
                            }}
                        />
                    </div>
                </div>
                <div className="h-[500px] overflow-y-auto overflow-x-hidden px-5">
                    {hostingError && (
                        <div className="flex h-[500px] w-full items-center justify-center">
                            No Search Data.
                        </div>
                    )}

                    {hostingData &&
                        hostingData.searchHostingAdmin.map((value, index) => (
                            <Fragment key={index}>
                                {value && (
                                    <div
                                        className="flex-start flex items-center border-b py-3 pl-5"
                                        onClick={() => {
                                            props.onApplyHosting({
                                                hostingId: value.id,
                                                hostingDomainUrl: value.domainUrl,
                                            });
                                        }}>
                                        {value.domainUrl}
                                    </div>
                                )}
                            </Fragment>
                        ))}
                </div>
            </Dialog>
        </>
    );
};

export interface ISearchHosting {
    hostingId: number;
    hostingDomainUrl: string;
}
