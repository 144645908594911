import React from "react";
import { RequestStatus } from "../../../graphql/operation";

export const RequestStatusText = (props: { requestStatus: RequestStatus }) => {
    let requestStatus = "";

    if (props.requestStatus === RequestStatus.Proceeding) {
        requestStatus = "처리중";
    } else if (props.requestStatus === RequestStatus.Solved) {
        requestStatus = "완료";
    } else {
        requestStatus = "요청중";
    }

    return <>{requestStatus}</>;
};
