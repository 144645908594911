export class ErrorConstants {
    static swalCancel = "swalCencel";
    static default = "default";
    static alreadyUser = "alreadyUser";
    static needLogin = "needLogin";
    static notFoundUser = "notFoundUser";
    static notPermission = "notPermission";
    static wrongInput = "wrongInput";
    static noData = "noData";
    static enoughDate = "enoughDate";
}
