import React from "react";
import { useParams } from "react-router-dom";
import { LimitWidthContainer } from "../../../components/limitWidthContainer/LimitWidthContainer";
import { TitleBox } from "../../../components/titleBox/TitleBox";
import { useQueryFindFirstUserAdminQuery } from "../../../../graphql/operation";
import { LeftUserContainer } from "../../../components/leftUserContainer/LeftUserContainer";

export const ViewUserDetail = () => {
    const { id } = useParams();

    const {
        data: userData,
        loading: userLoading,
        error: userError,
    } = useQueryFindFirstUserAdminQuery({
        variables: {
            input: {
                userId: Number(id),
            },
        },
    });

    return (
        <>
            <LimitWidthContainer>
                <TitleBox title="회원" />
                {!userLoading && userError && (
                    <div className="bg-lightPaper dark:bg-darkPaper p-5 rounded">
                        <div className="w-full min-h-[300px]">데이터가 없습니다.</div>
                    </div>
                )}

                {!userLoading && userData && (
                    <div className="w-full flex justify-between gap-10">
                        <div className="w-[300px]">
                            <div className="bg-lightPaper dark:bg-darkPaper p-5  rounded w-full">
                                <LeftUserContainer userId={userData.findFirstUserAdmin.id} />
                            </div>
                        </div>
                        <div className="w-[900px]">
                            <div className="w-full bg-lightPaper dark:bg-darkPaper p-5 rounded">
                                <UserDetailContainer />
                            </div>
                        </div>
                    </div>
                )}
            </LimitWidthContainer>
        </>
    );
};

const UserDetailContainer = () => {
    return (
        <>
            <div>UserDetailContainer</div>
        </>
    );
};
