import React from "react";
import { RouteObject } from "react-router-dom";
import { UserVars } from "../variables/MakeVar";
import { ViewAuthSignIn } from "../views/pages/auth/signin/ViewAuthSignIn";
import { ViewAuthSignUp } from "../views/pages/auth/signup/ViewAuthSignUp";
import { ViewIndex } from "../views/pages/index/ViewIndex";
import { ViewCertificateList } from "../views/pages/certificate/list/ViewCertificateList";
import { ViewCertificateCreate } from "../views/pages/certificate/create/ViewCertificateCreate";
import { ViewUserList } from "../views/pages/user/list/ViewUserList";
import { ViewCertificateDetail } from "../views/pages/certificate/detail/ViewCertificateDetail";
import { ViewCertificateUpdate } from "../views/pages/certificate/update/ViewCertificateUpdate";
import { ViewUserCreate } from "../views/pages/user/create/ViewUserCreate";
import { ViewUserDetail } from "../views/pages/user/detail/ViewUserDetail";
import { ViewUserProduct } from "../views/pages/user/product/ViewUserProduct";
import { ViewPaymentCreateCertificate } from "../views/pages/payment/create/certificate/ViewPaymentCreateCertificate";
import { ViewUserUpdateInfo } from "../views/pages/user/update/info/ViewUserUpdateInfo";
import { ViewPaymentUpdateCertificate } from "../views/pages/payment/update/certificate/ViewPaymentUpdateCertificate";
import { ViewPaymentDetail } from "../views/pages/payment/detail/ViewPaymentDetail";
import { ViewCertificateMakeCSR } from "../views/pages/certificate/makeCSR/ViewCertificateMakeCSR";
import { ViewDomainList } from "../views/pages/domain/list/ViewDomainList";
import { ViewDomainCreate } from "../views/pages/domain/create/ViewDomainCreate";
import { ViewDomainUpdate } from "../views/pages/domain/update/ViewDomainUpdate";
import { ViewDomainDetail } from "../views/pages/domain/detail/ViewDomainDetail";
import { ViewPaymentCreateDomain } from "../views/pages/payment/create/domain/ViewPaymentCreateDomain";
import { ViewPaymentUpdateDomain } from "../views/pages/payment/update/domain/ViewPaymentUpdateDomain";
import { ViewHostingList } from "../views/pages/hosting/list/ViewHostingList";
import { ViewHostingCreate } from "../views/pages/hosting/create/ViewHostingCreate";
import { ViewHostingUpdate } from "../views/pages/hosting/update/ViewHostingUpdate";
import { ViewHostingDetail } from "../views/pages/hosting/detail/ViewHostingDetail";
import { ViewPaymentCreateHosting } from "../views/pages/payment/create/hosting/ViewPaymentCreateHosting";
import { ViewPaymentUpdateHosting } from "../views/pages/payment/update/hosting/ViewPaymentUpdateHosting";
import { ViewPaymentList } from "../views/pages/payment/list/ViewPaymentList";
import { ViewPaymentCreateEtc } from "../views/pages/payment/create/etc/ViewPaymentCreateEtc";
import { ViewRequestList } from "../views/pages/request/list/ViewRequestList";
import { ViewRequestCreate } from "../views/pages/request/create/ViewRequestCreate";
import { ViewQuestionList } from "../views/pages/question/list/ViewQuestionList";
import { ViewUserPayment } from "../views/pages/user/payment/ViewUserPayment";
import { ViewPaymentImminent } from "../views/pages/payment/imminent/ViewPaymentImminent";
import { ViewEmailList } from "../views/pages/email/list/ViewEmailList";
import { ViewEmailCreate } from "../views/pages/email/create/ViewEmailCreate";
import { ViewEmailDetail } from "../views/pages/email/detail/ViewEmailDetail";
import { ViewEmailAccountCreate } from "../views/pages/email/account/create/ViewEmailAccountCreate";
import { ViewEmailAccountUpdate } from "../views/pages/email/account/update/ViewEmailAccountUpdate";
import { ViewPaymentCreateEmail } from "../views/pages/payment/create/email/ViewPaymentCreateEmail";
import { ViewPaymentUpdateEmail } from "../views/pages/payment/update/email/ViewPaymentUpdateEmail";
import { ViewPaymentUpdateEtc } from "../views/pages/payment/update/etc/ViewPaymentUpdateEtc";
import { ViewEmailUpdate } from "../views/pages/email/update/ViewEmailUpdate";

export const GetRouter = (isLoggedIn: boolean): RouteObject[] => {
    const userInfo = UserVars.userInfo();
    let nextRouter: RouteObject[] = [...commonRouter];

    if (!isLoggedIn) {
        nextRouter = [...nextRouter, ...notAuthRouter];
    }

    if (isLoggedIn) {
        nextRouter = [...nextRouter, ...authRouter];
    }

    if (isLoggedIn && userInfo && userInfo.authLevel === 10) {
        nextRouter = [...nextRouter, ...adminRouter];
    }

    return nextRouter;
};

const notAuthRouter: RouteObject[] = [
    {
        path: "/auth/signup",
        element: <ViewAuthSignUp />,
    },
    {
        path: "/auth/signin",
        element: <ViewAuthSignIn />,
    },
    {
        path: "/",
        element: <ViewAuthSignIn />,
    },
];

const authRouter: RouteObject[] = [
    {
        path: "/certificate/makeCSR",
        element: <ViewCertificateMakeCSR />,
    },
    {
        path: "/certificate/create/:userId",
        element: <ViewCertificateCreate />,
    },
    {
        path: "/certificate/create",
        element: <ViewCertificateCreate />,
    },
    {
        path: "/certificate/detail/:id",
        element: <ViewCertificateDetail />,
    },
    {
        path: "/certificate/update/:id",
        element: <ViewCertificateUpdate />,
    },
    {
        path: "/certificate/:method/:take/:page",
        element: <ViewCertificateList />,
    },
    {
        path: "/certificate",
        element: <ViewCertificateList />,
    },
    {
        path: "/user/create",
        element: <ViewUserCreate />,
    },
    {
        path: "/domain/create/:userId",
        element: <ViewDomainCreate />,
    },
    {
        path: "/domain/create",
        element: <ViewDomainCreate />,
    },
    {
        path: "/domain/detail/:id",
        element: <ViewDomainDetail />,
    },
    {
        path: "/domain/update/:id",
        element: <ViewDomainUpdate />,
    },
    {
        path: "/domain/:method/:take/:page",
        element: <ViewDomainList />,
    },
    {
        path: "/domain",
        element: <ViewDomainList />,
    },
    {
        path: "/email/account/create/:emailId",
        element: <ViewEmailAccountCreate />,
    },
    {
        path: "/email/account/update/:emailAccountId",
        element: <ViewEmailAccountUpdate />,
    },
    {
        path: "/email/create/:userId",
        element: <ViewEmailCreate />,
    },
    {
        path: "/email/detail/:id",
        element: <ViewEmailDetail />,
    },
    {
        path: "/email/create",
        element: <ViewEmailCreate />,
    },
    {
        path: "/email/:method/:take/:page",
        element: <ViewEmailList />,
    },
    {
        path: "/email/update/:id",
        element: <ViewEmailUpdate />,
    },
    {
        path: "/email",
        element: <ViewEmailList />,
    },
    {
        path: "/hosting/create/:userId",
        element: <ViewHostingCreate />,
    },
    {
        path: "/hosting/create",
        element: <ViewHostingCreate />,
    },
    {
        path: "/hosting/detail/:id",
        element: <ViewHostingDetail />,
    },
    {
        path: "/hosting/update/:id",
        element: <ViewHostingUpdate />,
    },
    {
        path: "/hosting/:method/:take/:page",
        element: <ViewHostingList />,
    },
    {
        path: "/hosting",
        element: <ViewHostingList />,
    },
    {
        path: "/user/detail/:id",
        element: <ViewUserDetail />,
    },
    {
        path: "/user/payment/:id/:take/:page",
        element: <ViewUserPayment />,
    },
    {
        path: "/user/payment/:id",
        element: <ViewUserPayment />,
    },
    {
        path: "/user/product/:id",
        element: <ViewUserProduct />,
    },
    {
        path: "/user/update/info/:id",
        element: <ViewUserUpdateInfo />,
    },
    {
        path: "/user/:method/:take/:page",
        element: <ViewUserList />,
    },
    {
        path: "/user",
        element: <ViewUserList />,
    },
    {
        path: "/payment/detail/:paymentId",
        element: <ViewPaymentDetail />,
    },
    {
        path: "/payment/create/certificate/:certificateId",
        element: <ViewPaymentCreateCertificate />,
    },
    {
        path: "/payment/create/domain/:domainId",
        element: <ViewPaymentCreateDomain />,
    },
    {
        path: "/payment/create/email/:emailId",
        element: <ViewPaymentCreateEmail />,
    },
    {
        path: "/payment/create/etc",
        element: <ViewPaymentCreateEtc />,
    },
    {
        path: "/payment/imminent",
        element: <ViewPaymentImminent />,
    },
    {
        path: "/payment",
        element: <ViewPaymentList />,
    },
    {
        path: "/payment/create/hosting/:hostingId",
        element: <ViewPaymentCreateHosting />,
    },
    {
        path: "/payment/update/certificate/:paymentId",
        element: <ViewPaymentUpdateCertificate />,
    },
    {
        path: "/payment/update/domain/:paymentId",
        element: <ViewPaymentUpdateDomain />,
    },
    {
        path: "/payment/update/email/:paymentId",
        element: <ViewPaymentUpdateEmail />,
    },
    {
        path: "/payment/update/etc/:paymentId",
        element: <ViewPaymentUpdateEtc />,
    },
    {
        path: "/payment/update/hosting/:paymentId",
        element: <ViewPaymentUpdateHosting />,
    },
    {
        path: "/question/:method/:take/:page",
        element: <ViewQuestionList />,
    },
    {
        path: "/question",
        element: <ViewQuestionList />,
    },
    {
        path: "/request/create",
        element: <ViewRequestCreate />,
    },
    {
        path: "/request/:method/:take/:page",
        element: <ViewRequestList />,
    },
    {
        path: "/request",
        element: <ViewRequestList />,
    },
    {
        path: "/",
        element: <ViewIndex />,
    },
];

const adminRouter: RouteObject[] = [];

const commonRouter: RouteObject[] = [];
