import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
    QueryCountEmailAccountAdminQuery,
    QueryCountPaymentForEmailAdminQuery,
    QueryFindFirstEmailAdminQuery,
    QueryFindManyEmailAccountAdminQuery,
    QueryFindManyPaymentForEmailAdminQuery,
    useMutationDeleteEmailAccountAdminMutation,
    useMutationDeletePaymentAdminMutation,
    useQueryCountEmailAccountAdminQuery,
    useQueryCountPaymentForEmailAdminQuery,
    useQueryFindFirstEmailAdminQuery,
    useQueryFindManyEmailAccountAdminQuery,
    useQueryFindManyPaymentForEmailAdminQuery,
} from "../../../../graphql/operation";
import { SwalUtil } from "../../../../utils/swal/SwalUtil";
import { ErrorCatchUtil } from "../../../../utils/errorCatch/ErrorCatchUtil";
import { ErrorConstants } from "../../../../variables/ErrorConstants";
import { TitleBox } from "../../../components/titleBox/TitleBox";
import { NoDataBox } from "../../../components/noDataBox/NoDataBox";
import { LimitWidthContainer } from "../../../components/limitWidthContainer/LimitWidthContainer";
import { LeftUserContainer } from "../../../components/leftUserContainer/LeftUserContainer";
import { Button, IconButton, Tooltip } from "@mui/material";
import { InputLine } from "../../../components/inputLine/InputLine";
import { RegisteredCompanyText } from "../../../components/RegisteredCompanyText/RegisteredCompanyText";
import NumberFormat from "react-number-format";
import moment from "moment";
import { OnChangeNumber } from "../../../../types/ArgsInterface";
import { PaymentMethodText } from "../../../components/paymentMethodText/PaymentMethodText";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBookOpen, faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import { ViewPaginationBox } from "../../../components/paginationBox/ViewPagenationBox";
import { ProductActiveText } from "../../../components/productActiveText/ProductActiveText";

export const ViewEmailDetail = () => {
    const { id } = useParams();

    const initPagination = {
        take: 10,
        page: 1,
    };

    const [paginationPaymentData, setPaginationPaymentData] = useState(initPagination);
    const [paginationEmailAccountData, setPaginationEmailAccountData] = useState(initPagination);

    const {
        data: emailData,
        refetch: emailRefetch,
        error: emailError,
    } = useQueryFindFirstEmailAdminQuery({
        variables: {
            input: {
                id: Number(id),
            },
        },
    });

    const { data: paymentData, refetch: paymentRefetch } =
        useQueryFindManyPaymentForEmailAdminQuery({
            variables: {
                input: {
                    emailId: Number(id),
                },
            },
        });

    const { data: countPaymentData } = useQueryCountPaymentForEmailAdminQuery({
        variables: {
            input: {
                emailId: Number(id),
            },
        },
    });

    const { data: emailAccountData, refetch: emailAccountRefetch } =
        useQueryFindManyEmailAccountAdminQuery({
            variables: {
                input: {
                    emailId: Number(id),
                    ...paginationEmailAccountData,
                },
            },
        });

    const { data: countEmailAccountData } = useQueryCountEmailAccountAdminQuery({
        variables: {
            input: {
                emailId: Number(id),
            },
        },
    });

    const [deletePaymentAdminMutation] = useMutationDeletePaymentAdminMutation();
    const [deleteEmailAccountAdmin] = useMutationDeleteEmailAccountAdminMutation();

    const handler = {
        onChangePaymentPage: (page: number) => {
            setPaginationPaymentData({
                take: paginationPaymentData.take,
                page,
            });
        },
        onChangeEmailAccountPage: (page: number) => {
            setPaginationEmailAccountData({
                take: paginationEmailAccountData.take,
                page,
            });
        },
        onClickDeletePayment: (id: number) => {
            SwalUtil.yn({
                title: "정말 삭제하시겠습니까?",
                text: "복구가 불가능합니다.",
                icon: "warning",
            })
                .then(() => {
                    return deletePaymentAdminMutation({
                        variables: {
                            input: {
                                paymentId: id,
                            },
                        },
                    });
                })
                .then(() => {
                    return SwalUtil.ok({
                        title: "납부 정보가 삭제 되었습니다.",
                        icon: "success",
                    });
                })
                .then(() => {
                    return paymentRefetch();
                })
                .catch(
                    ErrorCatchUtil({
                        [ErrorConstants.notPermission]: () => {
                            return SwalUtil.ok({
                                title: "권한이 없습니다.",
                                icon: "error",
                            });
                        },
                        [ErrorConstants.default]: e => {
                            return SwalUtil.ok({
                                title: e,
                                icon: "error",
                            });
                        },
                    }),
                );
        },
        onDeleteEmailAccount: (emailAccountId: number) => {
            SwalUtil.yn({
                title: "정말 삭제하시겠습니까?",
                text: "복구가 불가능합니다.",
                icon: "warning",
            })
                .then(() => {
                    return deleteEmailAccountAdmin({
                        variables: {
                            input: {
                                emailAccountId: emailAccountId,
                            },
                        },
                    });
                })
                .then(() => {
                    return SwalUtil.ok({
                        title: "메일 계정이 삭제 되었습니다.",
                        icon: "success",
                    });
                })
                .then(() => {
                    return emailAccountRefetch();
                })
                .then(() => {
                    return emailRefetch();
                })
                .catch(
                    ErrorCatchUtil({
                        [ErrorConstants.notPermission]: () => {
                            return SwalUtil.ok({
                                title: "권한이 없습니다.",
                                icon: "error",
                            });
                        },
                        [ErrorConstants.default]: e => {
                            return SwalUtil.ok({
                                title: e,
                                icon: "error",
                            });
                        },
                    }),
                );
        },
    };

    return (
        <>
            <LimitWidthContainer>
                <TitleBox title="이메일 서비스" />
                {emailError && (
                    <div className="bg-lightPaper dark:bg-darkPaper p-5 rounded">
                        <NoDataBox />
                    </div>
                )}

                {emailData && (
                    <div className="w-full flex justify-between gap-10">
                        <div className="w-[300px]">
                            <div className="bg-lightPaper dark:bg-darkPaper p-5  rounded w-full">
                                <LeftUserContainer userId={emailData.findFirstEmailAdmin.userId} />
                            </div>
                        </div>
                        <div className="w-[900px]">
                            <div className="w-full bg-lightPaper dark:bg-darkPaper p-5 rounded">
                                <EmailDetailContainer emailId={Number(id)} emailData={emailData} />
                            </div>
                            <div className="mb-10" />
                            <div className="w-full bg-lightPaper dark:bg-darkPaper p-5 rounded">
                                <EmailAccountContainer
                                    emailAccountData={emailAccountData}
                                    countEmailAccountData={countEmailAccountData}
                                    emailId={Number(id)}
                                    onDeleteEmailAccount={handler.onDeleteEmailAccount}
                                />
                                {countEmailAccountData && (
                                    <>
                                        <div className="mb-10" />
                                        <ViewPaginationBox
                                            total={countEmailAccountData.countEmailAccountAdmin}
                                            size={paginationEmailAccountData.take}
                                            page={paginationEmailAccountData.page}
                                            onChange={handler.onChangeEmailAccountPage}
                                        />
                                    </>
                                )}
                            </div>
                            <div className="mb-10" />
                            <div className="w-full bg-lightPaper dark:bg-darkPaper p-5 rounded">
                                <EmailPaymentContainer
                                    paymentData={paymentData}
                                    countPaymentData={countPaymentData}
                                    emailId={Number(id)}
                                    onClickDeletePayment={handler.onClickDeletePayment}
                                />
                                {countPaymentData && (
                                    <>
                                        <div className="mb-10" />
                                        <ViewPaginationBox
                                            total={countPaymentData.countPaymentForEmailAdmin}
                                            size={paginationPaymentData.take}
                                            page={paginationPaymentData.page}
                                            onChange={handler.onChangePaymentPage}
                                        />
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                )}
            </LimitWidthContainer>
        </>
    );
};

const EmailDetailContainer = (props: {
    emailData: QueryFindFirstEmailAdminQuery | undefined;
    emailId: number;
}) => {
    const navigate = useNavigate();

    const handler = {
        onUpdate: () => {
            navigate(`/email/update/${props.emailId}`);
        },
    };

    return (
        <>
            {!props.emailData && <NoDataBox />}
            {props.emailData && (
                <>
                    <div className="w-full flex justify-between">
                        <div className="flex-1">
                            <TitleBox title={"상세정보"} notBottomMargin={true} />
                        </div>
                        <div className="flex items-center gap-5">
                            <Button variant="contained" color="primary" onClick={handler.onUpdate}>
                                정보수정
                            </Button>
                            {props.emailData.findFirstEmailAdmin.userId ? (
                                <Button variant="contained" color="warning">
                                    연결해제
                                </Button>
                            ) : (
                                <Button variant="contained" color="primary">
                                    회원연결
                                </Button>
                            )}
                        </div>
                    </div>
                    <InputLine title="도메인">
                        {props.emailData.findFirstEmailAdmin.domainUrl}
                    </InputLine>
                    <InputLine title="등록기관">
                        <RegisteredCompanyText
                            registeredCompany={
                                props.emailData.findFirstEmailAdmin.registeredCompany
                            }
                        />
                    </InputLine>
                    <InputLine title="계정 갯수">
                        {props.emailData.findFirstEmailAdmin.emailAccount.length} 개
                    </InputLine>
                    <InputLine title={"가격"}>
                        <NumberFormat
                            displayType="text"
                            thousandSeparator=","
                            suffix=" 원"
                            value={props.emailData.findFirstEmailAdmin.price}
                        />
                    </InputLine>
                    <InputLine title="시작일">
                        {props.emailData.findFirstEmailAdmin.createdAt
                            ? moment(props.emailData.findFirstEmailAdmin.createdAt)
                                  .locale("ko")
                                  .format("YYYY-MM-DD")
                            : "-"}
                    </InputLine>
                    <InputLine title="마지막 결제일">
                        {props.emailData.findFirstEmailAdmin.lastBillingDate
                            ? moment(props.emailData.findFirstEmailAdmin.lastBillingDate)
                                  .locale("ko")
                                  .format("YYYY-MM-DD")
                            : "-"}
                    </InputLine>
                    <InputLine title="다음 청구일">
                        {props.emailData.findFirstEmailAdmin.nextBillingDate
                            ? moment(props.emailData.findFirstEmailAdmin.nextBillingDate)
                                  .locale("ko")
                                  .format("YYYY-MM-DD")
                            : "-"}
                    </InputLine>
                    <InputLine title="상태">
                        <ProductActiveText
                            productActive={props.emailData.findFirstEmailAdmin.productActive}
                        />
                    </InputLine>
                    <div
                        className="w-full max-h-[100px] border p-5 overflow-y-auto"
                        dangerouslySetInnerHTML={{
                            __html: props.emailData.findFirstEmailAdmin.memo
                                ? props.emailData.findFirstEmailAdmin.memo
                                : "메모없음",
                        }}></div>
                </>
            )}
        </>
    );
};

const EmailAccountContainer = (props: {
    emailAccountData: QueryFindManyEmailAccountAdminQuery | undefined;
    countEmailAccountData: QueryCountEmailAccountAdminQuery | undefined;
    emailId: number;
    onDeleteEmailAccount: OnChangeNumber;
}) => {
    const navigate = useNavigate();

    return (
        <>
            <div className="w-full flex justify-between mb-5">
                <div className="flex-1">
                    <TitleBox
                        title={`계정목록 (총 ${
                            props.countEmailAccountData
                                ? props.countEmailAccountData.countEmailAccountAdmin
                                : "0"
                        }개)`}
                        notBottomMargin={true}
                    />
                </div>
                <div className="flex gap-5">
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            navigate(`/email/account/create/${props.emailId}`);
                        }}>
                        계정등록
                    </Button>
                </div>
            </div>
            <div className="bg-lightBackground dark:bg-darkBackground rounded text-sm h-[35px] items-center flex">
                <div className="w-[23%]">계정주소</div>
                <div className="w-[11%]">용량</div>
                <div className="w-[11%]">무료용량</div>
                <div className="w-[15%]">생성일자</div>
                <div className="w-[30%]">메모</div>
                <div className="w-[10%]">기능</div>
            </div>

            {props.emailAccountData?.findManyEmailAccountAdmin.map((value, index) => (
                <div key={index} className="w-full flex h-[45px] border-b items-center">
                    {value && (
                        <>
                            <div className="w-[23%]">
                                <Tooltip title={value.account}>
                                    <div className="w-full truncate">{value.account}</div>
                                </Tooltip>
                            </div>
                            <div className="w-[11%] pr-2 text-right">
                                <NumberFormat
                                    displayType="text"
                                    value={value.quota}
                                    thousandSeparator=","
                                />{" "}
                                MB
                            </div>
                            <div className="w-[11%] pr-2 text-right">
                                <NumberFormat
                                    displayType="text"
                                    value={value.freeQuota}
                                    thousandSeparator=","
                                />{" "}
                                MB
                            </div>
                            <div className="w-[15%]">
                                {moment(value.createdAt).locale("ko").format("YYYY-MM-DD")}
                            </div>
                            <div
                                className="w-[30%] truncate"
                                dangerouslySetInnerHTML={{
                                    __html: value.memo,
                                }}
                            />
                            <div className="w-[10%]">
                                <Tooltip title="수정" arrow>
                                    <IconButton
                                        color="primary"
                                        onClick={() => {
                                            navigate(`/email/account/update/${value.id}`);
                                        }}>
                                        <FontAwesomeIcon icon={faPen} size="sm" />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="삭제" arrow>
                                    <IconButton
                                        color="primary"
                                        onClick={() => {
                                            props.onDeleteEmailAccount(value.id);
                                        }}>
                                        <FontAwesomeIcon icon={faTrash} size="sm" />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </>
                    )}
                </div>
            ))}
        </>
    );
};

const EmailPaymentContainer = (props: {
    paymentData: QueryFindManyPaymentForEmailAdminQuery | undefined;
    countPaymentData: QueryCountPaymentForEmailAdminQuery | undefined;
    emailId: number;
    onClickDeletePayment: OnChangeNumber;
}) => {
    const navigate = useNavigate();

    return (
        <>
            <div className="w-full flex justify-between mb-5">
                <div className="flex-1">
                    <TitleBox title="납부목록" notBottomMargin={true} />
                </div>
                <div className="flex gap-5">
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            navigate(`/payment/create/email/${props.emailId}`);
                        }}>
                        납부등록
                    </Button>
                </div>
            </div>
            <div className="bg-lightBackground dark:bg-darkBackground rounded text-sm h-[35px] items-center flex">
                <div className="w-[20%]">상품금액</div>
                <div className="w-[12.5%]">결제일</div>
                <div className="w-[12.5%]">결제방법</div>
                <div className="w-[15%]">입금인</div>
                <div className="w-[25%]">메모</div>
                <div className="w-[15%]">기능</div>
            </div>
            {!props.paymentData && <NoDataBox />}
            {props.paymentData?.findManyPaymentForEmailAdmin.map((value, index) => (
                <div key={index} className="w-full flex h-[45px] border-b items-center">
                    {value && (
                        <>
                            <div className="w-[20%]">
                                <NumberFormat
                                    thousandSeparator=","
                                    suffix=" 원"
                                    displayType="text"
                                    value={value.price}
                                />
                            </div>
                            <div className="w-[12.5%]">
                                {moment(value.createdAt).locale("ko").format("YYYY-MM-DD")}
                            </div>
                            <div className="w-[12.5%]">
                                <PaymentMethodText paymentMethod={value.paymentMethod} />
                            </div>
                            <div className="w-[15%]">{value.depositor && value.depositor}</div>
                            <div
                                className="w-[25%] truncate"
                                dangerouslySetInnerHTML={{
                                    __html: value.memo,
                                }}
                            />
                            <div className="w-[15%] justify-between">
                                <Tooltip title="상세보기">
                                    <IconButton
                                        color="primary"
                                        onClick={() => {
                                            navigate(`/payment/detail/${value.id}`);
                                        }}>
                                        <FontAwesomeIcon icon={faBookOpen} size="sm" />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="수정" arrow>
                                    <IconButton
                                        color="primary"
                                        onClick={() => {
                                            navigate(`/payment/update/email/${value.id}`);
                                        }}>
                                        <FontAwesomeIcon icon={faPen} size="sm" />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="삭제" arrow>
                                    <IconButton
                                        color="primary"
                                        onClick={() => {
                                            props.onClickDeletePayment(value.id);
                                        }}>
                                        <FontAwesomeIcon icon={faTrash} size="sm" />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </>
                    )}
                </div>
            ))}
        </>
    );
};
