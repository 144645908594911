import React from "react";
import { RegisteredCompany } from "../../../graphql/operation";

export const RegisteredCompanyText = (props: { registeredCompany: RegisteredCompany }) => {
    let value = "";

    if (props.registeredCompany === RegisteredCompany.Cafe24) {
        value = "카페24";
    } else if (props.registeredCompany === RegisteredCompany.Gabia) {
        value = "가비아";
    } else if (props.registeredCompany === RegisteredCompany.A2Hosting) {
        value = "A2Hosting";
    } else if (props.registeredCompany === RegisteredCompany.Hostinger) {
        value = "Hostinger";
    } else if (props.registeredCompany === RegisteredCompany.GoDaddy) {
        value = "GoDaddy";
    } else if (props.registeredCompany === RegisteredCompany.Sectigo) {
        value = "Sectigo";
    } else if (props.registeredCompany === RegisteredCompany.HostingKr) {
        value = "호스팅KR";
    } else if (props.registeredCompany === RegisteredCompany.Etc) {
        value = "기타";
    } else {
        value = "골든넷";
    }

    return <>{value}</>;
};
