import { useReactiveVar } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { LayoutVars, SystemVars, UserVars } from "../variables/MakeVar";
import { RouteObject, useRoutes } from "react-router-dom";
import { GetRouter } from "../router/Router";
import { useQueryMeQuery } from "../graphql/operation";
import { Helmet } from "react-helmet-async";
import { createTheme, ThemeProvider } from "@mui/material";
import { ThemeUtil } from "../utils/theme/ThemeUtil";
import { ThemeSyncProvider } from "../provider/themeSync/ThemeSyncProvider";
import { ViewBackDrop } from "./components/backdrop/ViewBackDrop";
import { ViewLoading } from "./components/loading/ViewLoading";
import { ViewLayoutNotAuth } from "./layouts/notAuth/ViewLayoutNotAuth";
import { ViewLayoutAuth } from "./layouts/auth/ViewLayoutAuth";

export const App = () => {
    const isLoadingView = useReactiveVar(SystemVars.loadingView);
    const isLoggedIn = useReactiveVar(UserVars.isLoggedIn);
    const darkMode = useReactiveVar(LayoutVars.darkMode);
    const [router, setRouter] = useState<RouteObject[]>(GetRouter(isLoggedIn));
    const children = useRoutes(router);

    const { refetch: meRefetch, loading } = useQueryMeQuery({
        onCompleted: data => {
            if (data) {
                UserVars.userInfo({
                    emails: [],
                    company: undefined,
                    designs: [],
                    questions: [],
                    smss: [],
                    certificates: [],
                    domains: [],
                    hostings: [],
                    payments: [],
                    updateAt: [],
                    ...data.me,
                    password: "",
                });
                UserVars.isLoggedIn(true);
            }
        },
    });

    useEffect(() => {
        setRouter(GetRouter(isLoggedIn));
        meRefetch().then();
    }, [isLoggedIn]);

    return (
        <>
            <Helmet>
                <title>Goldennet Administration</title>
            </Helmet>
            <ThemeProvider
                theme={createTheme(darkMode === "dark" ? ThemeUtil.dark() : ThemeUtil.light())}>
                <ThemeSyncProvider initialTheme={darkMode}>
                    {!loading && (
                        <>
                            {isLoadingView && (
                                <ViewBackDrop>
                                    <ViewLoading />
                                </ViewBackDrop>
                            )}
                            {!isLoggedIn && <ViewLayoutNotAuth>{children}</ViewLayoutNotAuth>}
                            {isLoggedIn && <ViewLayoutAuth>{children}</ViewLayoutAuth>}
                        </>
                    )}
                </ThemeSyncProvider>
            </ThemeProvider>
        </>
    );
};
