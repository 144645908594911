import React from "react";
import { MenuItem, TextField } from "@mui/material";
import { HostingType } from "../../../graphql/operation";
import { OnChangeValue } from "../../../types/ArgsInterface";

export const HostingTypeSelect = (props: {
    hostingType: HostingType;
    onChangeState: OnChangeValue<HostingType>;
    readOnly?: boolean;
}) => {
    return (
        <>
            <TextField
                variant="outlined"
                label="호스팅 종류"
                color="primary"
                fullWidth
                select
                value={props.hostingType}
                size="small"
                onChange={value => {
                    let hostingType: HostingType;
                    if (value.target.value === "LinuxPro") {
                        hostingType = HostingType.LinuxPro;
                    } else if (value.target.value === "LinuxPremium") {
                        hostingType = HostingType.LinuxPremium;
                    } else if (value.target.value === "WindowsBasic") {
                        hostingType = HostingType.WindowsBasic;
                    } else if (value.target.value === HostingType.WindowsPro) {
                        hostingType = HostingType.WindowsPro;
                    } else if (value.target.value === HostingType.Server) {
                        hostingType = HostingType.Server;
                    } else if (value.target.value === HostingType.GoldenShop) {
                        hostingType = HostingType.GoldenShop;
                    } else {
                        hostingType = HostingType.LinuxBasic;
                    }
                    props.onChangeState(hostingType);
                }}>
                <MenuItem value={HostingType.LinuxBasic}>리눅스 베이직</MenuItem>
                <MenuItem value={HostingType.LinuxPro}>리눅스 프로</MenuItem>
                <MenuItem value={HostingType.LinuxPremium}>리눅스 프리미엄</MenuItem>
                <MenuItem value={HostingType.WindowsBasic}>윈도우 베이직</MenuItem>
                <MenuItem value={HostingType.WindowsPro}>윈도우 프로</MenuItem>
                <MenuItem value={HostingType.Server}>리눅스 서버</MenuItem>
                <MenuItem value={HostingType.GoldenShop}>골든샵</MenuItem>
            </TextField>
        </>
    );
};
