import React from "react";
import {Receipts} from "../../../graphql/operation";

export const ReceiptsText = (props: { receipts: Receipts }) => {
    let value = "";
    if (props.receipts === Receipts.Company) {
        value = "세금계산서";
    } else if (props.receipts === Receipts.Cash) {
        value = "현금영수증";
    } else {
        value = "신청없음";
    }

    return <>{value}</>;
};
