import React, { createContext, ReactNode, useEffect, useState } from "react";
import { PaletteMode } from "@mui/material";
import { LayoutVars } from "../../variables/MakeVar";

export type ThemeType = PaletteMode;
type ThemeContextType = {
    theme: ThemeType;
    setTheme: (name: ThemeType) => void;
};

const getInitialTheme = () => {
    if (localStorage.getItem("color-theme") === "dark") {
        return "dark";
    }
    return "light";
};

export const ThemeContext = createContext<ThemeContextType>({} as ThemeContextType);

export const ThemeSyncProvider = (props: { initialTheme?: ThemeType; children: ReactNode }) => {
    const [theme, setTheme] = useState<ThemeType>(getInitialTheme);

    const rawSetTheme = (rawTheme: ThemeType) => {
        const root = window.document.documentElement;
        const isDark = rawTheme === "dark";

        root.classList.remove(isDark ? "light" : "dark");
        root.classList.add(rawTheme);

        localStorage.setItem("color-theme", rawTheme);
    };

    if (props.initialTheme) {
        rawSetTheme(props.initialTheme);
    }

    useEffect(() => {
        rawSetTheme(theme);
        LayoutVars.darkMode(theme);
    }, [theme]);

    return (
        <ThemeContext.Provider value={{ theme, setTheme }}>{props.children}</ThemeContext.Provider>
    );
};
