import React, { useState } from "react";
import { LimitWidthContainer } from "../../../components/limitWidthContainer/LimitWidthContainer";
import { TitleBox } from "../../../components/titleBox/TitleBox";
import {
    CertificateType,
    DomainType,
    HostingType,
    PaymentMethod,
    Period,
    ProductActive,
    ProductType,
    QueryFindManyAndSummaryPaymentAdminQuery,
    Receipts,
    ReceiptsStatus,
    RegisteredCompany,
    useMutationDeletePaymentAdminMutation,
    useQueryFindManyAndSummaryPaymentAdminQuery,
    UserType,
} from "../../../../graphql/operation";
import { NumberFormatText } from "../../../components/numberFormatText/NumberFormatText";
import moment from "moment";
import { ProductTypeText } from "../../../components/productTypeText/ProductTypeText";
import { PaymentMethodText } from "../../../components/paymentMethodText/PaymentMethodText";
import { ReceiptsText } from "../../../components/receiptsText/ReceiptsText";
import { ReceiptsStatusText } from "../../../components/receiptsStatusText/ReceiptsStatusText";
import { Button, IconButton, TextField, Tooltip } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBookOpen, faPen, faServer, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { OnChangeNumber, OnVoidFunction } from "../../../../types/ArgsInterface";
import { SystemVars } from "../../../../variables/MakeVar";
import { SwalUtil } from "../../../../utils/swal/SwalUtil";
import { ErrorCatchUtil } from "../../../../utils/errorCatch/ErrorCatchUtil";
import { ErrorConstants } from "../../../../variables/ErrorConstants";
import { NoDataBox } from "../../../components/noDataBox/NoDataBox";

export const ViewPaymentList = () => {
    const [searchDate, setSearchDate] = useState<string | null>(
        moment(new Date()).startOf("month").utc().toISOString(),
    );
    const initDate = String(moment(new Date()).startOf("month").utc().toISOString());

    const { data, refetch } = useQueryFindManyAndSummaryPaymentAdminQuery({
        variables: {
            findManyPaymentAdmin: {
                searchDate: initDate,
            },
            summaryPaymentAdmin: {
                searchDate: initDate,
            },
        },
    });

    const [deletePaymentAdmin] = useMutationDeletePaymentAdminMutation();

    const handler = {
        onSearch: () => {
            refetch({
                findManyPaymentAdmin: {
                    searchDate: searchDate || moment().startOf("month").utc().toISOString(),
                },
                summaryPaymentAdmin: {
                    searchDate: searchDate || moment().startOf("month").utc().toISOString(),
                },
            })
                .then(() => {
                    SystemVars.loadingView(false);
                })
                .catch(e => {
                    console.log(e);
                });
        },
        onClickDeletePayment: (id: number) => {
            SwalUtil.yn({
                title: "정말 삭제하시겠습니까?",
                text: "복구가 불가능합니다.",
                icon: "warning",
            })
                .then(() => {
                    return deletePaymentAdmin({
                        variables: {
                            input: {
                                paymentId: id,
                            },
                        },
                    });
                })
                .then(() => {
                    return SwalUtil.ok({
                        title: "납부 정보가 삭제 되었습니다.",
                        icon: "success",
                    });
                })
                .then(() => {
                    return refetch({
                        findManyPaymentAdmin: {
                            searchDate: searchDate || moment().startOf("month").utc().toISOString(),
                        },
                        summaryPaymentAdmin: {
                            searchDate: searchDate || moment().startOf("month").utc().toISOString(),
                        },
                    });
                })
                .catch(
                    ErrorCatchUtil({
                        [ErrorConstants.notPermission]: () => {
                            return SwalUtil.ok({
                                title: "권한이 없습니다.",
                                icon: "error",
                            });
                        },
                        [ErrorConstants.default]: e => {
                            return SwalUtil.ok({
                                title: e,
                                icon: "error",
                            });
                        },
                    }),
                );
        },
    };

    return (
        <>
            <LimitWidthContainer>
                <TitleBox title={"입출금내역"} />
                <div className="bg-lightPaper dark:bg-darkPaper p-5">
                    {data && <SummaryContainer summaryData={data} />}
                    <div className="my-10" />
                    <PaymentSearchContainer
                        searchDate={searchDate}
                        setSearchDate={setSearchDate}
                        onSearch={handler.onSearch}
                    />
                    <div className="my-10" />
                    <div className="flex py-2 bg-lightBackground dark:bg-darkBackground">
                        <div className="w-[9%] flex justify-center">납부일자</div>
                        <div className="w-[9%] flex justify-center">상품종류</div>
                        <div className="w-[12%] flex justify-center">결제금액</div>
                        <div className="w-[10%] flex justify-center text-sm truncate">사용자</div>
                        <div className="w-[20%] flex justify-center text-sm truncate">도메인</div>
                        <div className="w-[9%] flex justify-center">결제방법</div>
                        <div className="w-[9%] flex justify-center">영수증신청</div>
                        <div className="w-[9%] flex justify-center">영수증발행</div>
                        <div className="w-[13%] flex justify-center">기능</div>
                    </div>
                    {data && data.findManyPaymentAdmin.length === 0 && <NoDataBox />}
                    {data &&
                        data.findManyPaymentAdmin.map((value, index) => (
                            <div key={index} className="flex py-3 border-b items-center">
                                {value && (
                                    <PaymentLine
                                        payment={value}
                                        onClickDeletePayment={handler.onClickDeletePayment}
                                    />
                                )}
                            </div>
                        ))}
                </div>
            </LimitWidthContainer>
        </>
    );
};

const SummaryContainer = (props: { summaryData: QueryFindManyAndSummaryPaymentAdminQuery }) => {
    return (
        <div className="bg-lightBackground dark:bg-darkBackground p-5">
            <div className="bg-lightPaper dark:bg-darkPaper flex py-2 border-b">
                <div className="w-[10%] flex justify-center">입금구분</div>
                <div className="w-[calc(90%/8)] flex justify-center">총 입금액</div>
                <div className="w-[calc(90%/8)] flex justify-center">도메인</div>
                <div className="w-[calc(90%/8)] flex justify-center">호스팅</div>
                <div className="w-[calc(90%/8)] flex justify-center">보안인증서</div>
                <div className="w-[calc(90%/8)] flex justify-center">디자인</div>
                <div className="w-[calc(90%/8)] flex justify-center">이메일</div>
                <div className="w-[calc(90%/8)] flex justify-center">SMS</div>
                <div className="w-[calc(90%/8)] flex justify-center">기타</div>
            </div>
            <div className="flex py-2 border-b">
                <div className="w-[10%] flex justify-center">전자결제</div>
                <div className="w-[calc(90%/8)] flex justify-end pr-2">
                    <NumberFormatText
                        value={props.summaryData.summaryPaymentAdmin.checkOut.total}
                    />
                </div>
                <div className="w-[calc(90%/8)] flex justify-end pr-2">
                    <NumberFormatText
                        value={props.summaryData.summaryPaymentAdmin.checkOut.domain}
                    />
                </div>
                <div className="w-[calc(90%/8)] flex justify-end pr-2">
                    <NumberFormatText
                        value={props.summaryData.summaryPaymentAdmin.checkOut.hosting}
                    />
                </div>
                <div className="w-[calc(90%/8)] flex justify-end pr-2">
                    <NumberFormatText
                        value={props.summaryData.summaryPaymentAdmin.checkOut.certificate}
                    />
                </div>
                <div className="w-[calc(90%/8)] flex justify-end pr-2">
                    <NumberFormatText
                        value={props.summaryData.summaryPaymentAdmin.checkOut.design}
                    />
                </div>
                <div className="w-[calc(90%/8)] flex justify-end pr-2">
                    <NumberFormatText
                        value={props.summaryData.summaryPaymentAdmin.checkOut.email}
                    />
                </div>
                <div className="w-[calc(90%/8)] flex justify-end pr-2">
                    <NumberFormatText value={props.summaryData.summaryPaymentAdmin.checkOut.sms} />
                </div>
                <div className="w-[calc(90%/8)] flex justify-end pr-2">
                    <NumberFormatText value={props.summaryData.summaryPaymentAdmin.checkOut.etc} />
                </div>
            </div>
            <div className="flex py-2 border-b">
                <div className="w-[10%] flex justify-center">무통장입금</div>
                <div className="w-[calc(90%/8)] flex justify-end pr-2">
                    <NumberFormatText value={props.summaryData.summaryPaymentAdmin.cash.total} />
                </div>
                <div className="w-[calc(90%/8)] flex justify-end pr-2">
                    <NumberFormatText value={props.summaryData.summaryPaymentAdmin.cash.domain} />
                </div>
                <div className="w-[calc(90%/8)] flex justify-end pr-2">
                    <NumberFormatText value={props.summaryData.summaryPaymentAdmin.cash.hosting} />
                </div>
                <div className="w-[calc(90%/8)] flex justify-end pr-2">
                    <NumberFormatText
                        value={props.summaryData.summaryPaymentAdmin.cash.certificate}
                    />
                </div>
                <div className="w-[calc(90%/8)] flex justify-end pr-2">
                    <NumberFormatText value={props.summaryData.summaryPaymentAdmin.cash.design} />
                </div>
                <div className="w-[calc(90%/8)] flex justify-end pr-2">
                    <NumberFormatText value={props.summaryData.summaryPaymentAdmin.cash.email} />
                </div>
                <div className="w-[calc(90%/8)] flex justify-end pr-2">
                    <NumberFormatText value={props.summaryData.summaryPaymentAdmin.cash.sms} />
                </div>
                <div className="w-[calc(90%/8)] flex justify-end pr-2">
                    <NumberFormatText value={props.summaryData.summaryPaymentAdmin.cash.etc} />
                </div>
            </div>
            <div className="flex py-2  border-b">
                <div className="w-[10%] flex justify-center">합계</div>
                <div className="w-[calc(90%/8)] flex justify-end pr-2">
                    <NumberFormatText value={props.summaryData.summaryPaymentAdmin.total.total} />
                </div>
                <div className="w-[calc(90%/8)] flex justify-end pr-2">
                    <NumberFormatText value={props.summaryData.summaryPaymentAdmin.total.domain} />
                </div>
                <div className="w-[calc(90%/8)] flex justify-end pr-2">
                    <NumberFormatText value={props.summaryData.summaryPaymentAdmin.total.hosting} />
                </div>
                <div className="w-[calc(90%/8)] flex justify-end pr-2">
                    <NumberFormatText
                        value={props.summaryData.summaryPaymentAdmin.total.certificate}
                    />
                </div>
                <div className="w-[calc(90%/8)] flex justify-end pr-2">
                    <NumberFormatText value={props.summaryData.summaryPaymentAdmin.total.design} />
                </div>
                <div className="w-[calc(90%/8)] flex justify-end pr-2">
                    <NumberFormatText value={props.summaryData.summaryPaymentAdmin.total.email} />
                </div>
                <div className="w-[calc(90%/8)] flex justify-end pr-2">
                    <NumberFormatText value={props.summaryData.summaryPaymentAdmin.total.sms} />
                </div>
                <div className="w-[calc(90%/8)] flex justify-end pr-2">
                    <NumberFormatText value={props.summaryData.summaryPaymentAdmin.total.etc} />
                </div>
            </div>
        </div>
    );
};

const PaymentSearchContainer = (props: {
    searchDate: string | null;
    setSearchDate: React.Dispatch<React.SetStateAction<string | null>>;
    onSearch: OnVoidFunction;
}) => {
    const navigate = useNavigate();

    return (
        <>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <div className="flex justify-between">
                    <div className="flex">
                        <MobileDatePicker
                            views={["year", "month"]}
                            label="검색월"
                            onChange={newValue => {
                                props.setSearchDate(moment(newValue).toISOString());
                            }}
                            value={props.searchDate}
                            renderInput={params => <TextField {...params} size="small" />}
                        />
                        <div className="mr-2" />
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                props.onSearch();
                            }}>
                            월 검색
                        </Button>
                    </div>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            navigate("/payment/create/etc");
                        }}>
                        기타 등록
                    </Button>
                </div>
            </LocalizationProvider>
        </>
    );
};

const PaymentLine = (props: { payment: PaymentLineType; onClickDeletePayment: OnChangeNumber }) => {
    const navigate = useNavigate();

    console.log(props.payment);

    let productId = 0;
    if (props.payment.domain) {
        productId = props.payment.domain.id;
    }
    if (props.payment.hosting) {
        productId = props.payment.hosting.id;
    }
    if (props.payment.certificate) {
        productId = props.payment.certificate.id;
    }
    if (props.payment.email) {
        productId = props.payment.email.id;
    }

    return (
        <>
            <div className="w-[9%] flex justify-center">
                {moment(props.payment.createdAt).locale("ko").format("YYYY-MM-DD")}
            </div>
            <div className="w-[9%] flex justify-center">
                <ProductTypeText productType={props.payment.type} />
            </div>
            <div className="w-[12%] flex justify-center">
                <NumberFormatText value={props.payment.price} />
            </div>
            <div
                className="w-[10%] flex justify-center text-sm truncate"
                onClick={() => {
                    navigate(`/user/detail/${props.payment.user?.id}`);
                }}>
                {props.payment.user?.username}
            </div>
            <div className="w-[20%] flex justify-center text-sm truncate">
                {props.payment.domainUrl}
            </div>
            <div className="w-[9%] flex justify-center">
                <PaymentMethodText paymentMethod={props.payment.paymentMethod} />
            </div>
            <div className="w-[9%] flex justify-center">
                <ReceiptsText receipts={props.payment.receipts} />
            </div>
            <div className="w-[9%] flex justify-center">
                <ReceiptsStatusText receiptsStatus={props.payment.receiptsStatus} />
            </div>
            <div className="w-[13%] flex flex-start">
                <Tooltip title="상세보기">
                    <IconButton
                        color="primary"
                        onClick={() => {
                            navigate(`/payment/detail/${props.payment.id}`);
                        }}>
                        <FontAwesomeIcon icon={faBookOpen} size="sm" />
                    </IconButton>
                </Tooltip>
                <Tooltip title="수정" arrow>
                    <IconButton
                        color="primary"
                        onClick={() => {
                            navigate(
                                `/payment/update/${props.payment.type.toLowerCase()}/${
                                    props.payment.id
                                }`,
                            );
                        }}>
                        <FontAwesomeIcon icon={faPen} size="sm" />
                    </IconButton>
                </Tooltip>
                <Tooltip title="삭제" arrow>
                    <IconButton
                        color="primary"
                        onClick={() => {
                            props.onClickDeletePayment(props.payment.id);
                        }}>
                        <FontAwesomeIcon icon={faTrash} size="sm" />
                    </IconButton>
                </Tooltip>
                {props.payment.type !== ProductType.Etc && (
                    <>
                        <div className="w-[1px] border-r" />
                        <Tooltip title="상품상세보기" arrow>
                            <IconButton
                                color="primary"
                                onClick={() => {
                                    navigate(
                                        `/${props.payment.type.toLowerCase()}/detail/${productId}`,
                                    );
                                }}>
                                <FontAwesomeIcon icon={faServer} size="sm" />
                            </IconButton>
                        </Tooltip>
                    </>
                )}
            </div>
        </>
    );
};

type PaymentLineType = {
    __typename?: "Payment";
    createdAt?: any | null;
    depositor?: string | null;
    domainUrl: string;
    id: number;
    memo: string;
    paymentMethod: PaymentMethod;
    price: number;
    type: ProductType;
    receipts: Receipts;
    receiptsStatus: ReceiptsStatus;
    phoneNumber?: string | null;
    domain?: {
        __typename?: "Domain";
        productActive: ProductActive;
        createdAt?: any | null;
        domainUrl: string;
        domainType: DomainType;
        id: number;
        lastBillingDate?: any | null;
        nextBillingDate?: any | null;
        period: Period;
        price: number;
        registeredCompany: RegisteredCompany;
        memo: string;
    } | null;
    hosting?: {
        __typename?: "Hosting";
        productActive: ProductActive;
        createdAt?: any | null;
        domainUrl: string;
        hostingType: HostingType;
        id: number;
        lastBillingDate?: any | null;
        nextBillingDate?: any | null;
        period: Period;
        price: number;
        registeredCompany: RegisteredCompany;
        memo: string;
    } | null;
    certificate?: {
        __typename?: "Certificate";
        domainUrl: string;
        createdAt?: any | null;
        id: number;
        type: CertificateType;
        period: Period;
        price: number;
        productActive: ProductActive;
        registeredCompany: RegisteredCompany;
        lastBillingDate?: any | null;
        nextBillingDate?: any | null;
        userId?: number | null;
        memo: string;
    } | null;
    email?: {
        __typename?: "Email";
        createdAt?: any | null;
        domainUrl: string;
        id: number;
        lastBillingDate?: any | null;
        memo: string;
        nextBillingDate?: any | null;
        period: Period;
        price: number;
        productActive: ProductActive;
        registeredCompany: RegisteredCompany;
        userId?: number | null;
    } | null;
    user?: {
        __typename?: "User";
        id: number;
        name: string;
        username: string;
        nickName: string;
        userType: UserType;
        email: string;
        phoneNumber: string;
        authLevel: number;
        active: boolean;
        applyReceiveMail: boolean;
        applyReceiveSMS: boolean;
        memo: string;
    } | null;
};
