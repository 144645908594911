import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, TextField, ToggleButton, ToggleButtonGroup } from "@mui/material";

export const SearchContainer = (props: {
    originSearchTerm: string | undefined;
    takeInput: number;
    pageInput: number;
    methodInput: string;
    productType: string;
}) => {
    const navigate = useNavigate();
    const { method } = useParams();

    let searchTerm = "";

    const handler = {
        onMethod: (value: string) => {
            navigate(`/${props.productType}/${value}/${props.takeInput}/1`, {
                state: { term: props.originSearchTerm },
            });
        },
        onSearch: (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            navigate(`/${props.productType}/${method}/${props.takeInput}/1`, {
                state: { term: searchTerm },
            });
        },
    };

    return (
        <>
            <div className="w-full flex justify-between items-center mb-5">
                <div className="flex items-center gap-5">
                    <ToggleButtonGroup color="primary" size={"small"} value={props.methodInput}>
                        <ToggleButton
                            className="w-[90px]"
                            value={"createdAt"}
                            onClick={(event, value) => {
                                handler.onMethod(value);
                            }}>
                            등록일자순
                        </ToggleButton>
                        <ToggleButton
                            className="w-[90px]"
                            value={"nextBillingDate"}
                            onClick={(event, value) => {
                                handler.onMethod(value);
                            }}>
                            다음결제일순
                        </ToggleButton>
                        <ToggleButton
                            className="w-[90px]"
                            value={"active"}
                            onClick={(event, value) => {
                                handler.onMethod(value);
                            }}>
                            활성화만
                        </ToggleButton>
                    </ToggleButtonGroup>
                    <form
                        onSubmit={event => {
                            handler.onSearch(event);
                        }}>
                        <TextField
                            variant="outlined"
                            color="primary"
                            type="text"
                            label="도메인 검색"
                            size="small"
                            defaultValue={props.originSearchTerm}
                            onChange={value => {
                                searchTerm = value.target.value;
                            }}
                        />
                    </form>
                </div>
                <div className="flex items-center">
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            navigate(`/${props.productType}/create`);
                        }}>
                        추가
                    </Button>
                </div>
            </div>
        </>
    );
};
