import React from "react";
import NumberFormat from "react-number-format";

export const NumberFormatText = (props: { value: number }) => {
    return (
        <>
            <NumberFormat
                displayType="text"
                thousandSeparator=","
                suffix=" 원"
                value={props.value}
            />
        </>
    );
};
