import React, { Fragment } from "react";
import { OnChangeNumber, OnChangeValue } from "../../../types/ArgsInterface";
import { useQuerySearchEmailAdminLazyQuery } from "../../../graphql/operation";
import { Dialog, DialogTitle, TextField } from "@mui/material";

export const SearchEmailModal = (props: {
    open: boolean;
    onClose: OnChangeNumber;
    onApplyEmail: OnChangeValue<ISearchEmail>;
}) => {
    const [searchEmailLazyQuery, { data: emailData, error: emailError }] =
        useQuerySearchEmailAdminLazyQuery();

    const handler = {
        onSearch: async (value: string) => {
            await searchEmailLazyQuery({
                variables: {
                    input: {
                        searchValue: value,
                    },
                },
            });
        },
    };

    return (
        <>
            <Dialog open={props.open} onClose={props.onClose}>
                <DialogTitle>이메일 상품 검색</DialogTitle>
                <div className="flex w-full w-[300px] flex-col px-5 lg:w-[600px]">
                    <div className="flex w-full justify-between">
                        <TextField
                            fullWidth
                            placeholder="도메인으로 검색"
                            onChange={async value => {
                                await handler.onSearch(value.currentTarget.value);
                            }}
                        />
                    </div>
                </div>
                <div className="h-[500px] overflow-y-auto overflow-x-hidden px-5">
                    {emailError && (
                        <div className="flex h-[500px] w-full items-center justify-center">
                            No Search Data.
                        </div>
                    )}

                    {emailData &&
                        emailData.searchEmailAdmin.map((value, index) => (
                            <Fragment key={index}>
                                {value && (
                                    <div
                                        className="flex-start flex items-center border-b py-3 pl-5"
                                        onClick={() => {
                                            props.onApplyEmail({
                                                emailId: value.id,
                                                emailDomainUrl: value.domainUrl,
                                            });
                                        }}>
                                        {value.domainUrl}
                                    </div>
                                )}
                            </Fragment>
                        ))}
                </div>
            </Dialog>
        </>
    );
};

export interface ISearchEmail {
    emailId: number;
    emailDomainUrl: string;
}
