import { useParams } from "react-router-dom";
import { LimitWidthContainer } from "../../../components/limitWidthContainer/LimitWidthContainer";
import { TitleBox } from "../../../components/titleBox/TitleBox";
import { LeftUserContainer } from "../../../components/leftUserContainer/LeftUserContainer";
import React from "react";
import {
    Exact,
    FindFirstUserAdminInput,
    QueryFindFirstUserAdminQuery,
    useQueryFindFirstUserAdminQuery,
} from "../../../../graphql/operation";
import { ViewUserProductCertificate } from "./certificate/ViewUserProductCertificate";
import { ApolloQueryResult } from "@apollo/client";
import { ViewUserProductDomain } from "./domain/ViewUserProductDomain";
import { ViewUserProductHosting } from "./hosting/ViewUserProductHosting";
import { ViewUserProductEmail } from "./email/ViewUserProductEmail";

export const ViewUserProduct = () => {
    const { id } = useParams();

    const {
        data: userData,
        loading: userLoading,
        error: userError,
        refetch: userRefetch,
    } = useQueryFindFirstUserAdminQuery({
        variables: {
            input: {
                userId: Number(id),
            },
        },
    });

    return (
        <>
            <LimitWidthContainer>
                <TitleBox title="회원" />
                {!userLoading && userError && (
                    <div className="bg-lightPaper dark:bg-darkPaper p-5 rounded">
                        <div className="w-full min-h-[300px]">데이터가 없습니다.</div>
                    </div>
                )}

                {!userLoading && userData && (
                    <div className="w-full flex justify-between gap-10">
                        <div className="w-[300px]">
                            <div className="bg-lightPaper dark:bg-darkPaper p-5  rounded w-full">
                                <LeftUserContainer userId={userData.findFirstUserAdmin.id} />
                            </div>
                        </div>
                        <div className="w-[900px]">
                            <UserProductContainer userData={userData} userRefetch={userRefetch} />
                        </div>
                    </div>
                )}
            </LimitWidthContainer>
        </>
    );
};

const UserProductContainer = (props: {
    userData: QueryFindFirstUserAdminQuery;
    userRefetch: (
        variables?: Partial<Exact<{ input: FindFirstUserAdminInput }>> | undefined,
    ) => Promise<ApolloQueryResult<QueryFindFirstUserAdminQuery>>;
}) => {
    return (
        <>
            <div className="w-full bg-lightPaper dark:bg-darkPaper p-5 rounded">
                <ViewUserProductDomain userData={props.userData} userRefetch={props.userRefetch} />
            </div>
            <div className="mb-10" />
            <div className="w-full bg-lightPaper dark:bg-darkPaper p-5 rounded">
                <ViewUserProductHosting userData={props.userData} userRefetch={props.userRefetch} />
            </div>
            <div className="mb-10" />
            <div className="w-full bg-lightPaper dark:bg-darkPaper p-5 rounded">
                <ViewUserProductCertificate
                    userData={props.userData}
                    userRefetch={props.userRefetch}
                />
            </div>
            <div className="mb-10" />
            <div className="w-full bg-lightPaper dark:bg-darkPaper p-5 rounded">
                <ViewUserProductEmail userData={props.userData} userRefetch={props.userRefetch} />
            </div>
            <div className="mb-10" />
        </>
    );
};
