import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestion, faUser } from "@fortawesome/free-solid-svg-icons";
import { Divider } from "@mui/material";
import {
    QueryFindFirstUserAdminQuery,
    useQueryFindFirstUserAdminQuery,
} from "../../../graphql/operation";
import { useNavigate } from "react-router-dom";

export const LeftUserContainer = (props: { userId: number | null | undefined }) => {
    const navigate = useNavigate();
    let userData: QueryFindFirstUserAdminQuery | undefined;
    let userLoading = true;

    if (props.userId) {
        ({ data: userData, loading: userLoading } = useQueryFindFirstUserAdminQuery({
            variables: {
                input: {
                    userId: props.userId,
                },
            },
        }));
    }

    const handler = {
        onUpdateInfo: () => {
            navigate(`/user/update/info/${props.userId}`);
        },
        onDetail: () => {
            navigate(`/user/detail/${props.userId}`);
        },
        onProduct: () => {
            navigate(`/user/product/${props.userId}`);
        },
        onPayment: () => {
            navigate(`/user/payment/${props.userId}`);
        },
    };

    return (
        <>
            {!props.userId && (
                <div className="w-full flex flex-col items-center">
                    <div className="w-[100px] h-[100px] rounded-full bg-lightBackground dark:bg-darkBackground flex justify-center items-center mb-5">
                        <FontAwesomeIcon icon={faQuestion} size="3x" />
                    </div>
                    <div className="text-xl text-center">(사용자없음)</div>
                </div>
            )}
            {!userLoading && (
                <div className="w-full flex flex-col items-center">
                    <div className="w-[100px] h-[100px] rounded-full bg-lightBackground dark:bg-darkBackground flex justify-center items-center mb-5">
                        <FontAwesomeIcon icon={faUser} size="3x" />
                    </div>
                    <div className="text-xl text-center">
                        {userData && (
                            <>
                                <div className="cursor-pointer" onClick={handler.onDetail}>
                                    {userData.findFirstUserAdmin.name}
                                </div>
                                <div className="cursor-pointer" onClick={handler.onDetail}>
                                    ({userData.findFirstUserAdmin.username})
                                </div>
                            </>
                        )}
                    </div>
                    <div className="mb-5" />
                    <Divider className="w-full" />
                    <div className="flex w-full p-3 cursor-pointer" onClick={handler.onUpdateInfo}>
                        회원정보 수정
                    </div>
                    <Divider className="w-full" />
                    <div className="flex w-full p-3">비밀번호 변경</div>
                    <Divider className="w-full" />
                    <div className="flex w-full p-3 cursor-pointer" onClick={handler.onProduct}>
                        상품 사용 현황
                    </div>
                    <Divider className="w-full" />
                    <div className="flex w-full p-3 cursor-pointer" onClick={handler.onPayment}>
                        납부 목록
                    </div>
                    <Divider className="w-full" />
                    <div className="flex w-full p-3">1:1 문의</div>
                </div>
            )}
        </>
    );
};
