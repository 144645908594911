import React from "react";
import { UserType } from "../../../graphql/operation";
import { MenuItem, TextField } from "@mui/material";
import { OnChangeValue } from "../../../types/ArgsInterface";

export const UserTypeSelect = (props: {
    userType: UserType;
    onChangeState: OnChangeValue<UserType>;
    readOnly?: boolean;
}) => {
    return (
        <>
            <TextField
                variant="outlined"
                label="회원 유형"
                color="primary"
                fullWidth
                select
                value={props.userType}
                size="small"
                onChange={value => {
                    let userType: UserType;
                    if (value.target.value === "Company") {
                        userType = UserType.Company;
                    } else {
                        userType = UserType.Personal;
                    }
                    props.onChangeState(userType);
                }}>
                <MenuItem value={UserType.Company}>사업자</MenuItem>
                <MenuItem value={UserType.Personal}>개인</MenuItem>
            </TextField>
        </>
    );
};
