import React, { ReactNode, useEffect } from "react";
import { LayoutVars } from "../../variables/MakeVar";

const updateIsMobile = (width: number) => {
    LayoutVars.isMobile(width < 992);
};

export const ResizeProvider = (props: { children: ReactNode }) => {
    useEffect(() => {
        updateIsMobile(window.innerWidth);
        const listener = () => {
            updateIsMobile(window.innerWidth);
        };
        window.addEventListener("resize", listener);
        return () => {
            window.removeEventListener("resize", listener);
        };
    }, [window.innerWidth]);

    return <>{props.children}</>;
};
