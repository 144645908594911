import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
    QueryFindManyPaymentForUserAdminQuery,
    useQueryCountPaymentForUserAdminQuery,
    useQueryFindFirstUserAdminQuery,
    useQueryFindManyPaymentForUserAdminQuery,
} from "../../../../graphql/operation";
import { LimitWidthContainer } from "../../../components/limitWidthContainer/LimitWidthContainer";
import { TitleBox } from "../../../components/titleBox/TitleBox";
import { LeftUserContainer } from "../../../components/leftUserContainer/LeftUserContainer";
import { NoDataBox } from "../../../components/noDataBox/NoDataBox";
import NumberFormat from "react-number-format";
import moment from "moment";
import { PaymentMethodText } from "../../../components/paymentMethodText/PaymentMethodText";
import { IconButton, Tooltip } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBookOpen } from "@fortawesome/free-solid-svg-icons";
import { ViewPaginationBox } from "../../../components/paginationBox/ViewPagenationBox";
import { ProductTypeText } from "../../../components/productTypeText/ProductTypeText";

export const ViewUserPayment = () => {
    const { id, take, page } = useParams();
    const navigate = useNavigate();

    let takeInput = 15;
    let pageInput = 1;

    if (take) {
        takeInput = Number(take);
    }

    if (page) {
        pageInput = Number(page);
    }

    const {
        data: userData,
        loading: userLoading,
        error: userError,
    } = useQueryFindFirstUserAdminQuery({
        variables: {
            input: {
                userId: Number(id),
            },
        },
    });

    const { data: paymentData } = useQueryFindManyPaymentForUserAdminQuery({
        variables: {
            input: {
                take: takeInput,
                page: pageInput,
                userId: Number(id),
            },
        },
    });
    const { data: countData } = useQueryCountPaymentForUserAdminQuery({
        variables: {
            input: {
                userId: Number(id),
            },
        },
    });

    const handler = {
        onChangePage: (page: number) => {
            navigate(`/user/payment/${id}/${takeInput}/${page}`);
        },
    };

    return (
        <>
            <LimitWidthContainer>
                <TitleBox title="회원" />
                {!userLoading && userError && (
                    <div className="bg-lightPaper dark:bg-darkPaper p-5 rounded">
                        <div className="w-full min-h-[300px]">데이터가 없습니다.</div>
                    </div>
                )}

                {!userLoading && userData && (
                    <div className="w-full flex justify-between gap-10">
                        <div className="w-[300px]">
                            <div className="bg-lightPaper dark:bg-darkPaper p-5  rounded w-full">
                                <LeftUserContainer userId={userData.findFirstUserAdmin.id} />
                            </div>
                        </div>
                        <div className="w-[900px]">
                            <div className="w-full bg-lightPaper dark:bg-darkPaper p-5 rounded">
                                <TitleBox title="납부 목록" />
                                <div className="bg-lightBackground dark:bg-darkBackground rounded text-sm h-[35px] items-center flex">
                                    <div className="w-[10%] flex justify-center">상품종류</div>
                                    <div className="w-[15%] flex justify-center truncate">
                                        도메인
                                    </div>
                                    <div className="w-[15%] flex justify-center">결제금액</div>
                                    <div className="w-[11%] flex justify-center">결제일</div>
                                    <div className="w-[10%] flex justify-center">결제방법</div>
                                    <div className="w-[13%] flex justify-center truncate">
                                        입금인
                                    </div>
                                    <div className="w-[20%] flex justify-center">메모</div>
                                    <div className="w-[6%] flex justify-center">기능</div>
                                </div>

                                <UserPaymentContainer paymentData={paymentData} />
                                <div className="my-10" />

                                {countData && (
                                    <ViewPaginationBox
                                        total={countData.countPaymentForUserAdmin}
                                        size={takeInput}
                                        page={pageInput}
                                        onChange={handler.onChangePage}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                )}
            </LimitWidthContainer>
        </>
    );
};

const UserPaymentContainer = (props: {
    paymentData: QueryFindManyPaymentForUserAdminQuery | undefined;
}) => {
    const navigate = useNavigate();

    return (
        <>
            {!props.paymentData && <NoDataBox />}
            {props.paymentData &&
                props.paymentData.findManyPaymentForUserAdmin.map((value, index) => (
                    <div key={index} className="w-full flex h-[45px] border-b items-center">
                        {value && (
                            <>
                                <div className="w-[10%] flex justify-center">
                                    <ProductTypeText productType={value.type} />
                                </div>
                                <div className="w-[15%] flex justify-center truncate">
                                    {value.domainUrl}
                                </div>
                                <div className="w-[15%] flex justify-end pr-4">
                                    <NumberFormat
                                        thousandSeparator=","
                                        suffix=" 원"
                                        displayType="text"
                                        value={value.price}
                                    />
                                </div>
                                <div className="w-[11%] flex justify-center">
                                    {moment(value.createdAt).locale("ko").format("YYYY-MM-DD")}
                                </div>
                                <div className="w-[10%] flex justify-center">
                                    <PaymentMethodText paymentMethod={value.paymentMethod} />
                                </div>
                                <div className="w-[13%] flex justify-center">
                                    {value.depositor && value.depositor}
                                </div>
                                <div
                                    className="w-[20%] truncate"
                                    dangerouslySetInnerHTML={{
                                        __html: value.memo,
                                    }}
                                />
                                <div className="w-[6%] justify-between">
                                    <Tooltip title="상세보기">
                                        <IconButton
                                            color="primary"
                                            onClick={() => {
                                                navigate(`/payment/detail/${value.id}`);
                                            }}>
                                            <FontAwesomeIcon icon={faBookOpen} size="sm" />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            </>
                        )}
                    </div>
                ))}
        </>
    );
};
